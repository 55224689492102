import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import { AxiosResponse } from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  // setEditMode,
  setLoading,
  setPreviewMode,
  setSectionInLesson,
  setTitle,
} from "../../../Redux/reducers/reflectionBuilderSlice";
import { educationRequest } from "../../../utils/education";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { StyledHeader } from "../../Common/styles/header";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const id = useQuery().get("id");

  const { sections, title } = useAppSelector(
    (state) => state.reflectionBuilder
  );

  const handleBack = () => {
    navigate(-1);
  };

  const handleTitleChange = (event: any) => {
    dispatch(setTitle(event.target.value));
  };

  // const handleMandatoryChange = (event: any) => {
  //   dispatch(setQuestionMandatory(event.target.checked));
  // };

  // const cancelEditMode = () => {
  //   dispatch(setEditMode(false));
  // };

  const submitHandler = async () => {
    try {
      if (!title.trim()) {
        toastMessage("warning", "Exercise name cannot be empty");
        return;
      }
      dispatch(setLoading(true));
      const sectionResult = educationRequest(sections);
      const imageSection = sectionResult.find(
        (section) => section.type === "picture"
      );

      let obj = {
        title: title,
        imageUrl: imageSection?.value,
        sections: sectionResult,
        // areQuestionsMandatory: questionsMandatory,
      };

      if (!obj.imageUrl) {
        delete obj.imageUrl;
      }
      if (id) {
        const res: AxiosResponse = await http.patch(
          `/self-reflection/${id}`,
          obj
        );
        toastMessage("success", res.data.message);
      } else {
        const res: AxiosResponse = await http.post(`/self-reflection`, obj);
        toastMessage("success", res.data.message);
      }
      navigate(-1);
    } catch (err) {
      dispatch(setLoading(false));
      errorToastMessage(err as Error);
    }
  };

  const showPreview = () => {
    try {
      if (!title.trim()) {
        toastMessage("warning", "Exercise name cannot be empty");
        return;
      }
      const sectionResult = educationRequest(sections);
      dispatch(
        setSectionInLesson({
          sections: sectionResult,
        })
      );
      dispatch(setPreviewMode(true));
    } catch (err) {
      errorToastMessage(err as Error);
    }
  };

  return (
    <StyledHeader>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ChevronLeft
          onClick={handleBack}
          sx={{ cursor: "pointer", height: 50, width: 50, mr: 1 }}
        />
        <TextField
          value={title}
          onChange={handleTitleChange}
          placeholder="Exercise name"
          sx={{ width: "300px" }}
        />
        {/* <FormControlLabel
          control={
            <Switch
              onChange={handleMandatoryChange}
              checked={questionsMandatory}
            />
          }
          label="Make questions Mandatory"
          labelPlacement="start"
        /> */}
      </Box>
      {/* {editMode && ( */}
      <Box sx={{ marginLeft: "auto", display: "flex", gap: 2 }}>
        {/* <Button onClick={cancelEditMode} variant="outlined" sx={{ mr: 1.5 }}>
          Back to Sections
        </Button> */}
        <Button onClick={showPreview} variant="contained">
          Preview
        </Button>
        <Button onClick={submitHandler} variant="contained">
          Save
        </Button>
      </Box>
      {/* )} */}
    </StyledHeader>
  );
};

export default Header;
