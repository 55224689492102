import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

export interface ParticipantsState {
  patientsUrlLoaded: boolean;
  myPatientsTab: string;
  patientName: string;

  journalType: string;
  journalPage: number;
  journalFilter: string;

  logsType: string;
  logsPage: number;
  logsStartDate: any;
  logsEndDate: any;
  logsFilter: string;

  statinsPage: number;
  statinsFilter: string;

  analyticsType: string;

  dairyEndDate: any;

  sortOrder: string;
  sortColumn: string;
}

const initialState: ParticipantsState = {
  patientsUrlLoaded: false,
  myPatientsTab: "profile",
  patientName: "",

  journalType: "selfReflection",
  journalPage: 0,
  journalFilter: "",

  logsType: "medication",
  logsPage: 0,
  logsStartDate: DateTime.now().minus({ days: 6 }).toISO(),
  logsEndDate: DateTime.now().toISO(),
  logsFilter: "",

  statinsPage: 0,
  statinsFilter: "",

  analyticsType: "active",

  dairyEndDate: DateTime.now().toISO(),

  sortOrder: "",
  sortColumn: "",
};

export const myPatientsSlice = createSlice({
  name: "myPatients",
  initialState: initialState,
  reducers: {
    setMyPatientsTab: (state, action: PayloadAction<any>) => {
      state.myPatientsTab = action.payload;
      state.journalType = "selfReflection";
      state.journalPage = 0;
      state.journalFilter = "";
      state.logsType = "medication";
      state.logsPage = 0;
      state.logsStartDate = DateTime.now().minus({ days: 6 }).toISO();
      state.logsEndDate = DateTime.now().toISO();
      state.logsFilter = "";
      state.statinsPage = 0;
      state.statinsFilter = "";
      state.dairyEndDate = DateTime.now().toISO();
      state.sortColumn = "";
      state.sortOrder = "";
    },
    setJournalTab: (state, action: PayloadAction<string>) => {
      state.journalType = action.payload;
      state.journalPage = 0;
      state.journalFilter = "";
      state.sortColumn = "";
      state.sortOrder = "";
    },
    setJournalPage: (state, action: PayloadAction<number>) => {
      state.journalPage = action.payload;
    },
    setJournalFilter: (state, action: PayloadAction<string>) => {
      state.journalFilter = action.payload;
      state.journalPage = 0;
    },
    setLogsTab: (state, action: PayloadAction<string>) => {
      state.logsType = action.payload;
      state.logsPage = 0;
      state.logsFilter = "";
      state.sortColumn = "";
      state.sortOrder = "";
    },
    setLogsPage: (state, action: PayloadAction<number>) => {
      state.logsPage = action.payload;
    },
    setLogsDate: (
      state,
      action: PayloadAction<{
        startDate?: any;
        endDate?: any;
      }>
    ) => {
      const { startDate, endDate } = action.payload;
      if (startDate) {
        state.logsStartDate = startDate;
      } else {
        state.logsEndDate = endDate;
      }
      state.logsPage = 0;
    },
    setLogsFilter: (state, action: PayloadAction<string>) => {
      state.logsFilter = action.payload;
      state.logsPage = 0;
    },
    setStatinsPage: (state, action: PayloadAction<number>) => {
      state.statinsPage = action.payload;
    },
    setStatinsFilter: (state, action: PayloadAction<string>) => {
      state.statinsFilter = action.payload;
      state.statinsPage = 0;
    },
    setAnalyticsTab: (state, action: PayloadAction<string>) => {
      state.analyticsType = action.payload;
    },
    setDairyDate: (state, action: PayloadAction<any>) => {
      state.dairyEndDate = action.payload;
    },
    setMyPatientDefaults: (
      state,
      action: PayloadAction<{
        tab: string;
        user: string;
        type: string;
        filter: string;
        page: number;
        startDate: any;
        endDate: any;
        sort: string;
        order: string;
      }>
    ) => {
      state.myPatientsTab = action.payload.tab;
      state.patientName = action.payload.user;
      state.sortColumn = action.payload.sort;
      state.sortOrder = action.payload.order;
      if (action.payload.tab === "journal") {
        state.journalType = action.payload.type;
        state.journalFilter = action.payload.filter;
        state.journalPage = action.payload.page;
      }
      if (action.payload.tab === "statins") {
        state.statinsFilter = action.payload.filter;
        state.statinsPage = action.payload.page;
      }
      if (action.payload.tab === "learn") {
        state.analyticsType = action.payload.type;
      }
      // if (action.payload.tab === "dairies") {
      //   state.dairyEndDate = action.payload.date;
      // }
      if (action.payload.tab === "logs") {
        state.logsType = action.payload.type;
        state.logsPage = action.payload.page;
        state.logsStartDate = action.payload.startDate;
        state.logsEndDate = action.payload.endDate;
        state.logsFilter = action.payload.filter;
      }
      state.patientsUrlLoaded = true;
    },
    setMyPatientUrlLoaded: (state, action: PayloadAction<boolean>) => {
      state.patientsUrlLoaded = action.payload;
    },
    setPatientName: (state, action: PayloadAction<string>) => {
      state.patientName = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<string>) => {
      state.sortOrder = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setMyPatientsTab,
  setMyPatientUrlLoaded,
  setPatientName,
  setMyPatientDefaults,
  setJournalTab,
  setJournalPage,
  setJournalFilter,
  setStatinsPage,
  setStatinsFilter,
  setAnalyticsTab,
  setDairyDate,
  setSortColumn,
  setSortOrder,
  setLogsTab,
  setLogsPage,
  setLogsDate,
  setLogsFilter,
  reset,
} = myPatientsSlice.actions;

export default myPatientsSlice.reducer;
