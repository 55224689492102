import { Breadcrumbs, Link, LinkProps, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { StyledHeader } from "../../../Common/styles/header";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../../../Common/UI/TabPanel";
import PatientJournal from "./PatientJournal/PatientJournal";
// import Journal from "../../../PatientDetails/FoodJournal/Journal";
import PatientStatins from "./PatientStatins";
import LearningAnalytics from "./LearningAnalytics/LearningAnalytics";
import SessionRecording from "../../../SessionRecording/SessionRecording";
import Chat from "./Chat/Chat";
import Profile from "./Profile/Profile";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import MyPatientsUrlLoader from "./MyPatientsUrlLoader";
import MypatientsUrlSetter from "./MypatientsUrlSetter";
import { useEffect } from "react";
import {
  setMyPatientUrlLoaded,
  setMyPatientsTab,
} from "../../../../Redux/reducers/myPatientsSlice";
import Logs from "./Logs/Logs";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

const ParticipantDetailsTab = () => {
  const { myPatientsTab, patientsUrlLoaded, patientName } = useAppSelector(
    (state) => state.myPatients
  );

  const dispatch = useAppDispatch();

  const handleChange = (_: any, newType: string) => {
    dispatch(setMyPatientsTab(newType));
  };

  useEffect(() => {
    return () => {
      dispatch(setMyPatientUrlLoaded(false));
    };
  }, [dispatch]);

  return patientsUrlLoaded ? (
    <>
      <StyledHeader>
        <Breadcrumbs
          sx={{
            ".MuiBreadcrumbs-separator": {
              fontSize: "20px",
            },
          }}
        >
          <LinkRouter
            underline="none"
            color="#111928"
            variant="h2"
            to={"/app/participants"}
          >
            My patients
          </LinkRouter>
          <Link
            underline="none"
            variant="subtitle2"
            fontWeight="medium"
            color="secondary"
          >
            {patientName || "Patient"}
          </Link>
        </Breadcrumbs>
      </StyledHeader>
      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={myPatientsTab} onChange={handleChange}>
          <StyledTab label="Profile" value="profile" {...a11yProps(0)} />
          <StyledTab label="Chat" value="chat" {...a11yProps(1)} />
          <StyledTab
            label="Patient journal"
            value="journal"
            {...a11yProps(2)}
          />
          <StyledTab label="Statins" value="statins" {...a11yProps(3)} />
          <StyledTab
            label="Session recording"
            value="session"
            {...a11yProps(4)}
          />
          {/* <StyledTab label="Dairies" value="dairies" {...a11yProps(5)} /> */}
          <StyledTab label="Logs" value="logs" {...a11yProps(5)} />
          <StyledTab label="App data" value="learn" {...a11yProps(6)} />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 146px)",
          overflow: "auto",
        }}
      >
        <MainTabPanel value={myPatientsTab} index={"profile"}>
          <Profile />
        </MainTabPanel>
        <MainTabPanel value={myPatientsTab} index={"chat"}>
          <Chat />
        </MainTabPanel>
        <MainTabPanel value={myPatientsTab} index={"journal"} noPadding>
          <PatientJournal />
        </MainTabPanel>
        <MainTabPanel value={myPatientsTab} index={"statins"}>
          <PatientStatins />
        </MainTabPanel>
        <MainTabPanel value={myPatientsTab} index={"session"}>
          <SessionRecording />
        </MainTabPanel>
        <MainTabPanel value={myPatientsTab} index={"logs"} noPadding>
          <Logs />
        </MainTabPanel>
        <MainTabPanel value={myPatientsTab} index={"learn"} noPadding>
          <LearningAnalytics />
        </MainTabPanel>
      </Box>
      <MypatientsUrlSetter />
    </>
  ) : (
    <MyPatientsUrlLoader />
  );
};

export default ParticipantDetailsTab;
