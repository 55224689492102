import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Divider,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { foodContainer, headerContainer, journalContainer } from "./style";
import MedicationJournal from "./MedicationJournal";
import { ExpandMore } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { errorToastMessage } from "../../../utils/toast";
import { CalendarIcon } from "../../Common/assets/FoodJournalIcons";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import Sleep from "./Sleep";
import Steps from "./Steps";
import Food from "./Food";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import Appointments from "./Appointments";
import { setDairyDate } from "../../../Redux/reducers/myPatientsSlice";

// const menuItems = [
//   {
//     label: "Food",
//     value: "food",
//   },
//   {
//     label: "Medication",
//     value: "med",
//   },
//   {
//     label: "Feet",
//     value: "feet",
//   },
// ];

// interface LinkRouterProps extends LinkProps {
//   to: string;
//   replace?: boolean;
// }

// function LinkRouter(props: LinkRouterProps) {
//   return <Link {...props} component={RouterLink as any} />;
// }

const Journal = () => {
  const { dairyEndDate } = useAppSelector((state) => state.myPatients);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [showCalendar, setShowCalendar] = useState(false);
  // const [endDate, setEndDate] = useState<any>(
  //   sessionStorage.getItem("end-date") &&
  //     DateTime.fromISO(sessionStorage.getItem("end-date") || "").isValid
  //     ? DateTime.fromISO(sessionStorage.getItem("end-date") || "")
  //     : DateTime.now()
  // );
  // const [filters, setFilters] = useState(
  //   searchParams.get("selected")?.split(",") || []
  // );
  // const [selectedOptions, setSelectedOptions] = useState<any>(filters);
  // const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);
  // const openFilter = Boolean(filterEl);
  const [expanded, setExpanded] = useState<string | false>(false);
  // const [showNotifModal, setShowNotifModal] = useState(false);
  // const { hasHealthCoachAccess } = useAppSelector((state) => state.user);

  // const location = useLocation();
  // const user = location.state?.user || "";

  // const [studyNumber] = useState(searchParams.get("user") || user);

  const { id } = useParams();

  // useEffect(() => {
  //   const params = new URLSearchParams();

  //   if (studyNumber) {
  //     params.set("user", studyNumber);
  //   }

  //   if (endDate) {
  //     params.set("date", endDate?.toFormat("dd-LL-yyyy"));
  //   }
  //   if (filters.length > 0) {
  //     params.set("selected", filters.join(","));
  //   }

  //   setSearchParams(params, {
  //     replace: true,
  //   });
  // }, [endDate, filters, setSearchParams, studyNumber]);

  useEffect(() => {
    const fetchJournalDetails = async () => {
      try {
        setLoading(true);
        let startDate = DateTime.fromISO(dairyEndDate)
          ?.minus({ day: 6 })
          .startOf("day");
        const res: AxiosResponse = await http.get(
          `/activity/all?userId=${id}&startDate=${startDate
            .toUTC()
            .toISO()}&endDate=${DateTime.fromISO(dairyEndDate)
            .endOf("day")
            .toUTC()
            .toISO()}`
        );
        const data = res.data.data;

        const values = Object.keys(data)
          .sort()
          .reverse()
          .map((key: any) => {
            return {
              ...data[key],
              date: DateTime.fromFormat(key || "", "yyyy-LL-dd").toFormat(
                "dd LLLL yyyy"
              ),
              day: DateTime.fromFormat(key || "", "yyyy-LL-dd").weekdayLong,
            };
          });
        setData(values);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchJournalDetails();
  }, [dairyEndDate, id]);

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  // const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setFilterEl(event?.currentTarget);
  // };

  // const handleFilter = (value: string) => {
  //   if (selectedOptions.includes(value)) {
  //     setSelectedOptions((prev: any) =>
  //       prev.filter((item: string) => item !== value)
  //     );
  //   } else {
  //     setSelectedOptions((prev: any) => [...prev, value]);
  //   }
  // };

  // const clearFilter = () => {
  //   setSelectedOptions([]);
  //   setFilters([]);
  //   setFilterEl(null);
  // };

  // const closeFilterMenu = () => {
  //   setFilterEl(null);
  //   if (
  //     filters.length !== selectedOptions.length ||
  //     JSON.stringify(filters.sort()) !== JSON.stringify(selectedOptions.sort())
  //   ) {
  //     setFilters(selectedOptions);
  //   }
  // };

  // const openNotifModal = () => {
  //   setShowNotifModal(true);
  // };

  // const closeNotifModal = () => {
  //   setShowNotifModal(false);
  // };

  const handleChangeDate = (newDate: any) => {
    // setEndDate(newDate);
    // sessionStorage.setItem("end-date", newDate);
    dispatch(setDairyDate(newDate.toISO()));
  };

  return (
    <>
      {!loading ? (
        <Box sx={journalContainer}>
          <Box sx={headerContainer}>
            {/* <Breadcrumbs>
              <LinkRouter
                underline="none"
                color="#111928"
                variant="h6"
                fontWeight="medium"
                to={"/app/participants"}
              >
                My Patients
              </LinkRouter>
              <LinkRouter
                underline="none"
                variant="body1"
                fontWeight="medium"
                color="#111928"
                to={`/app/participants/${id}`}
              >
                {studyNumber || ""}
              </LinkRouter>
              <Link
                underline="none"
                variant="body1"
                fontWeight="medium"
                color="#EB8900"
              >
                Journal
              </Link>
            </Breadcrumbs> */}
            <span style={{ marginLeft: "auto" }} />
            <Box sx={{ display: "flex", gap: 2, mr: 2, alignItems: "center" }}>
              {/* <Button
                startIcon={<FilterIcon />}
                variant="outlined"
                onClick={openFilterMenu}
              >
                Filter
              </Button> */}
              <Typography variant="body1" fontWeight={"medium"}>
                Start Date :{" "}
                <Typography component={"span"} fontWeight={"regular"}>
                  {DateTime.fromISO(dairyEndDate)
                    ?.minus({ day: 6 })
                    .toFormat("dd-LL-yyyy")}
                </Typography>
              </Typography>
              <Typography variant="body1" fontWeight={"medium"}>
                End Date :{" "}
                <Typography component={"span"} fontWeight={"regular"}>
                  {DateTime.fromISO(dairyEndDate)?.toFormat("dd-LL-yyyy")}
                </Typography>
              </Typography>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                value={DateTime.fromISO(dairyEndDate)}
                open={showCalendar}
                disableFuture
                onClose={() => setShowCalendar(false)}
                onChange={handleChangeDate}
                renderInput={({ inputRef }) => (
                  <IconButton
                    ref={inputRef}
                    onClick={() => setShowCalendar(true)}
                  >
                    <CalendarIcon />
                  </IconButton>
                )}
              />
              {/* {hasHealthCoachAccess && (
                <Button variant="contained" onClick={openNotifModal}>
                  Send Notification
                </Button>
              )} */}
            </Box>
          </Box>
          {data.length ? (
            data?.map((item: any, index: number) => {
              return (
                <Accordion
                  key={index}
                  TransitionProps={{ unmountOnExit: true }}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  sx={{
                    boxShadow: "none",
                    border: "1px solid #E5E7EB",
                    borderRadius: "8px !important",
                    mb: 2.5,
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <IconButton>
                        <ExpandMore sx={{ fontSize: 24 }} />
                      </IconButton>
                    }
                    sx={{
                      ".Mui-expanded": {
                        m: "12px 0px !important",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        py: 1.5,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          color={
                            expanded !== `panel${index}`
                              ? "#111928"
                              : "secondary.main"
                          }
                          mb={0.5}
                        >
                          {item?.date}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          fontWeight="regular"
                          color={
                            expanded !== `panel${index}`
                              ? "#6B7280"
                              : "secondary.main"
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          {item?.day}
                        </Typography>
                      </Box>
                      {/* <Button
                      startIcon={<Edit />}
                      variant="outlined"
                      onClick={(e) => e.stopPropagation()}
                      sx={{ mr: 1 }}
                    >
                      Edit Logs
                    </Button> */}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <Divider />
                    <Food food={item?.food || []} />
                    <Divider />
                    <Box sx={foodContainer}>
                      {/* <FootJournal feet={item?.feet?.[0]} /> */}
                      <MedicationJournal meds={item?.medication || []} />
                    </Box>

                    <Divider />
                    <Box
                      sx={{
                        display: "flex",

                        gap: "10px",
                        alignItems: "flex-start",
                      }}
                    >
                      <Sleep data={item?.sleep?.[0]} />
                      <Divider orientation="vertical" flexItem />
                      <Steps data={item?.step?.[0]} />
                    </Box>
                    <Divider />
                    <Appointments data={item?.appointment || []} />
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
              <Typography
                variant="subtitle1"
                fontWeight="regular"
                color={"gray"}
              >
                No Data found for this date range
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* <Menu
        anchorEl={filterEl}
        open={openFilter}
        onClose={closeFilterMenu}
        PaperProps={{
          style: {
            width: "300px",
          },
        }}
      >
        {menuItems?.map((item: any) => (
          <MenuItem key={item?.value} onClick={() => handleFilter(item?.value)}>
            <ListItemIcon>
              <Checkbox checked={selectedOptions.includes(item?.value)} />
            </ListItemIcon>
            <ListItemText>{item?.label}</ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",

            justifyContent: "space-evenly",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button variant="text" onClick={clearFilter}>
            Reset Filters
          </Button>
          <Button variant="contained" onClick={closeFilterMenu}>
            Show Results
          </Button>
        </Box>
      </Menu> */}
      {/* {showNotifModal && (
        <NotificationModal
          userId={id}
          closeModal={closeNotifModal}
          showModal={showNotifModal}
        />
      )} */}
    </>
  );
};

export default Journal;
