import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  // ListItemText,
  // Menu,
  // MenuItem,
  Typography,
} from "@mui/material";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import { DateTime } from "luxon";
import { errorToastMessage } from "../../utils/toast";
import { CalendarPicker } from "@mui/x-date-pickers";
import {
  ApptCard,
  ApptContainer,
  CalendarPickerWrapper,
  CalendarScreensWrapper,
  CalendarWrapper,
  DisApptCard,
  RowContainer,
} from "./style";
import { LoadingContainer } from "../CMS/cms.style";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import RescheduleModal from "./RescheduleModal";
import DeleteIcon from "@mui/icons-material/Delete";

const MyCalendar = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState<DateTime>(
    searchParams.get("date") &&
      DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy").isValid
      ? DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy")
      : DateTime.now()
  );
  const [data, setData] = useState<any[]>([]);
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  const [row, setRow] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);

  // const openMenu = (event: React.MouseEvent<HTMLElement>, row: any) => {
  //   event.stopPropagation();
  //   setRow(row);
  //   setAnchorEl(event.currentTarget);
  // };
  // const closeMenu = () => {
  //   setAnchorEl(null);
  //   setRow(null);
  // };

  const closeModal = () => {
    setShowModal(false);
    setRow(null);
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (date) {
      params.set("date", date?.toFormat("dd-LL-yyyy"));
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, date]);

  const RefreshPage = () => {
    setRefreshPage((prev) => !prev);
    // setAnchorEl(null);
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url = `/consultation?startDate=${date
          ?.startOf("day")
          .toUTC()
          .toISO()}&endDate=${date?.endOf("day").toUTC().toISO()}`;
        const res: AxiosResponse = await http.get(url);
        const resData = res.data?.data;
        const newData = resData
          ?.map((patient: any) => ({
            id: patient?.id,
            patientId: patient?.userId,
            startTime: DateTime.fromISO(patient?.scheduledAt).toFormat(
              "hh:mm a"
            ),
            endTime: DateTime.fromISO(patient?.scheduledEndTime).toFormat(
              "hh:mm a"
            ),
            unformattedEndTime: DateTime.fromISO(patient?.scheduledEndTime),
            unformattedStartTime: DateTime.fromISO(patient?.scheduledAt),
            name: patient?.user?.code || "",
            status: patient?.status,
          }))
          .sort((a: any, b: any) => {
            return (
              (DateTime.fromFormat(a?.startTime || "", "hh:mm a") as any) -
              (DateTime.fromFormat(b?.startTime || "", "hh:mm a") as any)
            );
          });

        setData(newData);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [date, refreshPage]);

  // const reschedule = () => {
  //   navigate(
  //     `/app/my-calendar/reschedule/${row.id}?date=${date}&user=${row.name}`
  //   );
  // };

  const handleClick = (appt: any) => {
    if (
      appt?.unformattedEndTime >= DateTime.local() &&
      appt?.status === "pending"
    ) {
      navigate(
        `/app/participants/my-patients/${appt?.patientId}?tab=profile&user=${appt?.name}`
      );
    }
  };

  const handleNavigate = (route: string) => {
    navigate(`/app/my-calendar/${route}`);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 0 }}>
          <Typography fontSize={30} fontWeight="bold">
            My calendar
          </Typography>
        </Box>
        <Box sx={HeaderRightContent}>
          <Button
            variant="contained"
            onClick={() => handleNavigate("my-availability")}
          >
            My availability
          </Button>
          <Button
            variant="contained"
            onClick={() => handleNavigate("assign-slots")}
          >
            Assign slots
          </Button>
        </Box>
      </StyledHeader>
      <Box sx={CalendarScreensWrapper}>
        <Box sx={CalendarWrapper}>
          <Box sx={RowContainer}>
            <Box sx={{ flex: 1 }}>
              <Typography fontWeight="medium" variant="h6" sx={{ mb: "50px" }}>
                Date
              </Typography>
              <Box sx={CalendarPickerWrapper}>
                <CalendarPicker
                  date={date}
                  onChange={(newDate: any) => {
                    setDate(newDate);
                  }}
                  views={["day"]}
                  className="calendar-view"
                />
              </Box>
            </Box>
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                mt: "80px",
                // pl: "10%",
                borderColor: "#D7D7D7",
              }}
            />
            <Box sx={{ ...ApptContainer, mt: "20px", maxHeight: "60vh" }}>
              {!loading ? (
                data?.length !== 0 ? (
                  data?.map((appt) => {
                    return (
                      <Box
                        sx={
                          appt?.unformattedEndTime >= DateTime.local() &&
                          appt?.status === "pending"
                            ? { ...ApptCard, paddingRight: 1 }
                            : DisApptCard
                        }
                        key={appt?.id}
                        onClick={() => {
                          handleClick(appt);
                        }}
                      >
                        <Box></Box>
                        <Typography
                          sx={{
                            fontSize: {
                              md: 16,
                              lg: 24,
                            },
                          }}
                          fontWeight="medium"
                        >
                          {appt?.startTime} - {appt?.endTime} {appt?.name}
                        </Typography>
                        <Box>
                          {appt?.unformattedEndTime >= DateTime.local() && (
                            <Tooltip title="cancel slot">
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setRow(appt);
                                  setShowModal(true);
                                }}
                                sx={{
                                  cursor: "pointer",
                                }}
                              >
                                <DeleteIcon
                                  color="error"
                                  sx={{ fontSize: "24px" }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      height: "100%",
                      width: "100%",
                      paddingLeft: "40%",
                    }}
                  >
                    <Typography variant="subtitle2" color="gray">
                      No data available
                    </Typography>
                  </Box>
                )
              ) : (
                <Box sx={{ ...LoadingContainer, flex: 1 }}>
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>

            {/* <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={closeMenu}
              PaperProps={{
                style: {
                  minWidth: "150px",
                },
              }}
            >
              <MenuItem onClick={reschedule}>
                <ListItemText
                  sx={{
                    color: "#111928",
                  }}
                >
                  Re-schedule
                </ListItemText>
              </MenuItem>
              <Divider />

              <MenuItem
              // onClick={deleteSlot}
              // disabled={delLoader}
              >
                {/* {delLoader ? (
                    <CircularProgress size={24} />
                  ) : (
                    <> */}

            {/* <ListItemText
                  sx={{
                    color: "#F05252",
                  }}
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Cancel time slot
                </ListItemText>
                {/* </>
                  )} */}
            {/* </MenuItem>
            </Menu>  */}
          </Box>
        </Box>
        {showModal && (
          <RescheduleModal
            showModal={showModal}
            closeModal={closeModal}
            id={row.id}
            reschedule={false}
            refreshPage={RefreshPage}
          />
        )}
      </Box>
    </>
  );
};

export default MyCalendar;
