import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  addResource,
  deleteResource,
  handleResourceChange,
} from "../../../../Redux/reducers/reflectionBuilderSlice";
import {
  CMSInputWrapper,
  CMSInputLabel,
  resourceWrapper,
  ArrayIconButtonStyle,
} from "../styles";

type Props = {
  section: any;
  index: number;
};

const ResourceItem: React.FC<Props> = ({ section, index }) => {
  const dispatch = useDispatch();

  const deleteItem = (deleteIndex: number) => {
    dispatch(
      deleteResource({
        resourceIndex: deleteIndex,
        sectionIndex: index,
      })
    );
  };

  const addItem = () => {
    dispatch(
      addResource({
        sectionIndex: index,
      })
    );
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string,
    resourceIndex: number
  ) => {
    dispatch(
      handleResourceChange({
        resourceIndex: resourceIndex,
        sectionIndex: index,
        type,
        value: event.target.value,
      })
    );
  };

  return (
    <>
      <Typography variant="h6" mb={2} fontWeight={"medium"}>
        Resources
      </Typography>
      {section.resources.map((resource: any, resourceIndex: any) => {
        return (
          <Box key={resource.key} sx={resourceWrapper}>
            <FormControl sx={CMSInputWrapper}>
              <FormLabel sx={CMSInputLabel}>Resource Label</FormLabel>
              <TextField
                fullWidth
                value={resource.label}
                onChange={(event) => {
                  handleChange(event, "label", resourceIndex);
                }}
                placeholder="Resource name"
              />
            </FormControl>
            <FormControl sx={CMSInputWrapper}>
              <FormLabel sx={CMSInputLabel}>Resource Link</FormLabel>
              <TextField
                fullWidth
                value={resource.value}
                onChange={(event) => {
                  handleChange(event, "value", resourceIndex);
                }}
                placeholder="Resource link"
              />
            </FormControl>
            <IconButton
              onClick={() => deleteItem(resourceIndex)}
              sx={ArrayIconButtonStyle}
              color="error"
              disabled={section.resources.length < 2}
            >
              <Delete />
            </IconButton>
          </Box>
        );
      })}
      <Button onClick={addItem} variant="contained" sx={{ mb: 2 }}>
        Add Resource
      </Button>
    </>
  );
};

export default ResourceItem;
