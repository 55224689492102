import { useCallback, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import http from "../../utils/http";
import { useDropzone } from "react-dropzone";
import { ImageUploadIcon } from "../CMS/Icons";
import { UploadWrapper } from "../CMS/cms.style";
import { uploadFile } from "../../utils/upload";

const UploadItem: React.FC<any> = ({ image, setFieldValue }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const url = await uploadFile(file, "questionnaire_image");
          setFieldValue("imageUrl", url);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...UploadWrapper, mt: 0, mb: 2.5 }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const schema = yup.object().shape({
  firstName: yup.string().required("*First name is required"),
  lastName: yup.string().required("*Last name is required"),
  about: yup.string().required("*About is required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: any;
  isCoach: boolean;
  refreshPage: () => void;
};

const EditProfile = ({
  showModal,
  closeModal,
  data,
  isCoach,
  refreshPage,
}: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      setSubmitLoader(true);
      let body = {
        imageUrl: values?.imageUrl,
        about: values?.about,
        firstName: values?.firstName,
        lastName: values?.lastName,
      };
      let url = "";
      if (isCoach) {
        url = `/coach/profile`;
      } else {
        url = "clinical-assistant/profile";
      }
      const res: AxiosResponse = await http.patch(url, body);
      toastMessage("success", res.data?.message);
      closeModal();
      refreshPage();
      setSubmitLoader(false);
    } catch (error) {
      errorToastMessage(error as Error);
      setSubmitLoader(false);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title="Edit Profile" onCloseClick={closeModal} />
        {/* {type === "health_coach" && (
          <Box
            sx={{
              backgroundColor: "rgba(222, 141, 51, 0.10)",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#DE8D33",
              borderRadius: "6px",
              width: "80px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Image sx={{ color: "#DE8D33", fontSize: "40px" }}></Image>
          </Box>
        )} */}
        <Formik
          initialValues={{
            firstName: data?.firstName || "",
            lastName: data?.lastName || "",
            imageUrl: data?.imageUrl || "",
            about: data?.about || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ errors, touched, getFieldProps, values, setFieldValue }) => (
            <Form>
              <Box sx={{ display: "flex", gap: 1.5 }}>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel sx={LabelStyle} htmlFor="firstName">
                    First name
                  </FormLabel>
                  <TextField
                    id="firstName"
                    placeholder="First name"
                    error={
                      touched?.firstName && errors?.firstName ? true : false
                    }
                    helperText={
                      touched?.firstName && errors?.firstName
                        ? (errors?.firstName as string)
                        : " "
                    }
                    {...getFieldProps("firstName")}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel sx={LabelStyle} htmlFor="lastName">
                    Last name
                  </FormLabel>
                  <TextField
                    id="lastName"
                    placeholder="Last name"
                    error={touched?.lastName && errors?.lastName ? true : false}
                    helperText={
                      touched?.lastName && errors?.lastName
                        ? (errors?.lastName as string)
                        : " "
                    }
                    {...getFieldProps("lastName")}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="imageUrl">
                    Profile picture
                  </FormLabel>
                  <UploadItem
                    image={values?.imageUrl}
                    setFieldValue={setFieldValue}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="about">
                    About
                  </FormLabel>
                  <TextField
                    id="about"
                    placeholder="Type something..."
                    fullWidth
                    multiline
                    minRows={4}
                    error={touched?.about && errors?.about ? true : false}
                    helperText={
                      touched?.about && errors?.about
                        ? (errors?.about as string)
                        : " "
                    }
                    {...getFieldProps("about")}
                  />
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default EditProfile;
