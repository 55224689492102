import { MicOff } from "@mui/icons-material";
import { Box } from "@mui/material";
import { Session } from "@opentok/client";
import React, { useEffect, useRef, useState } from "react";
import { PublisherIconWrapper } from "../../../style";

const Subscriber: React.FC<any> = ({
  session,
  stream,
}: {
  session: Session;
  stream: any;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [mic, setMic] = useState<boolean | null>(stream?.hasAudio);

  useEffect(() => {
    if (ref.current)
      session.subscribe(stream, ref.current, {
        showControls: false,
        height: "100%",
        width: "100%",
        fitMode: "contain",
      });
  }, [session, stream]);

  useEffect(() => {
    function handleStreamPropertyChange(event: any) {
      if (
        event.stream.connection.connectionId ===
        session?.connection?.connectionId
      ) {
        return;
      }
      if (event.changedProperty === "hasAudio") {
        if (event.newValue) {
          setMic(true);
        } else {
          setMic(false);
        }
      }
    }

    session.on("streamPropertyChanged", handleStreamPropertyChange);
    return () => {
      session.off("streamPropertyChanged", handleStreamPropertyChange);
    };
  }, [session]);

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      {stream && !mic && (
        <Box
          sx={{
            ...PublisherIconWrapper,
            position: "absolute",
            top: 20,
            left: 20,
            zIndex: 999,
          }}
        >
          <MicOff htmlColor="#fff" />
        </Box>
      )}
      <Box id="ot-subscriber" ref={ref} sx={{ height: "100%" }} />
    </Box>
  );
};

export default React.memo(Subscriber);
