import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { debounce } from "lodash";
import { AxiosResponse } from "axios";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { DateTime } from "luxon";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { langMap } from "../../utils/lang";
import { Ethnicity, Gender } from "../../utils/notification";
import { useAppSelector } from "../../Redux/hooks";

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  body: yup.string().required("Body is required"),
  scheduledOnDate: yup
    .string()
    .required("Schedule date is required")
    .typeError("Schedule date is required"),
  scheduledOnTime: yup
    .string()
    .required("Schedule time is required")
    .typeError("Schedule time is required"),
  bot: yup.object().when("entity", {
    is: (val: string) => val === "bot",
    then: (schema) => schema.required("*Bot is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  faq: yup.object().when("entity", {
    is: (val: string) => val === "faq_bot",
    then: (schema) => schema.required("*FAQ is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  educationLesson: yup.object().when("entity", {
    is: (val: string) => val === "lesson",
    then: (schema) => schema.required("*Lesson is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  users: yup.array().when("assignToAllPatients", {
    is: (val: boolean) => !val,
    then: (schema) => schema.min(1, "*Please select atleast 1 user"),
    otherwise: (schema) => schema.min(0),
  }),
});

const userTarget = ["Patient", "Health Coach"];

const CreateNotificationModal: React.FC<any> = ({
  showModal,
  notification,
  closeModal,
  refreshPage,
}: any) => {
  const [lessons, setLessons] = useState<any[]>([]);
  const [bots, setBots] = useState<any[]>([]);
  const [faqs, setFaqs] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const { hasAdminAccess, hasHealthCoachAccess, userId } = useAppSelector(
    (state) => state.user
  );

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string, lang: string) => {
        try {
          if (value) {
            setLoading(true);
            const res: AxiosResponse = await http.get(
              `/lms/lessons?page=1&size=20&search=${value}&status=active&lang=${lang}`
            );
            const newLessons = res.data?.data?.educationLessons.map(
              (lesson: any) => {
                return {
                  id: lesson?.id,
                  name: lesson?.name,
                  lang: langMap[lesson?.lang] || "English",
                };
              }
            );
            setLessons(newLessons);
            setLoading(false);
          } else {
            setLessons([]);
          }
        } catch (err) {
          setLoading(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    []
  );

  const handleBotSearch = useMemo(
    () =>
      debounce(async (value: string, lang: string, type: string) => {
        try {
          if (value) {
            setLoading(true);
            const res: AxiosResponse = await http.get(
              `/bots?page=1&size=15&status=active&search=${value}&type=${type}&lang=${lang}`
            );
            const newBots = res.data.data.bots.map((bot: any) => {
              return {
                id: bot?.id,
                name: bot?.name,
              };
            });
            if (type === "general") {
              setBots(newBots);
            } else {
              setFaqs(newBots);
            }
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    []
  );

  const handleUserSearch = useMemo(
    () =>
      debounce(async (value: string, lang: string) => {
        try {
          setSearchLoader(true);
          let url;
          let newIds = [];
          if (hasHealthCoachAccess) {
            url = `/coach/participant?doctorId=${userId}`;
          } else {
            url = `/participants?page=1&size=15&lang=${lang}&status=active`;
          }
          if (value) {
            url += `&search=${value}`;
          }
          const res: AxiosResponse = await http.get(url);
          if (hasHealthCoachAccess) {
            newIds = res.data?.data?.map((user: any) => {
              return {
                id: user?.participant?.id,
                name: user?.participant?.code,
              };
            });
          } else {
            newIds = res.data.data.users.map((user: any) => {
              return {
                id: user?.id,
                name: user?.code,
              };
            });
          }
          setUserList(newIds);
          setSearchLoader(false);
        } catch (err) {
          setSearchLoader(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    [userId, hasHealthCoachAccess]
  );

  useEffect(() => {
    handleUserSearch("", "en");
  }, [handleUserSearch]);

  const submitHandler = async (values: any) => {
    try {
      const hour = values.scheduledOnTime.get("hour");
      const minute = values.scheduledOnTime.get("minute");
      const dateTime = values.scheduledOnDate.set({
        hour,
        minute,
      });

      if (dateTime < DateTime.now()) {
        toastMessage("warning", "The schedule time cannot be in the past");
        return;
      }

      let body = null;

      if (hasAdminAccess) {
        body = {
          title: values.title,
          body: values.body,
          scheduledOn: dateTime?.toUTC()?.toISO(),
          botId:
            values?.type === 2
              ? null
              : values.bot?.id || values.faq?.id || null,
          educationLessonId:
            values?.type === 2 ? null : values.educationLesson?.id || null,
          type: values?.showUserId ? 1 : values.type,
          lang: "en",
          userIds:
            values?.users?.length > 0 && values?.showUserId
              ? values.users.map((item: any) => item.id)
              : [],
          filters: {
            gender:
              values?.showUserId || values?.type === 2
                ? null
                : values.gender || null,
            ethnicity:
              values?.showUserId || values?.type === 2
                ? []
                : values?.ethnicity || [],
          },
          entity: values?.entity || "general",
          createTemplate: !notification?.assign,
        };
      } else {
        body = {
          title: values.title,
          body: values.body,
          scheduledOn: dateTime?.toUTC()?.toISO(),
          userIds:
            values?.users?.length > 0
              ? values.users.map((item: any) => item.id)
              : [],
          entity: "general",
          type: 1,
          createTemplate: !notification?.assign,
        };
      }

      setButtonLoader(true);
      let res: AxiosResponse;
      if (notification?.id) {
        body.createTemplate = false;
        res = await http.put(
          `/notifications/broadcast/${notification?.id}`,
          body
        );
      } else {
        res = await http.post("/notifications/broadcast", body);
      }
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  useEffect(() => {
    if (notification?.educationLesson) {
      setLessons([
        {
          id: notification?.educationLesson?.id,
          name: notification?.educationLesson?.name,
          lang: notification?.educationLesson?.lang,
        },
      ]);
    }
    if (notification?.bot) {
      setBots([{ id: notification?.bot?.id, name: notification?.bot?.name }]);
    }
    if (notification?.faq) {
      setFaqs([{ id: notification?.faq?.id, name: notification?.faq?.name }]);
    }
    if (notification?.users?.length > 0) {
      setUserList([...(notification.users as any[])]);
    }
  }, [setBots, setLessons, setFaqs, setUserList, notification]);

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title={""} onCloseClick={closeModal} />
        <Formik
          initialValues={{
            title: notification?.title || "",
            body: notification?.body || "",
            scheduledOnDate: notification?.scheduledOn
              ? DateTime.fromISO(notification?.scheduledOn)
              : null,
            scheduledOnTime: notification?.scheduledOn
              ? DateTime.fromISO(notification?.scheduledOn)
              : null,
            entity: notification?.entity || "general",
            //Assets
            educationLesson: notification?.educationLesson || null,
            bot: notification?.bot || null,
            faq: notification?.faq || null,
            // Filters
            type: notification?.type || 1,
            lang: notification?.lang || "en",
            gender: notification?.gender || "",
            ethnicity: notification?.ethnicity || [],
            showUserId:
              (notification && notification?.users?.length > 0) || false,
            users: notification?.users || [],
            //only for coach
            assignToAllPatients: hasHealthCoachAccess
              ? (notification && notification?.users?.length === 0) || false
              : true,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ values, errors, touched, setFieldValue, getFieldProps }: any) => (
            <Form>
              <FormControl
                sx={InputWrapper}
                disabled={notification && notification?.assign}
              >
                <FormLabel sx={LabelStyle} htmlFor="title">
                  Title
                </FormLabel>
                <TextField
                  id="title"
                  disabled={notification && notification?.assign}
                  placeholder="Title"
                  error={touched?.title && errors?.title ? true : false}
                  helperText={
                    touched?.title && errors?.title ? errors?.title : " "
                  }
                  {...getFieldProps("title")}
                />
              </FormControl>
              <FormControl
                sx={InputWrapper}
                disabled={notification && notification?.assign}
              >
                <FormLabel sx={LabelStyle} htmlFor="body">
                  Body
                </FormLabel>
                <TextField
                  id="body"
                  disabled={notification && notification?.assign}
                  placeholder="Body"
                  error={touched?.body && errors?.body ? true : false}
                  helperText={
                    touched?.body && errors?.body ? errors?.body : " "
                  }
                  {...getFieldProps("body")}
                />
              </FormControl>
              {/*hasAdminAccess && (
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="lang">
                    Language
                  </FormLabel>
                  <Select
                    fullWidth
                    id="language"
                    sx={{ mb: 2 }}
                    value={values.lang}
                    onChange={(e) => {
                      setFieldValue("lang", e.target.value);
                      setFieldValue("educationLesson", null);
                      setFieldValue("bot", null);
                      setFieldValue("faq", null);
                    }}
                  >
                    {LANGUAGES.map((lang) => (
                      <MenuItem key={lang.value} value={lang.value}>
                        {lang.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )*/}
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="">
                  Scheduled time
                </FormLabel>
                <Box sx={{ display: "flex", gap: 1.5 }}>
                  <DatePicker
                    disablePast
                    inputFormat="dd/MM/yyyy"
                    value={
                      values?.scheduledOnDate ? values?.scheduledOnDate : null
                    }
                    onChange={(newValue: any) => {
                      setFieldValue("scheduledOnDate", newValue);
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                          placeholder: "Select date",
                        }}
                        error={
                          touched?.scheduledOnDate && errors?.scheduledOnDate
                            ? true
                            : false
                        }
                        helperText={
                          touched?.scheduledOnDate && errors?.scheduledOnDate
                            ? errors?.scheduledOnDate
                            : " "
                        }
                      />
                    )}
                  />
                  <TimePicker
                    inputFormat="hh:mm a"
                    value={
                      values?.scheduledOnTime ? values?.scheduledOnTime : null
                    }
                    onChange={(newValue: any) =>
                      setFieldValue("scheduledOnTime", newValue)
                    }
                    minutesStep={30}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                          placeholder: "Select time",
                        }}
                        error={
                          touched?.scheduledOnTime && errors?.scheduledOnTime
                            ? true
                            : false
                        }
                        helperText={
                          touched?.scheduledOnTime && errors?.scheduledOnTime
                            ? errors?.scheduledOnTime
                            : " "
                        }
                      />
                    )}
                  />
                </Box>
              </FormControl>
              {hasAdminAccess && (
                <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                  <FormControlLabel
                    label="Assign to user ID's"
                    control={
                      <Checkbox
                        checked={values?.showUserId}
                        onChange={(event) => {
                          setFieldValue("showUserId", event.target.checked);
                          if (event.target.checked) {
                            setFieldValue("assignToAllPatients", false);
                          } else {
                            setFieldValue("assignToAllPatients", true);
                          }
                        }}
                      />
                    }
                  />
                </FormControl>
              )}

              {values?.showUserId || hasHealthCoachAccess ? (
                <FormControl
                  sx={InputWrapper}
                  disabled={values?.assignToAllPatients}
                >
                  <FormLabel sx={LabelStyle}>Assign to</FormLabel>
                  <Autocomplete
                    multiple
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input")
                        handleUserSearch(value, values.lang);
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("users", value);
                    }}
                    options={userList}
                    disabled={values?.assignToAllPatients}
                    forcePopupIcon={!values?.assignToAllPatients}
                    getOptionLabel={(option) => option?.name}
                    loading={searchLoader}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    value={values?.users || null}
                    isOptionEqualToValue={(option, value) => {
                      return option?.id === value?.id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          values?.assignToAllPatients
                            ? "All patients"
                            : "Search users by typing..."
                        }
                        error={
                          touched?.users &&
                          errors?.users &&
                          !values?.assignToAllPatients
                            ? true
                            : false
                        }
                        helperText={
                          touched?.users &&
                          errors?.users &&
                          !values?.assignToAllPatients
                            ? (errors?.users as string)
                            : " "
                        }
                      />
                    )}
                  />
                </FormControl>
              ) : (
                <>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle}>Assign to</FormLabel>
                    <Select
                      id="type"
                      fullWidth
                      sx={{ mb: 2 }}
                      value={values?.type}
                      onChange={(e) => {
                        setFieldValue("type", e.target.value);
                        setFieldValue("entity", "general");
                      }}
                    >
                      {userTarget.map((item, index) => (
                        <MenuItem key={item} value={index + 1}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {values.type === 1 && (
                    <>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle} htmlFor="gender">
                          Gender
                        </FormLabel>
                        <Select
                          fullWidth
                          id="gender"
                          sx={{ mb: 2 }}
                          value={values.gender}
                          onChange={(e) => {
                            setFieldValue("gender", e.target.value);
                          }}
                        >
                          <MenuItem key="" value="">
                            No Filter
                          </MenuItem>
                          {Gender.map((gender) => (
                            <MenuItem key={gender.value} value={gender.value}>
                              {gender.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle} htmlFor="ethnicity">
                          Ethnicity
                        </FormLabel>
                        <Select
                          fullWidth
                          multiple
                          id="ethnicity"
                          value={values.ethnicity}
                          onChange={(e) => {
                            setFieldValue("ethnicity", e.target.value);
                          }}
                        >
                          {Ethnicity.map((lang) => (
                            <MenuItem key={lang.value} value={lang.value}>
                              {lang.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </>
              )}
              {hasHealthCoachAccess && (
                <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                  <FormControlLabel
                    label="Assign to all"
                    control={
                      <Checkbox
                        checked={values?.assignToAllPatients}
                        onChange={(event) => {
                          setFieldValue(
                            "assignToAllPatients",
                            event.target.checked
                          );
                          if (event.target.checked) {
                            setFieldValue("users", []);
                            setUserList([]);
                          }
                        }}
                      />
                    }
                  />
                </FormControl>
              )}
              {hasAdminAccess && values.type === 1 && (
                <>
                  <FormControl sx={{ ...InputWrapper, mt: 2, width: "auto" }}>
                    <FormControlLabel
                      label="Link lesson"
                      control={
                        <Checkbox
                          checked={values?.entity === "lesson"}
                          onChange={(event) => {
                            setFieldValue("bot", null);
                            setFieldValue("faq", null);
                            if (!event.target.checked) {
                              setFieldValue("entity", "general");
                              setFieldValue("educationLesson", null);
                            } else {
                              setFieldValue("entity", "lesson");
                            }
                          }}
                        />
                      }
                    />
                  </FormControl>
                  {values?.entity === "lesson" && (
                    <FormControl sx={{ ...InputWrapper, marginY: 1 }}>
                      <FormLabel sx={LabelStyle}>Education Module</FormLabel>
                      <Autocomplete
                        filterOptions={(x) => x}
                        onInputChange={(
                          _1: any,
                          value: any,
                          reason: string
                        ) => {
                          if (reason === "input")
                            handleSearch(value, values.lang);
                        }}
                        onChange={(_1: any, value: any) => {
                          setFieldValue("educationLesson", value);
                        }}
                        isOptionEqualToValue={(option, value) => {
                          return option?.id === value?.id;
                        }}
                        clearOnBlur={true}
                        disableClearable
                        options={lessons}
                        getOptionLabel={(option) => option?.name}
                        value={values?.educationLesson}
                        loading={loading}
                        loadingText={<CircularProgress size={20} />}
                        noOptionsText="No Results"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search Module by typing..."
                          />
                        )}
                      />
                      <FormHelperText
                        error={
                          touched?.educationLesson && errors?.educationLesson
                            ? true
                            : false
                        }
                      >
                        {touched?.educationLesson && errors?.educationLesson
                          ? (errors?.educationLesson as string)
                          : " "}
                      </FormHelperText>
                    </FormControl>
                  )}
                  <div>
                    <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                      <FormControlLabel
                        label="Link bot"
                        control={
                          <Checkbox
                            checked={values?.entity === "bot"}
                            onChange={(event) => {
                              setFieldValue("faq", null);
                              setFieldValue("educationLesson", null);
                              if (!event.target.checked) {
                                setFieldValue("entity", "general");
                                setFieldValue("bot", null);
                              } else {
                                setFieldValue("entity", "bot");
                              }
                            }}
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  {values?.entity === "bot" && (
                    <FormControl sx={{ ...InputWrapper, marginY: 1 }}>
                      <FormLabel sx={LabelStyle}>Chat bot</FormLabel>
                      <Autocomplete
                        filterOptions={(x) => x}
                        onInputChange={(
                          _1: any,
                          value: any,
                          reason: string
                        ) => {
                          if (reason === "input")
                            handleBotSearch(value, values.lang, "general");
                        }}
                        onChange={(_1: any, value: any) => {
                          setFieldValue("bot", value);
                        }}
                        options={bots}
                        getOptionLabel={(option) => option?.name}
                        loading={loading}
                        loadingText={<CircularProgress size={20} />}
                        noOptionsText="No Results"
                        value={values?.bot || null}
                        isOptionEqualToValue={(option, value) => {
                          return option?.id === value?.id;
                        }}
                        clearOnBlur={true}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search Chat bot by typing..."
                          />
                        )}
                      />
                      <FormHelperText
                        error={touched?.bot && errors?.bot ? true : false}
                      >
                        {touched?.bot && errors?.bot
                          ? (errors?.bot as string)
                          : " "}
                      </FormHelperText>
                    </FormControl>
                  )}
                  <div>
                    <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                      <FormControlLabel
                        label="Link FAQ"
                        control={
                          <Checkbox
                            checked={values?.entity === "faq_bot"}
                            onChange={(event) => {
                              setFieldValue("educationLesson", null);
                              setFieldValue("bot", null);
                              if (!event.target.checked) {
                                setFieldValue("entity", "general");
                                setFieldValue("faq", null);
                              } else {
                                setFieldValue("entity", "faq_bot");
                              }
                            }}
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  {values?.entity === "faq_bot" && (
                    <FormControl sx={{ ...InputWrapper, marginY: 1 }}>
                      <FormLabel sx={LabelStyle}>FAQ</FormLabel>
                      <Autocomplete
                        filterOptions={(x) => x}
                        onInputChange={(
                          _1: any,
                          value: any,
                          reason: string
                        ) => {
                          if (reason === "input")
                            handleBotSearch(value, values.lang, "faq");
                        }}
                        onChange={(_1: any, value: any) => {
                          setFieldValue("faq", value);
                        }}
                        options={faqs}
                        getOptionLabel={(option) => option?.name}
                        loading={loading}
                        loadingText={<CircularProgress size={20} />}
                        noOptionsText="No Results"
                        value={values?.faq || null}
                        isOptionEqualToValue={(option, value) => {
                          return option?.id === value?.id;
                        }}
                        clearOnBlur={true}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search FAQ by typing..."
                          />
                        )}
                      />
                      <FormHelperText
                        error={touched?.faq && errors?.faq ? true : false}
                      >
                        {touched?.faq && errors?.faq
                          ? (errors?.faq as string)
                          : " "}
                      </FormHelperText>
                    </FormControl>
                  )}
                  {/* <div>
                    <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                      <FormControlLabel
                        label="Link Feet Diary"
                        control={
                          <Checkbox
                            checked={values?.entity === "feet_diary"}
                            onChange={(event) => {
                              setFieldValue("faq", null);
                              setFieldValue("educationLesson", null);
                              setFieldValue("bot", null);
                              if (!event.target.checked) {
                                setFieldValue("entity", "general");
                              } else {
                                setFieldValue("entity", "feet_diary");
                              }
                            }}
                          />
                        }
                      />
                    </FormControl>
                  </div> */}
                  <div>
                    <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                      <FormControlLabel
                        label="Link food diary"
                        control={
                          <Checkbox
                            checked={values?.entity === "food_diary"}
                            onChange={(event) => {
                              setFieldValue("faq", null);
                              setFieldValue("educationLesson", null);
                              setFieldValue("bot", null);
                              if (!event.target.checked) {
                                setFieldValue("entity", "general");
                              } else {
                                setFieldValue("entity", "food_diary");
                              }
                            }}
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                      <FormControlLabel
                        label="Link medication"
                        control={
                          <Checkbox
                            checked={values?.entity === "medication_diary"}
                            onChange={(event) => {
                              setFieldValue("faq", null);
                              setFieldValue("educationLesson", null);
                              setFieldValue("bot", null);
                              if (!event.target.checked) {
                                setFieldValue("entity", "general");
                              } else {
                                setFieldValue("entity", "medication_diary");
                              }
                            }}
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                      <FormControlLabel
                        label="Link self-reflection"
                        control={
                          <Checkbox
                            checked={values?.entity === "self_reflection"}
                            onChange={(event) => {
                              setFieldValue("faq", null);
                              setFieldValue("educationLesson", null);
                              setFieldValue("bot", null);
                              if (!event.target.checked) {
                                setFieldValue("entity", "general");
                              } else {
                                setFieldValue("entity", "self_reflection");
                              }
                            }}
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                      <FormControlLabel
                        label="Link appointment"
                        control={
                          <Checkbox
                            checked={values?.entity === "appointment"}
                            onChange={(event) => {
                              setFieldValue("faq", null);
                              setFieldValue("educationLesson", null);
                              setFieldValue("bot", null);
                              if (!event.target.checked) {
                                setFieldValue("entity", "general");
                              } else {
                                setFieldValue("entity", "appointment");
                              }
                            }}
                          />
                        }
                      />
                    </FormControl>
                  </div>
                </>
              )}
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                {!buttonLoader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default CreateNotificationModal;
