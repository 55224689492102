import { Box } from "@mui/material";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../../Common/UI/TabPanel";
import ParticipantList from "./ParticipantList";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { setActiveParticipantsType } from "../../../Redux/reducers/participantsSlice";

const PtpActiveTabs = () => {
  const { activePtpType } = useAppSelector((state) => state.participants);
  const dispatch = useAppDispatch();

  const handleChange = (_: any, newType: string) => {
    dispatch(setActiveParticipantsType(newType));
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={activePtpType} onChange={handleChange}>
          <StyledTab
            label="Assigned Participants"
            value="assigned"
            {...a11yProps(0)}
          />
          <StyledTab
            label="Unassigned Participants"
            value="unassigned"
            {...a11yProps(1)}
          />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 282px)",
          overflow: "auto",
        }}
      >
        <MainTabPanel value={activePtpType} index={activePtpType}>
          <ParticipantList
            adminActivePtpView={true}
            hasAssignCoachAccess={activePtpType === "unassigned"}
          />
        </MainTabPanel>
      </Box>
    </>
  );
};

export default PtpActiveTabs;
