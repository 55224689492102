import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import participantsSlice from "./reducers/participantsSlice";
import userSlice from "./reducers/userSlice";
import administratorsSlice from "./reducers/administratorsSlice";
import chatbotSlice from "./reducers/chatbotSlice";
import onboardingBuilderSlice from "./reducers/onboardingSlice";
import cmsSlice from "./reducers/cmsSlice";
import cloudSlice from "./reducers/cloudSlice";
import chatbotBuilderSlice from "./reducers/chatbotBuilderSlice";
import cmsBuilderSlice from "./reducers/cmsBuilderSlice";
import reflectionBuilderSlice from "./reducers/reflectionBuilderSlice";
import myPatientsSlice from "./reducers/myPatientsSlice";
import notificationSlice from "./reducers/notificationSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    participants: participantsSlice,
    administrators: administratorsSlice,
    chatbot: chatbotSlice,
    chatbotBuilder: chatbotBuilderSlice,
    onboarding: onboardingBuilderSlice,
    cms: cmsSlice,
    cloud: cloudSlice,
    cmsBuilder: cmsBuilderSlice,
    reflectionBuilder: reflectionBuilderSlice,
    myPatients: myPatientsSlice,
    notification: notificationSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
