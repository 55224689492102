import { reset as adminReset } from "../reducers/administratorsSlice";
import { reset as partReset } from "../reducers/participantsSlice";
import { reset as userReset } from "../reducers/userSlice";
import { reset as cbbReset } from "../reducers/chatbotBuilderSlice";
import { reset as cbReset } from "../reducers/chatbotSlice";
import { reset as cloudReset } from "../reducers/cloudSlice";
import { reset as cmsBReset } from "../reducers/cmsBuilderSlice";
import { reset as cmsReset } from "../reducers/cmsSlice";
import { reset as onboardReset } from "../reducers/onboardingSlice";
import { reset as refBReset } from "../reducers/reflectionBuilderSlice";
import { reset as myPartReset } from "../reducers/myPatientsSlice";
import { AppThunk } from "../store";

export const resetState = (): AppThunk => (dispatch) => {
  dispatch(partReset());
  dispatch(userReset());
  dispatch(adminReset());
  dispatch(cbbReset());
  dispatch(cbReset());
  dispatch(cloudReset());
  dispatch(cmsBReset());
  dispatch(cmsReset());
  dispatch(onboardReset());
  dispatch(refBReset());
  dispatch(myPartReset());
};
