import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingContainer } from "../CMS/cms.style";
import { ApptCard, DisApptCard } from "../MyCalendar/style";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import RescheduleModal from "../MyCalendar/RescheduleModal";

const Session = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [row, setRow] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const RefreshPage = () => {
    setRefreshPage((prev) => !prev);
  };

  const closeModal = () => {
    setShowModal(false);
    setRow(null);
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const date = DateTime.now();
        let url = `/consultation?startDate=${date
          ?.startOf("day")
          .toUTC()
          .toISO()}&endDate=${date?.endOf("day").toUTC().toISO()}`;
        const res: AxiosResponse = await http.get(url);
        const resData = res.data?.data;
        const newData = resData
          ?.map((patient: any) => ({
            id: patient?.id,
            patientId: patient?.userId,
            startTime: DateTime.fromISO(patient?.scheduledAt).toFormat(
              "hh:mm a"
            ),
            endTime: DateTime.fromISO(patient?.scheduledEndTime).toFormat(
              "hh:mm a"
            ),
            unformattedEndTime: DateTime.fromISO(patient?.scheduledEndTime),
            unformattedStartTime: DateTime.fromISO(patient?.scheduledAt),
            name: patient?.user?.code || "",
            status: patient?.status,
          }))
          .sort((a: any, b: any) => {
            return (
              (DateTime.fromFormat(a?.startTime || "", "hh:mm a") as any) -
              (DateTime.fromFormat(b?.startTime || "", "hh:mm a") as any)
            );
          });

        setData(newData || []);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [refreshPage]);
  const handleClick = (appt: any) => {
    if (
      appt?.unformattedEndTime >= DateTime.local() &&
      appt?.status === "pending"
    ) {
      navigate(
        `/app/participants/my-patients/${appt?.patientId}?tab=profile&user=${appt?.name}`
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyItems: "center",
          marginTop: "50px",
          flex: 1,
          gap: 5,
        }}
      >
        {!loading ? (
          data?.length !== 0 ? (
            <>
              <Box
                sx={{
                  flex: 1,
                  pr: 1,
                }}
              >
                {data
                  ?.filter((_, index) => index % 2 === 0)
                  ?.map((appt) => {
                    return (
                      <Box
                        sx={
                          appt?.unformattedEndTime >= DateTime.local() &&
                          appt?.status === "pending"
                            ? { ...ApptCard, paddingRight: 1 }
                            : DisApptCard
                        }
                        key={appt?.id}
                        onClick={() => {
                          handleClick(appt);
                        }}
                      >
                        <Box></Box>
                        <Typography fontSize={24} fontWeight="medium">
                          {appt?.startTime} - {appt?.endTime} {appt?.name}
                        </Typography>
                        <Box>
                          {appt?.unformattedEndTime >= DateTime.local() && (
                            <Tooltip title="cancel slot">
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setRow(appt);
                                  setShowModal(true);
                                }}
                                sx={{
                                  cursor: "pointer",
                                }}
                              >
                                <DeleteIcon
                                  color="error"
                                  sx={{ fontSize: "24px" }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
              <Box
                sx={{
                  flex: 1,
                  pr: 1,
                }}
              >
                {data
                  ?.filter((_, index) => index % 2 !== 0)
                  ?.map((appt) => {
                    return (
                      <Box
                        sx={
                          appt?.unformattedEndTime >= DateTime.local() &&
                          appt?.status === "pending"
                            ? { ...ApptCard, paddingRight: 1 }
                            : DisApptCard
                        }
                        key={appt?.id}
                        onClick={() => {
                          handleClick(appt);
                        }}
                      >
                        <Box></Box>
                        <Typography fontSize={24} fontWeight="medium">
                          {appt?.startTime} - {appt?.endTime} {appt?.name}
                        </Typography>
                        <Box>
                          {appt?.unformattedEndTime >= DateTime.local() && (
                            <Tooltip title="cancel slot">
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setRow(appt);
                                  setShowModal(true);
                                }}
                                sx={{
                                  cursor: "pointer",
                                }}
                              >
                                <DeleteIcon
                                  color="error"
                                  sx={{ fontSize: "24px" }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
                height: "100%",
                width: "100%",
                paddingLeft: "40%",
              }}
            >
              <Typography variant="subtitle2" color="gray">
                No data available
              </Typography>
            </Box>
          )
        ) : (
          <Box sx={{ ...LoadingContainer, flex: 1 }}>
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>
      {showModal && (
        <RescheduleModal
          showModal={showModal}
          closeModal={closeModal}
          id={row.id}
          reschedule={false}
          refreshPage={RefreshPage}
        />
      )}
    </>
  );
};

export default Session;
