import React, { useEffect, useState } from "react";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import {
  Box,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { NoDataContainer, StyledTableCell } from "../../Common/styles/table";
import { errorToastMessage } from "../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import EditSettingsModal from "./EditSettingsModal";
import ThemeConfigModal from "./ThemeConfigModal";

const MobileSettings = () => {
  const [widgetLoading, setWidgetLoading] = useState(true);
  const [widgetToggleLoader, setWidgetToggleLoader] = useState(false);
  const [widgetSettingsData, setWidgetSettingsData] = useState<any>([]);
  const [showWidgetModal, setShowWidgetModal] = useState(false);

  const [langLoading, setLangLoading] = useState(true);
  const [langToggleLoader, setLangToggleLoader] = useState(false);
  const [langSettingsData, setLangSettingsData] = useState<any>([]);
  const [showLangModal, setShowLangModal] = useState(false);

  const [themeLoading, setthemeLoading] = useState(true);
  const [themeToggleLoader, setThemeToggleLoader] = useState(false);
  const [themeConfigData, setThemeConfigData] = useState<any>({});
  const [showThemeConfigModal, setShowThemeConfigModal] = useState(false);

  useEffect(() => {
    const fetchSettingsData = async () => {
      try {
        setWidgetLoading(true);
        const res: AxiosResponse = await http.get("/settings/app-widget");
        const data = res.data?.data;
        const newData = data.map((settings: any) => {
          return {
            id: settings?.id,
            label: settings?.label,
            isEnabled: settings?.isEnabled,
          };
        });
        setWidgetSettingsData(newData);
        setWidgetLoading(false);
      } catch (err) {
        setWidgetLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchSettingsData();
  }, [widgetToggleLoader]);

  useEffect(() => {
    const fetchLangSettingsData = async () => {
      try {
        setLangLoading(true);
        const res: AxiosResponse = await http.get("/settings/app-language");
        const data = res.data?.data?.config || [];
        const newData = data.map((settings: any) => {
          return {
            id: settings?.id,
            label: settings?.label,
            isEnabled: settings?.isEnabled,
            code: settings?.code,
          };
        });
        setLangSettingsData(newData);
        setLangLoading(false);
      } catch (err) {
        setLangLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchLangSettingsData();
  }, [langToggleLoader]);

  useEffect(() => {
    const fetchThemeConfigs = async () => {
      try {
        setthemeLoading(true);
        const res: AxiosResponse = await http.get("/settings/app-theme");
        const configs = res.data?.data.config;
        setThemeConfigData(configs);
        setthemeLoading(false);
      } catch (err) {
        setthemeLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchThemeConfigs();
  }, [themeToggleLoader]);

  const openWidgetModal = () => {
    setShowWidgetModal(true);
  };

  const closeWidgetModal = () => {
    setShowWidgetModal(false);
  };

  const openLangModal = () => {
    setShowLangModal(true);
  };

  const closeLangModal = () => {
    setShowLangModal(false);
  };

  const openThemeConfigModal = () => {
    setShowThemeConfigModal(true);
  };

  const closeThemeConfigModal = () => {
    setShowThemeConfigModal(false);
  };

  const refreshWidgetTable = () => {
    setWidgetToggleLoader((prev) => !prev);
  };

  const refreshLangTable = () => {
    setLangToggleLoader((prev) => !prev);
  };

  const refreshThemeConfigTable = () => {
    setThemeToggleLoader((prev) => !prev);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Mobile configurations
          </Typography>
        </Box>
        <Box sx={HeaderRightContent}>
          <Button variant="contained" onClick={openThemeConfigModal}>
            Edit theme configurations
          </Button>
          <Button variant="contained" onClick={openWidgetModal}>
            Edit widget settings
          </Button>
          <Button variant="contained" onClick={openLangModal}>
            Edit language settings
          </Button>
        </Box>
      </StyledHeader>
      <Box
        sx={{
          p: 2.5,
          height: "calc(100% - 85px)",
          overflow: "auto",
        }}
      >
        <Typography fontSize={24} fontWeight="bold" mb={2}>
          App settings
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: "30%" }}>Name</StyledTableCell>
              <StyledTableCell>Value</StyledTableCell>
            </TableRow>
          </TableHead>
          {!themeLoading && (
            <TableBody>
              <TableRow>
                <StyledTableCell>App Name</StyledTableCell>
                <StyledTableCell>
                  {themeConfigData?.appName || "-"}
                </StyledTableCell>
              </TableRow>
              {/* <TableRow>
                <StyledTableCell>Dark Theme Color</StyledTableCell>
                <StyledTableCell>
                  {themeConfigData?.theme?.dark?.primaryColor ? (
                    <Box
                      sx={{
                        ...ColorBox,
                        bgcolor:
                          themeConfigData?.theme?.dark?.primaryColor || "#fff",
                      }}
                    />
                  ) : (
                    "-"
                  )}
                </StyledTableCell>
              </TableRow> */}
              <TableRow>
                <StyledTableCell>Dark Theme Logo</StyledTableCell>
                <StyledTableCell>
                  {themeConfigData?.theme?.dark?.logoUrl ? (
                    <img
                      src={themeConfigData?.theme?.dark?.logoUrl}
                      height={40}
                      width={40}
                      alt="logo"
                    />
                  ) : (
                    <Typography
                      variant="subtitle1"
                      fontWeight="regular"
                      color={"GrayText"}
                    >
                      No image
                    </Typography>
                  )}
                </StyledTableCell>
              </TableRow>
              {/* <TableRow>
                <StyledTableCell>Light Theme Color</StyledTableCell>
                <StyledTableCell>
                  {themeConfigData?.theme?.light?.primaryColor ? (
                    <Box
                      sx={{
                        ...ColorBox,
                        bgcolor:
                          themeConfigData?.theme?.light?.primaryColor || "#fff",
                      }}
                    />
                  ) : (
                    "-"
                  )}
                </StyledTableCell>
              </TableRow> */}
              <TableRow>
                <StyledTableCell>Light Theme Logo</StyledTableCell>
                <StyledTableCell>
                  {themeConfigData?.theme?.light?.logoUrl ? (
                    <img
                      src={themeConfigData?.theme?.light?.logoUrl}
                      height={40}
                      width={40}
                      alt="logo"
                    />
                  ) : (
                    <Typography
                      variant="subtitle1"
                      fontWeight="regular"
                      color={"GrayText"}
                    >
                      No image
                    </Typography>
                  )}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        {themeLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Typography fontSize={24} fontWeight="bold" my={2}>
          Widget settings
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: "30%" }}>Widget</StyledTableCell>
              <StyledTableCell>Enabled</StyledTableCell>
            </TableRow>
          </TableHead>
          {!widgetLoading && (
            <TableBody>
              {widgetSettingsData?.map(
                (row: { id: string; label: string; isEnabled: boolean }) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell>{row?.label}</StyledTableCell>
                    <StyledTableCell>
                      {row?.isEnabled ? "Yes" : "No"}
                    </StyledTableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          )}
        </Table>
        {widgetLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {!widgetLoading && widgetSettingsData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        <Typography fontSize={24} fontWeight="bold" my={2}>
          Language settings
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: "30%" }}>Language</StyledTableCell>
              <StyledTableCell>Enabled</StyledTableCell>
            </TableRow>
          </TableHead>
          {!langLoading && (
            <TableBody>
              {langSettingsData?.map(
                (row: { id: string; label: string; isEnabled: boolean }) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell>{row?.label}</StyledTableCell>
                    <StyledTableCell>
                      {row?.isEnabled ? "Yes" : "No"}
                    </StyledTableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          )}
        </Table>
        {langLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {!langLoading && langSettingsData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
      </Box>

      {showWidgetModal && (
        <EditSettingsModal
          showModal={showWidgetModal}
          closeModal={closeWidgetModal}
          data={widgetSettingsData}
          refreshPage={refreshWidgetTable}
          type="widget"
        />
      )}
      {showLangModal && (
        <EditSettingsModal
          showModal={showLangModal}
          closeModal={closeLangModal}
          data={langSettingsData}
          refreshPage={refreshLangTable}
          type="lang"
        />
      )}
      {showThemeConfigModal && (
        <ThemeConfigModal
          showModal={showThemeConfigModal}
          closeModal={closeThemeConfigModal}
          refreshPage={refreshThemeConfigTable}
          data={themeConfigData}
        />
      )}
    </>
  );
};

export default MobileSettings;
