import { useMemo, useState } from "react";
import { debounce } from "lodash";
import { Box, Button, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
// import UploadIcon from "@mui/icons-material/Upload";

import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import AddAdminModal from "./AddAdminModal";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { setAdministratorsSearchText } from "../../../Redux/reducers/administratorsSlice";
// import { errorToastMessage, toastMessage } from "../../../utils/toast";
// import http from "../../../utils/http";
// import { refreshAdministratorsPage } from "../../../Redux/reducers/administratorsSlice";
// import { downloadDocSample } from "../../../utils/download";
import { AdminMap } from "./Administrators";

const AdminHeader = () => {
  const [showModal, setShowModal] = useState(false);
  // const [loader, setLoader] = useState(false);
  const dispatch = useAppDispatch();
  const { type, searchText } = useAppSelector((state) => state.administrators);
  const { hasMasterAdminAccess } = useAppSelector((state) => state.user);

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setAdministratorsSearchText(val));
      }, 500),
    [dispatch]
  );

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  // const uploadDocList = async (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const file = e.target.files[0];
  //     const isLt5M = file.size / 1024 / 1024 < 5;
  //     if (!isLt5M) {
  //       toastMessage("warning", "File size must smaller than 5MB!");
  //       return false;
  //     }
  //     try {
  //       setLoader(true);
  //       const formData = new FormData();
  //       formData.append("file", file);
  //       await http.post("/doctors/import-doctors", formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //       toastMessage("success", "Health Coaches added successfully");
  //       setLoader(false);
  //       dispatch(refreshAdministratorsPage());
  //     } catch (err) {
  //       errorToastMessage(err as Error);
  //       setLoader(false);
  //     }
  //   }
  // };

  // const downloadSample = () => {
  //   downloadDocSample();
  // };

  return (
    <StyledHeader>
      <Box sx={HeaderLeftContent}>
        <Typography fontSize={30} fontWeight="bold">
          Administrators
        </Typography>

        <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "300px" }}
          placeholder="Search for Administrators"
          defaultValue={searchText}
          key={type}
        />
      </Box>
      <Box sx={HeaderRightContent}>
        {(hasMasterAdminAccess || type !== "master_admin") && (
          <Button
            className="mr-3"
            variant="contained"
            onClick={openModal}
            startIcon={<AddIcon />}
          >
            Add {AdminMap[type]}
          </Button>
        )}
        {/* {!loader ? (
          <>
            <Button
              className="mr-3"
              variant="contained"
              onClick={downloadSample}
            >
              Download Sample CSV
            </Button>
            <Button
              variant="outlined"
              component="label"
              startIcon={<UploadIcon />}
            >
              Upload Health Coach details
              <input
                hidden
                accept="text/csv"
                type="file"
                onChange={uploadDocList}
              />
            </Button>
          </>
        ) : (
          <CircularProgress size={25} />
        )} */}
      </Box>
      {showModal && (
        <AddAdminModal
          showModal={showModal}
          closeModal={closeModal}
          type={type}
        />
      )}
    </StyledHeader>
  );
};

export default AdminHeader;
