import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  Popover,
} from "@mui/material";
import { InsertEmoticonOutlined } from "@mui/icons-material";
import { AttachIcon, SendIcon } from "./ChatIcons";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { InputContainer } from "./style";
import { errorToastMessage, toastMessage } from "../../../../../utils/toast";
import { db } from "../../../../../utils/firebaseInit";
import http from "../../../../../utils/http";
// import ChatAttachment from "./ChatAttachment";
import { VisuallyHiddenInput } from "../../../../Common/styles/form";
import { uploadFile } from "../../../../../utils/upload";
import ChatMedia from "./ChatMedia";
import EmojiPicker, {
  EmojiClickData,
  SuggestionMode,
} from "emoji-picker-react";
import ChatAttachment from "./ChatAttachment";

const ChatInput = ({ roomName, id }: any) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [hiddenValue, setHiddenValue] = useState(null);
  const [attachment, setAttachment] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [coachTyping, setCoachTyping] = useState(false);
  const [docExists, setDocExists] = useState<"not-set" | "true">("not-set");

  const chatInputRef = useRef<any>(null);
  const coachTypingRef = useRef<any>(false);
  const globalTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const showEmojiPicker = (event: React.MouseEvent<HTMLElement>) => {
    if (!buttonLoader) {
      setAnchorEl(event.currentTarget);
    }
  };
  const closeEmojiPicker = () => {
    setAnchorEl(null);
    if (chatInputRef.current) {
      setTimeout(() => {
        const inputLength = chatInputRef.current?.value?.length;
        chatInputRef.current?.setSelectionRange(inputLength, inputLength);
        chatInputRef.current?.focus();
      }, 0);
    }
  };

  const notifyUser = async (userMessage: string) => {
    try {
      await http.post("/coach/chat-notify", {
        sendTo: id,
        message: userMessage,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateIsTyping = useCallback(
    async (val: boolean) => {
      try {
        setCoachTyping(val);
        coachTypingRef.current = val;
        const userId = localStorage.getItem("coachId") || "";
        if (userId) {
          const chatDocRef = doc(db, "chat", roomName);

          if (docExists === "not-set") {
            const docSnapshot = await getDoc(chatDocRef);
            if (docSnapshot.exists()) {
              // console.log("not set called with update");

              setDocExists("true");
              await updateDoc(chatDocRef, {
                [`typing.${userId}`]: val,
              });
            } else {
              // console.log("not set called with add");

              await setDoc(chatDocRef, {
                typing: {
                  [userId]: val,
                },
              }).then(() => setDocExists("true"));
            }
          } else if (docExists === "true") {
            // console.log("update called");

            await updateDoc(chatDocRef, {
              [`typing.${userId}`]: val,
            });
          }
        }
      } catch (err) {
        setDocExists("not-set");
      }
    },
    [roomName, docExists]
  );

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (globalTimeoutRef.current) {
        clearTimeout(globalTimeoutRef.current);
      }
      if (coachTyping) updateIsTyping(false);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [updateIsTyping, coachTyping]);

  const sendData = () => {
    const finalText = textValue.trim();
    if (finalText || attachment) {
      setButtonLoader(true);
      const timestamp = serverTimestamp;
      const userId = localStorage.getItem("coachId");
      const userName = localStorage.getItem("coachName") || "Health Coach";
      const newMessage: any = {
        sentBy: userId,
        sentAt: timestamp(),
        text: textValue,
        userName,
        isCoach: true,
        resourceType: "text",
      };
      if (attachment) {
        let attType = "attachment";
        if (attachment.type.includes("image")) {
          attType = "image";
        }
        // else if (attachment.type.includes("video")) {
        //   attType = "video";
        // } else if (attachment.type.includes("audio")) {
        //   attType = "audio";
        // }

        newMessage.resourceType = attType;
        newMessage.resourceUrl = attachment.resourceUrl;
        if (attachment.name) {
          newMessage.resourceName = attachment.name;
        }
      }
      const messageRef = collection(db, "chat", roomName, "messages");
      addDoc(messageRef, newMessage)
        .then(() => {
          setTextValue("");
          setAttachment(null);
          if (globalTimeoutRef.current) {
            clearTimeout(globalTimeoutRef.current);
          }
          if (coachTyping) {
            updateIsTyping(false);
          }
          // if (!oldUser) {
          //   setDoc(
          //     doc(db, "chat", roomName),
          //     {
          //       users: arrayUnion(userId),
          //     },
          //     { merge: true }
          //   );
          // }
          // setOldUser(true);
          setButtonLoader(false);
          if (newMessage.resourceType === "attachment" && !finalText) {
            newMessage.text = "Sent an attachment";
          }
          if (newMessage.resourceType === "image" && !finalText) {
            newMessage.text = "Sent an image";
          }
          notifyUser(newMessage?.text || "");
        })
        .catch((err) => {
          errorToastMessage(err as Error);
          setButtonLoader(false);
        });
    }
  };

  const handleKeyDown = (event: any) => {
    if (!buttonLoader) {
      if (event.key === "Enter") {
        sendData();
      }
    }
  };

  const handleEmojiPickerKeyDown = (event: any) => {
    if (!buttonLoader) {
      if (event.key === "Enter") {
        event.preventDefault();
        sendData();
        closeEmojiPicker();
      }
    }
  };

  const handleTextChange = (event: any) => {
    setTextValue(event.target.value);

    //set typing true immediately after user starts typing
    if (event.target.value !== "" && !coachTyping) {
      updateIsTyping(true);
    }
    if (globalTimeoutRef.current) {
      clearTimeout(globalTimeoutRef.current);
    }
    //set typing false immediately after user clears the text
    if (coachTyping && !event.target.value.length) {
      updateIsTyping(false);
      return;
    }
    //set typing false after user stops typing and doesn't send the message
    if (coachTyping || event.target.value.length) {
      globalTimeoutRef.current = setTimeout(() => {
        updateIsTyping(false);
      }, 5000);
    }
  };

  const clearAttachment = useCallback(() => {
    setAttachment(null);
  }, [setAttachment]);

  const attachmentHandler = async (event: any) => {
    try {
      const file = event?.target?.files?.[0];
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          toastMessage("warning", "File Size cannot be greater than 5 MB!");
          return;
        }
        setButtonLoader(true);
        const url = await uploadFile(file, "chat");
        if (chatInputRef.current) {
          chatInputRef.current?.focus();
        }
        setAttachment({
          type: file?.type,
          resourceUrl: url,
          name: file?.name,
        });

        setButtonLoader(false);
      }
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    setTextValue((prev) => prev + emojiData.emoji);
    if (!coachTypingRef.current) {
      updateIsTyping(true);
    }
    if (globalTimeoutRef.current) {
      clearTimeout(globalTimeoutRef.current);
    }

    globalTimeoutRef.current = setTimeout(() => {
      updateIsTyping(false);
    }, 5000);
  };

  return (
    <>
      {attachment && (
        <>
          {/* <ChatMedia
            attachment={attachment}
            clearAttachment={clearAttachment}
          /> */}
          {!["image", "video"].some((type) =>
            attachment.type.includes(type)
          ) ? (
            <ChatAttachment
              attachment={attachment}
              clearAttachment={clearAttachment}
            />
          ) : (
            <ChatMedia
              attachment={attachment}
              clearAttachment={clearAttachment}
            />
          )}
        </>
      )}
      <Box sx={InputContainer}>
        <IconButton
          sx={{ p: "10px" }}
          onClick={showEmojiPicker}
          disabled={buttonLoader}
        >
          <InsertEmoticonOutlined htmlColor="#637E85" />
        </IconButton>
        <InputBase
          inputRef={chatInputRef}
          autoFocus
          sx={{ ml: 1, flex: 1, fontSize: 16 }}
          value={textValue}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          placeholder="Type here..."
        />
        {buttonLoader ? (
          <Box
            sx={{
              width: 88,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={22} />
          </Box>
        ) : (
          <>
            <IconButton
              component="label"
              sx={{ p: "10px" }}
              onClick={() => setHiddenValue(null)}
            >
              <AttachIcon />
              <VisuallyHiddenInput
                type="file"
                onChange={attachmentHandler}
                value={hiddenValue || ""}
                accept="image/jpeg , image/jpg , image/png, application/pdf"
              />
            </IconButton>
            <IconButton onClick={sendData} type="button" sx={{ p: "10px" }}>
              <SendIcon />
            </IconButton>
          </>
        )}
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onKeyDown={handleEmojiPickerKeyDown}
        onClose={closeEmojiPicker}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          top: "-5px !important",
        }}
      >
        <EmojiPicker
          skinTonesDisabled={true}
          autoFocusSearch={false}
          height={300}
          width={325}
          suggestedEmojisMode={SuggestionMode.RECENT}
          // categories={
          //   [
          //     {
          //       category: "suggested",
          //     },
          //     {
          //       category: "smileys_people",
          //     },
          //   ] as any
          // }
          onEmojiClick={handleEmojiClick}
          previewConfig={{
            showPreview: false,
          }}
        />
      </Popover>
    </>
  );
};

export default React.memo(ChatInput);
