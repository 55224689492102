import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  debounce,
  FormControl,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import { delayOptions } from "../../utils/delay";
import { ImageUploadIcon } from "./Builder/Icons";
import { uploadFile } from "../../utils/upload";
import { useDropzone } from "react-dropzone";
import { UploadWrapper } from "./Builder/chatbotBuilder.style";

const UploadItem: React.FC<any> = ({ image, setFieldValue }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const url = await uploadFile(file, "questionnaire_image");
          setFieldValue("imageUrl", url);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...UploadWrapper, mt: 2, mb: 2.5 }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const schema = yup.object().shape({
  name: yup.string().required("Chat bot name is Required"),
  externalName: yup.string().required("User facing title is Required"),
});

const CreateChatbotModal = ({
  showModal,
  closeModal,
  chat,
  type,
  refreshPage,
}: any) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [lessons, setLessons] = useState<any>([]);
  const [bots, setBots] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const chatId = chat?.id;

  const submitHandler = async (values: any) => {
    try {
      setButtonLoader(true);
      const body: any = {
        name: values.name,
        externalName: type === "faq" ? values.name : values.externalName,
        lang: "en",
        userType: values.userType || 0,
      };
      if (type !== "faq") {
        body.exitBotId = values.exitBot?.id || null;
        body.exitLessonId = values.exitLesson?.id || null;
        body.exitLessonDelay = values.exitLessonDelay || 0;
        body.exitBotDelay = values.exitBotDelay || 0;
        body.imageUrl = values.imageUrl || null;
        body.isVisible = values.isVisible || false;
      }
      let res: AxiosResponse;
      let chatId = chat?.id;
      if (chatId) {
        body.status = chat.status;
        res = await http.put(`/bots/${chatId}`, body);
      } else {
        body.type = type;
        body.status = "draft";
        res = await http.post(`/bots`, body);
      }
      closeModal();
      toastMessage("success", res.data.message);
      setButtonLoader(false);
      if (chatId) {
        refreshPage();
      } else {
        navigate(`/app/chatbot/builder/${res.data.data.id}`);
      }
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string, lang: string) => {
        try {
          if (value) {
            setLoading(true);
            let url = `/lms/lessons?page=1&size=15&status=active&lang=${lang}&search=${value}`;

            const res: AxiosResponse = await http.get(url);
            const lessons = res.data.data.educationLessons.map(
              (lesson: any) => {
                return {
                  id: lesson.id,
                  name: lesson.name,
                };
              }
            );
            setLessons(lessons);
            setLoading(false);
          }
        } catch (err) {
          errorToastMessage(err as Error);
          setLoading(false);
        }
      }, 500),
    []
  );

  const handleBotSearch = useMemo(
    () =>
      debounce(async (value: string, lang: string) => {
        try {
          if (value) {
            setLoading(true);
            const res: AxiosResponse = await http.get(
              `/bots?page=1&size=15&status=active&search=${value}&type=general&lang=${lang}`
            );
            const newBots = res.data.data.bots.map((bot: any) => {
              return {
                id: bot?.id,
                name: bot?.name,
              };
            });
            setBots(newBots);
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    []
  );

  useEffect(() => {
    if (chat?.exitLesson) {
      setLessons([
        {
          name: chat?.exitLesson?.name,
          id: chat?.exitLesson?.id,
        },
      ]);
    }
  }, [chat, setLessons]);

  useEffect(() => {
    if (chat?.exitBot) {
      setBots([
        {
          name: chat?.exitBot?.name,
          id: chat?.exitBot?.id,
        },
      ]);
    }
  }, [chat, setBots]);

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader
          title={chat?.id ? "Edit Chatbot" : "Create New Chatbot"}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            name: chat?.name || "",
            externalName:
              type === "faq"
                ? chat?.externalName || "FAQ title"
                : chat?.externalName || "",
            exitLesson: chat?.exitLesson || null,
            exitLessonDelay: chat?.exitLessonDelay || 0,
            exitBot: chat?.exitBot || null,
            exitBotDelay: chat?.exitBotDelay || 0,
            lang: chat?.lang || "en",
            imageUrl: chat?.imageUrl || "",
            isVisible: chat?.isVisible || false,
            userType: 0,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, setFieldValue, getFieldProps, values }: any) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="name">
                  Chatbot name
                </FormLabel>
                <TextField
                  id="name"
                  placeholder="Chatbot name"
                  {...getFieldProps("name")}
                  error={touched?.name && errors?.name ? true : false}
                  helperText={
                    touched?.name && errors?.name ? errors?.name : " "
                  }
                />
              </FormControl>
              {type !== "faq" && (
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="name">
                    User title
                  </FormLabel>
                  <TextField
                    id="externalName"
                    placeholder="User title"
                    {...getFieldProps("externalName")}
                    error={
                      touched?.externalName && errors?.externalName
                        ? true
                        : false
                    }
                    helperText={
                      touched?.externalName && errors?.externalName
                        ? errors?.externalName
                        : " "
                    }
                  />
                </FormControl>
              )}
              {/* <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="language">
                  Language
                </FormLabel>
                <Select
                  value={values.lang}
                  onChange={(e) => {
                    setFieldValue("lang", e.target.value);
                    setFieldValue("exitLesson", null);
                    setFieldValue("exitBot", null);
                  }}
                  fullWidth
                  id="lang"
                  sx={{ mb: 2.5 }}
                >
                  {LANGUAGES.map((lang) => (
                    <MenuItem key={lang.value} value={lang.value}>
                      {lang.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              {type !== "faq" && (
                <>
                  {/* <FormControl
                    sx={InputWrapper}
                    error={touched?.userType && errors?.userType ? true : false}
                  >
                    <FormLabel sx={LabelStyle} htmlFor="chatbot-type">
                      Show to
                    </FormLabel>
                    <Select
                      id="chatbot-type"
                      fullWidth
                      value={values?.userType}
                      onChange={(e) =>
                        setFieldValue("userType", e.target.value)
                      }
                    >
                      <MenuItem key="patient" value={0}>
                        Patient
                      </MenuItem>
                      <MenuItem key="Caregiver" value={1}>
                        Caregiver
                      </MenuItem>
                      <MenuItem key="both" value={2}>
                        Both Patient and Caregiver
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {touched?.userType && errors?.userType
                        ? (errors?.userType as string)
                        : " "}
                    </FormHelperText>
                  </FormControl> */}
                  <UploadItem
                    image={values?.imageUrl}
                    setFieldValue={setFieldValue}
                  />
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    spacing={1}
                    mb={2}
                  >
                    <FormLabel
                      sx={{ ...LabelStyle, mb: 0 }}
                      htmlFor="show-in-library"
                    >
                      Show in Library
                    </FormLabel>
                    <Switch
                      id="show-in-library"
                      checked={values?.isVisible}
                      onChange={(e) =>
                        setFieldValue("isVisible", e.target.checked)
                      }
                    />
                  </Stack>
                  <Box sx={{ display: "flex", gap: 2, mb: 2.5 }}>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle}>
                        Lesson shown after completion of this chatbot
                      </FormLabel>
                      <Autocomplete
                        filterOptions={(x) => x}
                        onInputChange={(
                          _1: any,
                          value: any,
                          reason: string
                        ) => {
                          if (reason === "input")
                            handleSearch(value, values.lang);
                        }}
                        onChange={(_1: any, value: any) => {
                          setFieldValue("exitLesson", value);
                        }}
                        options={lessons}
                        value={values?.exitLesson || null}
                        getOptionLabel={(option) => option?.name}
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id;
                        }}
                        loading={loading}
                        loadingText={<CircularProgress size={20} />}
                        noOptionsText="No Results"
                        clearOnBlur={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search lessons by typing..."
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle}>Trigger in Days</FormLabel>
                      <Select
                        value={values.exitLessonDelay}
                        onChange={(e) =>
                          setFieldValue(
                            "exitLessonDelay",
                            parseInt(e.target.value)
                          )
                        }
                      >
                        {delayOptions.map((number) => (
                          <MenuItem key={number} value={number}>
                            {number}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle}>
                        Bot shown after completion of this chatbot
                      </FormLabel>
                      <Autocomplete
                        filterOptions={(x) => x}
                        onInputChange={(
                          _1: any,
                          value: any,
                          reason: string
                        ) => {
                          if (reason === "input")
                            handleBotSearch(value, values.lang);
                        }}
                        onChange={(_1: any, value: any) => {
                          setFieldValue("exitBot", value);
                        }}
                        options={bots}
                        value={values?.exitBot || null}
                        getOptionLabel={(option) => option?.name}
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id;
                        }}
                        loading={loading}
                        loadingText={<CircularProgress size={20} />}
                        noOptionsText="No Results"
                        clearOnBlur={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search Bots by typing..."
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle}>Trigger in Days</FormLabel>
                      <Select
                        value={values.exitBotDelay}
                        onChange={(e) =>
                          setFieldValue(
                            "exitBotDelay",
                            parseInt(e.target.value)
                          )
                        }
                      >
                        {delayOptions.map((number) => (
                          <MenuItem key={number} value={number}>
                            {number}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1.5,
                  mt: 3,
                }}
              >
                {!buttonLoader ? (
                  <>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default CreateChatbotModal;
