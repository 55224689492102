import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  StyledHeader,
  HeaderLeftContent,
  HeaderRightContent,
} from "../Common/styles/header";
import { debounce } from "lodash";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { DateTime } from "luxon";
import {
  StyledTableCell,
  pageSize,
  TablePaginationStyle,
  paginationLabel,
  NoDataContainer,
  StyledSortLabel,
} from "../Common/styles/table";
import SelfReflectionModal from "./SelfReflectionModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import http from "../../utils/http";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../Common/UI/TabPanel";
import {
  Assignment,
  FilterAlt,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import { AxiosResponse } from "axios";
import { useAppSelector } from "../../Redux/hooks";

const SelfReflection = () => {
  const { userId } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [delLoader, setDelLoader] = useState(false);
  const [dupLoader, setDupLoader] = useState(false);
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const [showModal, setShowModal] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [tab, setTab] = useState(searchParams.get("tab") || "exercise");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [row, setRow] = useState<any>(null);
  const [columnSort, setColumnSort] = useState(searchParams.get("sort") || "");
  const [sortOrder, setSortOrder] = useState(searchParams.get("order") || "");
  const [filterId, setFilterId] = useState(searchParams.get("filter") || "");
  const [selectedOption, setSelectedOption] = useState(filterId);
  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(filterEl);
  const [creatorList, setCreatorList] = useState([]);
  const [listfilterEl, setListFilterEl] = useState<null | HTMLElement>(null);
  const openListFilter = Boolean(listfilterEl);

  const openFilterMenu = (
    event: React.MouseEvent<HTMLElement>,
    type: string
  ) => {
    if (type === "creator-menu") {
      setListFilterEl(event?.currentTarget);
    } else {
      setFilterEl(event?.currentTarget);
    }
  };

  const handleFilterItem = (value: string) => {
    setSelectedOption(value);
  };
  const closeFilterMenu = (type: string) => {
    if (type === "creator-menu") {
      setListFilterEl(null);
    } else {
      setFilterEl(null);
    }
    if (selectedOption !== filterId) {
      setFilterId(selectedOption);
      setPage(0);
    }
  };

  const clearFilterMenu = (type: string) => {
    if (type === "creator-menu") {
      setListFilterEl(null);
    } else {
      setFilterEl(null);
    }
    setFilterId("");
    setSelectedOption("");
  };

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    if (searchText) {
      params.set("search", searchText);
    }
    if (tab) {
      params.set("tab", tab);
    }

    if (sortOrder) {
      params.set("order", sortOrder);
    }
    if (columnSort) {
      params.set("sort", columnSort);
    }
    if (filterId) {
      params.set("filter", filterId);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, searchText, tab, page, sortOrder, columnSort, filterId]);

  const handleChange = (_: any, newValue: string) => {
    setTab(newValue);
    setPage(0);
    setSearchText("");
    setSortOrder("");
    setColumnSort("");
    setFilterId("");
    setSelectedOption("");
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setRow(row);
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setRow(null);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setSearchText(val);
        setPage(0);
      }, 500),
    []
  );

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const openModal = () => {
    setShowModal(true);
    setAnchorEl(null);
  };
  const closeModal = () => {
    setShowModal(false);
    setRow(null);
  };

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const navigateToBuilder = () => {
    navigate("/app/self-reflection-exercises/builder");
  };

  const editExercise = () => {
    if (tab === "assign") openModal();
    if (tab === "exercise")
      navigate(`/app/self-reflection-exercises/builder?id=${row?.id}`);
  };

  const deleteExercise = async () => {
    try {
      setDelLoader(true);
      let url;
      if (tab === "exercise") {
        url = `/self-reflection/${row.id}`;
      } else {
        url = `self-reflection-assignments/${row.id}`;
      }
      const res: AxiosResponse = await http.delete(url);
      refreshPage();
      setDelLoader(false);
      toastMessage("success", res.data.message);
      setAnchorEl(null);
      setRow(null);
    } catch (error) {
      errorToastMessage(error as Error);
      setDelLoader(false);
    }
  };

  const duplicateExercise = async () => {
    try {
      setDupLoader(true);
      const url = `/self-reflection/${row.id}/duplicate`;
      const res: AxiosResponse = await http.post(url);
      refreshPage();
      toastMessage("success", res.data.message);
      setDupLoader(false);
      setAnchorEl(null);
      setRow(null);
    } catch (error) {
      setDupLoader(false);
      errorToastMessage(error as Error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url;
        if (tab === "exercise") {
          url = `/self-reflection?pagination=true&page=${
            page + 1
          }&size=${pageSize}`;
          if (filterId) {
            url += `&userId=${filterId}`;
          }
        } else {
          url = `/self-reflection-assignments?page=${
            page + 1
          }&size=${pageSize}`;
          if (filterId) {
            url += `&status=${filterId}`;
          }
        }
        if (columnSort && sortOrder) {
          url += `&sortBy=${columnSort}&orderBy=${sortOrder}`;
        }

        if (searchText) {
          url += `&search=${searchText}`;
        }
        const res = await http.get(url);

        if (
          res.data.data?.rows?.length === 0 &&
          res.data.data?.count > 0 &&
          page > 0
        ) {
          setPage(0);
          return;
        }

        const editableIds: string[] = [];
        if (tab === "exercise") {
          const response = await http.get("/self-reflection/editable");
          const data: any = response.data?.data || [];
          data.forEach((item: any) => {
            if (item?.isEditable) {
              return editableIds.push(item?.selfReflectionExerxiseId);
            }
          });
        }

        let newData = [];
        if (tab === "exercise") {
          newData = res.data.data?.rows?.map((item: any) => {
            return {
              id: item?.id,
              selfReflectionId: item?.id,
              slNo: item?.serialNumber,
              userTitle: item?.title,
              coachId: item?.userId,
              firstName: item?.user?.firstName || "",
              lastName: item?.user?.lastName || "",
              language: item?.language || "english",
              createdAt: DateTime.fromISO(item?.createdAt).toFormat(
                "dd LLL yyyy"
              ),
              lastEdit: DateTime.fromISO(item?.updatedAt).toFormat(
                "dd LLL yyyy"
              ),
              editable: editableIds.includes(item?.id),
              type: "template",
            };
          });
        } else {
          newData = res.data.data?.rows?.map((item: any) => {
            return {
              id: item?.id,
              slNo: item?.selfReflectionExercise?.serialNumber,
              userTitle: item?.selfReflectionExercise?.title,
              selfReflectionId: item?.selfReflectionExerciseId,
              coachId: item?.assignedBy || "",
              scheduledDate: item?.scheduledDate,
              firstName: item?.selfReflectionExercise?.user?.firstName || "",
              lastName: item?.selfReflectionExercise?.user?.lastName || "",
              language: item?.language || "english",
              assignedTo: item?.assigned?.code,
              assignedToId: item?.assignedTo,
              dueDate: item?.dueDate
                ? DateTime.fromISO(item?.dueDate).toFormat("dd LLL yyyy")
                : null,
              status: item?.status,
              reminderWindowLength: item?.reminderWindowLength,
              editable:
                item?.assignedBy &&
                item?.assignedBy === userId &&
                item?.status !== "completed",
              type: "history",
            };
          });
        }

        setData(newData);
        setLoading(false);
        setTotalCount(res.data.data?.count);
      } catch (error) {
        errorToastMessage(error as Error);
        setLoading(false);
      }
    };
    fetchData();
  }, [
    setLoading,
    setData,
    setTotalCount,
    page,
    searchText,
    toggleLoader,
    tab,
    columnSort,
    sortOrder,
    filterId,
    userId,
  ]);

  useEffect(() => {
    const fetchList = async () => {
      try {
        let url;

        url = "/coach";
        const res: AxiosResponse = await http.get(url);
        const list = res.data?.data?.map((creator: any) => {
          return {
            value: creator?.id,
            text: creator?.firstName + " " + creator?.lastName,
          };
        });
        setCreatorList(list);
      } catch (err) {
        errorToastMessage(err as Error);
      }
    };
    fetchList();
  }, []);

  const handleSort = (order: string, column: string) => {
    if (columnSort === column && sortOrder === order) {
      setSortOrder("");
      setColumnSort("");
    } else {
      setColumnSort(column);
      setSortOrder(order);
    }
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === columnSort && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === columnSort && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  const previewExercise = (id: string) => {
    navigate(`/app/self-reflection-exercises/preview/${id}`);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Self-reflection exercises
          </Typography>
          <TextField
            style={{ width: "280px" }}
            placeholder="Search for self-reflection exercises"
            onChange={(e) => modifySearchTerm(e.target.value)}
            defaultValue={searchText}
            key={tab}
          />
        </Box>
        <Box sx={HeaderRightContent}>
          {/* {tab === "assign" && (
            <Button variant="contained" onClick={openModal}>
              Assign
            </Button>
          )} */}

          <Button variant="contained" onClick={navigateToBuilder}>
            Create
          </Button>
        </Box>
      </StyledHeader>

      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={tab} onChange={handleChange}>
          <StyledTab label="Template" value="exercise" {...a11yProps(0)} />
          <StyledTab label="History" value="assign" {...a11yProps(1)} />
        </StyledTabs>
      </Box>

      <Box
        sx={{
          height: "calc(100% - 146px)",
          overflow: "auto",
        }}
      >
        <MainTabPanel value={tab} index={tab}>
          <>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <StyledTableCell>Sl.No.</StyledTableCell> */}
                  <StyledTableCell>
                    Title
                    <SortLabel column="title" />
                  </StyledTableCell>
                  {/* <StyledTableCell>Language</StyledTableCell> */}
                  {tab === "exercise" && (
                    <>
                      <StyledTableCell>
                        Created by
                        <IconButton
                          onClick={(e) => openFilterMenu(e, "creator-menu")}
                        >
                          <FilterAlt
                            color={filterId ? "primary" : "disabled"}
                          />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell>
                        Date created
                        <SortLabel column="createdAt" />
                      </StyledTableCell>
                      <StyledTableCell>
                        Last edit
                        <SortLabel column="updatedAt" />
                      </StyledTableCell>
                    </>
                  )}
                  {tab === "assign" && (
                    <>
                      <StyledTableCell>
                        Assigned to
                        <SortLabel column="code" />
                      </StyledTableCell>
                      <StyledTableCell>
                        Due date
                        <SortLabel column="dueDate" />
                      </StyledTableCell>
                      <StyledTableCell>
                        Status
                        <IconButton
                          onClick={(e) => openFilterMenu(e, "status-menu")}
                        >
                          <FilterAlt
                            color={filterId ? "primary" : "disabled"}
                          />
                        </IconButton>
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              {!loading && data?.length > 0 && (
                <>
                  <TableBody>
                    {data?.map((item: any) => (
                      <TableRow key={item.id}>
                        {/* <StyledTableCell>{item?.slNo || "-"}</StyledTableCell> */}
                        <StyledTableCell
                          sx={{ width: "20%", cursor: "pointer" }}
                          onClick={() => previewExercise(item?.id)}
                        >
                          {item.userTitle || "-"}
                        </StyledTableCell>
                        {/* <StyledTableCell sx={{ textTransform: "capitalize" }}>
                          {item.language}
                        </StyledTableCell> */}

                        {tab === "exercise" && (
                          <>
                            <StyledTableCell
                              sx={{ textTransform: "capitalize" }}
                            >
                              {!item?.firstName && !item?.lastName
                                ? "-"
                                : `${item?.firstName} ${item?.lastName}`}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ textTransform: "capitalize" }}
                            >
                              {item?.createdAt || "-"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ textTransform: "capitalize" }}
                            >
                              {item?.lastEdit || "-"}
                            </StyledTableCell>
                          </>
                        )}
                        {tab === "assign" && (
                          <>
                            <StyledTableCell
                              sx={{ textTransform: "capitalize" }}
                            >
                              {item?.assignedTo || "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item?.dueDate || "-"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{
                                color: item?.status
                                  ? item?.status === "completed"
                                    ? "#29B355"
                                    : item?.status === "overdue"
                                    ? "#F05252"
                                    : "#FFC20A"
                                  : "",
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.status || "-"}
                            </StyledTableCell>
                          </>
                        )}
                        <StyledTableCell align="right">
                          {(tab === "exercise" || item?.editable) && (
                            <IconButton
                              size="small"
                              onClick={(e) => openMenu(e, item)}
                            >
                              <MoreVertIcon sx={{ fontSize: "20px" }} />
                            </IconButton>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      {totalCount > pageSize && (
                        <TablePagination
                          sx={TablePaginationStyle}
                          count={totalCount}
                          page={page}
                          rowsPerPage={pageSize}
                          rowsPerPageOptions={[pageSize]}
                          onPageChange={handleChangePage}
                          labelDisplayedRows={paginationLabel}
                        />
                      )}
                    </TableRow>
                  </TableFooter>
                </>
              )}
            </Table>

            {!loading && data?.length === 0 && (
              <NoDataContainer>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            )}

            {loading && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </>
        </MainTabPanel>
      </Box>
      <Menu
        anchorEl={filterEl}
        open={openFilter}
        onClose={() => closeFilterMenu("status-menu")}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "20ch",
          },
        }}
      >
        {["completed", "pending", "overdue"]?.map((item: any) => (
          <MenuItem
            selected={item === selectedOption}
            key={item}
            onClick={() => handleFilterItem(item)}
          >
            <ListItemIcon>
              {item === selectedOption ? (
                <RadioButtonChecked fontSize="small" color="primary" />
              ) : (
                <RadioButtonUnchecked fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {item}
            </ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button variant="text" onClick={() => clearFilterMenu("status-menu")}>
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={() => closeFilterMenu("status-menu")}
          >
            Ok
          </Button>
        </Box>
      </Menu>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            minWidth: "200px",
          },
        }}
        TransitionProps={{
          exit: false,
        }}
      >
        {tab === "exercise" && (
          <MenuItem onClick={openModal} disabled={delLoader || dupLoader}>
            <ListItemIcon>
              <Assignment fontSize="small" />
            </ListItemIcon>
            <ListItemText>Assign</ListItemText>
          </MenuItem>
        )}
        {row?.editable && (
          <MenuItem onClick={editExercise} disabled={delLoader || dupLoader}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {tab === "exercise" && (
          <MenuItem
            onClick={duplicateExercise}
            disabled={delLoader || dupLoader}
          >
            {dupLoader ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <ListItemIcon>
                  <ContentCopy fontSize="small" />
                </ListItemIcon>
                <ListItemText>Duplicate</ListItemText>
              </>
            )}
          </MenuItem>
        )}
        {row?.editable && (
          <MenuItem onClick={deleteExercise} disabled={delLoader || dupLoader}>
            {delLoader ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <ListItemIcon>
                  <Delete fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </>
            )}
          </MenuItem>
        )}
      </Menu>

      <Menu
        anchorEl={listfilterEl}
        open={openListFilter}
        onClose={() => closeFilterMenu("creator-menu")}
        PaperProps={{
          style: {
            maxHeight: "400px",
            minWidth: "20ch",
          },
        }}
      >
        {creatorList?.map((item: any) => (
          <MenuItem
            selected={item?.value === selectedOption}
            key={item?.value}
            onClick={() => handleFilterItem(item?.value)}
          >
            <ListItemIcon>
              {item?.value === selectedOption ? (
                <RadioButtonChecked fontSize="small" color="primary" />
              ) : (
                <RadioButtonUnchecked fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {item?.text}
            </ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button
            variant="text"
            onClick={() => clearFilterMenu("creator-menu")}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={() => closeFilterMenu("creator-menu")}
          >
            Ok
          </Button>
        </Box>
      </Menu>
      {showModal && (
        <SelfReflectionModal
          showModal={showModal}
          closeModal={closeModal}
          refreshPage={refreshPage}
          data={row}
        />
      )}
    </>
  );
};

export default SelfReflection;
