import React from "react";
import { Badge, Box, IconButton, Typography } from "@mui/material";
import { errorToastMessage } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  setMyPatientsTab,
  setPatientName,
} from "../../Redux/reducers/myPatientsSlice";
import http from "../../utils/http";
import { setRefreshNotification } from "../../Redux/reducers/notificationSlice";
import { Delete } from "@mui/icons-material";

const ChatNotifications = ({
  setAnchorElChat,
  data,
  unreadChatCount,
  openModal,
}: any) => {
  const navigate = useNavigate();
  const { patientsUrlLoaded } = useAppSelector((state) => state.myPatients);
  const dispatch = useAppDispatch();

  const markAsRead = async (id?: any) => {
    try {
      if (id) {
        const url = `/notifications/chat/grouped`;
        const body = { code: id };
        await http.patch(url, body);
      } else {
        const url = `notifications/chat`;
        const body = { isRead: true };
        await http.patch(url, body);
      }

      dispatch(setRefreshNotification());
    } catch (error) {
      errorToastMessage(error as Error);
    }
  };

  const handleClick = async (item: any) => {
    if (item?.patientId) {
      if (!item?.isRead) {
        await markAsRead(item?.patientCode);
      }
      // if user is already in my patients screen then resetting the tab & patient
      if (patientsUrlLoaded) {
        dispatch(setPatientName(item?.patientCode));
        dispatch(setMyPatientsTab("chat"));
      }
      navigate(
        `/app/participants/my-patients/${item?.patientId}?tab=chat&user=${item?.patientCode}`
      );
      setAnchorElChat(null);
    } else {
      errorToastMessage(new Error("User Id not available"));
    }
  };

  function navigateToNotificationPage() {
    try {
      navigate(`/app/coach-notification?type=chat`);
      setAnchorElChat(null);
    } catch (error) {
      errorToastMessage(error as Error);
    }
  }

  const openDeleteModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    if (item?.isRead) {
      event?.stopPropagation();
      openModal({
        data: item?.patientCode,
        type: "chat",
      });
    }
  };

  return (
    <>
      {data?.length !== 0 ? (
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: "6px",
            border: "1px solid rgba(33, 33, 33, 0.10)",
            boxShadow: "6px 6px 38px 0px rgba(0, 0, 0, 0.06)",
            width: "18.75rem",
            minHeight: "9.5rem",
            maxHeight: "30rem",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minHeight: "2rem",
              alignItems: "center",
              padding: ".5rem",
              borderBottom: "1px solid  #E0E3EB",
            }}
          >
            <Typography variant="subtitle1" fontWeight="medium">
              Messages
            </Typography>
            {unreadChatCount !== 0 && (
              <Typography
                variant="subtitle1"
                color="secondary"
                sx={{ cursor: "pointer" }}
                onClick={() => markAsRead()}
              >
                Mark all as read
              </Typography>
            )}
          </Box>
          {data?.map((item: any) => (
            <Box
              key={item.id}
              sx={{
                borderRadius: "5px 5px 0px 0px",
                borderBottom: "1px solid #E0E3EB",
                background: item?.isRead ? "transparent" : "#FEF1F4",
                height: "6rem",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                handleClick(item);
              }}
            >
              {/* <Box
                sx={{
                  borderRadius: "8px",
                  height: "3rem",
                  width: "3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "1rem",
                  background: item?.isRead ? "#F3F4F6" : "#D714401A",
                }}
              >
                {item.gender === "male" ? <MaleIcon /> : <FemaleIcon />}
              </Box> */}
              <Box
                sx={{
                  marginLeft: ".5rem",
                  width: "18rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ flex: 1, gap: 2 }}>
                      <Typography
                        variant="body1"
                        fontWeight="regular"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "15rem",
                        }}
                      >
                        {item?.patientCode}
                      </Typography>
                      <Typography
                        fontSize={12}
                        fontWeight="light"
                        sx={{
                          color: "#6B7280",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "wrap",
                          maxWidth: "15rem",
                          maxHeight: "2.5rem",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item?.message}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", gap: 1.5, alignItems: "center" }}
                    >
                      <Badge color="error" badgeContent={item?.unreadCount} />

                      <IconButton
                        disabled={!item?.isRead}
                        color="error"
                        onClick={(e) => openDeleteModal(e, item)}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <Typography
                      fontWeight="regular"
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#6B7280",
                        alignSelf: "flex-end",
                      }}
                    >
                      {item?.time}
                    </Typography>
                    <Typography
                      fontWeight="regular"
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#6B7280",
                        alignSelf: "flex-end",
                      }}
                    >
                      {item?.day}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              minHeight: "2.5rem",
              alignItems: "center",
              padding: ".5rem",
              borderBottom: "1px solid  #E0E3EB",
              alignSelf: "flex-end",
            }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              fontWeight="regular"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigateToNotificationPage();
              }}
            >
              See all chat notifications
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            borderRadius: "5px 5px 0px 0px",
            borderBottom: "1px solid  #E0E3EB",
            minHeight: "15rem",
            display: "flex",
            flexDirection: "row",
            paddingTop: "2rem",
            justifyContent: "center",
            width: "18.75rem",
          }}
        >
          <Typography variant="subtitle1" fontWeight="light" color={"#808080"}>
            There are no chat notifications
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ChatNotifications;
