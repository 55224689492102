import { Box, Slider, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { indxToAlpha } from "../../../utils/education";
import { getTranslation } from "../../../utils/webviewLang";

type Props = {
  questions: any;
  lang: string;
  isQuestionMandatory: boolean;
};

const CMSQuestionPreview: React.FC<Props> = ({
  questions,
  lang,
  isQuestionMandatory,
}) => {
  const questionTypes: any = {
    multi_select: MCQ,
    single_select: Quiz,
    slider: SliderComp,
    // timer: Timer,
    text: Text,
  };

  return (
    <>
      {questions.map((q: any) => {
        if (questionTypes[q.type] === undefined) return null;
        const TypeComponent = questionTypes[q.type];
        return (
          <div className="question-container" key={q.id}>
            <div className="question-title">{q.title}</div>
            {q.type !== "timer" && q.imageUrl && (
              <img src={q.imageUrl} className="question-image" alt="question" />
            )}
            <TypeComponent
              question={q}
              lang={lang}
              isQuestionMandatory={isQuestionMandatory}
            />
          </div>
        );
      })}
    </>
  );
};

const MCQ = ({ question, lang, isQuestionMandatory }: any) => {
  const [answer, setAnswer] = useState<any[]>([]);

  const changeHandler = (id: string) => {
    if (answer.includes(id)) {
      setAnswer(answer.filter((a) => a !== id));
    } else {
      setAnswer((pre) => [...pre, id]);
    }
  };

  let submitted = answer?.length > 0;
  let explanation = null;
  if (submitted && question?.correctAnswer?.length > 0 && answer?.length > 0) {
    const correct =
      question?.correctAnswer?.every((id: any) => {
        return answer?.includes(id);
      }) && question?.correctAnswer?.length === answer.length;
    correct ? (explanation = "correct") : (explanation = "wrong");
  }

  return (
    <>
      <div className="question-choices">
        {question.choices.map((choice: any, index: any) => {
          const selected = answer.includes(choice.id);
          return (
            <div
              key={choice.id}
              className={
                selected ? "question-choice selected" : "question-choice"
              }
              onClick={() => changeHandler(choice.id)}
            >
              {choice.image && (
                <img
                  src={choice.image}
                  className="question-choice-image"
                  alt="choice"
                />
              )}
              <span>{indxToAlpha(index) + ". " + choice.label}</span>
            </div>
          );
        })}
      </div>
      {isQuestionMandatory && (
        <div className="compulsory">
          {"(" + getTranslation("question_compulsory", lang) + ")"}
        </div>
      )}
      {submitted && explanation ? (
        <div className={"explanation " + explanation}>
          {explanation === "correct"
            ? question?.correctExplanation
            : question?.wrongExplanation}
        </div>
      ) : null}
    </>
  );
};

const Text = ({ question, lang }: any) => {
  return (
    <TextField
      multiline
      rows={3}
      placeholder={getTranslation("answer_placeholder", lang)}
      fullWidth
    />
  );
};

const Quiz = ({ question, lang, isQuestionMandatory }: any) => {
  const [answer, setAnswer] = useState<any>(null);

  const changeHandler = (id: string) => {
    setAnswer(id);
  };

  let submitted = answer ? true : false;
  let explanation = null;
  if (submitted && question?.correctAnswer?.length > 0) {
    question?.correctAnswer?.[0] === answer
      ? (explanation = "correct")
      : (explanation = "wrong");
  }

  return (
    <>
      <div className="question-choices">
        {question.choices.map((choice: any, index: number) => {
          return (
            <div
              key={choice.id}
              className={
                answer === choice.id
                  ? "question-choice selected"
                  : "question-choice"
              }
              onClick={() => changeHandler(choice.id)}
            >
              {choice.image && (
                <img
                  src={choice.image}
                  className="question-choice-image"
                  alt="choice"
                />
              )}
              <span>{indxToAlpha(index) + ". " + choice.label}</span>
            </div>
          );
        })}
      </div>
      {isQuestionMandatory && (
        <div className="compulsory">
          {"(" + getTranslation("question_compulsory", lang) + ")"}
        </div>
      )}
      {submitted && explanation ? (
        <div className={"explanation " + explanation}>
          {explanation === "correct"
            ? question?.correctExplanation
            : question?.wrongExplanation}
        </div>
      ) : null}
    </>
  );
};

const SliderComp = ({ question }: any) => {
  const [marks, setMarks] = useState<any[]>([]);

  const [val, setVal] = useState<any>();
  const choice = question.choices[0];

  const changeHandler = (_1: any, newValue: number | number[]) => {
    setVal(newValue);
  };

  useEffect(() => {
    const newMarks = [
      {
        value: parseInt(choice.minValue),
        label: "",
        text: choice.minLabel,
      },
    ];
    if (choice.midValue && choice.midLabel) {
      newMarks.push({
        value: parseInt(choice.midValue),
        label: "",
        text: choice.midLabel,
      });
    }
    newMarks.push({
      value: parseInt(choice.maxValue),
      label: "",
      text: choice.maxLabel,
    });
    setMarks(newMarks);
  }, [choice, setMarks]);

  let explanation = null;
  if (typeof val === "number") {
    const cutoff = parseInt(choice.cutoff);
    if (typeof cutoff === "number" && !isNaN(cutoff)) {
      if (val > cutoff) {
        explanation = "correct";
      } else {
        explanation = "wrong";
      }
    }
  }

  return (
    <>
      <Box sx={{ pr: 1 }}>
        <Slider
          step={1}
          marks={marks}
          value={typeof val === "number" ? val : 0}
          onChange={changeHandler}
          min={parseInt(choice.minValue)}
          max={parseInt(choice.maxValue)}
        />
      </Box>
      <div className="slider-marks">
        {marks.map((mark) => {
          return (
            <div
              className={
                val >= mark.value ? "slider-mark selected" : "slider-mark"
              }
              key={mark.value}
            >
              {mark.text}
            </div>
          );
        })}
      </div>
      {explanation ? (
        <div className={"explanation " + explanation}>
          {explanation === "correct"
            ? question?.correctExplanation
            : question?.wrongExplanation}
        </div>
      ) : null}
    </>
  );
};

// const Timer = ({ question }: any) => {
//   const [started, setStarted] = useState(false);
//   const [complete,setComplete] = useState(false);

//   const [timerStarted, setTimerStarted] = useState(false);
//   const [total, setTotal] = useState(0);
//   const [progress,setProgress] = useState(0);
//   const [percent, setPercent] = useState(0);

//   const choice = question.choices[0];

//   const startChallenge = () => {
//     setStarted(true);
//   };

//   useEffect(() => {
//     let seconds = 0;
//     if (choice.minutes !== "" && !isNaN(choice.minutes)) {
//       seconds = parseInt(choice.minutes) * 60;
//     }
//     if (choice.seconds !== "" && !isNaN(choice.seconds)) {
//       seconds += parseInt(choice.seconds);
//     }
//     setTotal(seconds);
//   }, [choice, setTotal]);

//   useEffect(()=>{
//     let timer:any;
//     if(timerStarted && !complete){
//       timer = setInterval(() => {
//         setProgress((prevProgress) => (prevProgress+1));
//       }, 1000)
//     }
//     return () => {
//       clearInterval(timer);
//     };
//   },[timerStarted,complete]);

//   useEffect(()=>{
//     if(total > 0){
//       const percent = ((total - progress) * 100) / total;
//       setPercent(percent);
//     }else{
//       setPercent(0)
//     }
//   },[progress,total,setPercent])

//   return (
//     <>
//       <Button variant="contained" onClick={startChallenge} sx={{ marginBottom: "1rem"}}>
//         Start Timer Challenge
//       </Button>
//       {started && question?.correctExplanation && (
//         <div className={"explanation correct"}>
//           {question?.correctExplanation}
//         </div>
//       )}
//       {
//         <Dialog
//           open={started}
//           onClose={() => {
//             setStarted(false)
//             setTimerStarted(false)
//             setPercent(0)
//             setProgress(0)
//           }}
//           fullWidth
//           maxWidth="xs"
//           sx={{ zIndex: (theme) => theme.zIndex.drawer }}
//         >
//           <DialogTitle>Challenge</DialogTitle>
//         <DialogContent>
//           <DialogContentText>{question.title}</DialogContentText>
//           {question.imageUrl && (
//             <img src={question.imageUrl} className="question-image" alt="question" />
//           )}
//       </DialogContent>
//       </Dialog>
//       }
//     </>
//   );
// };

export default CMSQuestionPreview;
