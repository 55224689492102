import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createNewAssessment,
  createNewSection,
  modifyAssessment,
} from "../../utils/education";
import { v4 as uuid } from "uuid";
import { toastMessage } from "../../utils/toast";

export interface OnboardingState {
  sections: any[];
  lesson: { pages: any[]; title: string; position: number };

  editMode: boolean;
  previewMode: boolean;

  title: string;
  questionsMandatory: boolean;

  lang: string;

  loading: boolean;
}

const initialState: OnboardingState = {
  editMode: false,
  lesson: { pages: [], title: "", position: 0 },
  sections: [],
  loading: true,
  previewMode: false,
  title: "",
  questionsMandatory: false,
  lang: "",
};

export const cmsBuilderSlice = createSlice({
  name: "cmsBuilder",
  initialState: initialState,
  reducers: {
    setSections: (state, action: PayloadAction<{ sections: any[] }>) => {
      state.sections = action.payload.sections;
    },
    addSection: (
      state,
      action: PayloadAction<{
        type: string;
        dropIndex?: number;
      }>
    ) => {
      if (action.payload.type === "spaced_learning") {
        const present = state.sections.some(
          (section) => section.type === action.payload.type
        );
        if (present) {
          toastMessage(
            "warning",
            "Only one spaced learning can be added in a page"
          );
          return;
        }
      }
      const newSection = createNewSection(action.payload.type);
      if (action.payload.dropIndex !== undefined) {
        state.sections.splice(action.payload.dropIndex, 0, newSection);
      } else {
        state.sections.push(newSection);
      }
    },
    moveSection: (
      state,
      action: PayloadAction<{
        dragIndex: number;
        dropIndex: number;
      }>
    ) => {
      const movedItem = state.sections[action.payload.dragIndex];
      state.sections.splice(action.payload.dragIndex, 1);
      state.sections.splice(action.payload.dropIndex, 0, movedItem);
    },
    deleteSection: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      state.sections.splice(action.payload.index, 1);
    },
    clearSection: (state) => {
      state.sections = [];
    },
    setEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    setPreviewMode: (state, action: PayloadAction<boolean>) => {
      state.previewMode = action.payload;
    },
    setDefaults: (
      state,
      action: PayloadAction<{
        lesson: { pages: any[]; title: string; position: number };
        sections: any[];
        title: string;
        questionsMandatory: boolean;
        loading: boolean;
        editMode: boolean;
        previewMode: boolean;
        lang: string;
      }>
    ) => {
      state.lesson = action.payload.lesson;
      state.questionsMandatory = action.payload.questionsMandatory;
      state.title = action.payload.title;
      state.sections = action.payload.sections;
      state.loading = action.payload.loading;
      state.editMode = action.payload.editMode;
      state.previewMode = action.payload.previewMode;
      state.lang = action.payload.lang;
    },
    setSectionInLesson: (
      state,
      action: PayloadAction<{ sections: any[]; key: string | null }>
    ) => {
      if (action.payload.key) {
        state.lesson.pages.forEach((page) => {
          if (page.id === action.payload.key) {
            page.sections = action.payload.sections;
          }
        });
      } else {
        state.lesson.pages.push({
          sections: action.payload.sections,
          position: state.lesson.pages.length + 1,
        });
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setQuestionMandatory: (state, action: PayloadAction<boolean>) => {
      state.questionsMandatory = action.payload;
    },
    changeSectionValue: (
      state,
      action: PayloadAction<{
        index: number;
        type: string;
        value: any;
      }>
    ) => {
      state.sections[action.payload.index][action.payload.type] =
        action.payload.value;
    },
    addResource: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
      }>
    ) => {
      state.sections[action.payload.sectionIndex].resources.push({
        key: uuid(),
        label: "",
        value: "",
      });
    },
    deleteResource: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        resourceIndex: number;
      }>
    ) => {
      if (state.sections[action.payload.sectionIndex].resources.length > 1)
        state.sections[action.payload.sectionIndex].resources.splice(
          action.payload.resourceIndex,
          1
        );
    },
    handleResourceChange: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        resourceIndex: number;
        type: string;
        value: string;
      }>
    ) => {
      state.sections[action.payload.sectionIndex].resources[
        action.payload.resourceIndex
      ][action.payload.type] = action.payload.value;
    },
    addPollOption: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
      }>
    ) => {
      state.sections[action.payload.sectionIndex].choices.push({
        key: uuid(),
        label: "",
        image: "",
      });
    },
    deletePollOption: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        choiceIndex: number;
      }>
    ) => {
      if (state.sections[action.payload.sectionIndex].choices.length > 1)
        state.sections[action.payload.sectionIndex].choices.splice(
          action.payload.choiceIndex,
          1
        );
    },
    handlePollChange: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        choiceIndex: number;
        type: string;
        value: string;
      }>
    ) => {
      state.sections[action.payload.sectionIndex].choices[
        action.payload.choiceIndex
      ][action.payload.type] = action.payload.value;
    },
    addAssessment: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
      }>
    ) => {
      state.sections[action.payload.sectionIndex].questions.push(
        createNewAssessment()
      );
    },
    deleteAssessment: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        questionIndex: number;
      }>
    ) => {
      if (state.sections[action.payload.sectionIndex].questions.length > 1)
        state.sections[action.payload.sectionIndex].questions.splice(
          action.payload.questionIndex,
          1
        );
    },
    handleAssessmentChange: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        questionIndex: number;
        type: string;
        value: any;
      }>
    ) => {
      const question =
        state.sections[action.payload.sectionIndex].questions[
          action.payload.questionIndex
        ];
      question[action.payload.type] = action.payload.value;
      if (action.payload.type === "linkModule") {
        if (action.payload.value === false) {
          question["educationLessonId"] = "";
          question["educationLessonPageId"] = "";
          question["educationLessonUnitId"] = "";
        }
      }

      if (action.payload.type === "educationLessonId") {
        question["educationLessonPageId"] = "";
        question["educationLessonUnitId"] = "";
      }
      if (
        action.payload.type === "educationLessonUnitId" &&
        action.payload.value === "all"
      ) {
        question["educationLessonPageId"] = "";
      }
    },
    moveAssessment: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        questionIndex: number;
        offset: number;
      }>
    ) => {
      const questions = state.sections[action.payload.sectionIndex].questions;
      const swapItem = questions[action.payload.questionIndex];
      const swapToItem =
        questions[action.payload.questionIndex + action.payload.offset];
      questions[action.payload.questionIndex] = swapToItem;
      questions[action.payload.questionIndex + action.payload.offset] =
        swapItem;
    },
    changeQuestionType: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        questionIndex: number;
        newType: string;
      }>
    ) => {
      const questions = state.sections[action.payload.sectionIndex].questions;
      const newQuestion = modifyAssessment(action.payload.newType);
      if (newQuestion) questions[action.payload.questionIndex] = newQuestion;
    },
    handleAssessmentChoiceChange: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        questionIndex: number;
        type: string;
        value: string;
        optionIndex: number;
        questionType?: string;
      }>
    ) => {
      const question =
        state.sections[action.payload.sectionIndex].questions[
          action.payload.questionIndex
        ];
      if (
        action.payload.type === "isCorrect" &&
        action.payload.questionType === "single_select"
      ) {
        question.choices = question.choices.map(
          (choice: any, index: number) => {
            if (index === action.payload.optionIndex) {
              return {
                ...choice,
                isCorrect: action.payload.value,
              };
            } else {
              return {
                ...choice,
                isCorrect: false,
              };
            }
          }
        );
      } else {
        question.choices[action.payload.optionIndex][action.payload.type] =
          action.payload.value;
      }
    },
    addAssessmentChoice: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        questionIndex: number;
      }>
    ) => {
      state.sections[action.payload.sectionIndex].questions[
        action.payload.questionIndex
      ].choices.push({
        key: uuid(),
        label: "",
        image: "",
        isCorrect: false,
      });
    },
    deleteAssessmentChoice: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        questionIndex: number;
        choiceIndex: number;
      }>
    ) => {
      state.sections[action.payload.sectionIndex].questions[
        action.payload.questionIndex
      ].choices.splice(action.payload.choiceIndex, 1);
    },
    addTag: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        tag: any;
      }>
    ) => {
      state.sections[action.payload.sectionIndex].tags.push(action.payload.tag);
    },
    deleteTag: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        tag: any;
      }>
    ) => {
      const section = state.sections[action.payload.sectionIndex];
      section.tags = section.tags.filter(
        (prevTag: any) => prevTag !== action.payload.tag
      );
    },
    addHighlight: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        highlight: any;
      }>
    ) => {
      state.sections[action.payload.sectionIndex].highlights.push(
        action.payload.highlight
      );
    },
    deleteHighlights: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        highlightId: any;
      }>
    ) => {
      const section = state.sections[action.payload.sectionIndex];
      section.highlights = section.highlights.filter(
        (high: any) => high.id !== action.payload.highlightId
      );
    },
    handleSpacedChange: (
      state,
      action: PayloadAction<{
        index: number;
        type: string;
        value: string | boolean;
      }>
    ) => {
      const section = state.sections[action.payload.index]["spacedLearning"];
      section[action.payload.type] = action.payload.value;
    },
    handleSpacedChoiceChange: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        type: string;
        value: string;
        optionIndex: number;
      }>
    ) => {
      const section =
        state.sections[action.payload.sectionIndex]["spacedLearning"];
      section.choices[action.payload.optionIndex][action.payload.type] =
        action.payload.value;
    },
    addSpacedChoice: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      state.sections[action.payload.index].spacedLearning.choices.push({
        key: uuid(),
        label: "",
        image: "",
        isCorrect: false,
      });
    },
    deleteSpacedChoice: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        choiceIndex: number;
      }>
    ) => {
      const choices =
        state.sections[action.payload.sectionIndex].spacedLearning.choices;
      if (choices.length > 2) {
        choices.splice(action.payload.choiceIndex, 1);
      }
    },
    handleSpacedNotifChange: (
      state,
      action: PayloadAction<{
        index: number;
        type: string;
        value: string | number;
      }>
    ) => {
      const notification =
        state.sections[action.payload.index]["spacedLearning"]["notification"];
      notification[action.payload.type] = action.payload.value;
    },
    reset: () => initialState,
  },
});

export const {
  addSection,
  moveSection,
  deleteSection,
  clearSection,
  setEditMode,
  setPreviewMode,
  setSections,
  setDefaults,
  setSectionInLesson,
  setLoading,
  setQuestionMandatory,
  setTitle,
  changeSectionValue,
  addResource,
  deleteResource,
  handleResourceChange,
  addPollOption,
  deletePollOption,
  handlePollChange,
  addAssessment,
  deleteAssessment,
  handleAssessmentChange,
  moveAssessment,
  changeQuestionType,
  handleAssessmentChoiceChange,
  addAssessmentChoice,
  deleteAssessmentChoice,
  addHighlight,
  deleteHighlights,
  addTag,
  deleteTag,
  handleSpacedChange,
  handleSpacedChoiceChange,
  addSpacedChoice,
  deleteSpacedChoice,
  handleSpacedNotifChange,
  reset,
} = cmsBuilderSlice.actions;

export default cmsBuilderSlice.reducer;
