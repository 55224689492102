import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import http from "../../utils/http";
import { useAppDispatch } from "../../Redux/hooks";
import {
  setCloudModalProps,
  toggleCloud,
} from "../../Redux/reducers/cloudSlice";

type Props = {
  showModal: boolean;
  type: string;
  title: string;
  description: string;
  id: string;
};

export const TypeMap: any = {
  tag: "Tag",
  highlight: "Highlight",
};

const AddCloudModal = ({ showModal, type, title, description, id }: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const dispatch = useAppDispatch();

  const typeLabel = TypeMap[type];

  const closeModal = () => {
    dispatch(
      setCloudModalProps({ show: false, title: "", description: "", id: "" })
    );
  };

  const addTag = async (values: any) => {
    try {
      setSubmitLoader(true);
      const res = await http.post("/tag", { label: values?.name });
      toastMessage("success", res.data.message);
      dispatch(toggleCloud());
      closeModal();
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  const addHighlight = async (values: any) => {
    try {
      setSubmitLoader(true);
      let res;
      if (id) {
        res = await http.patch(`/highlight/${id}`, {
          label: values?.name,
          description: values?.description,
        });
      } else {
        res = await http.post("/highlight", {
          label: values?.name,
          description: values?.description,
        });
      }
      toastMessage("success", res.data.message);
      dispatch(toggleCloud());
      closeModal();
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box
        sx={{
          ...ModalBaseStyles,
          minHeight: "10vh",
        }}
      >
        <ModalHeader
          title={`${id ? "Edit" : "Create"} ${typeLabel}`}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            name: title || "",
            description: description || "",
          }}
          validationSchema={yup.object().shape({
            name: yup.string().required("*Name is Required"),
            description:
              type === "highlight"
                ? yup.string().required("*Description is Required")
                : yup.string(),
          })}
          onSubmit={(values) => {
            if (type === "tag") {
              addTag(values);
            } else {
              addHighlight(values);
            }
          }}
        >
          {({ errors, touched, getFieldProps }) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="name">
                  {typeLabel} name
                </FormLabel>
                <TextField
                  id="name"
                  placeholder={`${typeLabel} name`}
                  error={touched?.name && errors?.name ? true : false}
                  helperText={
                    touched?.name && errors?.name ? errors?.name : " "
                  }
                  {...getFieldProps("name")}
                />
              </FormControl>
              {type === "highlight" && (
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="description">
                    {typeLabel} description
                  </FormLabel>
                  <TextField
                    id="description"
                    multiline
                    minRows={3}
                    placeholder={`${typeLabel} description`}
                    error={
                      touched?.description && errors?.description ? true : false
                    }
                    helperText={
                      touched?.description && errors?.description
                        ? errors?.description
                        : " "
                    }
                    {...getFieldProps("description")}
                  />
                </FormControl>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                {!submitLoader ? (
                  <Button type="submit" variant="contained">
                    {id
                      ? "Edit " + typeLabel
                      : `Create a new ${type === "tag" ? "tag" : "highlight"}`}
                  </Button>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddCloudModal;
