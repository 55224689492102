import ParticipantDetails from "../../ParticipantDetails";
import PatientNotes from "./PatientNotes";
import { Divider } from "@mui/material";

const Profile = () => {
  return (
    <>
      <ParticipantDetails />
      <Divider sx={{ marginTop: "2rem", marginBottom: "1rem" }} />
      <PatientNotes />
    </>
  );
};

export default Profile;
