import { Backdrop, CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { highlightScript, previewLesson } from "../../../utils/education";
import http from "../../../utils/http";
import { errorToastMessage } from "../../../utils/toast";
import CMSPreview from "./CMSPreview";
import CMSThemeWrapper from "./CMSThemeWrapper";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const ReflectionPreviewWrapper: React.FC = () => {
  const { id } = useParams();
  // const pageId = useQuery().get("pageId");
  // const lessonId = useQuery().get("lessonId");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [title, setTitle] = useState("");
  // const [lang, setLang] = useState("en");
  // const [position, setPosition] = useState(1);
  // const [pages, setPages] = useState<any[]>([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [sections, setSections] = useState<any[]>([]);
  // const [isDarkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (id) {
          setLoading(true);
          const res: AxiosResponse = await http.get(`/self-reflection/${id}`);
          // const lesson = {
          //   title: res.data?.data?.title,
          //   pages: [
          //     {
          //       sections: res.data?.data?.sections,
          //     },
          //   ],
          //   position: 0,
          // };
          // let newPages = [...lesson.pages];
          // newPages.sort((a: any, b: any) => {
          //   return a.position - b.position;
          // });
          // newPages = newPages.map((item: any) => {
          //   if (item.sections && item.sections.length > 0) {
          //     const newSections = [...item.sections];
          //     newSections.sort(function (a: any, b: any) {
          //       return a.position - b.position;
          //     });
          //     return {
          //       ...item,
          //       sections: newSections,
          //     };
          //   } else {
          //     return item;
          //   }
          // });
          const sections = res.data?.data?.sections || [];

          const { previewSections } = previewLesson(sections);
          setSections(
            previewSections?.sort((a: any, b: any) => a.position - b.position)
          );

          setTitle(res.data?.data?.title || "");

          // setPages(newPages);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [id, setTitle, setLoading, setSections]);

  // useEffect(() => {
  //   if (pageId) {
  //     const index = pages.findIndex((page: any) => {
  //       return page.id === pageId;
  //     });
  //     if (index !== -1) {
  //       setCurrentPage(index + 1);
  //     } else {
  //       setCurrentPage(1);
  //     }
  //   } else {
  //     setCurrentPage(1);
  //   }
  // }, [pageId, setCurrentPage, pages]);

  // useEffect(() => {
  //   const sections = pages[currentPage - 1]?.sections;
  //   if (sections) {
  //     const { previewSections } = previewLesson(sections);
  //     setSections(previewSections);
  //   }
  // }, [setSections, currentPage, pages]);

  // const handlePagination = (offset: number) => {
  //   const newPage = currentPage + offset;
  //   if (newPage > 0 && newPage <= pages.length) {
  //     setCurrentPage(newPage);
  //   } else if (newPage === pages.length + 1 || newPage === pages.length + 2) {
  //     handleBack();
  //   }
  // };

  // const moveToPage = (newPage: number) => {
  //   if (newPage > 0 && newPage <= pages.length) {
  //     setCurrentPage(newPage);
  //   }
  // };

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = highlightScript();
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  // const setMode = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setDarkMode(e.target.checked);
  // };

  return (
    <CMSThemeWrapper isDarkMode={false}>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CMSPreview
        title={title}
        sections={sections}
        handleBack={handleBack}
        finishHandler={handleBack}
      />
    </CMSThemeWrapper>
  );
};

export default ReflectionPreviewWrapper;
