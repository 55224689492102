import { Quill } from "react-quill";
//@ts-ignore
import quillEmoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";

Quill.register(
  {
    "formats/emoji": quillEmoji.EmojiBlot,
    "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
    "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
  },
  true
);

const Size = Quill.import("attributors/class/size");
Size.whitelist = [
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "28px",
  "36px",
];
Quill.register(Size, true);

export const quillModules = {
  toolbar: [
    [{ size: Size.whitelist }],
    ["bold", "italic", "underline", "strike"],
    [{ script: "sub" }, { script: "super" }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["link"],
    ["clean"],
    ["emoji"],
  ],
  "emoji-toolbar": true,
  "emoji-shortname": true,
  clipboard: {
    matchVisual: false,
  },
};

export const quillFormats = [
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "script",
  // "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "emoji",
];
