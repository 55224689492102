import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Skeleton, Typography } from "@mui/material";
// import { FemaleIcon, MaleIcon } from "../../../../Common/assets/HeaderIcons";
import ChatContent from "./ChatContent";
import ChatInput from "./ChatInput";
import { ChatContainer } from "./style";
import { errorToastMessage } from "../../../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useAppDispatch } from "../../../../../Redux/hooks";

const Chat = () => {
  const { id } = useParams();
  const [roomName, setRoomName] = useState("");
  // const [oldUser, setOldUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<any>(null);
  const dispatch = useAppDispatch();
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (id) {
      setRoomName("chat_" + id);
    }
  }, [id, setRoomName]);

  // const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/participants/${id}/profile`
        );
        setUserDetails({
          name: res.data?.data?.nickname || "",
          gender: res.data?.data?.gender,
        });
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading, id, dispatch]);

  return id && roomName ? (
    <>
      <Box sx={ChatContainer}>
        <Box
          sx={{
            height: "80px",
            borderBottom: "1px solid #E0E3EB",
            paddingInline: 2,
            paddingTop: "25px",
            paddingBottom: "15px",
          }}
        >
          {loading ? (
            <>
              {/* <Skeleton height={52} width={52} variant="rounded" /> */}
              <Skeleton variant="text" height={36} width={125} />
            </>
          ) : (
            <>
              {/* <Avatar
                  variant="rounded"
                  sx={{ bgcolor: "#F3F4F6", height: 52, width: 52 }}
                >
                  {userDetails?.gender === "female" ? (
                    <FemaleIcon />
                  ) : (
                    <MaleIcon />
                  )}
                </Avatar> */}
              <Typography
                variant="subtitle2"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {userDetails?.name || "Patient"}
              </Typography>
              {isTyping && (
                <Typography
                  variant="body1"
                  sx={{
                    color: "#6B7280",
                    fontStyle: "italic",
                    ml: "2px",
                  }}
                >
                  typing...
                </Typography>
              )}
              {/* <Typography variant="body1" color="#6B7280">
                Last active 12:30 PM
              </Typography> */}
            </>
          )}
          {/* <Box>
            <IconButton>
              <Search htmlColor="#637E85" />
            </IconButton>
            <IconButton onClick={handleMenuClick}>
              <MoreVert htmlColor="#637E85" />
            </IconButton>
          </Box> */}
        </Box>
        <ChatContent
          roomName={roomName}
          // setOldUser={setOldUser}
          setIsTyping={setIsTyping}
          participantId={id}
        />
        <ChatInput
          roomName={roomName}
          // setOldUser={setOldUser}
          // oldUser={oldUser}
          id={id}
        />
      </Box>
      {/* <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <ListItemText>Clear chat</ListItemText>
        </MenuItem>
      </Menu> */}
    </>
  ) : null;
};

export default Chat;
