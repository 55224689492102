import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../../Redux/hooks";
import {
  SectionCard,
  SectionCardContainer,
  SectionCardSvg,
  SectionDivider,
} from "../styles";
import ContentItem from "./ContentItem";
import { CMSIconMap } from "../../../CMS/CMSTypes";

const ContentBuilder: React.FC = () => {
  const { sections } = useAppSelector((state) => state.reflectionBuilder);

  return (
    <>
      <Box sx={SectionCardContainer}>
        <Typography variant="h6" fontWeight={"bold"}>
          Blocks
        </Typography>
        {sections.map((section) => {
          const Icon = CMSIconMap[section.type];
          return (
            <Box sx={SectionCard} key={section.key}>
              <Box
                sx={{
                  ...SectionCardSvg,
                  position: "relative",
                  top: 2.5,
                }}
              >
                <Icon />
              </Box>
              <Typography variant="subtitle1" fontWeight={"medium"}>
                {section.name}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box sx={SectionDivider}>
        {sections.map((section, index) => {
          return (
            <ContentItem key={section.key} index={index} section={section} />
          );
        })}
      </Box>
    </>
  );
};

export default ContentBuilder;
