import { DateTime } from "luxon";

export const AccMap = [
  { label: "Onboarding", value: "onboarding" },
  { label: "Quiz", value: "quiz" },
  { label: "Poll", value: "poll" },
  { label: "FAQ", value: "faq" },
  { label: "AOC", value: "aoc" },
  { label: "Reflection journal", value: "reflection" },
  { label: "Search", value: "searches" },
];

export const QuizMap = [
  { label: "Single best answer", value: "single_select" },
  { label: "Multiple choice quiz", value: "multi_select" },
  { label: "Text", value: "text" },
  { label: "Slider", value: "slider" },
];

export const SearchMap = [
  { label: "Found", value: "found" },
  { label: "Not found", value: "not_found" },
];

export const TableHeaders: any = {
  onboarding: [
    { label: "Question", key: "question" },
    { label: "Answer", key: "answer" },
    { label: "Date", key: "date" },
  ],
  poll: [
    {
      label: "Poll",
      key: "poll",
    },
    {
      label: "Choice ID's",
      key: "choiceId",
    },
    {
      label: "Date",
      key: "date",
    },
  ],
  faq: [
    { label: "Question", key: "name" },
    { label: "Answer", key: "value" },
    { label: "Date", key: "date" },
  ],
  aoc: [
    { label: "Question", key: "name" },
    { label: "Answer", key: "value" },
    { label: "Date", key: "date" },
  ],
  reflection: [
    {
      label: "Title",
      key: "title",
    },
    {
      label: "Number value",
      key: "numberValue",
    },
    {
      label: "Date",
      key: "date",
    },
  ],
  single_select: [
    {
      label: "Unit title",
      key: "title",
    },
    {
      label: "Question",
      key: "question",
    },
    {
      label: "Answer",
      key: "answer",
    },
    {
      label: "Correct / Wrong",
      key: "isCorrect",
    },
    {
      label: "Date",
      key: "date",
    },
  ],
  multi_select: [
    {
      label: "Unit title",
      key: "title",
    },
    {
      label: "Question",
      key: "question",
    },
    {
      label: "Answers",
      key: "answer",
    },
    {
      label: "Correct / Wrong",
      key: "isCorrect",
    },
    {
      label: "Date",
      key: "date",
    },
  ],
  text: [
    {
      label: "Unit title",
      key: "title",
    },
    {
      label: "Question",
      key: "question",
    },
    {
      label: "Answer",
      key: "answer",
    },
    {
      label: "Date",
      key: "date",
    },
  ],
  slider: [
    {
      label: "Unit title",
      key: "title",
    },
    {
      label: "Question",
      key: "question",
    },
    {
      label: "Answer",
      key: "answer",
    },
    {
      label: "Date",
      key: "date",
    },
  ],
  found: [
    {
      label: "Search term",
      key: "term",
    },
    {
      label: "Hits",
      key: "hits",
    },
    {
      label: "Date",
      key: "date",
    },
  ],
  not_found: [
    {
      label: "Search term",
      key: "term",
    },
    {
      label: "Date",
      key: "date",
    },
  ],
};

export const calculateIsCorrect = (type: string, item: any): boolean => {
  let isCorrect = false;
  if (type === "single_select") {
    const selectedAnswer = item?.question_choice_ids
      ? item?.question_choice_ids[0]
      : "";
    const correctAnswer = (item?.choices || [])?.find(
      (item: any) => item?.isCorrect
    );
    if (selectedAnswer === correctAnswer?.id) isCorrect = true;
  }
  if (type === "multi_select") {
    const selectedAnswers = item?.question_choice_ids
      ? item?.question_choice_ids
      : [];
    const correctAnswers = (item?.choices || [])
      ?.filter((item: any) => item?.isCorrect)
      ?.map((q: any) => q?.id);
    const allCorrectAnswersIncluded = correctAnswers?.every(
      (correctAnswer: any) => selectedAnswers?.includes(correctAnswer)
    );
    if (allCorrectAnswersIncluded) isCorrect = true;
  }

  return isCorrect;
};

export const getAppData = (resData: any, type: string) => {
  const typeData = (type: any) => {
    switch (type) {
      case "onboarding":
        return Object.entries(resData?.[0]?.data)?.map(([key, value]: any) => ({
          question: key,
          answer: value?.stepChoiceId
            ? value?.stepChoiceValue?.replace(/<\/?[^>]+(>|$)/g, "")
            : value?.value,
          date: value?.answeredAt
            ? DateTime?.fromISO(value?.answeredAt)?.toFormat(
                "dd/LL/yyyy hh:mm a"
              )
            : resData?.[0]?.updated_at
            ? DateTime?.fromISO(resData?.[0]?.updated_at)?.toFormat(
                "dd/LL/yyyy hh:mm a"
              )
            : "",
        }));
      case "aoc":
        return resData?.map((item: any) => ({
          name: item?.step_message_text,
          value: item?.step_option_text,
          date: DateTime?.fromISO(item?.created_at)?.isValid
            ? DateTime?.fromISO(item?.created_at)?.toFormat(
                "dd/LL/yyyy hh:mm a"
              )
            : "",
        }));
      case "faq":
        return resData.map((item: any) => ({
          name: item?.bot_step,
          value: item?.value,
          date: DateTime?.fromISO(item?.created_at)?.isValid
            ? DateTime?.fromISO(item?.created_at)?.toFormat(
                "dd/LL/yyyy hh:mm a"
              )
            : "",
        }));
      case "poll":
        return resData.map((item: any) => ({
          poll: item?.title,
          choiceId: item?.label,
          date: DateTime?.fromISO(item?.created_at)?.isValid
            ? DateTime?.fromISO(item?.created_at)?.toFormat(
                "dd/LL/yyyy hh:mm a"
              )
            : "",
        }));
      case "single_select":
      case "multi_select":
        return resData?.map((item: any) => ({
          title: item?.name,
          question: item?.title,
          answer: item?.question_choice_ids
            ? item?.question_choice_ids
                ?.map((id: string) => {
                  const qtnChoice = item?.choices?.find(
                    (ch: any) => ch.id === id
                  );

                  return qtnChoice?.label;
                })
                ?.join(", ")
            : "",
          isCorrect: calculateIsCorrect(type, item) ? "Correct" : "Wrong",
          date: DateTime?.fromISO(item?.created_at)?.isValid
            ? DateTime?.fromISO(item?.created_at)?.toFormat(
                "dd/LL/yyyy hh:mm a"
              )
            : "",
        }));
      case "text":
      case "slider":
        return resData?.map((item: any) => ({
          title: item?.name,
          question: item?.title,
          answer: item?.text_value || "",
          date: DateTime?.fromISO(item?.created_at)?.isValid
            ? DateTime?.fromISO(item?.created_at)?.toFormat(
                "dd/LL/yyyy hh:mm a"
              )
            : "",
        }));
      case "found":
      case "not_found":
        return resData?.map((item: any) => ({
          term: item?.keyword,
          hits: item?.hits || 0,
          date: DateTime?.fromISO(item?.created_at)?.isValid
            ? DateTime?.fromISO(item?.created_at)?.toFormat(
                "dd/LL/yyyy hh:mm a"
              )
            : "",
        }));
      case "reflection":
        return resData?.map((item: any) => ({
          title: item?.title,
          numberValue: item?.number_value?.toString(),
          date: DateTime?.fromISO(item?.updated_at).toFormat(
            "dd/LL/yyyy hh:mm a"
          ),
        }));

      default:
        return [];
    }
  };

  return typeData(type);
};
