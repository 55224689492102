import { Button } from "@mui/material";
import { indxToAlpha } from "../../../utils/education";
import { logCustomGaEvent } from "../../../utils/webviewAnalytics";
import { getTranslation } from "../../../utils/webviewLang";

type Props = {
  spacedLearning: any;
  spacedAnswers: any;
  sectionId: string;
  sectionTitle: string;
  modifyQuestionResponse: Function;
  submitQuestion: Function;
  isQuestionMandatory: boolean;

  pageId: string;
  pageTitle: string;

  handlePagination: Function;
  isDarkMode: boolean;
  lang: string;
};

const qnModified = (
  pageId: string,
  sectionId: string,
  pageTitle: string,
  sectionTitle: string,
  data: any
) => {
  logCustomGaEvent("em_page_sl_qn_ans_modified", {
    page_id: pageId,
    page_title: pageTitle,
    section_id: sectionId,
    section_title: sectionTitle,
    ...data,
  });
};

const navButtonClicked = (
  pageId: string,
  sectionId: string,
  event_name: "em_page_sl_skip_video" | "em_page_sl_watch_video"
) => {
  logCustomGaEvent(event_name, {
    page_id: pageId,
    section_id: sectionId,
  });
};

const SpacedQuestion: React.FC<Props> = ({
  spacedLearning,
  spacedAnswers,
  modifyQuestionResponse,
  submitQuestion,
  isQuestionMandatory,
  sectionId,
  pageTitle,
  sectionTitle,
  pageId,
  handlePagination,
  isDarkMode,
  lang,
}) => {
  const spacedAnswer = spacedAnswers[spacedLearning.id];

  const changeHandler = (choice: any) => {
    if (spacedAnswer?.submitted) {
      return;
    }
    const id = choice?.id;
    if (!spacedLearning.allowMultipleSelection) {
      const ans = [id];
      const ansTitle = [choice.label];
      qnModified(pageId, sectionId, pageTitle, sectionTitle, {
        question_id: spacedLearning.id,
        question_title: spacedLearning.title,
        answer_id: ans.join(","),
        answer_title: ansTitle.join(","),
      });
      modifyQuestionResponse(spacedLearning.id, "questionChoiceIds", ans);
    } else {
      if (spacedAnswer?.questionChoiceIds) {
        if (spacedAnswer?.questionChoiceIds.includes(id)) {
          const ans = spacedAnswer?.questionChoiceIds.filter(
            (aid: any) => aid !== id
          );
          const ansTitle = spacedLearning?.choices
            .filter((ch: any) => ans.includes(ch?.id))
            .map((ch: any) => ch?.label);
          qnModified(pageId, sectionId, pageTitle, sectionTitle, {
            question_id: spacedLearning.id,
            question_title: spacedLearning.title,
            answer_id: ans.join(","),
            answer_title: ansTitle.join(","),
          });
          modifyQuestionResponse(spacedLearning.id, "questionChoiceIds", ans);
        } else {
          const ans = [...spacedAnswer?.questionChoiceIds, id];
          const ansTitle = spacedLearning?.choices
            .filter((ch: any) => ans.includes(ch?.id))
            .map((ch: any) => ch?.label);

          qnModified(pageId, sectionId, pageTitle, sectionTitle, {
            question_id: spacedLearning.id,
            question_title: spacedLearning.title,
            answer_id: ans.join(","),
            answer_title: ansTitle.join(","),
          });
          modifyQuestionResponse(spacedLearning.id, "questionChoiceIds", ans);
        }
      } else {
        const ans = [id];
        const ansTitle = spacedLearning?.choices.find(
          (ch: any) => ch?.id === id
        );
        qnModified(pageId, sectionId, pageTitle, sectionTitle, {
          question_id: spacedLearning.id,
          question_title: spacedLearning.title,
          answer_id: ans.join(","),
          answer_title: [ansTitle?.label].join(","),
        });
        modifyQuestionResponse(spacedLearning.id, "questionChoiceIds", ans);
      }
    }
  };

  return (
    <>
      <div className="question-container" key={spacedLearning.id}>
        <div className="question-title">{spacedLearning.title}</div>
        {spacedLearning.imageUrl && (
          <img
            src={spacedLearning.imageUrl}
            className="question-image"
            alt="question"
          />
        )}
      </div>
      <div className="question-choices">
        {spacedLearning.choices.map((choice: any, index: number) => {
          const selected = spacedAnswer?.questionChoiceIds
            ? spacedAnswer?.questionChoiceIds.includes(choice.id)
            : false;
          return (
            <div
              key={choice.id}
              className={
                selected ? "question-choice selected" : "question-choice"
              }
              onClick={() => changeHandler(choice)}
            >
              {choice.imageUrl && (
                <img
                  src={choice.imageUrl}
                  className="question-choice-image"
                  alt="choice"
                />
              )}
              <span>{indxToAlpha(index) + ". " + choice.label}</span>
            </div>
          );
        })}
      </div>
      {isQuestionMandatory && (
        <div className="compulsory">
          {"(" + getTranslation("question_compulsory", lang) + ")"}
        </div>
      )}
      {spacedAnswer?.submitted && spacedAnswer?.explanation ? (
        spacedAnswer?.explanation === "correct" ? (
          <>
            <div className={"explanation " + spacedAnswer?.explanation}>
              {spacedLearning?.correctExplanation}
            </div>
            <Button
              fullWidth
              onClick={() => {
                navButtonClicked(
                  pageId,
                  spacedLearning.id,
                  "em_page_sl_skip_video"
                );
                handlePagination(2);
              }}
              size="large"
              sx={{
                my: 1,
                backgroundColor: isDarkMode ? "#2E2E2E" : "#FFFFFF",
                color: isDarkMode ? "#fff" : "#181C62",
                textTransform: "none",
                height: "60px",
                fontWeight: 600,
                fontSize: "1.125rem",
              }}
              variant="outlined"
            >
              {getTranslation("skip_video", lang)}
            </Button>
            <Button
              fullWidth
              onClick={() => {
                handlePagination(1, true);
                navButtonClicked(
                  pageId,
                  spacedLearning.id,
                  "em_page_sl_watch_video"
                );
              }}
              sx={{
                my: 1,
                mb: 2,
                textTransform: "none",
                height: "60px",
                fontWeight: 600,
                fontSize: "1.125rem",
              }}
              variant="contained"
              size="large"
            >
              {getTranslation("watch_video", lang)}
            </Button>
          </>
        ) : (
          <div className={"explanation " + spacedAnswer?.explanation}>
            {spacedLearning?.wrongExplanation}
          </div>
        )
      ) : (
        <Button
          sx={{ height: "60px", textTransform: "none", my: 2 }}
          variant="contained"
          fullWidth
          onClick={() =>
            submitQuestion(
              spacedLearning.id,
              spacedLearning.title,
              spacedLearning.choices,
              sectionId,
              sectionTitle
            )
          }
        >
          {getTranslation("submit", lang)}
        </Button>
      )}
    </>
  );
};

export default SpacedQuestion;
