import { useCallback, useEffect, useRef, useState } from "react";
import { ChatWrapper } from "./style";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { AxiosResponse } from "axios";

import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  getPreviewMessages,
  getPreviewStepMessages,
} from "../../../utils/chatbotBuilder";
import UserResponse from "./types/UserResponse";
import BotMessage from "./types/BotMessage";
import BotOptions from "./types/BotOptions";

const MsgTypes: any = {
  bot_choice: BotOptions,
  bot_message: BotMessage,
  user_response: UserResponse,
};

const ChatPreview = () => {
  const [loading, setLoading] = useState(true);
  const [steps, setSteps] = useState<any[]>([]);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBotResponse = async (id: string) => {
      try {
        setLoading(true);
        const response: AxiosResponse = await http.get(`/bots/${id}/steps`);
        const steps = response.data?.data?.botSteps;
        const firstStep = steps.find((step: any) => step?.position === 1);
        if (firstStep) {
          const res = await http.get(`/bots/steps/${firstStep.id}`);
          const previewSteps = getPreviewMessages(res.data?.data);
          setSteps(previewSteps);
          setLoading(false);
        } else {
          toastMessage("warning", "No steps present");
          setLoading(false);
          navigate(-1);
        }
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    if (id) {
      fetchBotResponse(id);
    }
  }, [id, navigate]);

  const showNextType = useCallback(
    (end: boolean = false) => {
      setSteps((prev) => {
        let madeActive = false;
        const newSteps = prev.map((step) => {
          if (!madeActive && !step.active) {
            madeActive = true;
            return {
              ...step,
              active: true,
            };
          } else {
            return step;
          }
        });
        if (!madeActive && !end) {
          return [
            ...newSteps,
            {
              id: "end-id",
              text: "This conversation ended",
              delay: 0,
              chatType: "bot_message",
              active: true,
              end: true,
            },
          ];
        } else {
          return newSteps;
        }
      });
    },
    [setSteps]
  );

  const submitResponse = async (
    answer: any,
    stepId: string,
    stepType: string,
    msgId: string,
    value: any,
    botId: any
  ) => {
    try {
      const body = {
        botStepId: stepId,
        stepType: stepType,
        response: {
          stepOptionId:
            stepType === "multiple_choice" || stepType === "yes_no"
              ? answer
              : undefined,
          textValue:
            stepType === "short_text" || stepType === "long_text"
              ? answer
              : undefined,
          numberValue:
            stepType === "number" || stepType === "slider" ? answer : undefined,
          dateValue: stepType === "calendar" ? answer : undefined,
        },
      };
      const res = await http.post(`/bots/${botId}/preview_next`, body);
      let newSteps = steps.filter((step) => {
        return step.id !== msgId;
      });
      let additionalSteps = [];
      additionalSteps.push({
        id: uuid(),
        chatType: "user_response",
        active: true,
        value: value,
      });
      let active = true;
      let fetchNext = true;
      if (res.data?.data?.prevStep) {
        const { msgs, stopFlag } = getPreviewStepMessages(
          res.data?.data?.prevStep
        );
        fetchNext = !stopFlag;
        if (msgs.length > 0) {
          active = false;
        }
        additionalSteps = [...additionalSteps, ...msgs];
      }
      if (fetchNext && res.data.data.currentStep) {
        const nextSteps = getPreviewMessages(res.data.data.currentStep, active);
        additionalSteps = [...additionalSteps, ...nextSteps];
      }
      if (additionalSteps.length === 1) {
        additionalSteps.push({
          id: "end-id",
          text: "This conversation ended",
          delay: 0,
          chatType: "bot_message",
          active: true,
          end: true,
        });
      }
      setSteps([...newSteps, ...additionalSteps]);
    } catch (err) {
      errorToastMessage(err as Error);
    }
  };

  const fetchNextBot = useCallback(
    async (botStepId: string) => {
      try {
        const res = await http.get(`/bots/steps/${botStepId}`);
        const previewSteps = getPreviewMessages(res.data?.data);
        setSteps((prev) => {
          return [...prev, ...previewSteps];
        });
      } catch (err) {
        errorToastMessage(err as Error);
      }
    },
    [setSteps]
  );

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [steps]);

  return (
    <Box sx={ChatWrapper}>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {steps.map((step: any) => {
        if (step?.active) {
          const Component = MsgTypes[step?.chatType];
          if (Component) {
            return (
              <Component
                step={step}
                key={step?.id}
                showNextType={showNextType}
                submitResponse={submitResponse}
                fetchNextBot={fetchNextBot}
              />
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      })}
      <Box ref={messagesEndRef} key="end-message" />
    </Box>
  );
};

export default ChatPreview;
