import { useCallback, useState } from "react";
import axios, { AxiosResponse } from "axios";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import * as yup from "yup";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { DualInput } from "./settings.style";
import { onboardingSetting } from "./Settings";
import { UploadWrapper } from "../CMS/cms.style";
import { VideoUploadIcon } from "../CMS/Icons";
import { useDropzone } from "react-dropzone";

const uploadPublicFile = async (file: any, type: string) => {
  const fileObj = {
    assets: [{ fileName: file.name, type }],
  };
  const res = await http.post("/assets/get_upload_urls", fileObj);
  await axios.put(res.data.data[0].presignedUploadUrl, file, {
    headers: { "Content-Type": res.data.data[0].mimeType },
  });
  return res.data.data[0]?.withoutSignedHeadersUrl;
};

const UploadItem: React.FC<any> = ({ videoUrl, changeHandler, id }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          // if (file.size > 5 * 1024 * 1024) {
          //   toastMessage("warning", "File Size cannot be greater than 5 MB!");
          //   return;
          // }
          setLoading(true);
          const url = await uploadPublicFile(file, "public");
          changeHandler(url, id);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [changeHandler, id]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "video/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...UploadWrapper, mt: 1, mb: 1 }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {videoUrl ? (
            <Typography variant="subtitle1" fontWeight={"medium"}>
              File available. Drop Files to change
            </Typography>
          ) : (
            <>
              <VideoUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: onboardingSetting[];
  refreshPage: () => void;
};

const EditOnboardingSettingsModal = ({
  showModal,
  closeModal,
  data,
  refreshPage,
}: Props) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [formData, setFormData] = useState<onboardingSetting[]>(
    JSON.parse(JSON.stringify(data))
  );

  const submitHandler = async () => {
    try {
      setButtonLoader(true);
      for (let i = 0; i < formData.length; i++) {
        const value = formData[i].value;
        let schema = yup.string().url();
        if (formData[i].type === "link") {
          const valid = await schema.validate(value);
          if (!value || !valid) {
            toastMessage(
              "warning",
              formData[i].label + " should be a valid link"
            );
            setButtonLoader(false);
            return;
          }
        } else {
          if (!value || !value.trim()) {
            toastMessage("warning", formData[i].label + " cannot be empty");
            setButtonLoader(false);
            return;
          }
        }
      }
      const res: AxiosResponse = await http.put("/app/settings/other", {
        values: formData.map((item) => {
          return {
            id: item.key,
            value: item.value,
          };
        }),
      });
      toastMessage("success", res?.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setButtonLoader(false);
    }
  };

  const changeHandler = (val: any, key: string) => {
    setFormData((formData) => {
      return formData.map((item) => {
        if (item.key === key) {
          const newItem = {
            ...item,
            value: val,
          };
          return newItem;
        } else {
          return item;
        }
      });
    });
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={"Edit Onboarding Settings"}
          onCloseClick={closeModal}
        />
        {formData.map((item) => {
          return (
            <FormControl sx={{ ...InputWrapper, mb: 2 }} key={item.key}>
              <FormLabel sx={LabelStyle}>{item.label}</FormLabel>
              {item?.enableUpload && (
                <>
                  <UploadItem
                    videoUrl={item?.value}
                    changeHandler={changeHandler}
                    id={item?.key}
                  />
                  <Typography sx={{ textAlign: "center", mb: 1 }} variant="h6">
                    OR
                  </Typography>
                </>
              )}
              <Box sx={DualInput}>
                <TextField
                  fullWidth
                  placeholder="Value"
                  value={item.value}
                  onChange={(e) => changeHandler(e.target.value, item.key)}
                />
              </Box>
            </FormControl>
          );
        })}

        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          {!buttonLoader ? (
            <>
              <Button variant="contained" onClick={submitHandler}>
                Save
              </Button>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EditOnboardingSettingsModal;
