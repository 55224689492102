import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import { AxiosResponse } from "axios";
import http from "../../../../utils/http";
import { errorToastMessage } from "../../../../utils/toast";
import { debounce } from "lodash";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { NoDataContainer } from "../../../Common/styles/table";
import { setLoading } from "../../../../Redux/reducers/cmsBuilderSlice";
import { CMSInputLabel } from "../styles";
import { InputWrapper } from "../../../Common/styles/form";

const QuestionAddon = ({ editable, handleAddonChange, question }: any) => {
  const { educationLessonId, educationLessonUnitId, educationLessonPageId } =
    question;

  const { lang } = useAppSelector((state) => state.cmsBuilder);
  const [lessons, setLessons] = useState<any[]>([]);
  const [units, setUnits] = useState<any[]>([]);
  const [pages, setPages] = useState<any[]>([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState<any>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchUnits = async (id: string) => {
      try {
        dispatch(setLoading(true));
        const res: AxiosResponse = await http.get(`/lms/lessons/${id}`);
        const lesson = res.data.data;
        lesson.units.sort((a: any, b: any) => {
          return a.position - b.position;
        });
        setLessons([{ id: lesson?.id, label: lesson?.name }]);
        setSelectedLesson({ id: lesson?.id, label: lesson?.name });
        setUnits(lesson?.units);
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
        errorToastMessage(err as Error);
      }
    };
    if (educationLessonId) {
      fetchUnits(educationLessonId);
    }
  }, [educationLessonId, setUnits, setLessons, dispatch]);

  useEffect(() => {
    if (educationLessonUnitId && units.length > 0) {
      const selectedUnit = units.find(
        (uni) => uni.id === educationLessonUnitId
      );
      if (selectedUnit) {
        setPages(selectedUnit?.pages);
      }
    }
  }, [educationLessonUnitId, units, setPages]);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            const res: AxiosResponse = await http.get(
              `/lms/lessons?page=1&size=20&search=${value}&status=active&lang=${lang}`
            );
            const newLessons = res.data?.data?.educationLessons.map(
              (lesson: any) => {
                return {
                  id: lesson?.id,
                  label: lesson?.name,
                };
              }
            );
            setLessons(newLessons);
            setSearchLoader(false);
          } else {
            setLessons([]);
          }
        } catch (err) {
          setSearchLoader(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    [lang]
  );

  const handleChange = (
    value: any,
    type:
      | "educationLessonUnitId"
      | "educationLessonPageId"
      | "educationLessonId"
  ) => {
    handleAddonChange(value, type);
    if (type === "educationLessonId") setPages([]);
  };

  const handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "educationLessonUnitId" | "educationLessonPageId"
  ) => {
    if (event.target.checked) {
      handleAddonChange("all", type);

      if (type === "educationLessonUnitId") {
        setPages([]);
      }
    } else {
      handleAddonChange("", type);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: 3, mb: 2 }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Education Module</FormLabel>
          <Autocomplete
            freeSolo
            filterOptions={(x) => x}
            onInputChange={(_1: any, value: any, reason: string) => {
              if (reason === "input") {
                handleSearch(value);
              }
            }}
            onChange={(_1: any, value: any) => {
              handleChange(value?.id, "educationLessonId");
            }}
            disabled={!editable}
            isOptionEqualToValue={(option, value) => option?.label === value}
            options={lessons}
            clearOnBlur={true}
            disableClearable
            value={selectedLesson?.label || null}
            loading={searchLoader}
            loadingText={<CircularProgress size={20} />}
            noOptionsText="No Results"
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search module by typing..."
              />
            )}
          />
          <FormControlLabel
            disabled={!educationLessonId}
            control={
              <Checkbox
                checked={educationLessonUnitId === "all"}
                onChange={(e) => handleCheckbox(e, "educationLessonUnitId")}
              />
            }
            label="Entire Module"
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Unit</FormLabel>
          <Select
            value={
              educationLessonUnitId && units.length > 0
                ? educationLessonUnitId
                : ""
            }
            disabled={!editable || educationLessonUnitId === "all"}
            fullWidth
            onChange={(e) =>
              handleChange(e.target.value, "educationLessonUnitId")
            }
          >
            {educationLessonUnitId === "all" ? (
              <MenuItem disabled value="all">
                all
              </MenuItem>
            ) : units.length > 0 ? (
              units?.map((unit: any) => {
                return (
                  <MenuItem value={unit?.id} key={unit?.id}>
                    {unit?.title}
                  </MenuItem>
                );
              })
            ) : (
              <NoDataContainer sx={{ p: 2.5 }}>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            )}
          </Select>
          <FormControlLabel
            disabled={!educationLessonUnitId || educationLessonUnitId === "all"}
            control={
              <Checkbox
                checked={educationLessonPageId === "all"}
                onChange={(e) => handleCheckbox(e, "educationLessonPageId")}
              />
            }
            label="Entire Unit"
          />
        </FormControl>
        <FormControl sx={{ ...InputWrapper, justifyContent: "flex-start" }}>
          <FormLabel sx={CMSInputLabel}>Page</FormLabel>
          <Select
            disabled={
              !editable ||
              educationLessonUnitId === "all" ||
              educationLessonPageId === "all"
            }
            value={
              educationLessonPageId && pages.length > 0
                ? educationLessonPageId
                : ""
            }
            fullWidth
            onChange={(e) =>
              handleChange(e.target.value, "educationLessonPageId")
            }
          >
            {educationLessonPageId === "all" ? (
              <MenuItem value="all" disabled>
                all
              </MenuItem>
            ) : pages.length > 0 ? (
              pages.map((page: any) => {
                return (
                  <MenuItem value={page?.id} key={page?.id}>
                    {page?.title}
                  </MenuItem>
                );
              })
            ) : (
              <NoDataContainer sx={{ p: 2.5 }}>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            )}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default QuestionAddon;
