import { AxiosResponse } from "axios";

import { pageSize } from "../../Components/Common/styles/table";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  setAdministratorsCount,
  setAdministratorsDetails,
  setAdministratorsLoader,
  setAdministratorsPage,
} from "../reducers/administratorsSlice";
import { AppThunk } from "../store";

export const fetchAdministratorsList =
  (
    page: number,
    type: string,
    sortColumn: string,
    sortOrder: string,
    searchText: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAdministratorsLoader(true));
      let url = "";
      if (type === "health_coach") {
        url = `/coach/paginated?page=${page + 1}&size=${pageSize}`;
      } else if (type === "clinical_assistant") {
        url = `/clinical-assistant/paginated?page=${page + 1}&size=${pageSize}`;
      } else {
        url = `/admins?page=${page + 1}&size=${pageSize}&role=${type}`;
      }

      if (sortColumn && sortOrder) {
        url += `&order=${sortOrder}`;
      }
      if (searchText) {
        url += `&search=${searchText}`;
      }
      const res: AxiosResponse = await http.get(url);
      const data = res.data?.data;
      const rows =
        type === "health_coach" || type === "clinical_assistant"
          ? data?.rows
          : data?.users;
      if (rows?.length === 0 && data?.count > 0 && page > 0) {
        dispatch(setAdministratorsPage(0));
        return;
      }

      const newDataList = rows?.map((user: any) => {
        return {
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          status: user?.status,
          studyNumber: user?.code,
          email: user?.email,
        };
      });

      dispatch(setAdministratorsDetails(newDataList || []));
      dispatch(setAdministratorsCount(data?.count));
      dispatch(setAdministratorsLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAdministratorsLoader(false));
    }
  };
