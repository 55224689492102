export const CoachIconPen: React.FC<any> = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          opacity="0.2"
          d="M20.7806 8.4695L18 11.2501L12.75 6.00012L15.5306 3.2195C15.6713 3.07895 15.862 3 16.0608 3C16.2596 3 16.4503 3.07895 16.5909 3.2195L20.7806 7.40637C20.8506 7.47606 20.9062 7.55889 20.9441 7.65012C20.982 7.74134 21.0015 7.83915 21.0015 7.93793C21.0015 8.03672 20.982 8.13453 20.9441 8.22575C20.9062 8.31697 20.8506 8.39981 20.7806 8.4695Z"
          fill="#D71440"
        />
        <path
          d="M21.3113 6.87821L17.1216 2.68946C16.9823 2.55014 16.8169 2.43962 16.6349 2.36421C16.4529 2.28881 16.2578 2.25 16.0608 2.25C15.8638 2.25 15.6687 2.28881 15.4867 2.36421C15.3047 2.43962 15.1393 2.55014 15 2.68946L3.4397 14.2498C3.2998 14.3886 3.18889 14.5538 3.11341 14.7358C3.03792 14.9178 2.99938 15.113 3.00001 15.3101V19.4998C3.00001 19.8976 3.15804 20.2791 3.43935 20.5604C3.72065 20.8417 4.10218 20.9998 4.50001 20.9998H20.25C20.4489 20.9998 20.6397 20.9208 20.7803 20.7801C20.921 20.6395 21 20.4487 21 20.2498C21 20.0509 20.921 19.8601 20.7803 19.7194C20.6397 19.5788 20.4489 19.4998 20.25 19.4998H10.8113L21.3113 8.99977C21.4506 8.86048 21.5611 8.69511 21.6365 8.5131C21.7119 8.33108 21.7507 8.136 21.7507 7.93899C21.7507 7.74198 21.7119 7.5469 21.6365 7.36489C21.5611 7.18288 21.4506 7.0175 21.3113 6.87821ZM4.50001 15.3101L12.75 7.06009L16.9397 11.2498L8.6897 19.4998H4.50001V15.3101ZM18 10.1895L13.8113 5.99977L16.0613 3.74977L20.25 7.93946L18 10.1895Z"
          fill="#D71440"
        />
      </svg>
    </>
  );
};

export const PatientIconPen: React.FC<any> = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
      >
        <path
          d="M10.5 17.5H18.375"
          stroke="#D71440"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4375 3.06319C14.7856 2.71509 15.2577 2.51953 15.75 2.51953C15.9938 2.51953 16.2351 2.56754 16.4603 2.66082C16.6855 2.7541 16.8901 2.89083 17.0625 3.06319C17.2349 3.23555 17.3716 3.44017 17.4649 3.66537C17.5581 3.89057 17.6062 4.13193 17.6062 4.37569C17.6062 4.61944 17.5581 4.86081 17.4649 5.08601C17.3716 5.31121 17.2349 5.51583 17.0625 5.68819L6.125 16.6257L2.625 17.5007L3.5 14.0007L14.4375 3.06319Z"
          stroke="#D71440"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export const LogOutIcon: React.FC<any> = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M17 16L21 12M21 12L17 8M21 12H7M13 16V17C13 17.7956 12.6839 18.5587 12.1213 19.1213C11.5587 19.6839 10.7956 20 10 20H6C5.20435 20 4.44129 19.6839 3.87868 19.1213C3.31607 18.5587 3 17.7956 3 17V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H10C10.7956 4 11.5587 4.31607 12.1213 4.87868C12.6839 5.44129 13 6.20435 13 7V8"
          stroke="#D71440"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
