import { AccMap, QuizMap, SearchMap } from "../../../../../utils/appData";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import HistoryTable from "./HistoryTable";

const History = () => {
  const { id } = useParams();

  return (
    <>
      {AccMap.map((accItem) => {
        const type: string = accItem.value;
        return (
          <Accordion
            key={type}
            TransitionProps={{ unmountOnExit: true }}
            sx={{
              boxShadow: "none",
              border: "1px solid #E5E7EB",
              borderRadius: "8px !important",
              mb: 2.5,
            }}
          >
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <ExpandMore sx={{ fontSize: 24 }} />
                </IconButton>
              }
              sx={{
                ".Mui-expanded": {
                  m: "12px 0px !important",
                },
              }}
            >
              <Box
                sx={{
                  py: 1.5,
                  width: "100%",
                }}
              >
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="secondary"
                  mb={0.5}
                >
                  {accItem.label}
                </Typography>
              </Box>
            </AccordionSummary>
            {id && (
              <AccordionDetails
                sx={{
                  p: 2,
                  mb: 2,
                }}
              >
                {["searches", "quiz"].includes(type) ? (
                  <>
                    {(type === "quiz" ? QuizMap : SearchMap)?.map(
                      (groupItem: any) => (
                        <Accordion
                          TransitionProps={{ unmountOnExit: true }}
                          sx={{
                            boxShadow: "none",
                            border: "1px solid #E5E7EB",
                            borderRadius: "8px !important",
                            mb: 2.5,
                          }}
                          key={groupItem.value}
                        >
                          <AccordionSummary
                            sx={{
                              ".Mui-expanded": {
                                m: "12px 0px !important",
                              },
                            }}
                            expandIcon={
                              <IconButton>
                                <ExpandMore sx={{ fontSize: 24 }} />
                              </IconButton>
                            }
                          >
                            <Box sx={{ py: 0.5 }}>
                              <Typography
                                variant="subtitle2"
                                fontWeight="medium"
                                color="secondary"
                                mb={0.5}
                              >
                                {groupItem?.label}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              p: 2,
                              mb: 2,
                            }}
                          >
                            <HistoryTable
                              key={groupItem.value}
                              type={type}
                              userId={id}
                              subType={groupItem.value}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )
                    )}
                  </>
                ) : (
                  <HistoryTable type={type} userId={id} />
                )}
              </AccordionDetails>
            )}
          </Accordion>
        );
      })}
    </>
  );
};

export default History;
