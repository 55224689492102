import { Box, Divider, IconButton, Modal, Typography } from "@mui/material";
import { ModalBaseStyles } from "../Common/styles/modal";
import CloseIcon from "@mui/icons-material/Close";

const CoachNotificationModal = ({ showModal, closeModal, data }: any) => {
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "15vh" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle2" fontWeight="medium">
            {data?.title}
          </Typography>
          <IconButton onClick={closeModal}>
            <CloseIcon htmlColor="#000" />
          </IconButton>
        </Box>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Typography variant="body1" fontWeight="regular" color="#6B7280">
          {data?.body}
        </Typography>
      </Box>
    </Modal>
  );
};

export default CoachNotificationModal;
