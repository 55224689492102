import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { errorToastMessage } from "../../../../utils/toast";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  Typography,
  Box,
  LinearProgress,
  IconButton,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  StyledTableCell,
  pageSize,
  TablePaginationStyle,
  paginationLabel,
  NoDataContainer,
  StyledSortLabel,
} from "../../../Common/styles/table";
import { FilterAlt } from "@mui/icons-material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import http from "../../../../utils/http";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import {
  setSortColumn,
  setSortOrder,
  setStatinsFilter,
  setStatinsPage,
} from "../../../../Redux/reducers/myPatientsSlice";

const PatientStatins = () => {
  const { statinsPage, statinsFilter, sortOrder, sortColumn } = useAppSelector(
    (state) => state.myPatients
  );
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  // const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  // const [filterId, setFilterId] = useState("");
  const [selectedOption, setSelectedOption] = useState(statinsFilter);

  // const [sortOrder, setSortOrder] = useState("");
  // const [sortColumn, setSortColumn] = useState("");

  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);

  const openFilter = Boolean(filterEl);
  const { id } = useParams();

  const handleChangePage = (_: any, newPage: number) => {
    // setPage(newPage);
    dispatch(setStatinsPage(newPage));
  };

  const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setFilterEl(event?.currentTarget);
  };

  const handleFilterItem = (value: string) => {
    setSelectedOption(value);
  };
  const closeFilterMenu = () => {
    setFilterEl(null);
    if (selectedOption !== statinsFilter) {
      // setFilterId(selectedOption);
      // setPage(0);
      dispatch(setStatinsFilter(selectedOption));
    }
  };
  const clearFilterMenu = () => {
    // setFilterId("");
    dispatch(setStatinsFilter(""));
    setSelectedOption("");
    setFilterEl(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url = `/activity/medications-status?page=${
          statinsPage + 1
        }&size=${pageSize}&userId=${id}&isStatin=true`;

        if (sortOrder && sortColumn) {
          url += `&order=${sortOrder}&sortBy=${sortColumn}`;
        }

        if (statinsFilter) {
          url += `&status=${statinsFilter}`;
        }
        const res = await http.get(url);

        const val = res?.data?.data?.rows?.map((item: any) => {
          return {
            id: item?.id,
            medicationName: item?.medication?.name,
            date: item?.trackDate
              ? DateTime.fromISO(item?.trackDate).toFormat("dd LLL yyyy")
              : "-",
            isSkipped: item?.isSkipped,
            isMissed: item?.isMissed || false,
          };
        });
        setData(val);
        setLoading(false);
        setTotalCount(res?.data?.data?.count);
      } catch (error) {
        errorToastMessage(error as Error);
        setLoading(false);
      }
    };
    fetchData();
  }, [
    setLoading,
    setData,
    setTotalCount,
    statinsPage,
    statinsFilter,
    id,
    sortOrder,
    sortColumn,
  ]);

  const handleSort = (order: string, column: string) => {
    if (sortColumn === column && sortOrder === order) {
      dispatch(setSortOrder(""));
      dispatch(setSortColumn(""));
    } else {
      dispatch(setSortOrder(order));
      dispatch(setSortColumn(column));
    }
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell width="33%">
              Date
              <SortLabel column="trackDate" />
            </StyledTableCell>
            <StyledTableCell width="33%">
              Medication name
              <SortLabel column="name" />
            </StyledTableCell>
            <StyledTableCell width="33%">
              Status
              <IconButton onClick={openFilterMenu}>
                <FilterAlt color={statinsFilter ? "primary" : "disabled"} />
              </IconButton>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {!loading && data.length > 0 && (
          <>
            <TableBody>
              {data.map((item: any) => (
                <TableRow key={item.id}>
                  <StyledTableCell>{item.date}</StyledTableCell>
                  <StyledTableCell>
                    {item.medicationName || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      color: item?.isMissed
                        ? "#6C727F"
                        : item?.isSkipped
                        ? "#F05252"
                        : "#29B355",
                      width: "75%",
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.isMissed
                      ? "missed"
                      : item?.isSkipped
                      ? "skipped"
                      : "taken"}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {totalCount > pageSize && (
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={totalCount}
                    page={statinsPage}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                )}
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {!loading && data.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No data available
          </Typography>
        </NoDataContainer>
      )}

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <Menu
        anchorEl={filterEl}
        open={openFilter}
        onClose={closeFilterMenu}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "20ch",
          },
        }}
      >
        {["taken", "skipped", "missed"]?.map((item: any) => (
          <MenuItem
            selected={item === selectedOption}
            key={item}
            onClick={() => handleFilterItem(item)}
          >
            <ListItemIcon>
              {item === selectedOption ? (
                <RadioButtonCheckedIcon fontSize="small" color="primary" />
              ) : (
                <RadioButtonUncheckedIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {item}
            </ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button variant="text" onClick={clearFilterMenu}>
            Reset
          </Button>
          <Button variant="contained" onClick={closeFilterMenu}>
            Ok
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default PatientStatins;
