import { Box, Rating, Typography } from "@mui/material";
import {
  ImageContainer,
  NoDataContainer,
  foodDetails,
  foodItemDetails,
  foodName,
  foodWrapper,
} from "./style";
import { EmptyFood } from "../../Common/assets/FoodJournalIcons";
import { DateTime } from "luxon";
import { useState } from "react";
import ImagePreview from "./ImagePreview";

const ratingMap: any = {
  not_very: {
    rating: 1,
    label: "Not very",
  },
  somewhat: {
    rating: 2,
    label: "Somewhat",
  },
  mostly: {
    rating: 3,
    label: "Mostly",
  },
};

const Food = ({ food }: any) => {
  const [showPreview, setShowPreview] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImagePreview = (visible: boolean, url: "string" | null) => {
    setShowPreview(visible);
    setSelectedImage(url);
  };

  return (
    <Box
      sx={{
        // border: 1,
        // borderRadius: 1,
        // borderColor: "divider",
        p: 1.5,
        m: 1.5,
      }}
    >
      <Typography variant="subtitle2" fontWeight={600} mb={2}>
        Food
      </Typography>
      {food.length > 0 ? (
        <Box sx={foodWrapper}>
          {food.map((data: any, index: number) => (
            <Box sx={foodDetails} key={index}>
              <Box sx={foodName}>
                {data?.url ? (
                  <Box
                    sx={{
                      ...ImageContainer,
                      height: "121px",
                    }}
                  >
                    <img
                      src={data?.url}
                      alt="Item"
                      onClick={() => handleImagePreview(true, data?.url)}
                    />
                  </Box>
                ) : (
                  <Box>
                    <EmptyFood />
                  </Box>
                )}

                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="subtitle2"
                    fontWeight="medium"
                    color="#216B64"
                    textTransform={"capitalize"}
                  >
                    {data?.name}
                  </Typography>

                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    mt={0.5}
                    textTransform={"capitalize"}
                  >
                    {data?.mealSize}
                    <Typography
                      component="span"
                      variant="body1"
                      fontWeight="regular"
                      color="#676970"
                      textTransform="none"
                    >
                      {" "}
                      meal
                    </Typography>
                  </Typography>

                  <Box sx={foodItemDetails}>
                    <Box
                      sx={{
                        px: 1,
                        py: 0.5,
                        fontWeight: 500,
                        color: "#4D4F56",
                        fontSize: "15px",
                        backgroundColor: "#F2F2F3",
                        borderRadius: "4px",
                        mt: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Rating
                        max={3}
                        value={ratingMap[data?.healthiness]?.rating}
                        size="small"
                        readOnly
                      />
                      <Typography
                        component="span"
                        variant="body1"
                        fontWeight="regular"
                        color="#676970"
                      >
                        {ratingMap[data?.healthiness]?.label}
                      </Typography>
                    </Box>
                    {data?.notes && (
                      <Box
                        sx={{
                          mt: 1,
                          fontWeight: 500,
                          fontSize: "13px",
                          color: "#4d4f56",
                          padding: "6px",
                          backgroundColor: "#F2F2F3",
                          borderRadius: "5px",
                          display: "inline-block",
                        }}
                      >
                        Notes: {data?.notes}
                      </Box>
                    )}

                    <Typography
                      sx={{
                        mt: 1,
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#676970",
                      }}
                    >
                      {DateTime.fromISO(data?.takenOn).toFormat("hh:mm a")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={NoDataContainer}>
          <Typography variant="subtitle1" fontWeight="regular" color={"gray"}>
            Data Unavailable
          </Typography>
        </Box>
      )}
      {showPreview && (
        <ImagePreview
          open={showPreview}
          handleClose={handleImagePreview}
          url={selectedImage}
        />
      )}
    </Box>
  );
};

export default Food;
