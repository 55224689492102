import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SectionType from "./SectionType";
import { cards } from "../../CMSTypes";
import { useDrop } from "react-dnd";
import React from "react";
import SectionItem from "./SectionItem";
import { toastMessage } from "../../../../utils/toast";
import { Box, Button, Typography } from "@mui/material";
import {
  CardContainer,
  SectionContainer,
  SectionDivider,
  TypeCardContainer,
} from "../styles";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import {
  addSection,
  clearSection,
  setEditMode,
} from "../../../../Redux/reducers/cmsBuilderSlice";

const SectionBuilder: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const { sections } = useAppSelector((state) => state.cmsBuilder);

  const deleteAll = () => {
    dispatch(clearSection());
  };

  const updateSections = () => {
    if (sections.length > 0) {
      dispatch(setEditMode(true));
    } else {
      toastMessage("warning", "Add atleast one block to proceed");
    }
  };

  const [, drop] = useDrop({
    accept: cards.map((card: any) => card.type),
    drop: (dropItem: any, monitor) => {
      if (!dropItem.sort && !monitor.didDrop()) {
        dispatch(addSection({ type: dropItem.type }));
      }
    },
  });

  return (
    <>
      <Box sx={CardContainer}>
        <Typography variant="h6" fontWeight={"bold"} mb={1}>
          Choose a block to add to your unit
        </Typography>
        <Typography variant="body1" fontWeight="regular" mb={2}>
          Drag and drop block to the section list
        </Typography>
        <Box sx={TypeCardContainer}>
          {cards.map((card: any) => {
            return <SectionType card={card} key={card.key} />;
          })}
        </Box>
      </Box>
      <Box sx={SectionDivider}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography variant="h6" fontWeight={"bold"} mb={1}>
              Unit layout
            </Typography>
            <Typography variant="body1" fontWeight="regular" mb={2}>
              Arrange the blocks below
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <Button variant="outlined" onClick={deleteAll}>
              Clear sections
            </Button>
            <Button variant="outlined" onClick={updateSections}>
              Edit sections
            </Button>
          </Box>
        </Box>
        <Box sx={SectionContainer} ref={drop}>
          {sections.map((card: any, index: number) => {
            return <SectionItem key={card.key} card={card} index={index} />;
          })}
        </Box>
      </Box>
    </>
  );
});

const SectionBuilderWrapper: React.FC = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <SectionBuilder />
    </DndProvider>
  );
};

export default React.memo(SectionBuilderWrapper);
