import { SxProps } from "@mui/material";

export const JournalWrapper: SxProps = {
  pt: 1.5,
  pb: 5,
  px: 2.5,
  bgcolor: "#f9fafb",
  height: "100%",
};

export const JournalCard: SxProps = {
  minHeight: "fit-content",
  height: "100%",
  borderRadius: "8px",
  p: 2.5,
  border: 1,
  borderColor: "divider",
  bgcolor: "#FFFFFF",
};

export const headerContainer: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  mb: 5,
  pt: 1.25,
};

export const profileCard: SxProps = {
  borderRadius: "8px",
  border: 1,
  width: "100%",
  p: 3,
  borderColor: "divider",
  display: "grid",
  gridTemplateColumns: {
    xl: "repeat(6, 1fr)",
    md: "repeat(4, 1fr)",
    sm: "repeat(3, 1fr)",
    xs: "repeat(auto-fill , 1fr)",
  },
  columnGap: "24px",
  rowGap: "48px",
  // gap: "24px",
};

export const historyCard: SxProps = {
  borderRadius: "8px",
  border: 1,
  width: "100%",
  p: 3,
  borderColor: "divider",
  backgroundColor: "#F8F9FF",
  display: "flex",
  flexDirection: "column",
  gap: 2,
};

export const notesCard: SxProps = {
  borderRadius: "8px",
  border: 1,
  width: "100%",
  p: 3,
  borderColor: "divider",

  display: "flex",
  flexDirection: "column",
  gap: 2,
};

export const notesLabel: SxProps = {
  color: "secondary.main",
  fontWeight: "600",
  fontSize: "14px",
  marginBottom: "16px",
};

export const OpenTokIconStyle: SxProps = {
  fontSize: "30px",
};

export const PublisherMiniWindowWrapper = {
  position: "absolute",
  top: "20px",
  right: "100px",
  zIndex: 999,
  borderRadius: "4px",
  overflow: "hidden",
};

export const PublisherIconWrapper: SxProps = {
  height: 35,
  width: 35,
  borderRadius: "50%",
  bgcolor: "#0F1821",
  opacity: 0.7,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const ControlsWrapper: SxProps = {
  position: "fixed",
  bottom: "76px",
  zIndex: 999,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  paddingInline: 5,
};

export const dateWrapper: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50px",
  padding: "8px 16px",
  borderRadius: "4px",
  border: "1.5px solid",
  cursor: "pointer",
};

export const GridContainer: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill , minmax(210px , 1fr))",
  gap: 2,
};
