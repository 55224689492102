import { Box, IconButton, Typography } from "@mui/material";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { setRefreshNotification } from "../../Redux/reducers/notificationSlice";
import { useAppDispatch } from "../../Redux/hooks";
import { Delete } from "@mui/icons-material";

const NotificationList = ({
  data,
  setAnchorElNotification,
  unreadNotificationCount,
  openModal,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const markAsRead = async (id?: any) => {
    try {
      if (id) {
        const url = `/notifications/${id}`;
        const body = { isRead: true };
        await http.put(url, body);
      } else {
        const url = `notifications/all`;
        const body = { isRead: true };
        await http.patch(url, body);
      }

      dispatch(setRefreshNotification());
    } catch (error) {
      errorToastMessage(error as Error);
    }
  };

  function navigateToNotificationPage() {
    try {
      navigate(`/app/coach-notification?type=notifications`);
      setAnchorElNotification(null);
    } catch (error) {
      errorToastMessage(error as Error);
    }
  }

  const openDeleteModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    if (item?.isRead) {
      event?.stopPropagation();
      openModal({
        data: item?.id,
        type: "notification",
      });
    }
  };

  return (
    <>
      {data?.length !== 0 ? (
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: "6px",
            border: "1px solid rgba(33, 33, 33, 0.10)",
            boxShadow: "6px 6px 38px 0px rgba(0, 0, 0, 0.06)",
            width: "22.75rem",
            maxHeight: "30rem",
            minHeight: "10rem",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minHeight: "2.5rem",
              alignItems: "center",
              padding: ".5rem",
              borderBottom: "1px solid  #E0E3EB",
            }}
          >
            <Typography variant="subtitle1" fontWeight="medium">
              Notifications
            </Typography>
            {unreadNotificationCount !== 0 && (
              <Typography
                variant="body1"
                color="secondary"
                fontWeight="regular"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  markAsRead();
                }}
              >
                Mark all as read
              </Typography>
            )}
          </Box>
          {data?.map((item: any) => (
            <Box
              key={item.id}
              sx={{
                // borderRadius: "5px 5px 0px 0px",
                borderBottom: "1px solid #E0E3EB",
                background: item?.read ? "transparent" : "#FEF1F4",
                minHeight: "7rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: item?.isRead ? "default" : "pointer",
                paddingRight: ".5rem",
              }}
              onClick={() => {
                if (!item.isRead) {
                  markAsRead(item.id);
                }
              }}
            >
              {/* <Box
                sx={{
                  borderRadius: "8px",
                  height: "3.2rem",
                  width: "4rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "1rem",
                  background: "#D714401A",
                }}
              >
                <Logo />
              </Box> */}
              <Box
                sx={{
                  marginLeft: ".5rem",
                  width: "22rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ flex: 1, gap: 2 }}>
                      <Typography
                        variant="body1"
                        fontWeight="regular"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "19rem",
                        }}
                      >
                        {item?.heading}
                      </Typography>
                      <Typography
                        fontSize={12}
                        fontWeight="light"
                        sx={{
                          color: "#6B7280",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "wrap",
                          maxHeight: "2.5rem",
                          maxWidth: "19rem",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item?.body}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        disabled={!item?.isRead}
                        color="error"
                        onClick={(e) => openDeleteModal(e, item)}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#6B7280",
                        alignSelf: "flex-end",
                      }}
                    >
                      {item?.time}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#6B7280",
                        alignSelf: "flex-end",
                      }}
                    >
                      {item?.date}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              minHeight: "2.5rem",
              alignItems: "center",
              padding: ".5rem",
              borderBottom: "1px solid  #E0E3EB",
            }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              fontWeight="regular"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigateToNotificationPage();
              }}
            >
              See all notifications
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            borderRadius: "5px 5px 0px 0px",
            borderBottom: "1px solid  #E0E3EB",
            minHeight: "15rem",
            display: "flex",
            flexDirection: "row",
            paddingTop: "2rem",
            justifyContent: "center",
            width: "18.75rem",
          }}
        >
          <Typography variant="subtitle1" fontWeight="light" color={"#808080"}>
            There are no notifications
          </Typography>
        </Box>
      )}
    </>
  );
};

export default NotificationList;
