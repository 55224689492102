import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../../Redux/hooks";
import { setParticipantDefaults } from "../../../Redux/reducers/participantsSlice";
import { pageSize } from "../../Common/styles/table";

const ParticipantUrlLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const type = searchParams.get("type") || "active";
    const search = searchParams.get("search") || "";
    const filterId = searchParams.get("filter") || "";
    const page = parseInt(searchParams.get("page") || "0") || 0;
    const activeTabType = searchParams.get("tab") || "assigned";
    dispatch(
      setParticipantDefaults({
        filterId,
        search,
        type,
        pagination: {
          page,
          pageSize,
        },
        activeTabType,
      })
    );
  }, [searchParams, dispatch]);

  return <></>;
};

export default React.memo(ParticipantUrlLoader);
