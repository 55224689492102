import { SxProps } from "@mui/material";

export const journalWrapper: SxProps = {
  pt: 1.5,
  pb: 5,
  px: 2.5,
  bgcolor: "#f9fafb",
  height: "100%",
  overflow: "auto",
};

export const journalContainer: SxProps = {
  // borderRadius: "8px",
  p: 1.5,
  // border: 1,
  // borderColor: "divider",
  bgcolor: "#FFFFFF",
};

export const headerContainer: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  mb: 5,
  // pt: 1.25,
};

export const foodContainer: SxProps = {
  display: "flex",
  p: 2.5,
  gap: "20px",
  alignItems: "flex-start",
};

export const foodWrapper: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
  gridGap: "20px",
};

export const foodBoxes: SxProps = {
  flex: 1,
  border: 1,
  borderColor: "divider",
  borderRadius: "8px",
  minWidth: "1px",
  padding: 2,
};
export const foodDetails: SxProps = {
  border: 1,
  borderColor: "divider",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
};
export const foodName: SxProps = {
  display: "flex",
  gap: 2,
  px: 1.75,
  pt: 1.75,
  pb: 2,
};

export const ImageContainer: SxProps = {
  width: "52px",
  minWidth: "52px",
  borderRadius: "15px",
  marginRight: "8px",
  overflow: "hidden",
  bgcolor: "lightgray",
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    cursor: "pointer",
  },
};

export const foodItemDetails: SxProps = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
};

export const medicationWrapper: SxProps = {
  // border: 1,
  // borderRadius: 1,
  // borderColor: "divider",
  p: 1.5,
  width: "100%",
};
export const medicationDetails: SxProps = {
  border: 1,
  borderColor: "divider",
  borderRadius: "16px",
};

export const medicationName: SxProps = {
  display: "flex",
  gap: 2,
  width: "100%",
  p: 1.75,
};

export const footContainerWrapper: SxProps = {
  p: 2.5,
  pb:4,
  
  // border: 1,
  // borderRadius: 1,
  // borderColor: "divider",
  width: "50%",
};

export const footWrapper: SxProps = {
  display: "flex",
  gap: 2,
  border: 1,
  borderRadius: 2,
  borderColor: "divider",
  p: 1.75,
  flexWrap: "wrap",
  position: "relative",
};

export const feetImageContainer: SxProps = {
  ".footImage": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    cursor: "pointer",
  },
  width: "70px",
  height: "70px",
  // backgroundColor: "#F9F3F3",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  borderRadius: 1,
};

export const footDetails: SxProps = {
  display: "flex",
  gap: 1.5,
  alignItems: "flex-start",
  mt: 1.5,
};
export const footType: SxProps = {
  mb: 1.5,
};

export const borderedContainer = {
  border: 1,
  borderRadius: 2,
  borderColor: "divider",
};

export const NoDataContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "200px",
};

export const PreviewImageWrapper = {
  height: "65%",
  overflow: "hidden",
  ".previewImage": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
};
