import { Box, IconButton, Modal } from "@mui/material";
// import { ModalBaseStyles } from "../../../../Common/styles/modal";
import CloseIcon from "@mui/icons-material/Close";
const ChatImageModal = ({ showModal, closeModal, url }: any) => {
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "transparent",
          outline: "none",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "inline-block",
          }}
        >
          <img
            src={url}
            alt="attachment"
            style={{
              width: "auto",
              maxWidth: "90vw",
              maxHeight: "90vh",
              objectFit: "contain",
              borderRadius: "0.7rem",
            }}
          />

          <Box
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              zIndex: 1,
            }}
          >
            <IconButton
              onClick={closeModal}
              sx={{
                backgroundColor: "rgba(0,0,0,0.3)",
                "&:hover": { backgroundColor: "rgba(0,0,0,0.5)" },
                height: "5vh",
                width: "5vh",
              }}
            >
              <CloseIcon
                htmlColor="#ffffff"
                sx={{ height: "3vh", width: "3vh" }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChatImageModal;
