import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../Redux/hooks";
import { DateTime } from "luxon";

const MypatientsUrlSetter = () => {
  const {
    myPatientsTab,
    patientName,
    journalType,
    journalFilter,
    journalPage,
    statinsPage,
    statinsFilter,
    analyticsType,
    logsType,
    logsStartDate,
    logsEndDate,
    logsPage,
    // dairyEndDate,
    logsFilter,
    sortColumn,
    sortOrder,
  } = useAppSelector((state) => state.myPatients);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    const params = new URLSearchParams();
    if (myPatientsTab) {
      params.set("tab", myPatientsTab);
    }

    if (patientName) {
      params.set("user", patientName);
    }

    if (myPatientsTab === "journal") {
      params.set("page", journalPage.toString());
      if (journalType) params.set("type", journalType);
      if (journalFilter) params.set("filter", journalFilter);
    }

    if (myPatientsTab === "statins") {
      params.set("page", statinsPage.toString());
      if (statinsFilter) params.set("filter", statinsFilter);
    }

    if (myPatientsTab === "learn") {
      if (analyticsType) params.set("type", analyticsType);
    }

    if (myPatientsTab === "logs") {
      params.set("page", logsPage.toString());
      if (logsType) params.set("type", logsType);
      if (logsStartDate)
        params.set(
          "startDate",
          DateTime?.fromISO(logsStartDate)?.toFormat("dd-LL-yyyy")
        );
      if (logsEndDate)
        params.set(
          "endDate",
          DateTime?.fromISO(logsEndDate)?.toFormat("dd-LL-yyyy")
        );
      if (logsFilter) params.set("filter", logsFilter);
    }

    // if (myPatientsTab === "dairies") {
    //   if (dairyEndDate)
    //     params.set(
    //       "date",
    //       DateTime.fromISO(dairyEndDate).toFormat("dd-LL-yyyy")
    //     );
    // }

    if (sortOrder) {
      params.set("order", sortOrder);
    }

    if (sortColumn) {
      params.set("sort", sortColumn);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [
    setSearchParams,
    myPatientsTab,
    patientName,
    journalType,
    journalFilter,
    journalPage,
    statinsPage,
    statinsFilter,
    analyticsType,
    // dairyEndDate,
    logsType,
    logsPage,
    logsFilter,
    logsStartDate,
    logsEndDate,
    sortColumn,
    sortOrder,
  ]);

  return <></>;
};

export default MypatientsUrlSetter;
