import { Box } from "@mui/material";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../../../../Common/UI/TabPanel";
import History from "./History";
import Analytics from "./Analytics";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { setAnalyticsTab } from "../../../../../Redux/reducers/myPatientsSlice";

const LearningAnalytics = () => {
  const dispatch = useAppDispatch();
  const { analyticsType } = useAppSelector((state) => state.myPatients);

  const handleChange = (_: any, newType: string) => {
    dispatch(setAnalyticsTab(newType));
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={analyticsType} onChange={handleChange}>
          <StyledTab label="Active data" value="active" {...a11yProps(0)} />
          <StyledTab label="Passive data" value="passive" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 282px)",
          overflow: "auto",
        }}
      >
        <MainTabPanel value={analyticsType} index={"active"}>
          <History />
        </MainTabPanel>
        <MainTabPanel value={analyticsType} index={"passive"}>
          <Analytics />
        </MainTabPanel>
      </Box>
    </>
  );
};

export default LearningAnalytics;
