import { AxiosResponse } from "axios";

import { pageSize } from "../../Components/Common/styles/table";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  setParticipantsLoader,
  setParticipantsCount,
  setParticipantsDetails,
  setParticipantsPage,
} from "../reducers/participantsSlice";
import { AppThunk } from "../store";
import { DateTime } from "luxon";

export const fetchParticipantsList =
  (
    page: number,
    type: string,
    filterId: string,
    searchText: string,
    sortColumn: string,
    sortOrder: string,
    hasHealthCoachAccess: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setParticipantsLoader(true));
      let newList = [];
      let data;
      if (hasHealthCoachAccess) {
        let url = `/coach/participant/active?page=${page + 1}&size=${pageSize}`;

        if (searchText) {
          url += `&search=${searchText}`;
        }

        if (sortColumn && sortOrder) {
          url += `&sortBy=${sortColumn}&order=${sortOrder}`;
        }

        const res: AxiosResponse = await http.get(url);
        data = res.data?.data;
        if (data?.users?.length === 0 && data?.count > 0 && page > 0) {
          dispatch(
            setParticipantsPage({
              page: 0,
              pageSize,
            })
          );
          return;
        }

        newList = data?.rows.map((user: any) => {
          return {
            id: user?.participantId,
            studyNumber: user?.participant?.code,
            // studyNumber: type !== "unregistered" ? user?.code : user?.studyNumber,
            name: user?.participant?.participantOnboarding?.data?.nickname,
            lastActive: user?.participant?.lastActiveAt
              ? DateTime?.fromISO(user?.participant?.lastActiveAt)?.toRelative()
              : "",
            nextMeeting: user?.participant?.consultation
              ? DateTime?.fromISO(
                  user?.participant?.consultation?.scheduledAt
                )?.toFormat("dd LLL yyyy, hh a")
              : "",
            lastMeeting: user?.participant?.lastMeeting
              ? DateTime?.fromISO(
                  user?.participant?.lastMeeting?.scheduledAt
                )?.toFormat("dd LLL yyyy, hh a")
              : "",
          };
        });
      } else {
        let url = `/participants?page=${
          page + 1
        }&size=${pageSize}&status=${type}`;
        if (filterId) {
          url += `&doctorId=${filterId}`;
        }
        if (type === "unregistered") {
          url = `/participants/import-study-numbers?page=${
            page + 1
          }&size=${pageSize}`;
        }
        if (searchText) {
          url += `&search=${searchText}`;
        }
        const res: AxiosResponse = await http.get(url);
        data = res.data?.data;
        if (
          type !== "unregistered" &&
          data?.users?.length === 0 &&
          data?.count > 0 &&
          page > 0
        ) {
          dispatch(
            setParticipantsPage({
              page: 0,
              pageSize,
            })
          );
          return;
        }
        if (
          type === "unregistered" &&
          data?.rows?.length === 0 &&
          data?.count > 0 &&
          page > 0
        ) {
          dispatch(
            setParticipantsPage({
              page: 0,
              pageSize,
            })
          );
          return;
        }

        newList = (data?.users || data?.rows).map((user: any) => {
          return {
            id: user?.id,
            studyNumber:
              type !== "unregistered" ? user?.code : user?.studyNumber,
            name: user?.participant?.nickname,
            doctor:
              !user?.doctor?.doctor?.firstName &&
              !user?.doctor?.doctor?.lastName
                ? "-"
                : (user?.doctor?.doctor?.firstName || "") +
                  " " +
                  (user?.doctor?.doctor?.lastName || ""),
            onboardingDate: user?.participantOnboarding?.updatedAt
              ? DateTime.fromISO(
                  user?.participantOnboarding?.updatedAt
                ).toFormat("dd/LL/yyyy")
              : "-",
            type:
              user?.participant?.isSelf === null
                ? "-"
                : user?.participant?.isSelf
                ? "Patient"
                : "Caregiver",
            status: user?.status,
          };
        });
      }

      dispatch(setParticipantsDetails(newList));
      dispatch(setParticipantsCount(data?.count));
      dispatch(setParticipantsLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setParticipantsLoader(false));
    }
  };

export const fetchAdminViewParticipantsList =
  (
    page: number,
    assigned: boolean,
    filterId: string,
    searchText: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setParticipantsLoader(true));
      let url = `/participants/all/paginated?page=${
        page + 1
      }&size=${pageSize}&assigned=${!assigned}&status=active`;
      if (filterId) {
        url += `&doctorId=${filterId}`;
      }
      if (searchText) {
        url += `&search=${searchText}`;
      }
      const res: AxiosResponse = await http.get(url);
      const data = res.data?.data;
      if (data?.rows?.length === 0 && data?.count > 0 && page > 0) {
        dispatch(
          setParticipantsPage({
            page: 0,
            pageSize,
          })
        );
        return;
      }

      const newList = data?.rows?.map((user: any) => {
        return {
          id: !assigned ? user?.participantId : user?.id,
          studyNumber: !assigned ? user?.participant?.code : user?.code,
          doctor:
            !user?.doctor?.firstName && !user?.doctor?.lastName
              ? "-"
              : (user?.doctor?.firstName || "") +
                " " +
                (user?.doctor?.lastName || ""),
          status: !assigned ? user?.participant?.status : user?.status,
          onboardingDate: !assigned
            ? user?.participant?.participantOnboarding?.updatedAt
              ? DateTime.fromISO(
                  user?.participant?.participantOnboarding?.updatedAt
                ).toFormat("dd/LL/yyyy")
              : "-"
            : user?.participantOnboarding?.updatedAt
            ? DateTime.fromISO(user?.participantOnboarding?.updatedAt).toFormat(
                "dd/LL/yyyy"
              )
            : "-",
        };
      });

      dispatch(setParticipantsDetails(newList));
      dispatch(setParticipantsCount(data?.count));
      dispatch(setParticipantsLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setParticipantsLoader(false));
    }
  };
