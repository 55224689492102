import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Popover,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { initializeFirebaseApp } from "../../utils/firebase";
import { BellIcon, ChatIcon } from "../Common/assets/HeaderIcons";
import ChatNotifications from "./ChatNotifications";
import NotificationList from "./NotificationList";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { DateTime } from "luxon";
import { getMessaging, isSupported, onMessage } from "firebase/messaging";
import { app, auth } from "../../utils/firebaseInit";
import { resetState } from "../../Redux/actions/resetAction";
import {
  setRefreshExercises,
  // setRefreshNotifTable,
  setRefreshNotification,
} from "../../Redux/reducers/notificationSlice";
import DeleteNotificationModal from "./DeleteNotificationModal";
import { getDateString } from "../../utils/notification";

const playAudio = () => {
  try {
    const audio = new Audio("/Notification.mp3");
    audio.play();
  } catch (err) {}
};

const Header = () => {
  const { hasHealthCoachAccess, role } = useAppSelector((state) => state.user);
  const { refreshNotifications } = useAppSelector(
    (state) => state.notification
  );
  const [unreadChatCount, setUnreadChatCount] = useState(0);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [anchorElChat, setAnchorElChat] = useState<null | HTMLElement>(null);
  // const [refreshPage, setRefreshPage] = useState(false);
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [chatNotificationData, setChatNotificationData] = useState<any[]>([]);

  const [anchorElNotification, setAnchorElNotification] =
    useState<null | HTMLElement>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const firstName = localStorage.getItem("userFirstName");
  const lastName = localStorage.getItem("userLastName");

  const [modalData, setModalData] = useState<any>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const dispatch = useAppDispatch();

  const logout = async () => {
    try {
      const res: AxiosResponse = await http.post("/auth/logout");
      auth
        .signOut()
        .then()
        .catch((err) => {
          console.log(err);
        });
      sessionStorage.setItem("role", role === "master_admin" ? "admin" : role);
      toastMessage("success", res.data.message);
    } catch (err) {
      console.log(err);
    } finally {
      localStorage.clear();
      dispatch(resetState());
    }
  };

  const handleChatClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElChat(event.currentTarget);
  };

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(anchorElNotification ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElNotification(null);
    setAnchorElChat(null);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (hasHealthCoachAccess) initializeFirebaseApp();
  }, [hasHealthCoachAccess]);

  useEffect(() => {
    const getMessage = async (listener: any) => {
      const messaging = await isSupported()
        .then((support) => {
          if (support) {
            return getMessaging(app);
          } else {
            return false;
          }
        })
        .catch(() => {
          return null;
        });
      try {
        if (messaging) {
          onMessage(messaging, (payload) => {
            dispatch(setRefreshNotification());
            // dispatch(setRefreshNotifTable());
            playAudio();
            if (
              payload?.data?.eventId === "self_reflection_assignment_completed"
            ) {
              dispatch(setRefreshExercises());
            }
          });
          navigator.serviceWorker.addEventListener("message", listener);
        }
      } catch (err) {
        console.error(err);
      }
    };
    const listener = (event: any) => {
      if (event.data.message === "notification_received") {
        dispatch(setRefreshNotification());
        // dispatch(setRefreshNotifTable());
        if (event?.data?.event === "self_reflection_assignment_completed") {
          dispatch(setRefreshExercises());
        }
      } else if (event.data.message === "notification_sound") {
        playAudio();
      }
    };
    getMessage(listener);
    return () => {
      navigator.serviceWorker.removeEventListener("message", listener);
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchNotificationData = async () => {
      try {
        const url = `/notifications`;
        const res: AxiosResponse = await http.get(url);
        const resNotificationData = res.data?.data?.notifications;
        const newNotificationData = resNotificationData?.map((item: any) => {
          return {
            ...item,
            heading: item?.title,
            time: DateTime.fromISO(item?.createdAt).toFormat("hh:mm a"),
            date: getDateString(item?.createdAt),
            read: item?.isRead,
          };
        });

        setUnreadNotificationCount(res.data?.data?.unreadCount);
        setNotificationData(newNotificationData);
      } catch (error) {
        errorToastMessage(error as Error);
      }
    };
    if (hasHealthCoachAccess) {
      fetchNotificationData();
    }
  }, [hasHealthCoachAccess, refreshNotifications]);

  useEffect(() => {
    //Chat Notification Get
    const fetchChatNotifcation = async () => {
      try {
        const url = `/notifications/chat/grouped`;
        const res: AxiosResponse = await http.get(url);
        const newChatData = res?.data?.data?.data?.map((item: any) => {
          return {
            id: item?.code,
            patientId: item?.mostRecentEntry?.data?.data?.participantId,
            patientCode: item?.code,
            gender: item?.mostRecentEntry?.data?.data?.gender,
            message: item?.mostRecentEntry?.data?.params?.message,
            time: item?.mostRecentEntry?.createdAt
              ? DateTime.fromISO(item?.mostRecentEntry?.createdAt).toFormat(
                  "hh:mm a"
                )
              : "",
            isRead: item?.mostRecentEntry?.isRead,
            day: getDateString(item?.mostRecentEntry?.createdAt),
            unreadCount: item?.unreadCount,
          };
        });
        setChatNotificationData(newChatData || []);
        setUnreadChatCount(+res.data?.data?.totalUnreadCount || 0);
      } catch (error) {
        errorToastMessage(error as Error);
      }
    };
    if (hasHealthCoachAccess) {
      fetchChatNotifcation();
    }
  }, [hasHealthCoachAccess, refreshNotifications]);

  const openModal = (data: { data: string; type: string }) => {
    setModalData(data);
  };

  const closeModal = () => {
    setModalData(null);
  };

  const refresh = () => {
    dispatch(setRefreshNotification());
  };

  return (
    <>
      <Box
        sx={{
          height: "75px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 3,
          borderBottom: "1px solid #E5E7EB",
          padding: "0px 30px",
        }}
      >
        <Box>
          {hasHealthCoachAccess && (
            <>
              <IconButton onClick={handleNotificationClick}>
                <Badge
                  badgeContent={unreadNotificationCount}
                  color="secondary"
                  overlap="circular"
                  sx={{
                    "& .MuiBadge-badge": {
                      border: "2px solid #FFFFFF",
                    },
                  }}
                >
                  <BellIcon />
                </Badge>
              </IconButton>

              <IconButton onClick={handleChatClick}>
                <Badge
                  badgeContent={unreadChatCount}
                  color="secondary"
                  overlap="circular"
                  sx={{
                    "& .MuiBadge-badge": {
                      border: "2px solid #FFFFFF",
                    },
                  }}
                >
                  <ChatIcon />
                </Badge>
              </IconButton>
            </>
          )}

          <IconButton onClick={handleClick} sx={{ ml: "auto" }}>
            <Avatar
              sx={{
                width: 40,
                height: 40,
                bgcolor: "lightgray",
                color: "#000",
                fontSize: 16,
              }}
            >
              {firstName &&
                firstName?.charAt(0).toUpperCase() +
                  lastName?.charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>
        </Box>

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>

        <Popover
          open={Boolean(anchorElChat)}
          anchorEl={anchorElChat}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ChatNotifications
            setAnchorElChat={setAnchorElChat}
            data={chatNotificationData}
            unreadChatCount={unreadChatCount}
            openModal={openModal}
          />
        </Popover>

        <Popover
          open={Boolean(anchorElNotification)}
          anchorEl={anchorElNotification}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <NotificationList
            data={notificationData}
            setAnchorElNotification={setAnchorElNotification}
            unreadNotificationCount={unreadNotificationCount}
            openModal={openModal}
          />
        </Popover>
      </Box>
      {modalData && (
        <DeleteNotificationModal
          data={modalData}
          closeModal={closeModal}
          refresh={refresh}
        />
      )}
    </>
  );
};

export default Header;
