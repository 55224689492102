import { ChangeEvent, useMemo } from "react";
import { debounce } from "lodash";
import { Box, Button, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  setCmsLoader,
  setCmsSearchText,
  setModalEducationId,
  setModalLearningId,
} from "../../Redux/reducers/cmsSlice";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import AddLearningModal from "./modals/AddLearningModal";
import AddEducationModal from "./modals/AddEducationModal";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { importLesson } from "../../utils/education";

const CMSHeader = () => {
  const dispatch = useAppDispatch();
  const { type, searchText, learningModalId, educationModalId } =
    useAppSelector((state) => state.cms);
  const navigate = useNavigate();

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setCmsSearchText(val));
      }, 500),
    [dispatch]
  );

  const showModal = () => {
    if (type === "learning") dispatch(setModalLearningId("new"));
    else if (type === "education") dispatch(setModalEducationId("new"));
  };

  const importLessonModule = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      dispatch(setCmsLoader(true));
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        try {
          var result = JSON.parse(JSON.stringify(e.target.result));
          const id = await importLesson(JSON.parse(result));
          dispatch(setCmsLoader(false));
          if (id) {
            toastMessage("success", "Education Lesson created successfully");
            navigate(`/app/cms/${id}`);
          }
        } catch (err) {
          dispatch(setCmsLoader(false));
          errorToastMessage(err as Error);
        }
      };
      reader.readAsText(file);

      return false;
    }
  };

  return (
    <StyledHeader>
      <Box sx={HeaderLeftContent}>
        <Typography fontSize={30} fontWeight="bold">
          Content
        </Typography>

        <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "300px" }}
          placeholder="Search for Content"
          defaultValue={searchText}
          key={type}
        />
      </Box>
      <Box sx={HeaderRightContent}>
        {type === "education" && (
          <Button variant="outlined" component="label">
            Import
            <input
              hidden
              accept="application/json"
              type="file"
              onChange={importLessonModule}
            />
          </Button>
        )}
        <Button variant="contained" startIcon={<AddIcon />} onClick={showModal}>
          {type === "learning" ? "Add learning module" : "Add module"}
        </Button>
      </Box>
      {learningModalId && <AddLearningModal />}
      {educationModalId && <AddEducationModal />}
    </StyledHeader>
  );
};

export default CMSHeader;
