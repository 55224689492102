import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  NoDataContainer,
  StyledSortLabel,
  StyledTableCell,
} from "../../../../Common/styles/table";
import { useEffect, useState } from "react";
import { errorToastMessage } from "../../../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { getAppData, TableHeaders } from "../../../../../utils/appData";

const HistoryTable = ({
  type,
  userId,
  subType,
}: {
  type: string;
  userId: string;
  subType?: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({
    headers: [],
    rows: [],
  });
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url = `/activity/app-data/${type}?userId=${userId}`;

        if (["searches", "quiz"].includes(type)) {
          url += `&type=${subType}`;
        }

        if (sortOrder && sortColumn) {
          url += `&order=${sortOrder}`;
        }
        const res: AxiosResponse = await http.get(url);

        const resData = res.data?.data || [];
        const formattedData = getAppData(resData, subType ? subType : type);
        setData(formattedData || []);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading, type, userId, sortColumn, sortOrder, subType]);

  const handleSort = (order: string, column: string) => {
    if (sortColumn === column && sortOrder === order) {
      setSortColumn("");
      setSortOrder("");
    } else {
      setSortColumn(column);
      setSortOrder(order);
    }
  };

  const SortLabel = ({ column }: { column: "date" }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {TableHeaders[subType ? subType : type]?.map(
              (hdr: { label: string; key: string }) => (
                <StyledTableCell key={hdr?.key}>
                  {hdr?.label}
                  {hdr?.key === "date" && <SortLabel column={hdr?.key} />}
                </StyledTableCell>
              )
            )}
          </TableRow>
        </TableHead>
        {!loading && data?.length > 0 && (
          <TableBody>
            {data?.map?.((row: any, index: number) => (
              <TableRow key={index}>
                {TableHeaders[subType ? subType : type]?.map(
                  (hdr: { label: string; key: string }) => (
                    <StyledTableCell key={hdr?.key}>
                      {row[hdr?.key] || "-"}
                    </StyledTableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {!loading && data?.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No data available
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%", minHeight: "200px" }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

export default HistoryTable;
