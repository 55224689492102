import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { Form, Formik, FormikValues } from "formik";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { AuthLayout, SignInWrapper } from "./auth.style";

import { StyledButton } from "../Common/styles/button";
import { InputWrapper, LabelStyle } from "../Common/styles/form";

let schema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("*New Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("*Confirm Password is required"),
  // id: yup.string().when("isDoctor", {
  //   is: true,
  //   then: (schema) => schema.required("*Doctor ID is Required"),
  // }),
  // code: yup.string().when("isDoctor", {
  //   is: true,
  //   then: (schema) => schema.required("*Code is Required"),
  // }),
});

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const ResetPassword = () => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { token } = useParams();
  // const type = useQuery().get("type");
  const navigate = useNavigate();

  const handleClickShowPassword = (type: string) => {
    if (type === "password") {
      setShowPassword((prev) => !prev);
    } else {
      setShowConfirmPassword((prev) => !prev);
    }
  };

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      // if (type === "doctor") {
      //   let data = {
      //     password: values.password,
      //     code: values.code,
      //     email: values.id,
      //   };
      //   const res: AxiosResponse = await http.post(
      //     "/doctors/password-requests/verify",
      //     data
      //   );
      //   toastMessage("success", res.data.message);
      // } else {
      let data = {
        password: values.password,
        token: token,
      };
      const res: AxiosResponse = await http.patch(
        "/auth/forgot-password/reset",
        data
      );
      toastMessage("success", res.data.message);
      // }
      navigate("/auth/login");
      setSubmitLoader(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  return (
    <Box sx={AuthLayout}>
      <Box sx={SignInWrapper}>
        <Typography variant="h2" mb={3}>
          Reset Password
        </Typography>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
            // isDoctor: type === "doctor",
            // id: "",
            // code: "",
          }}
          onSubmit={(data: FormikValues) => {
            submitHandler(data);
          }}
          validationSchema={schema}
        >
          {({ values, errors, touched, getFieldProps }) => {
            return (
              <Form>
                {/* {values.isDoctor && (
                  <>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="id">
                        Doctor Id
                      </FormLabel>
                      <TextField
                        placeholder="Your Doctor id"
                        id="id"
                        {...getFieldProps("id")}
                        error={touched?.id && errors?.id ? true : false}
                        helperText={
                          touched?.id && errors?.id ? errors?.id : " "
                        }
                      />
                    </FormControl>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="code">
                        Code
                      </FormLabel>
                      <TextField
                        placeholder="Code from admin"
                        id="code"
                        {...getFieldProps("code")}
                        error={touched?.code && errors?.code ? true : false}
                        helperText={
                          touched?.code && errors?.code ? errors?.code : " "
                        }
                      />
                    </FormControl>
                  </>
                )} */}
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="password">
                    New Password
                  </FormLabel>
                  <TextField
                    placeholder="Enter your new password here"
                    id="password"
                    {...getFieldProps("password")}
                    error={touched?.password && errors?.password ? true : false}
                    helperText={
                      touched?.password && errors?.password
                        ? (errors?.password as string)
                        : " "
                    }
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => handleClickShowPassword("password")}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl sx={InputWrapper} className="input-wrapper mb-3">
                  <FormLabel sx={LabelStyle} htmlFor="confirmPassword">
                    Confirm New Password
                  </FormLabel>
                  <TextField
                    placeholder="Confirm password"
                    id="confirmPassword"
                    {...getFieldProps("confirmPassword")}
                    error={
                      touched?.confirmPassword && errors?.confirmPassword
                        ? true
                        : false
                    }
                    helperText={
                      touched?.confirmPassword && errors?.confirmPassword
                        ? (errors?.confirmPassword as string)
                        : " "
                    }
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() =>
                              handleClickShowPassword("confirmPassword")
                            }
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Box mt={1} sx={{ display: "flex", justifyContent: "center" }}>
                  {!submitLoader ? (
                    <StyledButton type="submit" variant="contained" fullWidth>
                      Set Password
                    </StyledButton>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default ResetPassword;
