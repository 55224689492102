import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../styles/modal";
import { InputWrapper, LabelStyle } from "../styles/form";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";

const schema = yup.object().shape({
  title: yup.string().required("*Title is required"),
  body: yup.string().when("type", {
    is: (val: string) => val === "notification",
    then: (schema) => schema.required("*Body is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

type Props = {
  showModal: string;
  data: any;
  closeModal: () => void;
  refreshPage: () => void;
};

const EditTemplateModal: React.FC<Props> = ({
  showModal,
  data,
  closeModal,
  refreshPage,
}: any) => {
  const [buttonLoader, setButtonLoader] = useState(false);

  const submitHandler = async (values: any) => {
    try {
      setButtonLoader(true);
      const body: any = {
        title: values?.title,
      };
      if (values.type === "notification") {
        body.body = values?.body;
      }
      const res: AxiosResponse = await http.patch(
        `/templates/${data?.id}`,
        body
      );
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal?.length > 0} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title={""} onCloseClick={closeModal} />
        <Formik
          initialValues={{
            title: data?.title || "",
            body: data?.body || "",
            type: showModal?.includes("notification")
              ? "notification"
              : "activity",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, getFieldProps, values }: any) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="title">
                  Title
                </FormLabel>
                <TextField
                  id="title"
                  placeholder="Title"
                  error={touched?.title && errors?.title ? true : false}
                  helperText={
                    touched?.title && errors?.title ? errors?.title : " "
                  }
                  {...getFieldProps("title")}
                />
              </FormControl>
              {values?.type === "notification" && (
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="body">
                    Body
                  </FormLabel>
                  <TextField
                    id="body"
                    placeholder="Body"
                    error={touched?.body && errors?.body ? true : false}
                    helperText={
                      touched?.body && errors?.body ? errors?.body : " "
                    }
                    {...getFieldProps("body")}
                  />
                </FormControl>
              )}
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                {!buttonLoader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default EditTemplateModal;
