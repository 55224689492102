import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ModalBaseStyles } from "../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { useAppSelector } from "../../Redux/hooks";

const ConfirmationModal = ({
  showModal,
  closeModal,
  patient,
  slots,
  refreshPage,
  setPatient,
  setSlots,
  coachId,
}: any) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const { hasHealthCoachAccess } = useAppSelector((state) => state.user);
  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);

      let body = {};
      if (hasHealthCoachAccess) {
        const coachId = localStorage.getItem("coachId");
        body = {
          slotIds: slots,
          assignedTo: patient?.id,
          coachId: coachId,
        };
      } else {
        body = {
          slotIds: slots,
          assignedTo: patient,
          coachId,
        };
      }

      const res: AxiosResponse = await http.patch(
        `/consultation/availability`,
        body
      );
      toastMessage("success", res.data.message);
      closeModal();
      setSlots([]);
      if (hasHealthCoachAccess) {
        setPatient(null);
      }
      refreshPage();
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      closeModal();
      refreshPage();
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          ...ModalBaseStyles,
          minHeight: 0,
          width: "max-content",
          p: 4,
        }}
      >
        <Typography variant="subtitle2" fontWeight="medium" mt={1.5}>
          Are you sure you want to assign this time slot?
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 3 }}
        >
          {!submitLoader ? (
            <>
              <Button onClick={closeModal} variant="outlined">
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Confirm
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
