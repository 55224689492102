import {
  Box,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { FilterAlt, MoreVert } from "@mui/icons-material";
import { errorToastMessage } from "../../../utils/toast";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import {
  ethnicityMap,
  genderMap,
  notificationEntitiesMap,
} from "../../../utils/notification";
import { useAppSelector } from "../../../Redux/hooks";
import { langMap } from "../../../utils/lang";

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  type: string;
  toggleLoader: boolean;
  sortColumn: string;
  sortOrder: string;
  handleSort: (order: string, column: string) => void;
  openFilterMenu: (e: React.MouseEvent<HTMLElement>, type: string) => void;
  filterId: string;
  openMenu: (e: React.MouseEvent<HTMLElement>, row: any) => void;
};

const userTarget = ["Patient", "Health coach"];

const NotifCenterList = ({
  page,
  setPage,
  type,
  toggleLoader,
  sortColumn,
  sortOrder,
  handleSort,
  openFilterMenu,
  filterId,
  openMenu,
}: Props) => {
  const { hasAdminAccess, userId } = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url;
        if (type === "template") {
          url = `/templates?size=${pageSize}&page=${page + 1}&type=broadcast`;
          if (filterId) url += `&createdBy=${filterId}`;
        } else {
          url = `/notifications/broadcast?size=${pageSize}&page=${page + 1}`;
          if (filterId) url += `&status=${filterId}`;
        }
        if (sortColumn && sortOrder) {
          url += `&sortBy=${sortColumn}&order=${sortOrder}`;
        }
        const res: AxiosResponse = await http.get(url);
        if (
          res.data.data?.count > 0 &&
          res.data.data.rows?.length === 0 &&
          page > 0
        ) {
          setPage(0);
          return;
        }
        setTotal(res.data?.data?.count);
        const notifications = res.data?.data?.rows?.map((notification: any) => {
          return {
            id: notification?.id,
            creatorId: notification?.creatorId || notification?.createdBy,
            title: notification?.title,
            body: notification?.body,
            scheduledOn: notification?.scheduledOn,
            scheduledOnTime: DateTime.fromISO(
              notification?.scheduledOn
            ).toFormat("dd'-'LL'-'yyyy hh:mm a"),
            entity: notification?.entity,
            entityLabel: notificationEntitiesMap[notification?.entity],
            educationLesson: notification?.educationLesson,
            educationLessonId: notification?.educationLessonId,
            bot: notification?.entity === "bot" ? notification?.bot : null,
            botId: notification?.entity === "bot" ? notification?.botId : null,
            faq: notification?.entity === "faq_bot" ? notification?.bot : null,
            faqId:
              notification?.entity === "faq_bot" ? notification?.botId : null,
            type: notification?.type,
            lang: notification?.lang || "en",
            langLabel: langMap[notification?.lang] || "English",
            users:
              notification?.users?.map((user: any) => {
                return {
                  id: user.userId,
                  name: user.user?.code,
                };
              }) || [],
            userLabel:
              notification?.users?.length > 0
                ? notification?.users
                    ?.map((user: any) => user.user?.code)
                    ?.join(", ")
                : "",
            gender: notification?.filters?.gender || "",
            genderLabel: genderMap[notification?.filters?.gender] || "",
            ethnicity: notification?.filters?.ethnicity || [],
            ethnicityLabel:
              notification?.filters?.ethnicity
                ?.map((eth: any) => {
                  return ethnicityMap[eth];
                })
                .join(", ") || "",
            status:
              DateTime?.fromISO(notification?.scheduledOn) < DateTime.now()
                ? "sent"
                : "upcoming",
            createdAt: notification?.createdAt
              ? DateTime?.fromISO(notification?.createdAt)?.toFormat(
                  "dd LLL yyyy"
                )
              : "",
            updatedAt: notification?.updatedAt
              ? DateTime?.fromISO(notification?.updatedAt)?.toFormat(
                  "dd LLL yyyy"
                )
              : "",
            createdBy: notification?.creator
              ? `${notification?.creator?.firstName || ""} ${
                  notification?.creator?.lastName || ""
                }`
              : "",
          };
        });
        setData(notifications);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [
    setPage,
    setData,
    page,
    type,
    toggleLoader,
    filterId,
    sortOrder,
    sortColumn,
  ]);

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              Title
              <SortLabel column="title" />
            </StyledTableCell>
            {type === "template" ? (
              <>
                <StyledTableCell>
                  Created by
                  <IconButton
                    onClick={(e) => openFilterMenu(e, "creator-menu")}
                  >
                    <FilterAlt color={filterId ? "primary" : "disabled"} />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell>
                  Date created
                  <SortLabel column="createdAt" />
                </StyledTableCell>
                <StyledTableCell>
                  Last edit
                  <SortLabel column="updatedAt" />
                </StyledTableCell>
              </>
            ) : (
              <>
                <StyledTableCell>Assigned to</StyledTableCell>
                {hasAdminAccess && <StyledTableCell>Resource</StyledTableCell>}
                <StyledTableCell>
                  Scheduled on
                  <SortLabel column="scheduledOn" />
                </StyledTableCell>
                <StyledTableCell>
                  Status
                  <IconButton onClick={(e) => openFilterMenu(e, "status-menu")}>
                    <FilterAlt color={filterId ? "primary" : "disabled"} />
                  </IconButton>
                </StyledTableCell>
              </>
            )}
            <StyledTableCell />
          </TableRow>
        </TableHead>
        {!loading && data?.length > 0 && (
          <>
            <TableBody>
              {data?.map((row: any) => (
                <TableRow key={row?.id}>
                  <StyledTableCell>{row?.title || "-"}</StyledTableCell>
                  {type === "template" ? (
                    <>
                      <StyledTableCell>
                        {row?.createdBy?.trim() || "-"}
                      </StyledTableCell>
                      <StyledTableCell>{row?.createdAt || "-"}</StyledTableCell>
                      <StyledTableCell>{row?.updatedAt || "-"}</StyledTableCell>
                    </>
                  ) : (
                    <>
                      <StyledTableCell sx={{ maxWidth: "200px" }}>
                        {row?.userLabel
                          ? row?.userLabel
                          : `All ${userTarget[row?.type - 1] || ""} ${
                              row?.genderLabel ? " - " + row?.genderLabel : ""
                            } ${
                              row?.ethnicityLabel
                                ? " - " + row?.ethnicityLabel
                                : ""
                            }`}
                      </StyledTableCell>
                      {hasAdminAccess && (
                        <StyledTableCell>
                          {row?.entityLabel +
                            (row?.educationLesson?.name ||
                            row?.bot?.name ||
                            row?.faq?.name
                              ? " - " +
                                (row?.educationLesson?.name ||
                                  row?.bot?.name ||
                                  row?.faq?.name)
                              : "")}
                        </StyledTableCell>
                      )}
                      <StyledTableCell>
                        {row?.scheduledOnTime || "-"}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          color: row?.status === "sent" ? "#30D073" : "#FFC20A",
                          fontWeight: "600 !important",
                          textTransform: "capitalize",
                        }}
                      >
                        {row?.status || "-"}
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell align="right">
                    {(type === "template" ||
                      row?.status === "sent" ||
                      userId === row?.creatorId) && (
                      <IconButton onClick={(e) => openMenu(e, row)}>
                        <MoreVert />
                      </IconButton>
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            {total > pageSize && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={total}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                </TableRow>
              </TableFooter>
            )}
          </>
        )}
      </Table>
      {!loading && data?.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No data available
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

export default NotifCenterList;
