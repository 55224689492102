import React, { useEffect, useMemo, useState } from "react";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import {
  Box,
  Button,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import { TemplateHistoryTabs } from "../../Common/UI/TabPanel";
import { useAppSelector } from "../../../Redux/hooks";
import TasksList from "./TasksList";
import CreateActivityModal from "../CreateActivityModal";
import {
  Assignment,
  ContentCopy,
  Delete,
  Edit,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import EditTemplateModal from "../../Common/UI/EditTemplateModal";

const Activities = () => {
  const { userId, hasAdminAccess } = useAppSelector((state) => state.user);

  const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState(searchParams.get("type") || "template");
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const [showModal, setShowModal] = useState("");
  const [toggleLoader, setToggleLoader] = useState(false);
  const [sortColumn, setSortColumn] = useState(searchParams.get("sort") || "");
  const [sortOrder, setSortOrder] = useState(searchParams.get("order") || "");
  const [filterId, setFilterId] = useState(searchParams.get("filter") || "");
  const [selectedOption, setSelectedOption] = useState(filterId);
  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(filterEl);
  const [listfilterEl, setListFilterEl] = useState<null | HTMLElement>(null);
  const openListFilter = Boolean(listfilterEl);
  const [row, setRow] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [menuLoader, setMenuLoader] = useState("");
  const [creatorList, setCreatorList] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    if (type) params.set("type", type);

    if (sortOrder) {
      params.set("order", sortOrder);
    }
    if (sortColumn) {
      params.set("sort", sortColumn);
    }
    if (filterId) {
      params.set("filter", filterId);
    }
    if (searchText) {
      params.set("search", searchText);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [
    type,
    setSearchParams,
    page,
    sortOrder,
    filterId,
    sortColumn,
    searchText,
  ]);

  useEffect(() => {
    const fetchList = async () => {
      try {
        let url;
        if (hasAdminAccess) {
          url = "/admins/list";
        } else {
          url = "/coach";
        }
        const res: AxiosResponse = await http.get(url);
        const list = res.data?.data?.map((creator: any) => {
          return {
            value: creator?.id,
            text: creator?.firstName + " " + creator?.lastName,
          };
        });
        setCreatorList(list);
      } catch (err) {
        errorToastMessage(err as Error);
      }
    };
    fetchList();
  }, [hasAdminAccess]);

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setPage(0);
        setSearchText(val);
      }, 500),
    []
  );

  const handleChange = (_: any, newValue: string) => {
    setType(newValue);
    setPage(0);
    setFilterId("");
    setSelectedOption("");
    setSortColumn("");
    setSortOrder("");
    setSearchText("");
  };

  const openModal = (row: any, type: string) => {
    if (type === "assign") {
      setRow({
        title: row?.title,
        assign: true,
      });
    }
    setShowModal(type);
    setAnchorEl(null);
  };

  const closeModal = () => {
    setRow(null);
    setShowModal("");
  };

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleSort = (order: string, column: string) => {
    if (sortColumn === column && sortOrder === order) {
      setSortColumn("");
      setSortOrder("");
    } else {
      setSortColumn(column);
      setSortOrder(order);
    }
  };

  const openFilterMenu = (
    event: React.MouseEvent<HTMLElement>,
    type: string
  ) => {
    if (type === "creator-menu") {
      setListFilterEl(event?.currentTarget);
    } else {
      setFilterEl(event?.currentTarget);
    }
  };

  const handleFilterItem = (value: string) => {
    setSelectedOption(value);
  };
  const closeFilterMenu = (type: string) => {
    if (type === "creator-menu") {
      setListFilterEl(null);
    } else {
      setFilterEl(null);
    }
    if (selectedOption !== filterId) {
      setFilterId(selectedOption);
      setPage(0);
    }
  };
  const clearFilterMenu = (type: string) => {
    if (type === "creator-menu") {
      setListFilterEl(null);
    } else {
      setFilterEl(null);
    }
    setFilterId("");
    setSelectedOption("");
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    setRow(rowData);
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setRow(null);
  };

  const deleteItem = async (id: string) => {
    try {
      setMenuLoader("delete");
      let url;
      if (type === "template") {
        url = `/templates/${id}`;
      } else {
        url = `/daily-task/${id}`;
      }
      const res: AxiosResponse = await http.delete(url);
      refreshPage();
      setMenuLoader("");
      toastMessage("success", res.data.message);
      setAnchorEl(null);
      setRow(null);
    } catch (error) {
      errorToastMessage(error as Error);
      setMenuLoader("");
    }
  };

  const duplicateTemplate = async (id: string) => {
    try {
      setMenuLoader("duplicate");
      const url = `/templates/${id}/duplicate`;
      const res: AxiosResponse = await http.post(url);
      refreshPage();
      toastMessage("success", res.data.message);
      setMenuLoader("");
      setAnchorEl(null);
      setRow(null);
    } catch (error) {
      setMenuLoader("");
      errorToastMessage(error as Error);
    }
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Daily activities
          </Typography>
          <TextField
            placeholder="Search for activities"
            style={{ width: "300px" }}
            defaultValue={searchText}
            onChange={(e) => modifySearchTerm(e.target.value)}
            key={type}
          />
        </Box>
        <Box sx={HeaderRightContent}>
          <Button variant="contained" onClick={() => openModal(row, "create")}>
            Create
          </Button>
        </Box>
      </StyledHeader>
      <TemplateHistoryTabs type={type} onChange={handleChange}>
        <TasksList
          page={page}
          setPage={setPage}
          type={type}
          toggleLoader={toggleLoader}
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          handleSort={handleSort}
          filterId={filterId}
          openFilterMenu={openFilterMenu}
          openMenu={openMenu}
          searchText={searchText}
        />
      </TemplateHistoryTabs>
      <Menu
        anchorEl={filterEl}
        open={openFilter}
        onClose={() => closeFilterMenu("status-menu")}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "20ch",
          },
        }}
      >
        {["true", "false"]?.map((item: any) => (
          <MenuItem
            selected={item === selectedOption}
            key={item}
            onClick={() => handleFilterItem(item)}
          >
            <ListItemIcon>
              {item === selectedOption ? (
                <RadioButtonChecked fontSize="small" color="primary" />
              ) : (
                <RadioButtonUnchecked fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText>
              {item === "true" ? "Visible" : "Invisible"}
            </ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button variant="text" onClick={() => clearFilterMenu("status-menu")}>
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={() => closeFilterMenu("status-menu")}
          >
            Ok
          </Button>
        </Box>
      </Menu>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            minWidth: "200px",
          },
        }}
        TransitionProps={{
          exit: false,
        }}
      >
        {type === "template" && [
          <MenuItem
            key="assign"
            disabled={menuLoader?.length > 0}
            onClick={() => openModal(row, "assign")}
          >
            <ListItemIcon>
              <Assignment fontSize="small" />
            </ListItemIcon>
            <ListItemText>Assign</ListItemText>
          </MenuItem>,
          <MenuItem
            key="duplicate"
            onClick={() => duplicateTemplate(row?.id)}
            disabled={menuLoader?.length > 0}
          >
            {menuLoader === "duplicate" ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <ListItemIcon>
                  <ContentCopy fontSize="small" />
                </ListItemIcon>
                <ListItemText>Duplicate</ListItemText>
              </>
            )}
          </MenuItem>,
        ]}
        {userId === row?.creatorId && [
          <MenuItem
            key="edit"
            disabled={menuLoader?.length > 0}
            onClick={() =>
              openModal(row, type === "template" ? "activity-template" : "edit")
            }
          >
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>,
          <MenuItem
            key="delete"
            onClick={() => deleteItem(row?.id)}
            disabled={menuLoader?.length > 0}
          >
            {menuLoader === "delete" ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <ListItemIcon>
                  <Delete fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </>
            )}
          </MenuItem>,
        ]}
      </Menu>

      <Menu
        anchorEl={listfilterEl}
        open={openListFilter}
        onClose={() => closeFilterMenu("creator-menu")}
        PaperProps={{
          style: {
            maxHeight: "400px",
            minWidth: "20ch",
          },
        }}
      >
        {creatorList?.map((item: any) => (
          <MenuItem
            selected={item?.value === selectedOption}
            key={item?.value}
            onClick={() => handleFilterItem(item?.value)}
          >
            <ListItemIcon>
              {item?.value === selectedOption ? (
                <RadioButtonChecked fontSize="small" color="primary" />
              ) : (
                <RadioButtonUnchecked fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {item?.text}
            </ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button
            variant="text"
            onClick={() => clearFilterMenu("creator-menu")}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={() => closeFilterMenu("creator-menu")}
          >
            Ok
          </Button>
        </Box>
      </Menu>

      {["create", "assign", "edit"].includes(showModal) && (
        <CreateActivityModal
          showModal={showModal?.length > 0}
          closeModal={closeModal}
          activity={row}
          refreshPage={refreshPage}
        />
      )}
      {showModal === "activity-template" && (
        <EditTemplateModal
          showModal={showModal}
          data={row}
          closeModal={closeModal}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default Activities;
