import { Box } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../Redux/hooks";
import {
  // AboutUsIcon,
  AdminIcon,
  ChatBotIcon,
  CloudIcon,
  CMSIcon,
  // DashboardIcon,
  Logo,
  NotificationIcon,
  ParticipantIcon,
  // PasswordIcon,
  SettingsIcon,
  DailyActivitiesIcon,
  LearningPathIcon,
  ScheduleIcon,
  CalendarIcon,
  SelfReflectionIcon,
  ProfileIcon,
  WorldIcon,
} from "../Common/assets/Sidebar";

const Sidebar = () => {
  const {
    hasAdminAccess,
    hasMasterAdminAccess,
    hasHealthCoachAccess,
    hasClinicalAssistantAccess,
  } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);

  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const dispatch = useAppDispatch();

  // const logout = async () => {
  //   try {
  //     await http.post("/auth/logout");
  //     auth
  //       .signOut()
  //       .then()
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     sessionStorage.setItem("role", role === "coach" ? "coach" : "admin");
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     localStorage.clear();
  //     dispatch(resetState());
  //   }
  // };

  const navToHome = () => {
    if (hasHealthCoachAccess) {
      navigate("/app/dashboard");
    } else {
      navigate("/app/participants");
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "60px",
        backgroundColor: "#FFF",
        borderRightWidth: "1px",
        borderRightStyle: "solid",
        borderRightColor: "#e5e7eb",
        display: "flex",
        flexDirection: "column",
      }}
      className="sidebar"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          borderBottom: 1,
          borderBottomColor: "#e5e7eb",
          paddingBottom: 2,
          margin: "20px 12px",
          cursor: "pointer",
        }}
        onClick={navToHome}
      >
        <Logo />
      </Box>

      {hasHealthCoachAccess && (
        <NavLink
          to="/app/dashboard"
          title="Dashboard"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <WorldIcon />
        </NavLink>
      )}
      <NavLink
        title="Participants"
        to="/app/participants"
        className={({ isActive }) =>
          isActive ? "sider-menu active" : "sider-menu"
        }
      >
        <ParticipantIcon />
      </NavLink>
      {hasAdminAccess && (
        <NavLink
          to="/app/administrators"
          title="Administrators"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <AdminIcon />
        </NavLink>
      )}
      {hasHealthCoachAccess && (
        <NavLink
          to="/app/my-calendar"
          title="My Calendar"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <CalendarIcon />
        </NavLink>
      )}
      {hasHealthCoachAccess && (
        <NavLink
          to="/app/schedule"
          title="Schedule"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <ScheduleIcon />
        </NavLink>
      )}
      {/* {hasClinicalAssistantAccess && (
        <NavLink
          to="/app/assign-slots"
          title="Assign slots"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <ScheduleIcon />
        </NavLink>
      )} */}
      {hasHealthCoachAccess && (
        <NavLink
          to="/app/self-reflection-exercises"
          title="Self-Reflection"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <SelfReflectionIcon />
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          to="/app/chatbot"
          title="Chatbot"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <ChatBotIcon />
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          to="/app/cms"
          title="CMS"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <CMSIcon />
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          title="Tags and Highlights"
          to="/app/tags-highlights"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <CloudIcon />
        </NavLink>
      )}
      {/* {hasAdminAccess && (
        <NavLink
          title="Password Request"
          to="/app/password-request"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <PasswordIcon />
        </NavLink>
      )} */}
      {(hasAdminAccess || hasHealthCoachAccess) && (
        <NavLink
          title="Notification Center"
          to="/app/notification"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <NotificationIcon />
        </NavLink>
      )}
      {/* {hasHealthCoachAccess && (
        <NavLink
          to="/app/dummy"
          title="Dummy"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <AddUserIcon />
        </NavLink>
      )} */}
      {/* {hasHealthCoachAccess && (
        <NavLink
          to="/app/dummy"
          title="Dummy"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <NoteBookIcon />
        </NavLink>
      )} */}
      {/* {hasAdminAccess && (
        <NavLink
          title="About Us"
          to="/app/about"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <AboutUsIcon />
        </NavLink>
      )} */}
      {hasMasterAdminAccess && (
        <NavLink
          title="Settings"
          to="/app/settings"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <SettingsIcon />
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          title="Learning Path"
          to="/app/learning-path"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <LearningPathIcon />
        </NavLink>
      )}
      {(hasAdminAccess || hasHealthCoachAccess) && (
        <NavLink
          title="Daily Activities"
          to="/app/daily-activities"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <DailyActivitiesIcon />
        </NavLink>
      )}
      {hasMasterAdminAccess && (
        <NavLink
          title="Mobile Settings"
          to="/app/mobile-settings"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <SettingsIcon />
        </NavLink>
      )}
      {(hasHealthCoachAccess || hasClinicalAssistantAccess) && (
        <NavLink
          to="/app/profile"
          title="Profile"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <ProfileIcon />
        </NavLink>
      )}
      {/* <Box sx={{ marginTop: "auto", marginBottom: "10px" }}>
        <IconButton
          className="sider-menu"
          sx={{ alignContent: "bottom" }}
          onClick={handleClick}
        >
          <LogOutIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </Box> */}
    </Box>
  );
};

export default Sidebar;
