import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { FilterAlt, MoreVert } from "@mui/icons-material";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { useAppSelector } from "../../../Redux/hooks";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { DateTime } from "luxon";
import { errorToastMessage } from "../../../utils/toast";

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  type: string;
  toggleLoader: boolean;
  sortColumn: string;
  sortOrder: string;
  handleSort: (order: string, column: string) => void;
  openFilterMenu: (e: React.MouseEvent<HTMLElement>, type: string) => void;
  filterId: string;
  openMenu: (e: React.MouseEvent<HTMLElement>, row: any) => void;
  searchText: string;
};

const TasksList = ({
  page,
  setPage,
  type,
  toggleLoader,
  sortColumn,
  sortOrder,
  handleSort,
  openFilterMenu,
  filterId,
  openMenu,
  searchText,
}: Props) => {
  const { userId } = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url;
        if (type === "template") {
          url = `/templates?size=${pageSize}&page=${page + 1}&type=daily_task`;
          if (filterId) url += `&createdBy=${filterId}`;
        } else {
          url = `/daily-task?size=${pageSize}&page=${page + 1}`;
          if (filterId) url += `&isVisible=${filterId}`;
        }
        if (searchText) {
          url += `&search=${searchText}`;
        }
        if (sortColumn && sortOrder) {
          url += `&sortBy=${sortColumn}&order=${sortOrder}`;
        }
        const res: AxiosResponse = await http.get(url);
        if (
          res.data.data?.count > 0 &&
          res.data.data.rows?.length === 0 &&
          page > 0
        ) {
          setPage(0);
          return;
        }
        setTotal(res.data?.data?.count);
        const tasks = res.data?.data?.rows?.map((task: any) => {
          return {
            id: task?.id,
            creatorId: task?.createdBy,
            title: task?.title,
            status: task?.isVisible ? "visible" : "invisible",
            userType: task?.userType,
            users:
              task?.userType === 1
                ? task?.assignments?.map((user: any) => {
                    return {
                      id: user?.assignedTo,
                      name: user?.user?.code,
                    };
                  })
                : [],
            userLabel:
              task?.userType === 0
                ? "All patients"
                : task?.assignments
                    ?.map((user: any) => user.user?.code)
                    ?.join(", "),
            createdBy: task?.creator
              ? `${task?.creator?.firstName || ""} ${
                  task?.creator?.lastName || ""
                }`
              : "",
            createdAt: task?.createdAt
              ? DateTime?.fromISO(task?.createdAt)?.toFormat("dd LLL yyyy")
              : "",
            updatedAt: task?.updatedAt
              ? DateTime?.fromISO(task?.updatedAt)?.toFormat("dd LLL yyyy")
              : "",
          };
        });
        setData(tasks);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [
    setPage,
    setData,
    page,
    type,
    toggleLoader,
    filterId,
    sortOrder,
    sortColumn,
    searchText,
  ]);

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              Title
              <SortLabel column="title" />
            </StyledTableCell>
            {type === "template" ? (
              <>
                <StyledTableCell>
                  Created by
                  <IconButton
                    onClick={(e) => openFilterMenu(e, "creator-menu")}
                  >
                    <FilterAlt color={filterId ? "primary" : "disabled"} />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell>
                  Date created
                  <SortLabel column="createdAt" />
                </StyledTableCell>
                <StyledTableCell>
                  Last edit
                  <SortLabel column="updatedAt" />
                </StyledTableCell>
              </>
            ) : (
              <>
                <StyledTableCell>Assigned to</StyledTableCell>
                <StyledTableCell>
                  Status
                  <IconButton onClick={(e) => openFilterMenu(e, "status-menu")}>
                    <FilterAlt color={filterId ? "primary" : "disabled"} />
                  </IconButton>
                </StyledTableCell>
              </>
            )}
            <StyledTableCell />
          </TableRow>
        </TableHead>
        {!loading && data?.length > 0 && (
          <>
            <TableBody>
              {data?.map((row: any) => (
                <TableRow key={row?.id}>
                  <StyledTableCell>{row?.title || "-"}</StyledTableCell>
                  {type === "template" ? (
                    <>
                      <StyledTableCell>
                        {row?.createdBy?.trim() || "-"}
                      </StyledTableCell>
                      <StyledTableCell>{row?.createdAt || "-"}</StyledTableCell>
                      <StyledTableCell>{row?.updatedAt || "-"}</StyledTableCell>
                    </>
                  ) : (
                    <>
                      <StyledTableCell sx={{ maxWidth: "200px" }}>
                        {row?.userLabel || "-"}
                      </StyledTableCell>

                      <StyledTableCell
                        sx={{
                          textTransform: "capitalize",
                        }}
                      >
                        {row?.status || "-"}
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell align="right">
                    {(type === "template" || userId === row?.creatorId) && (
                      <IconButton onClick={(e) => openMenu(e, row)}>
                        <MoreVert />
                      </IconButton>
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            {total > pageSize && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={total}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                </TableRow>
              </TableFooter>
            )}
          </>
        )}
      </Table>
      {!loading && data?.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No data available
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

export default TasksList;
