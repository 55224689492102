import React, { useEffect, useState } from "react";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { NoDataContainer } from "../Common/styles/table";
import { LoadingContainer } from "../CMS/cms.style";
import { DateTime } from "luxon";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import {
  ForwardIcon,
  PauseIcon,
  PlayIcon,
  RevertIcon,
  RewindIcon,
} from "./AudioPlayerIcons";
import { ExpandMore, FileDownloadOutlined } from "@mui/icons-material";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { useParams } from "react-router-dom";

const SessionRecording = () => {
  const [loading, setLoading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [data, setData] = useState<any>([]);
  const [jumpControls, setJumpControls] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [editable, setEditable] = useState(false);
  const [editedData, setEditedData] = useState<any>([]);
  const [archiveId, setArchiveId] = useState<any>(null);
  const [transcriptLoader, setTranscriptLoader] = useState(false);
  const [archiveData, setArchiveData] = useState<any>([]);
  const [archiveOriginalData, setArchiveOriginalData] = useState<any>([]);
  const [toggle, setToggle] = useState(false);
  const [deleteToggler, setDeleteToggler] = useState(false);
  const [transcriptId, setTranscriptId] = useState("");
  const [originalDownloadLoader, setOriginalDownloadLoader] = useState(false);
  const [editedDownloadLoader, setEditedDownloadLoader] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `opentok/archives?userId=${id}`
        );
        const newData = res.data?.data?.map((item: any) => ({
          ...item,
          date: DateTime.fromISO(item?.scheduledAt)?.toFormat("dd LLLL yyyy"),
          startTime: item?.startedAt
            ? DateTime.fromISO(item?.startedAt)?.toFormat("hh:mm a")
            : "",
          endTime: item?.endedAt
            ? DateTime.fromISO(item?.endedAt)?.toFormat("hh:mm a")
            : "",
          archives: item?.archives || [],
          duration:
            item?.startedAt && item?.endedAt
              ? DateTime.fromISO(item?.endedAt)?.diff(
                  DateTime.fromISO(item?.startedAt),
                  "minutes"
                )?.minutes
              : "",
        }));
        setData(newData);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading, id, deleteToggler]);

  useEffect(() => {
    if (archiveId) {
      const getTranscription = async () => {
        try {
          setTranscriptLoader(true);
          const url = `opentok/archives/${archiveId}/transcribe`;
          const response = await http.get(url);
          const data = response?.data?.data || [];
          const resData = data?.data
            ?.map((item: any) => {
              return {
                startTime: {
                  low: item?.startTime?.low,
                },
                transcript: item?.transcript,
              };
            })
            .sort((a: any, b: any) => a?.startTime?.low - b?.startTime?.low);

          const edited = data?.editedTranscription
            ?.map((item: any) => {
              return {
                // time: item?.startTime?.low,
                startTime: {
                  low: item?.startTime?.low,
                },
                transcript: item?.transcript,
              };
            })
            .sort((a: any, b: any) => a?.startTime?.low - b?.startTime?.low);

          setTranscriptId(data?.id || "");
          setTranscriptLoader(false);
          setArchiveData(edited || []);
          setArchiveOriginalData(resData || []);
        } catch (error) {
          setArchiveData([]);
          setTranscriptLoader(false);
          errorToastMessage(error as Error);
        }
      };
      getTranscription();
    }
  }, [archiveId, toggle]);

  const showJumpControls = (playerId: string) => {
    setJumpControls((prev) => [...prev, playerId]);
  };

  const hideJumpControls = (playerId: string) => {
    setJumpControls((prev) => prev.filter((id) => id !== playerId));
  };

  const handleChange =
    (panel: string, archiveId: string) =>
    (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setEditable(false);
      if (isExpanded) {
        setArchiveData([]);
        setArchiveId(archiveId);
      } else {
        setArchiveData([]);
        setArchiveId(null);
        setTranscriptId("");
      }
    };

  const enableEdit = (transcriptData: any) => {
    setEditable(true);
    setEditedData(transcriptData);
  };

  const disableEdit = () => {
    setEditable(false);
    setEditedData([]);
  };

  const handleInputChange = (val: string, inputIndex: number) => {
    setEditedData((prev: any) =>
      prev.map((item: any, index: number) =>
        index === inputIndex
          ? {
              ...item,
              transcript: val,
            }
          : item
      )
    );
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);
      const body = {
        editedTranscription: editedData,
      };
      const res: AxiosResponse = await http.patch(
        `/opentok/transcribe/${transcriptId}`,
        body
      );
      toastMessage("success", res?.data?.message);
      setToggle((prev) => !prev);
      setEditable(false);
      setSubmitLoader(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  const downloadTranscript = async (type: string) => {
    try {
      if (type === "original") {
        setOriginalDownloadLoader(true);
      } else {
        setEditedDownloadLoader(true);
      }
      const res: AxiosResponse = await http.get(
        `/opentok/transcribe/${transcriptId}/download?type=${type}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${type}-transcription.doc` || "file.doc");
      document.body.appendChild(link);
      link.click();
      setOriginalDownloadLoader(false);
      setEditedDownloadLoader(false);
    } catch (error) {
      setOriginalDownloadLoader(false);
      setEditedDownloadLoader(false);
      errorToastMessage(error as Error);
    }
  };

  const deleteArchive = async (id: string) => {
    try {
      setDeleteLoader(true);
      const res: AxiosResponse = await http.delete(`/opentok/archive/${id}`);
      toastMessage("success", res?.data?.message);
      setDeleteLoader(false);
      setDeleteToggler((prev) => !prev);
    } catch (error) {
      setDeleteLoader(false);
      errorToastMessage(error as Error);
    }
  };

  return loading ? (
    <Box sx={LoadingContainer}>
      <CircularProgress />
    </Box>
  ) : data.length ? (
    data?.map((item: any) => (
      <Box
        key={item?.id}
        sx={{
          bgcolor: "#fff",
          border: "1px solid #E5E7EB",
          borderRadius: "8px",
          p: 3,
          mb: 3,
        }}
      >
        <Typography
          variant="subtitle2"
          color="secondary"
          fontWeight="medium"
          sx={{ mb: "10px" }}
        >
          {item?.date}
        </Typography>
        <Box sx={{ display: "flex", gap: 2, mb: 5 }}>
          <Typography variant="body1" fontWeight="medium">
            Time: {item?.startTime} {item?.endTime && `- ${item?.endTime}`}
          </Typography>
          {item?.endTime && (
            <Typography variant="body1" fontWeight="medium">
              Duration: {Math.floor(item?.duration)}{" "}
              {Math.floor(item?.duration) > 1 ? "mins" : "min"}
            </Typography>
          )}
          {/* <Typography variant="body1" fontWeight="medium">
            Duration: 1 hour
          </Typography> */}
        </Box>
        {item?.archives?.map((archive: any) => {
          return (
            <Box key={archive?.archiveId} sx={{ mt: 1 }}>
              <AudioPlayer
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                className="custom-audio-player"
                src={archive?.url}
                showDownloadProgress
                showJumpControls={jumpControls.includes(archive?.archiveId)}
                progressJumpSteps={{ backward: 10000, forward: 10000 }}
                timeFormat="hh:mm:ss"
                onPlay={() => showJumpControls(archive?.archiveId)}
                onPause={() => hideJumpControls(archive?.archiveId)}
                customIcons={{
                  play: <PlayIcon />,
                  pause: <PauseIcon />,
                  rewind: <RewindIcon />,
                  forward: <ForwardIcon />,
                }}
                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                customProgressBarSection={[
                  RHAP_UI.PROGRESS_BAR,
                  RHAP_UI.CURRENT_TIME,
                ]}
              />

              <Box sx={{ mt: 5 }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {!deleteLoader ? (
                    <Button
                      variant="outlined"
                      onClick={() => deleteArchive(archive?.id)}
                    >
                      Delete
                    </Button>
                  ) : (
                    <CircularProgress size={18} />
                  )}
                </Box>
                <Accordion
                  key={item}
                  TransitionProps={{ unmountOnExit: true }}
                  expanded={expanded === `panel${archive?.archiveId}`}
                  onChange={handleChange(
                    `panel${archive?.archiveId}`,
                    archive.id
                  )}
                  sx={{
                    boxShadow: "none",
                    border: "none",
                    "&::before": {
                      opacity: 0,
                      backgroundColor: "transparent",
                      height: 0,
                    },
                  }}
                >
                  <Divider
                    sx={{
                      "&::before , &::after": {
                        borderTop: "2px solid #D9D9D9",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <IconButton color="primary">
                          <ExpandMore sx={{ fontSize: 24 }} color="primary" />
                        </IconButton>
                      }
                      sx={{
                        minHeight: "0px !important",
                        ".Mui-expanded": {
                          m: "0px !important",
                        },
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        fontWeight="medium"
                        color="primary"
                      >
                        View transcript
                      </Typography>
                    </AccordionSummary>
                  </Divider>
                  <AccordionDetails
                    sx={{
                      p: 2,
                      mb: 2,
                    }}
                  >
                    {transcriptLoader ? (
                      <>
                        <NoDataContainer>
                          <CircularProgress />
                        </NoDataContainer>
                      </>
                    ) : archiveOriginalData?.length ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: editable ? 4 : 2,
                            mb: 3,
                          }}
                        >
                          {editable ? (
                            !submitLoader ? (
                              <>
                                <Box
                                  sx={{
                                    justifyContent: "space-between",
                                    display: "flex",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    color="primary"
                                    fontWeight="medium"
                                    fontSize="1.125rem"
                                  >
                                    Correct transcript
                                  </Typography>
                                  <Box
                                    sx={{
                                      justifyContent: "flex-end",
                                      display: "flex",
                                      gap: 3,
                                    }}
                                  >
                                    <IconButton onClick={disableEdit}>
                                      <RevertIcon />
                                    </IconButton>
                                    <Button
                                      variant="contained"
                                      onClick={handleSubmit}
                                    >
                                      Done
                                    </Button>
                                  </Box>
                                </Box>
                              </>
                            ) : (
                              <CircularProgress size={18} />
                            )
                          ) : (
                            <>
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  enableEdit(
                                    archiveData?.length === 0
                                      ? archiveOriginalData
                                      : archiveData
                                  )
                                }
                              >
                                Edit transcript
                              </Button>
                            </>
                          )}
                        </Box>
                        {editable ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 3,
                            }}
                          >
                            {editedData?.map((tr: any, index: number) => {
                              return (
                                tr?.transcript && (
                                  <Box
                                    key={index}
                                    sx={{ display: "flex", gap: 5 }}
                                  >
                                    <Typography
                                      variant="body1"
                                      fontWeight="medium"
                                      color="secondary"
                                    >
                                      {`${Math.floor(
                                        tr?.startTime?.low / 60
                                      )}:${(tr?.startTime?.low % 60)
                                        .toString()
                                        .padStart(2, "0")}`}
                                    </Typography>{" "}
                                    <TextField
                                      multiline
                                      fullWidth
                                      value={
                                        editedData[index]?.transcript || ""
                                      }
                                      onChange={(e) =>
                                        handleInputChange(e.target.value, index)
                                      }
                                    />
                                  </Box>
                                )
                              );
                            })}
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 3,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 3,
                                flexGrow: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color="primary"
                                >
                                  Original transcript
                                </Typography>
                                {!originalDownloadLoader ? (
                                  <IconButton
                                    onClick={() => {
                                      downloadTranscript("original");
                                    }}
                                  >
                                    <FileDownloadOutlined color="primary" />
                                  </IconButton>
                                ) : (
                                  <CircularProgress size={18} sx={{ ml: 1 }} />
                                )}
                              </Box>
                              {archiveOriginalData?.map(
                                (tr: any, index: number) => {
                                  return (
                                    tr.transcript && (
                                      <Box
                                        key={index}
                                        sx={{ display: "flex", gap: 5 }}
                                      >
                                        <Typography
                                          variant="body1"
                                          fontWeight="medium"
                                          color="secondary"
                                        >
                                          {`${Math.floor(
                                            tr?.startTime?.low / 60
                                          )}:${(tr?.startTime?.low % 60)
                                            .toString()
                                            .padStart(2, "0")}`}
                                        </Typography>{" "}
                                        <Typography
                                          variant="body1"
                                          fontWeight="medium"
                                        >
                                          {tr?.transcript}
                                        </Typography>
                                      </Box>
                                    )
                                  );
                                }
                              )}
                            </Box>
                            {archiveData.length !== 0 && (
                              <>
                                <Divider
                                  flexItem
                                  orientation="vertical"
                                  sx={{
                                    borderColor: "#D7D7D7",
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 3,
                                    flexGrow: 1,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      gap: 1,
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      fontWeight="medium"
                                      color="primary"
                                    >
                                      Corrected transcript
                                    </Typography>
                                    {!editedDownloadLoader ? (
                                      <IconButton
                                        onClick={() => {
                                          downloadTranscript("edited");
                                        }}
                                      >
                                        <FileDownloadOutlined color="primary" />
                                      </IconButton>
                                    ) : (
                                      <CircularProgress
                                        size={18}
                                        sx={{ ml: 1 }}
                                      />
                                    )}
                                  </Box>
                                  {archiveData?.map(
                                    (tr: any, index: number) => {
                                      return (
                                        tr.transcript && (
                                          <Box
                                            key={index}
                                            sx={{ display: "flex", gap: 5 }}
                                          >
                                            <Typography
                                              variant="body1"
                                              fontWeight="medium"
                                              color="secondary"
                                            >
                                              {`${Math.floor(
                                                tr?.startTime?.low / 60
                                              )}:${(tr?.startTime?.low % 60)
                                                .toString()
                                                .padStart(2, "0")}`}
                                            </Typography>{" "}
                                            <Typography
                                              variant="body1"
                                              fontWeight="medium"
                                            >
                                              {tr?.transcript}
                                            </Typography>
                                          </Box>
                                        )
                                      );
                                    }
                                  )}
                                </Box>
                              </>
                            )}
                          </Box>
                        )}
                      </>
                    ) : (
                      <NoDataContainer>
                        <Typography variant="body1" color="gray">
                          No data available
                        </Typography>
                      </NoDataContainer>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          );
        })}
      </Box>
    ))
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No data available
      </Typography>
    </NoDataContainer>
  );
};

export default SessionRecording;
