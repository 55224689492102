import { Box } from "@mui/material";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../../../../Common/UI/TabPanel";
import PatientSelfReflection from "./PatientSelfReflection";
import PatientOwnDiary from "./PatientOwnDiary";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { setJournalTab } from "../../../../../Redux/reducers/myPatientsSlice";

const PatientJournal = () => {
  const dispatch = useAppDispatch();
  const { journalType } = useAppSelector((state) => state.myPatients);

  const handleChange = (_: any, newValue: string) => {
    dispatch(setJournalTab(newValue));
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={journalType} onChange={handleChange}>
          <StyledTab
            label="Self-reflection"
            value="selfReflection"
            {...a11yProps(0)}
          />
          <StyledTab label="Own diary" value="ownDiary" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 282px)",
          overflow: "auto",
        }}
      >
        <MainTabPanel value={journalType} index={"selfReflection"}>
          <PatientSelfReflection />
        </MainTabPanel>
        <MainTabPanel value={journalType} index={"ownDiary"}>
          <PatientOwnDiary />
        </MainTabPanel>
      </Box>
    </>
  );
};

export default PatientJournal;
