import { ChevronLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setEditMode,
  setLoading,
  setPreviewMode,
  setQuestionMandatory,
  setSectionInLesson,
  setTitle,
} from "../../../Redux/reducers/cmsBuilderSlice";
import { educationRequest } from "../../../utils/education";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { StyledHeader } from "../../Common/styles/header";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Header = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pageId = useQuery().get("pageId");

  const { editMode, sections, title, questionsMandatory } = useAppSelector(
    (state) => state.cmsBuilder
  );

  const handleBack = () => {
    navigate(-1);
  };

  const handleTitleChange = (event: any) => {
    dispatch(setTitle(event.target.value));
  };

  const handleMandatoryChange = (event: any) => {
    dispatch(setQuestionMandatory(event.target.checked));
  };

  const cancelEditMode = () => {
    dispatch(setEditMode(false));
  };

  const submitHandler = async () => {
    try {
      if (!title.trim()) {
        toastMessage("warning", "Title cannot be empty");
        return;
      }
      dispatch(setLoading(true));
      const sectionResult = educationRequest(sections);
      const imageSection = sectionResult.find(
        (section) => section.type === "picture"
      );

      let obj = {
        title: title,
        imageUrl: imageSection?.value,
        sections: sectionResult,
        areQuestionsMandatory: questionsMandatory,
      };

      if (!obj.imageUrl) {
        delete obj.imageUrl;
      }
      if (pageId) {
        const res: AxiosResponse = await http.put(
          `/lms/lessons/${id}/pages/${pageId}`,
          obj
        );
        toastMessage("success", res.data.message);
      } else {
        const res: AxiosResponse = await http.post(
          `/lms/lessons/${id}/pages`,
          obj
        );
        toastMessage("success", res.data.message);
      }
      navigate(-1);
    } catch (err) {
      dispatch(setLoading(false));
      errorToastMessage(err as Error);
    }
  };

  const showPreview = () => {
    try {
      const sectionResult = educationRequest(sections);
      dispatch(
        setSectionInLesson({
          sections: sectionResult,
          key: pageId,
        })
      );
      dispatch(setPreviewMode(true));
    } catch (err) {
      errorToastMessage(err as Error);
    }
  };

  return (
    <StyledHeader>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ChevronLeft
          onClick={handleBack}
          sx={{ cursor: "pointer", height: 50, width: 50, mr: 1 }}
        />
        <TextField
          value={title}
          onChange={handleTitleChange}
          placeholder="Title"
          sx={{ width: "300px" }}
        />
        <FormControlLabel
          control={
            <Switch
              onChange={handleMandatoryChange}
              checked={questionsMandatory}
            />
          }
          label="Make questions Mandatory"
          labelPlacement="start"
        />
      </Box>
      {editMode && (
        <Box sx={{ marginLeft: "auto", display: "flex", gap: 1.5 }}>
          <Button onClick={cancelEditMode} variant="outlined">
            Back to sections
          </Button>
          <Button onClick={showPreview} variant="contained">
            Preview
          </Button>
          <Button onClick={submitHandler} variant="contained">
            Save
          </Button>
        </Box>
      )}
    </StyledHeader>
  );
};

export default Header;
