import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";
import { ChevronLeft } from "@mui/icons-material";
import { DateTime } from "luxon";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { CalendarPicker, PickersDay } from "@mui/x-date-pickers";
import {
  ApptContainer,
  CalendarPickerWrapper,
  CalendarScreensWrapper,
  CalendarWrapper,
  RowContainer,
} from "../MyCalendar/style";
import { LoadingContainer } from "../CMS/cms.style";
import { getColor } from "../../utils/schedule";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
// import MarkAvailabilityModal from "./MarkAvailabilityModal";
import {
  GridContainer,
  dateWrapper,
} from "../UserManagement/Participants/style";

const slots = [
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
];

const SlotItem = ({
  data,
  slot,
  handleSlotSelection,
  makeAvailableSlots,
  makeUnavailableSlots,
  date,
}: any) => {
  const timing = data.find((t: any) => t?.startTime === slot);
  const status = timing ? timing?.status : "disabled";
  const allowSelection = ["available", "disabled"].includes(status);

  const parsedTime = DateTime.fromFormat(slot || "", "hh:mm a");
  const combinedDateTime = date?.set({
    hour: parsedTime.hour,
    minute: parsedTime.minute,
  });
  const startTime = combinedDateTime?.toISO();

  const editedStatus = makeAvailableSlots.includes(startTime)
    ? "available"
    : makeUnavailableSlots.includes(timing?.id)
    ? "disabled"
    : timing
    ? timing?.status
    : "disabled";

  return (
    <Box
      key={slot}
      sx={{
        ...dateWrapper,
        borderColor: getColor(editedStatus, false, "border"),
        color: getColor(editedStatus, false, "text"),
        cursor: allowSelection ? "pointer" : "default",
      }}
      onClick={() => {
        if (allowSelection) handleSlotSelection(status, slot, timing);
      }}
    >
      <Typography fontWeight={500} fontSize={18} fontStyle="normal">
        {`${slot} - ${DateTime.fromFormat(slot || "", "hh:mm a")
          .plus({ hour: 1 })
          .toFormat("hh:mm a")}`}
      </Typography>
    </Box>
  );
};

const Availability = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // const [submitLoader, setSubmitLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState<DateTime>(
    searchParams.get("date") &&
      DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy").isValid
      ? DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy")
      : DateTime.now()
  );
  const [data, setData] = useState<any[]>([]);
  // const [enableSelection, setEnableSelection] = useState<boolean>(false);
  const [makeAvailableSlots, setMakeAvailableSlots] = useState<string[]>([]);
  const [makeUnavailableSlots, setMakeUnavailableSlots] = useState<string[]>(
    []
  );
  const [toggle, setToggle] = useState(false);
  // const [showModal, setShowModal] = useState("");
  // const [availableSlots, setAvailableSlots] = useState<any[]>([]);
  // const [unavailableSlots, setUnavailableSlots] = useState<any[]>([]);
  const [month, setMonth] = useState(
    date ? date.toISO() : DateTime.now().toISO()
  );
  const [monthData, setMonthData] = useState<any[]>([]);
  const [submitLoader, setSubmitLoader] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams();
    if (date) {
      params.set("date", date?.toFormat("dd-LL-yyyy"));
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, date]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const coachId = localStorage.getItem("coachId");
        let url = `/consultation/availability?coachId=${coachId}&startDate=${date
          ?.startOf("day")
          .toUTC()
          .toISO()}&endDate=${date?.endOf("day").toUTC().toISO()}`;
        const res: AxiosResponse = await http.get(url);
        const resData = res.data?.data;

        const newData = resData?.map((item: any) => ({
          id: item.id,
          // time: DateTime.fromISO(item.dateTime).toFormat("hh:mm a"),
          startTime: DateTime.fromISO(item?.startTime).toFormat("hh:mm a"),
          endTime: item?.endTime
            ? DateTime.fromISO(item?.endTime).toFormat("hh:mm a")
            : DateTime.fromISO(item?.startTime)
                .plus({ hour: 1 })
                .toFormat("hh:mm a"),
          user: item.userId,
          status: item.assignedTo
            ? item.isConfirmed
              ? "unavailable"
              : "proposed"
            : "available",
        }));
        setData(newData);

        // const dataTimes = newData.map((d: any) => d?.startTime);
        // const unavlSlots = slots
        //   .filter((slot) => {
        //     const parsedTime = DateTime.fromFormat(slot || "", "hh:mm a");
        //     const combinedDateTime = date?.set({
        //       hour: parsedTime.hour,
        //       minute: parsedTime.minute,
        //     });

        //     return (
        //       !dataTimes.includes(slot) && combinedDateTime > DateTime.local()
        //     );
        //   })
        //   .map((slot, index) => ({
        //     id: index,
        //     time: slot,
        //     startTime: slot,
        //     endTime: DateTime.fromFormat(slot || "", "hh:mm a")
        //       .plus({ hour: 1 })
        //       .toFormat("hh:mm a"),
        //   }))
        //   .sort((a: any, b: any) => {
        //     return (
        //       (DateTime.fromFormat(a?.startTime || "", "hh:mm a") as any) -
        //       (DateTime.fromFormat(b?.startTime || "", "hh:mm a") as any)
        //     );
        //   });
        // setUnavailableSlots(unavlSlots);

        // const avlSlots = newData
        //   .filter((avl: any) => {
        //     const parsedTime = DateTime.fromFormat(
        //       avl?.startTime || "",
        //       "hh:mm a"
        //     );
        //     const combinedDateTime = date?.set({
        //       hour: parsedTime.hour,
        //       minute: parsedTime.minute,
        //     });

        //     return (
        //       avl?.status === "available" && combinedDateTime > DateTime.local()
        //     );
        //   })
        //   .sort((a: any, b: any) => {
        //     return (
        //       (DateTime.fromFormat(a?.startTime || "", "hh:mm a") as any) -
        //       (DateTime.fromFormat(b?.startTime || "", "hh:mm a") as any)
        //     );
        //   });
        // setAvailableSlots(avlSlots);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [setLoading, setData, toggle, date]);

  useEffect(() => {
    const getMonthData = async () => {
      try {
        const coachId = localStorage.getItem("coachId");
        let startDate = DateTime.fromISO(month)
          .startOf("month")
          .toUTC()
          .toISO();
        let endDate = DateTime.fromISO(month).endOf("month").toUTC().toISO();

        // if (DateTime.now() > DateTime.fromISO(month)) {
        //   startDate = DateTime.now().startOf("day").toUTC().toISO();
        // }
        if (DateTime.now() > DateTime.fromISO(startDate)) {
          startDate = DateTime.now().toUTC().toISO();
        }
        const res = await http.get(
          `/consultation/unassigned/count?startDate=${startDate}&endDate=${endDate}&coachId=${coachId}`
        );
        setMonthData(res?.data?.data);
      } catch (error) {
        errorToastMessage(error as Error);
      }
    };

    getMonthData();
  }, [month, date, toggle]);

  const handleSlotSelection = (status: string, slot: string, timing: any) => {
    if (status === "available") {
      setMakeUnavailableSlots((prev: any) => {
        if (prev.includes(timing.id)) {
          return prev.filter((item: string) => item !== timing.id);
        } else return [...prev, timing?.id];
      });
    } else {
      const parsedTime = DateTime.fromFormat(slot || "", "hh:mm a");
      const combinedDateTime = date?.set({
        hour: parsedTime.hour,
        minute: parsedTime.minute,
      });
      const startTime = combinedDateTime?.toISO();
      setMakeAvailableSlots((prev: any) => {
        if (prev.includes(startTime)) {
          return prev.filter((item: any) => item !== startTime);
        } else return [...prev, startTime];
      });
    }
  };

  const convertTime = (time: any) => {
    const parsedTime = DateTime.fromISO(time);
    const startTime = parsedTime?.toUTC().toISO();
    const endTime = parsedTime?.plus({ hour: 1 }).toUTC().toISO();
    return {
      startTime,
      endTime,
    };
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);
      const makeAvlSlots = makeAvailableSlots.map((time) => convertTime(time));
      const res: AxiosResponse = await http.post(`/consultation/availability`, {
        slotStartTime: makeAvlSlots,
        slotIds: makeUnavailableSlots,
      });
      toastMessage("success", res.data.message);
      refreshPage();
      clearSelection();
      setSubmitLoader(false);
    } catch (err) {
      refreshPage();
      clearSelection();
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const clearSelection = () => {
    setMakeAvailableSlots([]);
    setMakeUnavailableSlots([]);
  };

  const handleBack = () => {
    navigate(-1);
  };

  // const openModal = (action: string) => {
  //   setShowModal(action);
  // };

  // const closeModal = () => {
  //   setShowModal("");
  // };

  const refreshPage = () => {
    setToggle((prev) => !prev);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 0 }}>
          <IconButton onClick={handleBack}>
            <ChevronLeft fontSize="large" htmlColor="#111928" />
          </IconButton>
          <Typography fontSize={30} fontWeight="bold">
            My availability
          </Typography>
        </Box>
        {/* <Box sx={HeaderRightContent}>
          {availableSlots?.length > 0 &&
            date >= DateTime.local().startOf("day") && (
              <Button
                variant="outlined"
                onClick={() => openModal("makeUnavailable")}
              >
                Make unvailable
              </Button>
            )}
          {unavailableSlots?.length > 0 &&
            date >= DateTime.local().startOf("day") && (
              <Button
                variant="contained"
                onClick={() => openModal("makeAvailable")}
              >
                Make available
              </Button>
            )}
        </Box> */}
      </StyledHeader>
      <Box sx={CalendarScreensWrapper}>
        <Box sx={CalendarWrapper}>
          <Box sx={RowContainer}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" fontWeight="medium" sx={{ mb: "50px" }}>
                Date
              </Typography>
              <Box sx={CalendarPickerWrapper}>
                <CalendarPicker
                  date={date}
                  onMonthChange={(newMonth) => {
                    setMonth(newMonth.toISO());
                  }}
                  onChange={(newDate: any) => setDate(newDate)}
                  views={["day"]}
                  className="calendar-view"
                  renderDay={(day, _value, DayComponentProps) => {
                    const dayData = monthData?.find((item) =>
                      DateTime.fromISO(item?.date).hasSame(day, "day")
                    );

                    let backgroundColor = "#d71440";
                    let borderColor = "#d71440 !important";

                    if (dayData?.count >= 2) {
                      backgroundColor = " #07B152";
                      borderColor = " #07B152 !important";
                    } else if (dayData?.count === 1) {
                      backgroundColor = "#FFC20A";
                      borderColor = "#FFC20A !important";
                    }
                    return DayComponentProps?.disabled ? (
                      <PickersDay {...DayComponentProps} />
                    ) : (
                      <PickersDay
                        {...DayComponentProps}
                        sx={{
                          backgroundColor: backgroundColor,
                          color: "#ffffff !important",
                          borderColor: borderColor,
                          "&:hover": {
                            backgroundColor: backgroundColor,
                          },
                        }}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                mt: "80px",
                // pl: "10%",
                borderColor: "#D7D7D7",
              }}
            />
            <Box sx={{ ...ApptContainer, border: "none" }}>
              <Typography variant="h6" fontWeight="medium" sx={{ mb: "66px" }}>
                Time
              </Typography>
              {!loading ? (
                <>
                  <Box sx={GridContainer}>
                    {slots.map((slot) => (
                      <SlotItem
                        slot={slot}
                        date={date}
                        data={data}
                        key={slot}
                        handleSlotSelection={handleSlotSelection}
                        makeAvailableSlots={makeAvailableSlots}
                        makeUnavailableSlots={makeUnavailableSlots}
                      />
                    ))}
                  </Box>
                  <Box
                    sx={{
                      mt: 3,
                      display: "flex",
                      gap: 2,
                      justifyContent: "flex-end",
                    }}
                  >
                    {!submitLoader ? (
                      <>
                        <Button
                          disabled={
                            !makeAvailableSlots.length &&
                            !makeUnavailableSlots.length
                          }
                          variant="outlined"
                          onClick={clearSelection}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={
                            !makeAvailableSlots.length &&
                            !makeUnavailableSlots.length
                          }
                          variant="contained"
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <CircularProgress size={18} />
                    )}
                  </Box>
                </>
              ) : (
                <Box sx={{ ...LoadingContainer, flex: 1 }}>
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* {showModal && (
        <MarkAvailabilityModal
          showModal={showModal}
          closeModal={closeModal}
          data={
            showModal === "makeAvailable" ? unavailableSlots : availableSlots
          }
          selectedDate={date}
          refreshPage={refreshPage}
        />
      )} */}
    </>
  );
};

export default Availability;
