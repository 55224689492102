import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ModalBaseStyles } from "../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { useAppSelector } from "../../Redux/hooks";
import { useNavigate } from "react-router-dom";

const ConfirmationModal = ({
  showModal,
  closeModal,
  id,
  slot,
  refreshPage,
  reschedule,
  setSelectedSlot,
}: any) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const { hasHealthCoachAccess } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);
      if (reschedule) {
        let body = {};
        if (hasHealthCoachAccess) {
          body = {
            slotId: slot,
          };
        }
        // console.log("reassign", body);

        const res: AxiosResponse = await http.patch(
          `/consultation/${id}/reschedule`,
          body
        );
        toastMessage("success", res.data.message);
        closeModal();

        setSelectedSlot("");

        navigate("/app/my-calendar");
      } else {
        const res: AxiosResponse = await http.delete(`/consultation/${id}`);
        toastMessage("success", res.data.message);
        closeModal();
        refreshPage();
      }
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      closeModal();
      refreshPage();
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          ...ModalBaseStyles,
          minHeight: 0,
          width: "max-content",
          p: 4,
        }}
      >
        <Typography variant="subtitle2" fontWeight="medium" mt={1.5}>
          Are you sure you want to {reschedule ? "Reschedule" : "cancel"} this
          time slot?
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 3 }}
        >
          {!submitLoader ? (
            <>
              <Button onClick={closeModal} variant="outlined">
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Confirm
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
