import {
  Modal,
  Box,
  CircularProgress,
  Typography,
  Divider,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ModalBaseStyles, ModalHeader } from "../../../../Common/styles/modal";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { errorToastMessage } from "../../../../../utils/toast";
import { DisplayTextBox } from "../../../../Common/styles/form";

const PatientSelfReflectionModal = ({
  showModal,
  closeModal,
  data,
  participantId,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/self-reflection/question-response?participantId=${participantId}&selfReflectionExerciseId=${data?.selfReflectionId}`
        );

        const resData = res.data?.data;

        const title = resData?.title || "";

        const userTitle =
          resData?.sections?.find((item: any) => item?.type === "title")
            ?.value || "";

        const description =
          resData?.sections?.find((item: any) => item?.type === "text")
            ?.value || "";

        const questionAndAnswer = resData?.sections
          ?.find((section: any) => section.type === "question")
          ?.questions?.map((item: any) => {
            return {
              id: item?.id,
              question: item?.title,
              answer: item?.answers[0]?.textValue,
            };
          });

        setResponse({
          title,
          userTitle,
          description,
          questionAndAnswer,
        });

        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoading, data, participantId]);

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader title={""} onCloseClick={closeModal} />
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box>
                <Typography variant="subtitle1" fontWeight="medium">
                  Template name
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="regular"
                  color="#374151"
                >
                  {response?.title || "-"}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" fontWeight="medium">
                  Title
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="regular"
                  color="#374151"
                >
                  {response?.userTitle || "-"}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" fontWeight="medium">
                  Description
                </Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html: response?.description || "-",
                  }}
                ></div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 5,
                }}
              >
                <Box>
                  <Typography variant="subtitle1" fontWeight="medium">
                    Assign date
                  </Typography>
                  <Typography
                    variant="body1"
                    fontWeight="regular"
                    color="#374151"
                  >
                    {data?.assignDate || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle1" fontWeight="medium">
                    Due date
                  </Typography>
                  <Typography
                    variant="body1"
                    fontWeight="regular"
                    color="#374151"
                  >
                    {data?.dueDate || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle1" fontWeight="medium">
                    Status
                  </Typography>
                  <Typography
                    variant="body1"
                    fontWeight="regular"
                    sx={{
                      color:
                        data?.status === "completed" ? "#29B355" : "#FFC20A",
                      textTransform: "capitalize",
                    }}
                  >
                    {data?.status}
                  </Typography>
                </Box>
                {data?.status === "completed" && (
                  <Box>
                    <Typography variant="subtitle1" fontWeight="medium">
                      Completion date
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight="regular"
                      color="#374151"
                    >
                      {data?.completionDate || "-"}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            {data?.status === "completed" && (
              <>
                <Divider sx={{ mt: 3, mb: 2 }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="medium"
                    sx={{
                      textDecoration: "underline",
                      textUnderlineOffset: 4,
                    }}
                  >
                    Responses
                  </Typography>
                  <Box mb={1}>
                    {response?.questionAndAnswer?.length ? (
                      response?.questionAndAnswer?.map(
                        (item: any, index: number) => {
                          return (
                            <Box key={item?.id} mb={2}>
                              <Typography
                                variant="subtitle1"
                                fontWeight="medium"
                                mb={1.5}
                              >
                                {index + 1}
                                {". "}
                                {item?.question || ""}
                              </Typography>
                              <TextField
                                fullWidth
                                multiline
                                value={item?.answer || "-"}
                                sx={DisplayTextBox}
                                disabled
                              />
                            </Box>
                          );
                        }
                      )
                    ) : (
                      <Typography variant="body1" color="gray">
                        No Responses
                      </Typography>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default PatientSelfReflectionModal;
