import React, { useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import * as yup from "yup";
import { Formik } from "formik";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { quillFormats, quillModules } from "../../../utils/reactquill";
import { debounce } from "lodash";
import { delayOptions } from "../../../utils/delay";

let schema = yup.object().shape({
  title: yup.string().required("Unit Name is Required"),
  description: yup.string().required("Description is Required"),
  summary: yup.string().required("Summary is Required"),
  references: yup.string().required("References are required"),
});

type Props = {
  unit: any;
  lessonId: string;
  unitId: string;
  closeModalHandler: Function;
  callback: Function;
  viewOnly: boolean;
  lang: string;
};

const AddUnitModal: React.FC<Props> = ({
  unit,
  lessonId,
  closeModalHandler,
  callback,
  unitId,
  viewOnly,
  lang,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [options, setOptions] = useState<any>(
    unit?.initBot ? [unit?.initBot] : []
  );
  const [exitBotOptions, setExitBotOptions] = useState(
    unit?.exitBot ? [unit?.exitBot] : []
  );
  const [searchLoader, setSearchLoader] = useState(false);
  const [exitBotLoader, setExitBotLoader] = useState(false);

  const closeModal = () => {
    closeModalHandler();
  };

  const submitHandler = async (values: any) => {
    try {
      const body = {
        ...values,
      };

      if (!body.imageUrl) {
        delete body.imageUrl;
      }

      if (body.showSlider === true && body.sliderText.trim() === "") {
        toastMessage("warning", "Slider text cannot be empty");
        return;
      }

      if (body.showText === true && body.inputText.trim() === "") {
        toastMessage("warning", "Input text cannot be empty");
        return;
      }

      if (body.showSlider === false) {
        body.sliderText = null;
      }

      if (body.showText === false) {
        body.inputText = null;
      }

      body.initBotId = body.initBot?.id || null;
      body.exitBotId = body.exitBot?.id || null;

      delete body.showSlider;
      delete body.showText;
      delete body.initBot;
      delete body.exitBot;

      setSubmitLoader(true);
      let res: AxiosResponse;
      if (unitId !== "new") {
        res = await http.patch(
          `/lms/lessons/${lessonId}/units/${unitId}`,
          body
        );
      } else {
        res = await http.post(`/lms/lessons/${lessonId}/units`, body);
      }
      toastMessage("success", res.data.message);
      callback();
      closeModal();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string, type: string) => {
        try {
          if (value) {
            if (type === "initBot") {
              setSearchLoader(true);
            } else {
              setExitBotLoader(true);
            }
            const res: AxiosResponse = await http.get(
              `/bots?page=1&size=15&status=active&search=${value}&type=general&lang=${lang}`
            );
            const newBots = res.data.data.bots.map((bot: any) => {
              return {
                id: bot?.id,
                name: bot?.name,
              };
            });
            if (type === "initBot") {
              setOptions(newBots);
            } else {
              setExitBotOptions(newBots);
            }
            setOptions(newBots);
            if (type === "initBot") {
              setSearchLoader(false);
            } else {
              setExitBotLoader(false);
            }
          }
        } catch (err) {
          if (type === "initBot") {
            setSearchLoader(false);
          } else {
            setExitBotLoader(false);
          }
          errorToastMessage(err as Error);
        }
      }, 500),
    [lang]
  );

  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={unitId === "new" ? "Add Unit" : "Edit Unit"}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            title: unit?.title || "",
            description: unit?.description || "",
            imageUrl: unit?.imageUrl || "",
            showSlider: unit ? (unit?.sliderText ? true : false) : true,
            showText: unit ? (unit?.inputText ? true : false) : false,
            sliderText: unit
              ? unit?.sliderText || ""
              : "How new was this for you",
            inputText: unit?.inputText || "",
            references: unit?.references || "",
            summary: unit?.summary || "",
            initBot: unit?.initBot || null,
            exitBot: unit?.exitBot || null,
            exitBotDelay: unit?.exitBotDelay || 0,
            isMandatory: unit?.isMandatory || false,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="unit-name">
                  Unit Name*
                </FormLabel>
                <TextField
                  placeholder="Unit Name"
                  id="unit-name"
                  {...getFieldProps("title")}
                  error={touched?.title && errors?.title ? true : false}
                  helperText={
                    touched?.title && errors?.title
                      ? (errors?.title as string)
                      : " "
                  }
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="unit-description">
                  Description
                </FormLabel>
                <TextField
                  multiline
                  placeholder="Module Description"
                  id="education-module-description"
                  {...getFieldProps("description")}
                  error={
                    touched?.description && errors?.description ? true : false
                  }
                  helperText={
                    touched?.description && errors?.description
                      ? (errors?.description as string)
                      : " "
                  }
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="unit-summary">
                  Summary
                </FormLabel>
                <TextField
                  id="unit-summary"
                  multiline
                  rows={3}
                  placeholder="Enter your summary here"
                  {...getFieldProps("summary")}
                  error={touched?.summary && errors?.summary ? true : false}
                  helperText={
                    touched?.summary && errors?.summary
                      ? (errors?.summary as string)
                      : " "
                  }
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="unit-references">
                  References
                </FormLabel>
                <ReactQuill
                  id="unit-references"
                  modules={quillModules}
                  formats={quillFormats}
                  placeholder="References"
                  defaultValue={values.references}
                  className="quill-container"
                  onChange={(val: any) => {
                    setFieldValue("references", val);
                  }}
                />
                <FormHelperText
                  error={
                    touched?.references && errors?.references ? true : false
                  }
                >
                  {touched?.references && errors?.references
                    ? (errors?.references as string)
                    : " "}
                </FormHelperText>
              </FormControl>
              <Stack direction="row" alignItems={"center"} spacing={1} mb={2}>
                <FormLabel sx={{ ...LabelStyle, mb: 0 }} htmlFor="mandatory">
                  Mandatory
                </FormLabel>
                <Switch
                  id="mandatory"
                  checked={values.isMandatory}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setFieldValue("isMandatory", true);
                    } else {
                      setFieldValue("isMandatory", false);
                    }
                  }}
                />
              </Stack>
              <FormControl sx={{ ...InputWrapper, mb: 2.5 }}>
                <FormLabel sx={LabelStyle} htmlFor="prompt-bot">
                  Conversation prompt bot
                </FormLabel>
                <Autocomplete
                  id="prompt-bot"
                  filterOptions={(x) => x}
                  onInputChange={(_1: any, value: any, reason: string) => {
                    if (reason === "input") handleSearch(value, "initBot");
                  }}
                  onChange={(_1: any, value: any) => {
                    setFieldValue("initBot", value);
                  }}
                  options={options}
                  value={values?.initBot || null}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  loading={searchLoader}
                  loadingText={<CircularProgress size={20} />}
                  noOptionsText="No Results"
                  clearOnBlur={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Bots by typing..."
                    />
                  )}
                />
              </FormControl>
              <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="exit-bot">
                    Bot shown after completion of this unit
                  </FormLabel>
                  <Autocomplete
                    id="exit-bot"
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input") handleSearch(value, "exitBot");
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("exitBot", value);
                    }}
                    options={exitBotOptions}
                    value={values?.exitBot || null}
                    getOptionLabel={(option) => option?.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    loading={exitBotLoader}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    clearOnBlur={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Bots by typing..."
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle}>Trigger in Days</FormLabel>
                  <Select
                    value={values.exitBotDelay}
                    onChange={(e) =>
                      setFieldValue("exitBotDelay", parseInt(e.target.value))
                    }
                  >
                    {delayOptions.map((number) => (
                      <MenuItem key={number} value={number}>
                        {number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Stack direction="row" alignItems={"center"} spacing={1} mb={2}>
                <FormLabel sx={{ ...LabelStyle, mb: 0 }} htmlFor="unit-slider">
                  Show Slider in Learning notes
                </FormLabel>
                <Switch
                  id="unit-slider"
                  checked={values.showSlider}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setFieldValue("showSlider", true);
                    } else {
                      setFieldValue("showSlider", false);
                    }
                  }}
                />
              </Stack>
              {values.showSlider && (
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="unit-name">
                    Slider Text
                  </FormLabel>
                  <TextField
                    placeholder="Slider Text"
                    id="unit-sliderText"
                    {...getFieldProps("sliderText")}
                  />
                </FormControl>
              )}

              <Stack direction="row" alignItems={"center"} spacing={1} my={2}>
                <FormLabel sx={{ ...LabelStyle, mb: 0 }} htmlFor="unit-text">
                  Show Text input in Learning notes
                </FormLabel>
                <Switch
                  id="unit-text"
                  checked={values.showText}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setFieldValue("showText", true);
                    } else {
                      setFieldValue("showText", false);
                    }
                  }}
                />
              </Stack>
              {values.showText && (
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="unit-name">
                    Text input text
                  </FormLabel>
                  <TextField
                    placeholder="Input Text"
                    id="unit-sliderText"
                    {...getFieldProps("inputText")}
                  />
                </FormControl>
              )}
              {!viewOnly && (
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 1,
                  }}
                >
                  {!submitLoader ? (
                    <>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                      <Button onClick={closeModal} variant="outlined">
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              )}
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddUnitModal;
