import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";

import { errorToastMessage, toastMessage } from "../../../utils/toast";
import * as yup from "yup";
import { Form, Formik } from "formik";
import http from "../../../utils/http";

const schema = yup.object().shape({
  value: yup
    .number()
    .required("*Value is required")
    .min(0, "Value must be between 0 to 100")
    .max(100, "Value must be between 0 to 100"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  refreshPage: () => void;
  data: string;
  id: any;
  type: string;
};

const CvdEditModal = ({
  showModal,
  closeModal,
  data,
  id,
  refreshPage,
  type,
}: Props) => {
  const [buttonLoader, setButtonLoader] = useState(false);

  const submitHandler = async (values: any) => {
    try {
      setButtonLoader(true);
      const body: any = {
        [type]: +values.value,
      };
      const res = await http.patch(`/participants/${id}/disease`, body);
      toastMessage("success", res.data?.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader
          title={`Edit ${type === "cvd" ? "CVD risk" : "LDL"}`}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            value: data || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, getFieldProps }: any) => (
            <Form noValidate>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="title">
                  {type === "cvd" ? "CVD risk" : "LDL"}
                </FormLabel>
                <TextField
                  type="number"
                  placeholder={`Enter value`}
                  {...getFieldProps("value")}
                  inputProps={{
                    step: "0.1",
                    min: 0,
                    max: 100,
                  }}
                  error={touched.value && Boolean(errors.value)}
                  helperText={
                    touched.value && errors.value
                      ? (errors.value as string)
                      : " "
                  }
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1.5,
                  mt: 5,
                }}
              >
                {!buttonLoader ? (
                  <>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default CvdEditModal;
