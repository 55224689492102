import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { notesCard, notesLabel } from "../../style";
import { Form, Formik } from "formik";
import { errorToastMessage, toastMessage } from "../../../../../utils/toast";
import { InputWrapper } from "../../../../Common/styles/form";
import PatientHistory from "./PatientHistory";
import { useParams } from "react-router-dom";
import http from "../../../../../utils/http";
import { useState } from "react";

// const schema = yup.object().shape({
//   smartGoal: yup.string().required("*Smart Goal is required"),
//   notes: yup.string().required("*Notes is required"),
// });

const initialValues = { smartGoal: "", notes: "" };

const PatientNotes = () => {
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleSubmit = async (
    values: { smartGoal: string; notes: string },
    resetForm: any
  ) => {
    try {
      setLoader(true);
      if (!values.smartGoal) {
        throw new Error("Smart goal is required");
      }
      if (!values.notes) {
        throw new Error("Notes is required");
      }
      const url = `/notes`;
      const body = {
        title: values.smartGoal,
        note: values.notes,
        participantId: id,
      };

      const res = await http.post(url, body);
      toastMessage("success", res.data?.message);
      setRefresh((prev) => !prev);
      resetForm();
      setLoader(false);
    } catch (error) {
      errorToastMessage(error as Error);
      setLoader(false);
    }
  };

  return (
    <>
      <Typography variant="h6" fontWeight="medium" sx={{ p: "5px" }}>
        Notes
      </Typography>
      <Box sx={notesCard}>
        <Formik
          initialValues={initialValues}
          // validationSchema={schema}
          onSubmit={(values, helpers) => {
            const { resetForm } = helpers;
            handleSubmit(values, resetForm);
          }}
        >
          {({ errors, touched, getFieldProps, resetForm }) => (
            <Form style={{ flexDirection: "column", gap: 15 }}>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={notesLabel} htmlFor="smartGoal">
                  Smart goal
                </FormLabel>
                <TextField
                  id="smartGoal"
                  placeholder="Type something..."
                  fullWidth
                  error={touched?.smartGoal && errors?.smartGoal ? true : false}
                  helperText={
                    touched?.smartGoal && errors?.smartGoal
                      ? errors.smartGoal
                      : " "
                  }
                  sx={{
                    "& .MuiInputBase-root": {
                      bgcolor: "#FAFAFA",
                      "&.Mui-focused": {
                        bgcolor: "#FFFFFF",
                      },
                    },
                  }}
                  {...getFieldProps("smartGoal")}
                />
              </FormControl>

              <FormControl sx={InputWrapper}>
                <FormLabel sx={notesLabel} htmlFor="notes">
                  Notes
                </FormLabel>
                <TextField
                  id="notes"
                  placeholder="Type something..."
                  multiline
                  minRows={4}
                  error={touched?.notes && errors?.notes ? true : false}
                  helperText={
                    touched?.notes && errors?.notes ? errors.notes : " "
                  }
                  sx={{
                    "& .MuiInputBase-root": {
                      bgcolor: "#FAFAFA",
                      "&.Mui-focused": {
                        bgcolor: "#FFFFFF",
                      },
                    },
                  }}
                  {...getFieldProps("notes")}
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                }}
              >
                {!loader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Box sx={{ alignContent: "center" }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Form>
          )}
        </Formik>
        <PatientHistory refresh={refresh} />
      </Box>
    </>
  );
};

export default PatientNotes;
