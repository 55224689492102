import { styled, Tabs, Tab } from "@mui/material";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
  noPadding?: boolean;
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: string;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    textColor="secondary"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
  },
  "& 	.MuiTabs-flexContainer": {
    height: "60px",
  },
}));

export const StyledTab = styled(Tab)(() => ({
  textTransform: "none",
  color: "#6C727F",
}));

export const MainTabPanel = (props: TabPanelProps) => {
  const { children, value, index, noPadding = false, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: noPadding ? 0 : 2.5 }}>{children}</Box>}
    </div>
  );
};

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

type TemplateProps = {
  type: string;
  onChange: (_: any, type: string) => void;
  children: React.ReactNode;
};

export const TemplateHistoryTabs = ({
  type,
  onChange,
  children,
}: TemplateProps) => {
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={onChange}>
          <StyledTab label="Template" value="template" {...a11yProps(0)} />
          <StyledTab label="History" value="history" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 146px)",
          overflow: "auto",
        }}
      >
        <MainTabPanel value={type} index={type}>
          {children}
        </MainTabPanel>
      </Box>
    </>
  );
};
