import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  styled,
  Switch,
  SwitchProps,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  StyledHeader,
  HeaderLeftContent,
  HeaderRightContent,
} from "../Common/styles/header";
import { Image } from "@mui/icons-material";
import { ProfileCard, imageTheme } from "./style";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { Formik, Form } from "formik";
import { LabelStyle } from "../Common/styles/form";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { LoadingContainer } from "../CMS/cms.style";
import { useAppSelector } from "../../Redux/hooks";
import { AdminMap } from "../UserManagement/Administrators/Administrators";
import EditProfile from "./EditProfile";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 50,
  height: 30,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#30D158",
        opacity: 1,
        border: 0,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 26,
    height: 26,
  },
  "& .MuiSwitch-track": {
    borderRadius: 30 / 2,
    backgroundColor: "#78788052",
    opacity: 1,
    transition: "background-color 500ms",
  },
}));

const CoachProfile = () => {
  const [data, setData] = useState<any>({});
  const [Loader, setLoader] = useState(true);
  const [toggleLoader, setToggleLoader] = useState(false);
  // const [imageLoader, setImageLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const { role, hasHealthCoachAccess } = useAppSelector((state) => state.user);
  // const [editable, setEditable] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true);
        let url = "";
        if (hasHealthCoachAccess) {
          url = `/coach/profile`;
        } else {
          url = "clinical-assistant/profile";
        }
        const res: AxiosResponse = await http.get(url);
        const data = res.data?.data;
        const resData = {
          id: data?.id,
          firstName: data?.firstName || "",
          lastName: data?.lastName || "",
          about: data?.about || "",
          imageUrl: data?.imageUrl || "",
          email: data?.email || "",
          isOnline: data?.isOnline || false,
        };

        setData(resData);
        setSubmitLoader(false);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        setSubmitLoader(false);
        errorToastMessage(error as Error);
      }
    };
    fetchData();
  }, [toggleLoader, setData, setLoader, hasHealthCoachAccess]);

  // const handleSubmit = async (values: any) => {
  //   try {
  //     setSubmitLoader(true);
  //     let body = {};
  //     let url = "";
  //     if (hasHealthCoachAccess) {
  //       // const coachId = localStorage.getItem("coachId");
  //       body = {
  //         imageUrl: values?.imageUrl,
  //         about: values?.about.trim(),
  //         isOnline: values.isOnline,
  //       };
  //       // url = `/coach/${coachId}`;
  //       url = `/coach/profile`;
  //     } else {
  //       // const assistantId = localStorage.getItem("coachId");
  //       body = {
  //         imageUrl: values?.imageUrl,
  //         about: values?.about,
  //         firstName: values?.firstName,
  //         lastName: values?.lastName,
  //       };
  //       // url = `/clinical-assistant/${assistantId}`;
  //       url = "clinical-assistant/profile";
  //     }
  //     const res: AxiosResponse = await http.patch(url, body);
  //     toastMessage("success", res.data?.message);
  //     setToggleLoader((prev) => !prev);
  //     setEditable(false);
  //     // setSubmitLoader(false);
  //   } catch (error) {
  //     errorToastMessage(error as Error);
  //     setSubmitLoader(false);
  //   }
  // };

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const updateStatus = async (status: boolean) => {
    if (hasHealthCoachAccess) {
      try {
        setSubmitLoader(true);

        const body = {
          isOnline: status,
        };
        const res: AxiosResponse = await http.patch(`/coach/profile`, body);
        toastMessage("success", res.data?.message);
        setToggleLoader((prev) => !prev);
        setSubmitLoader(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setSubmitLoader(false);
      }
    }
  };

  // const handleFileChange = async (event: any, setFieldValue: any) => {
  //   try {
  //     const file = event.target.files[0];
  //     if (file) {
  //       if (file.size > 5 * 1024 * 1024) {
  //         toastMessage("warning", "File Size cannot be greater than 5 MB!");
  //         return;
  //       }

  //       setImageLoader(true);
  //       const url = await uploadFile(file, "questionnaire_image");
  //       setFieldValue("imageUrl", url);

  //       setImageLoader(false);
  //     }
  //   } catch (err) {
  //     setImageLoader(false);
  //     errorToastMessage(err as Error);
  //   }
  // };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Profile
          </Typography>
        </Box>
        <Box sx={HeaderRightContent}>
          <Button variant="contained" onClick={() => setShowModal(true)}>
            Edit
          </Button>
        </Box>
      </StyledHeader>
      <Box
        sx={{
          p: 3,
          height: "calc(100% - 85px)",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={ProfileCard}>
          {!Loader ? (
            <Formik
              initialValues={{
                id: data?.id || "",
                imageUrl: data?.imageUrl || "",
                about: data?.about || "",
                firstName: data?.firstName || "",
                lastName: data?.lastName || "",
                email: data?.email || "",
                isOnline: data?.isOnline || false,
              }}
              onSubmit={() => {
                // handleSubmit(values);
              }}
              // validationSchema={schema}
              // enableReinitialize
            >
              {({ setFieldValue, values }) => (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 3 }}>
                    <Box
                      sx={imageTheme}
                      style={{
                        position: "relative",
                        overflow: "visible",
                        borderRadius: "10px",
                      }}
                    >
                      {/* {!imageLoader ? ( */}
                      <Box style={{ position: "relative" }}>
                        {values?.imageUrl !== "" ? (
                          <img
                            src={values?.imageUrl}
                            className="preview-image"
                            alt="profile"
                          />
                        ) : (
                          <Image sx={{ color: "#DE8D33", fontSize: "60px" }} />
                        )}
                      </Box>
                      {/* ) : (
                        <Box>
                          <CircularProgress />
                        </Box>
                      )} */}

                      {/* <Box sx={circleTheme}>
                        <label style={{ cursor: "pointer" }}>
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileChange(e, setFieldValue)}
                          />
                          <CoachIconPen />
                        </label>
                      </Box> */}
                    </Box>
                    <Box sx={{ marginTop: "15px" }}>
                      {/* {editable && !hasHealthCoachAccess ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <TextField
                              {...getFieldProps("firstName")}
                              error={
                                touched?.firstName && errors?.firstName
                                  ? true
                                  : false
                              }
                              helperText={
                                touched?.firstName && errors?.firstName
                                  ? (errors?.firstName as string)
                                  : " "
                              }
                            />
                            <TextField
                              {...getFieldProps("lastName")}
                              error={
                                touched?.lastName && errors?.lastName
                                  ? true
                                  : false
                              }
                              helperText={
                                touched?.lastName && errors?.lastName
                                  ? (errors?.lastName as string)
                                  : " "
                              }
                            />
                          </Box>

                          <Box sx={{ pb: 3 }}>
                            <IconButton
                              onClick={() => {
                                if (!errors?.lastName && !errors?.firstName)
                                  setEditable(false);
                              }}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          </Box>
                        </Box>
                      ) : ( */}
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography variant="h2" fontWeight="regular">
                          {values?.firstName} {values?.lastName}
                        </Typography>
                        {/* {!hasHealthCoachAccess && (
                            <IconButton
                              onClick={() => {
                                setEditable(true);
                              }}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          )} */}
                      </Box>
                      {/* )} */}
                      <Typography variant="subtitle2">
                        {values?.email}
                      </Typography>
                    </Box>
                  </Box>
                  {hasHealthCoachAccess && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // textAlign: "center",
                        marginTop: 2,
                        marginBottom: 2.5,
                        gap: 2,
                      }}
                    >
                      <Typography sx={{ ...LabelStyle, mb: 0 }}>
                        Status :
                      </Typography>
                      <IOSSwitch
                        checked={values.isOnline}
                        onChange={(e) => {
                          setFieldValue("isOnline", e.target.checked);
                          updateStatus(e.target.checked);
                        }}
                      />

                      <Typography
                        sx={{
                          ...LabelStyle,
                          mb: 0,
                          color: values.isOnline ? "#0E9F6E" : "#6B7280",
                        }}
                      >
                        {values.isOnline ? "Online" : "Offline"}
                      </Typography>
                      {submitLoader && <CircularProgress size={12} />}
                    </Box>
                  )}
                  <FormControl sx={{ maxWidth: "60%" }}>
                    <FormLabel sx={LabelStyle}>
                      About {AdminMap[role === "coach" ? "health_coach" : role]}
                    </FormLabel>
                    {/* <TextField
                      id="about"
                      placeholder="Type Something..."
                      fullWidth
                      multiline
                      minRows={4}
                      error={touched?.about && errors?.about ? true : false}
                      helperText={
                        touched?.about && errors?.about
                          ? (errors?.about as string)
                          : " "
                      }
                      {...getFieldProps("about")}
                    /> */}
                    <Typography
                      sx={{ whiteSpace: "pre-wrap" }}
                      fontSize={15}
                      fontWeight="regular"
                      color="#4B5563"
                    >
                      {values?.about}
                    </Typography>
                  </FormControl>

                  {/* {dirty && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                        mt: "auto",
                      }}
                    >
                      {!submitLoader ? (
                        <>
                          <Button
                            variant="contained"
                            type="submit"
                            disabled={imageLoader}
                          >
                            Save
                          </Button>
                          <Button
                            disabled={imageLoader}
                            variant="outlined"
                            onClick={() => {
                              resetForm();
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Box sx={{ alignContent: "center" }}>
                          <CircularProgress />
                        </Box>
                      )}
                    </Box>
                  )} */}
                </Form>
              )}
            </Formik>
          ) : (
            <Box sx={LoadingContainer}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
      {showModal && (
        <EditProfile
          showModal={showModal}
          closeModal={closeModal}
          data={data}
          isCoach={hasHealthCoachAccess}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default CoachProfile;
