import { DateTime } from "luxon";

export const notificationEntitiesMap: any = {
  general: "General",
  lesson: "Education Lesson",
  bot: "Bot",
  faq_bot: "FAQ",
  food_diary: "Food diary",
  feet_diary: "Feet diary",
  medication_diary: "Medication",
  self_reflection: "Self-reflection",
  appointment: "Appointment",
};

export const Gender = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const genderMap: any = {
  male: "Male",
  female: "Female",
};

export const Ethnicity = [
  {
    label: "Malay",
    value: "malay",
  },
  {
    label: "Chinese",
    value: "chinese",
  },
  {
    label: "Indian",
    value: "indian",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const ethnicityMap: any = {
  chinese: "Chinese",
  malay: "Malay",
  indian: "Indian",
  other: "Other",
};

export const getDateString = (date: any) => {
  if (!date) return "";

  const dateTime = DateTime?.fromISO(date);
  const now = DateTime.now();

  if (dateTime?.hasSame(now, "day")) {
    return "Today";
  } else if (dateTime.hasSame(now.minus({ days: 1 }), "day")) {
    return "Yesterday";
  } else {
    return dateTime.toFormat("dd/LL/yy");
  }
};
