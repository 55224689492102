import { Box } from "@mui/material";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../../../../Common/UI/TabPanel";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { setLogsTab } from "../../../../../Redux/reducers/myPatientsSlice";
import LogsTabPanel from "./LogsTabPanel";
import DateSelectorBlock from "./DateSelectorBlock";

const Logs = () => {
  const dispatch = useAppDispatch();
  const { logsType } = useAppSelector((state) => state.myPatients);

  const handleChange = (_: any, newValue: string) => {
    dispatch(setLogsTab(newValue));
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={logsType} onChange={handleChange}>
          <StyledTab label="Medication" value="medication" {...a11yProps(0)} />
          <StyledTab label="Food" value="food" {...a11yProps(1)} />
          <StyledTab label="Sleep" value="sleep" {...a11yProps(2)} />
          <StyledTab label="Steps" value="step" {...a11yProps(3)} />
          <DateSelectorBlock />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 282px)",
          overflow: "auto",
        }}
      >
        <MainTabPanel value={logsType} index={logsType}>
          <LogsTabPanel />
        </MainTabPanel>
      </Box>
    </>
  );
};

export default Logs;
