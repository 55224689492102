import { auth } from "../../utils/firebaseInit";

export const firebaseLogout = () => {
  try {
    auth
      .signOut()
      .then()
      .catch((err) => {
        console.log(err);
      });
  } catch (err) {}
};
