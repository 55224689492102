import { SxProps } from "@mui/material";

export const CalendarWrapper: SxProps = {
  padding: "30px 20px 80px 20px",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 34px 0px rgba(0, 0, 0, 0.12)",
  borderRadius: "12px",
  minHeight: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
};

export const CalendarPickerWrapper: SxProps = {
  height: "100%",
  display: "flex",
  justifyContent: "center",
  paddingInline: "60px",
};

export const RowContainer: SxProps = {
  display: "flex",
  // justifyContent: "space-between",
  gap: 6,
  flexWrap: "wrap",
  height: "100%",
  flex: 1,
};

export const ApptContainer: SxProps = {
  flex: 1,
  // maxWidth: "40%",
  // paddingLeft: "5%",
  // paddingRight: "5%",
  paddingInline: "60px",
  // height: "55vh",
  overflow: "auto",
};

export const ApptCard: SxProps = {
  bgcolor: "rgba(24, 28, 98, 0.06)",
  borderRadius: "12px",
  border: "1px solid rgba(24, 28, 98, 0.20)",
  paddingBlock: "24px",
  display: "flex",
  justifyContent: "space-between",
  mb: 2,
  cursor: "pointer",
  color: "primary.main",
};

export const DisApptCard: SxProps = {
  bgcolor: "rgba(24, 28, 98, 0.06)",
  borderRadius: "12px",
  border: "1px solid lightgrey",
  paddingBlock: "24px",
  display: "flex",
  justifyContent: "space-between",
  mb: 2,
  color: "lightgray",
  cursor: "not-allowed",
};

export const CalendarScreensWrapper: SxProps = {
  p: "40px 32px",
  height: "calc(100% - 85px)",
  overflow: "auto",
};
