import React from "react";
import {
  Close,
  // PlayArrow
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import {
  ClearAttachmentWrapper,
  MediaAttachmentContainer,
  // PlayIconStyle,
  // VideoAttachmentWrapper,
} from "./style";

const ChatMedia = ({ attachment, clearAttachment }: any) => {
  return (
    <Box sx={MediaAttachmentContainer}>
      <img src={attachment?.resourceUrl} alt="attachment" />
      {/* {attachment?.type.includes("image") && (
      <img src={attachment?.resourceUrl} alt="attachment" />
      )} */}
      {/* {attachment?.type.includes("video") && (
        <Box sx={VideoAttachmentWrapper}>
          <video src={attachment?.resourceUrl} preload="metadata" />
          <PlayArrow fontSize="large" htmlColor="#fff" sx={PlayIconStyle} />
        </Box>
      )} */}
      <Box sx={ClearAttachmentWrapper}>
        <IconButton onClick={clearAttachment}>
          <Close />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatMedia;
