import React from "react";
import { Box, FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { useAppDispatch } from "../../../../Redux/hooks";
import { changeSectionValue } from "../../../../Redux/reducers/cmsBuilderSlice";

type Props = {
  section: any;
  index: number;
};

const aspectRatios = [
  {
    ratio: "16:9",
    val: "1.778",
  },
  {
    ratio: "9:16",
    val: "0.562",
  },
  {
    ratio: "4:3",
    val: "1.333",
  },
  {
    ratio: "3:4",
    val: "0.75",
  },
  {
    ratio: "1:1",
    val: "1",
  },
];

const VideoFormat = ({ section, index }: Props) => {
  const dispatch = useAppDispatch();

  const handleChange = (val: string) => {
    dispatch(
      changeSectionValue({
        value: val,
        type: "format",
        index,
      })
    );
  };

  return (
    <Box sx={{ mb: 2 }}>
      {/* <FormControl>
        <FormLabel
          sx={{
            fontWeight: 500,
            typography: "subtitle1",
            color: "#111928",
          }}
        >
          Video Format
        </FormLabel>
        <RadioGroup row value={section?.format} onChange={handleChange}>
          <FormControlLabel
            value="landscape"
            control={<Radio />}
            label="Landscape"
          />
          <FormControlLabel
            value="portrait"
            control={<Radio />}
            label="Portrait"
          />
        </RadioGroup>
      </FormControl> */}
      <FormControl>
        <FormLabel
          sx={{
            fontWeight: 500,
            typography: "subtitle1",
            color: "#111928",
            mb: 1.25,
          }}
          htmlFor="format"
        >
          Aspect ratio
        </FormLabel>
        <Select
          fullWidth
          id="format"
          sx={{ mb: 2 }}
          value={section?.format}
          onChange={(e) => handleChange(e?.target?.value)}
        >
          {aspectRatios.map((item) => (
            <MenuItem key={item?.ratio} value={item?.val}>
              {item?.ratio}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default VideoFormat;
