import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import OT, { Session } from "@opentok/client";
import React, { useEffect, useState } from "react";
import { errorToastMessage, toastMessage } from "../../../../../../utils/toast";
import Subscriber from "./Subscriber";
import Publisher from "./Publisher";
import { AxiosResponse } from "axios";
import http from "../../../../../../utils/http";
import { useLocation } from "react-router-dom";
import { Info } from "@mui/icons-material";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OpenTok: React.FC<any> = () => {
  const [status, setStatus] = useState("pending");
  const [stream, setStream] = useState<any>(null);
  const [archiveId, setArchiveId] = useState("");

  // const [streams, setStreams] = useState<any[]>([]);
  const [recordingLoader, setRecordingLoader] = useState(false);

  const [session, setSession] = useState<Session | null>(null);
  const consultationId = useQuery().get("consultationId") || "";
  // const [sessionId, setSessionId] = useState(
  //   consultationId === localStorage.getItem("consultation-id") ? true : false
  // );
  // const [connectionId, setConnectionId] = useState("");
  // const [creds, setCreds] = useState<any>(
  //   consultationId === localStorage.getItem("consultation-id")
  //     ? JSON.parse(localStorage.getItem("opentok-creds") || "null")
  //     : null
  // );
  const [creds, setCreds] = useState<any>(null);
  // const [accessRequested, setAccessRequested] = useState(false);
  // const [showInfoModal, setShowInfoModal] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       console.log("session api called");
  //       const body = {
  //         consultationId,
  //       };
  //       await http.post("/opentok/session", body);
  //       setSessionId(true);
  //       localStorage.setItem("consultation-id", consultationId);
  //     } catch (err) {
  //       errorToastMessage(err as Error);
  //     }
  //   };
  //   if (!sessionId) fetchData();
  // }, [sessionId, consultationId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log("session api called");
        const body = {
          consultationId,
        };
        const res: AxiosResponse = await http.post("/opentok/session", body);
        setCreds(res.data?.data);
        setArchiveId(res.data?.data?.archiveId);
      } catch (err) {
        errorToastMessage(err as Error);
      }
    };
    fetchData();
  }, [consultationId]);

  useEffect(() => {
    if (session) {
      session.on("streamCreated", (event: any) => {
        setStream(event.stream);
      });
      session.on("streamDestroyed", () => {
        setStream(null);
        // setAccessRequested(false);
        // setShowInfoModal(false);
      });
    }
  }, [session]);

  // useEffect(() => {
  //   if (session) {
  //     session.on("signal:responseRecording", async function (event: any) {
  //       if (event?.data === "continue recording") {
  //         try {
  //           setRecordingLoader(true);
  //           setShowInfoModal(false);
  //           setAccessRequested(false);
  //           const body = {
  //             consultationId,
  //           };
  //           const res: AxiosResponse = await http.post(
  //             "/opentok/session/start-archive",
  //             body
  //           );
  //           setArchiveId(res.data?.data?.id);
  //           setRecordingLoader(false);
  //         } catch (err) {
  //           setShowInfoModal(false);
  //           setRecordingLoader(false);
  //           errorToastMessage(err as Error);
  //         }
  //       } else {
  //         errorToastMessage(
  //           new Error("User has rejected the recording request")
  //         );
  //         setShowInfoModal(false);
  //         setAccessRequested(false);
  //       }
  //     });
  //   }
  // }, [session, consultationId]);

  useEffect(() => {
    let session: Session | undefined;
    if (creds) {
      session = OT.initSession(creds?.apiKey, creds?.sessionId);
      session.connect(creds?.token, (err: any) => {
        if (err) {
          // console.log(err);
          setStatus("failed");
          toastMessage("error", err?.message || "Something went wrong");
        } else {
          setStatus("success");
          // setConnectionId(session?.connection?.connectionId || "");
        }
      });
      setSession(session || null);
    }
    return () => {
      if (session) {
        try {
          session.off();
          session.disconnect();
        } catch (err) {
          // console.log("error on cleanup");
          console.log(err);
        }
      }
    };
  }, [creds, setStatus]);

  return (
    <>
      {recordingLoader && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {status === "pending" ? (
        <Typography
          variant="subtitle2"
          fontWeight={"medium"}
          color="grey"
          mt={2}
          sx={{ textAlign: "center" }}
        >
          Connecting your call
        </Typography>
      ) : status === "failed" ? (
        <Typography
          variant="subtitle2"
          fontWeight={"medium"}
          color="grey"
          mt={2}
          sx={{ textAlign: "center" }}
        >
          Something went wrong
        </Typography>
      ) : (
        <Box
          id="session-container"
          sx={{ position: "relative", height: "100%" }}
        >
          {/* {session
            ? streams.map((stream) => {
                return (
                  <Subscriber
                    stream={stream}
                    session={session}
                    key={stream.streamId}
                  />
                );
              })
            : null} */}
          {!archiveId && (
            <Box
              sx={{
                position: "absolute",
                top: 10,
                left: 70,
                bgcolor: "#fff",
                p: "8px 16px",
                borderRadius: "6px",
                zIndex: 999,

                overflow: "hidden",
              }}
            >
              <Box
                sx={{ mb: 0.5, display: "flex", alignItems: "center", gap: 1 }}
              >
                <Info htmlColor="#FFC20A" />
                <Typography variant="body1" fontWeight="regular">
                  Recording has not been started
                </Typography>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: "5px",
                  width: "100%",
                  bgcolor: "#FFC20A",
                  // zIndex: 999,
                }}
              />
            </Box>
          )}
          {session && stream ? (
            <Subscriber stream={stream} session={session} />
          ) : null}
          {/* {session && streams?.length > 0 ? (
            <Subscriber
              stream={streams[streams?.length - 1]}
              session={session}
            />
          ) : null} */}
          {session ? (
            <Publisher
              session={session}
              creds={creds}
              // isSubscriberAvailable={streams.length > 0}
              isSubscriberAvailable={!!stream}
              // connectionId={connectionId}
              consultationId={consultationId}
              setRecordingLoader={setRecordingLoader}
              archiveId={archiveId}
              setArchiveId={setArchiveId}
              // accessRequested={accessRequested}
              // setAccessRequested={setAccessRequested}
              // showModal={showInfoModal}
              // setShowModal={setShowInfoModal}
            />
          ) : null}
        </Box>
      )}
    </>
  );
};

export default OpenTok;
