export const SendIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.29167 12.5L3.375 3.5L20.625 12.5L3.375 21.5L5.29167 12.5ZM5.29167 12.5L12.9583 12.5"
      stroke="#637E85"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AttachIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.172 6.7081L8.58602 13.0197C8.395 13.1965 8.24264 13.408 8.13782 13.6418C8.033 13.8757 7.97783 14.1272 7.97552 14.3817C7.97321 14.6362 8.02381 14.8885 8.12438 15.1241C8.22494 15.3597 8.37344 15.5736 8.56123 15.7536C8.74902 15.9336 8.97232 16.0759 9.21811 16.1723C9.4639 16.2686 9.72726 16.3171 9.99282 16.3149C10.2584 16.3127 10.5208 16.2598 10.7648 16.1594C11.0088 16.0589 11.2295 15.9129 11.414 15.7299L17.828 9.41827C18.5567 8.6953 18.9598 7.72699 18.9507 6.7219C18.9416 5.71681 18.5209 4.75536 17.7793 4.04463C17.0377 3.3339 16.0344 2.93075 14.9856 2.92202C13.9368 2.91329 12.9264 3.29966 12.172 3.99794L5.75702 10.3086C4.63171 11.387 3.99951 12.8496 3.99951 14.3748C3.99951 15.8999 4.63171 17.3626 5.75702 18.441C6.88233 19.5194 8.40859 20.1253 10 20.1253C11.5915 20.1253 13.1177 19.5194 14.243 18.441L20.5 12.4581"
      stroke="#637E85"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
