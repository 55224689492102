import { Box, Divider, Modal, TextField, Typography } from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../../../../Common/styles/modal";
import { DisplayTextBox } from "../../../../Common/styles/form";

const JournalResponseModal = ({ showModal, closeModal, data }: any) => {
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader title={""} onCloseClick={closeModal} />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box>
            <Typography variant="subtitle1" fontWeight="medium">
              Title
            </Typography>
            <Typography variant="body1" fontWeight="regular" color="#374151">
              {data?.userTitle || "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" fontWeight="medium">
              Date
            </Typography>
            <Typography variant="body1" fontWeight="regular" color="#374151">
              {data?.date || "-"}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mt: 3, mb: 2 }} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {/* <Typography
            variant="subtitle2"
            fontWeight="medium"
            sx={{ textDecoration: "underline", textUnderlineOffset: 4 }}
          >
            Responses
          </Typography> */}
          <TextField
            multiline
            value={data?.value || "-"}
            sx={DisplayTextBox}
            disabled
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default JournalResponseModal;
