import {
  Badge,
  Box,
  // CircularProgress,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { StyledHeader, HeaderLeftContent } from "../Common/styles/header";
import {
  StyledTableCell,
  pageSize,
  TablePaginationStyle,
  paginationLabel,
  NoDataContainer,
} from "../Common/styles/table";
import { useSearchParams } from "react-router-dom";
import { errorToastMessage } from "../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { DateTime } from "luxon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete } from "@mui/icons-material";
import CoachNotificationModal from "./CoachNotificationModal";
import {
  // setRefreshNotifTable,
  setRefreshNotification,
} from "../../Redux/reducers/notificationSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import DeleteNotificationModal from "../Layout/DeleteNotificationModal";

const CoachNotifications = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [row, setRow] = useState<any>(null);
  // const [delLoader, setDelLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const type = ["notifications", "chat"].includes(
    searchParams.get("type") || ""
  )
    ? searchParams.get("type")
    : "notifications";
  const typeRef = useRef(type);
  const { refreshNotifications } = useAppSelector(
    (state) => state.notification
  );
  const [modalData, setModalData] = useState<any>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const params: any = new URLSearchParams();
    params.set("page", page.toString());
    params.set("type", type);

    setSearchParams(params, {
      replace: true,
    });
  }, [page, setSearchParams, type]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let resData = [];
        let dataCount = 0;
        if (type === "notifications") {
          const url = `/notifications?page=${page + 1}&size=${pageSize}`;
          const res: AxiosResponse = await http.get(url);
          resData = (res.data?.data?.notifications || [])?.map((item: any) => {
            return {
              ...item,
              heading: item?.data?.message?.title,
              time: DateTime.fromISO(item?.createdAt).toFormat("hh:mm a"),
              date: DateTime.fromISO(item?.createdAt).toFormat("dd/LL/yyyy"),
              notificationType: "notification",
            };
          });
          dataCount = res.data?.data?.count;
        } else {
          const url = `/notifications/chat/grouped?page=${
            page + 1
          }&size=${pageSize}`;
          const res: AxiosResponse = await http.get(url);
          resData = (res.data?.data?.data || [])?.map((item: any) => {
            return {
              id: item?.code,
              title: item?.mostRecentEntry?.title,
              body: item?.mostRecentEntry?.data?.params?.message,
              isRead: item?.mostRecentEntry?.isRead,
              time: DateTime.fromISO(item?.mostRecentEntry?.createdAt).toFormat(
                "hh:mm a"
              ),
              date: DateTime.fromISO(item?.mostRecentEntry?.createdAt).toFormat(
                "dd/LL/yyyy"
              ),
              notificationType: "chat",
            };
          });
          dataCount = parseInt(res.data?.data?.totalGroupedCount || "0");
        }
        if (resData?.length === 0 && dataCount > 0 && page > 0) {
          setPage(0);
          return;
        }

        setData(resData);
        setTotalCount(dataCount);
        setLoading(false);
      } catch (error) {
        errorToastMessage(error as Error);
        setLoading(false);
      }
    };
    // setting the page to 0 if the type has changed
    if (typeRef.current === type) {
      fetchData();
    } else {
      typeRef.current = type;
      setPage(0);
    }
  }, [page, type, refreshNotifications]);

  const openMenu = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setRow(row);
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setRow(null);
  };

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const openModal = () => {
    setShowModal(true);
    setAnchorEl(null);
  };
  const closeModal = () => {
    setShowModal(false);
    setRow(null);
  };

  const openDelNotifModal = (data: any) => {
    setModalData({
      data: data?.id,
      type,
    });
    closeMenu();
  };

  const closeDelNotifModal = () => {
    setModalData(null);
  };

  const notificationModalOpen = (item: any) => {
    setRow(item);
    openModal();
    if (!item.isRead) markAsRead(item?.id, item?.notificationType);
  };

  const markAsRead = async (id: string, type: string) => {
    try {
      let url;
      if (type === "chat") {
        const url = `/notifications/chat/grouped`;
        const body = { code: id };
        await http.patch(url, body);
      } else {
        url = `/notifications/${id}`;
        const body = { isRead: true };
        await http.put(url, body);
      }

      dispatch(setRefreshNotification());
      // dispatch(setRefreshNotifTable());
    } catch (error) {
      errorToastMessage(error as Error);
    }
  };

  const refresh = () => {
    dispatch(setRefreshNotification());
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography
            fontSize={30}
            fontWeight="bold"
            sx={{ textTransform: "capitalize" }}
          >
            {type}
          </Typography>
        </Box>
      </StyledHeader>
      <Box sx={{ p: 3, overflow: "auto", height: "calc(100% - 85px)" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Notification</StyledTableCell>
              <StyledTableCell>
                {type === "notifications"
                  ? "Notification description"
                  : "Message"}
              </StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Time</StyledTableCell>

              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          {!loading && data?.length > 0 && (
            <>
              <TableBody>
                {data?.map((item: any) => (
                  <TableRow key={item.id}>
                    <StyledTableCell
                      width="25%"
                      sx={{
                        maxWidth: 0,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                      }}
                      onClick={() => notificationModalOpen(item)}
                    >
                      <Badge
                        color="error"
                        badgeContent={" "}
                        variant="dot"
                        invisible={item?.isRead}
                        sx={{
                          ".MuiBadge-badge": { right: "-5px", top: 3 },
                        }}
                      >
                        {item?.title || "-"}
                      </Badge>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: "30ch",
                        minWidth: "1px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item?.body || "-"}
                    </StyledTableCell>
                    <StyledTableCell>{item?.date || "-"}</StyledTableCell>
                    <StyledTableCell>{item?.time || "-"}</StyledTableCell>

                    {item?.isRead ? (
                      <StyledTableCell align="right">
                        <IconButton
                          size="small"
                          onClick={(e) => openMenu(e, item)}
                        >
                          <MoreVertIcon sx={{ fontSize: "20px" }} />
                        </IconButton>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell align="right" />
                    )}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {totalCount > pageSize && (
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={totalCount}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>

        {!loading && data?.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No data available
            </Typography>
          </NoDataContainer>
        )}

        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          PaperProps={{
            style: {
              minWidth: "200px",
            },
          }}
        >
          <MenuItem onClick={() => openDelNotifModal(row)}>
            <ListItemIcon>
              {/* {delLoader ? (
                <CircularProgress size={18} />
              ) : ( */}
              <Delete fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>

        {showModal && (
          <CoachNotificationModal
            showModal={showModal}
            closeModal={closeModal}
            data={row}
          />
        )}
      </Box>
      {modalData && (
        <DeleteNotificationModal
          data={modalData}
          closeModal={closeDelNotifModal}
          refresh={refresh}
        />
      )}
    </>
  );
};

export default CoachNotifications;
