import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../Redux/hooks";

const ParticipantUrlSetter = () => {
  let [, setSearchParams] = useSearchParams();
  const { searchText, type, filterId, paginationModel, activePtpType } =
    useAppSelector((state) => state.participants);
  const { hasAdminAccess } = useAppSelector((state) => state.user);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", paginationModel.page.toString());
    if (searchText) {
      params.set("search", searchText);
    }
    if (filterId) {
      params.set("filter", filterId);
    }
    if (type && hasAdminAccess) {
      params.set("type", type);
    }
    if (hasAdminAccess && type === "active" && activePtpType) {
      params.set("tab", activePtpType);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [
    setSearchParams,
    searchText,
    type,
    paginationModel,
    filterId,
    activePtpType,
    hasAdminAccess,
  ]);

  return <></>;
};

export default ParticipantUrlSetter;
