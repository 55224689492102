import { Box, IconButton, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { CalendarIcon } from "../../../../Common/assets/FoodJournalIcons";
import { setLogsDate } from "../../../../../Redux/reducers/myPatientsSlice";

const DateSelectorBlock = () => {
  const dispatch = useAppDispatch();
  const { logsStartDate, logsEndDate } = useAppSelector(
    (state) => state.myPatients
  );
  const [showCalendar, setShowCalendar] = useState("");

  const handleChangeDate = (newDate: any, type: string) => {
    dispatch(
      setLogsDate({
        [type]: newDate?.toISO(),
      })
    );
  };

  return (
    <Box
      sx={{
        marginLeft: "auto",
        display: "flex",
        alignItems: "center",
        gap: 2,
        p: "12px 16px",
      }}
    >
      <Typography variant="body1" fontWeight={"medium"}>
        Start Date :{" "}
        <Typography component={"span"} fontWeight={"regular"}>
          {DateTime.fromISO(logsStartDate).toFormat("dd-LL-yyyy")}
        </Typography>
      </Typography>
      <DatePicker
        inputFormat="dd/MM/yyyy"
        value={DateTime.fromISO(logsStartDate)}
        open={showCalendar === "startDate"}
        disableFuture
        disableHighlightToday
        maxDate={DateTime.fromISO(logsEndDate)}
        onClose={() => setShowCalendar("")}
        onChange={(val) => handleChangeDate(val, "startDate")}
        renderInput={({ inputRef }) => (
          <IconButton
            ref={inputRef}
            onClick={() => setShowCalendar("startDate")}
          >
            <CalendarIcon />
          </IconButton>
        )}
      />
      <Typography variant="body1" fontWeight={"medium"}>
        End Date :{" "}
        <Typography component={"span"} fontWeight={"regular"}>
          {DateTime.fromISO(logsEndDate)?.toFormat("dd-LL-yyyy")}
        </Typography>
      </Typography>
      <DatePicker
        inputFormat="dd/MM/yyyy"
        value={DateTime.fromISO(logsEndDate)}
        open={showCalendar === "endDate"}
        disableFuture
        minDate={DateTime.fromISO(logsStartDate)}
        onClose={() => setShowCalendar("")}
        onChange={(val) => handleChangeDate(val, "endDate")}
        renderInput={({ inputRef }) => (
          <IconButton ref={inputRef} onClick={() => setShowCalendar("endDate")}>
            <CalendarIcon />
          </IconButton>
        )}
      />
    </Box>
  );
};

export default DateSelectorBlock;
