import React, { useEffect } from "react";
import { useAppDispatch } from "../../../../Redux/hooks";
import { useSearchParams } from "react-router-dom";
import { setMyPatientDefaults } from "../../../../Redux/reducers/myPatientsSlice";
import { DateTime } from "luxon";

const MyPatientsUrlLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const tab = searchParams.get("tab") || "profile";
    const user = searchParams.get("user") || "";
    const type =
      searchParams.get("type") ||
      (tab === "journal"
        ? "selfReflection"
        : tab === "logs"
        ? "medication"
        : "active");
    const filter = searchParams.get("filter") || "";
    const page = parseInt(searchParams.get("page") || "0");
    const startDate = DateTime.fromFormat(
      searchParams.get("startDate") || DateTime.now().toFormat("dd-LL-yyyy"),
      "dd-LL-yyyy"
    );
    const endDate = DateTime.fromFormat(
      searchParams.get("endDate") || DateTime.now().toFormat("dd-LL-yyyy"),
      "dd-LL-yyyy"
    );
    const sort = searchParams.get("sort") || "";
    const order = searchParams.get("order") || "";

    dispatch(
      setMyPatientDefaults({
        tab,
        user,
        type,
        filter,
        page,
        startDate: startDate?.toISO(),
        endDate: endDate?.toISO(),
        sort,
        order,
      })
    );
  }, [searchParams, dispatch]);

  return <></>;
};

export default React.memo(MyPatientsUrlLoader);
