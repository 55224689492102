import { Edit } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../Redux/hooks";

import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";
import EditLearningPath from "./EditLearningPath";
import { pathElement, pathHeader } from "./styles";
import { riskCategoriesMap } from "../../utils/learningPath";

// const userTarget = [
//   {
//     label: "Patient",
//     value: "participant",
//   },
//   { label: "Caregiver", value: "caregiver" },
// ];

const LearningPath = () => {
  // const dispatch = useAppDispatch();

  const { learningPathLanguage: lang, learningPathPatientType: patientType } =
    useAppSelector((state) => state.cloud);
  const [loading, setLoading] = useState(true);
  const [toggler, setToggler] = useState(false);
  const [risks, setRisk] = useState<any[]>([]);
  const [showModal, setModal] = useState("");

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const res = await http.get(
          `/learning-workflows?lang=${lang}&userType=${patientType}`
        );
        const data = res.data.data.rows.map((risk: any) => {
          // let str = risk?.riskCategory?.split("_")?.[1]?.toLowerCase();
          // if (str) {
          //   str === "active" ? (str = str + " ulcer") : (str = str + " risk");
          // }
          return {
            id: risk.id,
            label: riskCategoriesMap[risk?.riskCategory],
            // type: patientType === "caregiver" ? "Caregiver" : "Patient",
            lessons: risk.lessons.map((lesson: any) => {
              return {
                id: lesson.id,
                name: lesson?.lesson?.name || "Lesson Name",
              };
            }),
          };
        });
        setRisk(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [lang, patientType, toggler, setLoading, setRisk]);

  const editPath = (category: string) => {
    setModal(category);
  };

  const closeModal = () => {
    setModal("");
  };

  const callback = () => {
    setToggler((prev) => !prev);
  };

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Learning path
          </Typography>
        </Box>
        {/* <Box sx={HeaderRightContent}>
          <FormControl sx={{ width: 200 }}>
            <InputLabel id="language-label">Language</InputLabel>
            <Select
              fullWidth
              labelId="language-label"
              id="language"
              label="Language"
              value={lang}
              onChange={(e) => {
                dispatch(setLearningPathLang(e.target.value));
              }}
            >
              {LANGUAGES.map((lang) => (
                <MenuItem key={lang.value} value={lang.value}>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 200 }}>
            <InputLabel id="part-type-label">Participant Type</InputLabel>
            <Select
              labelId="part-type-label"
              id="part-type"
              fullWidth
              value={patientType}
              onChange={(e) => {
                dispatch(setLearningPathPatientType(e.target.value));
              }}
              label="Participant Type"
            >
              {userTarget.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box> */}
      </StyledHeader>
      <Box
        sx={{
          p: 2.5,
          height: "calc(100% - 85px)",
          overflow: "auto",
        }}
      >
        {risks.map((risk) => {
          return (
            <Box sx={pathElement} key={risk.id}>
              <Box sx={pathHeader}>
                <Typography
                  fontSize={"24px"}
                  fontWeight="medium"
                  sx={{ textTransform: "capitalize" }}
                >
                  {risk?.label}
                  {/* for {risk.type} */}
                </Typography>
                <Button
                  startIcon={<Edit />}
                  variant="outlined"
                  onClick={() => editPath(risk.id)}
                >
                  Edit path
                </Button>
              </Box>
              <Box>
                {risk.lessons.length > 0 ? (
                  risk.lessons.map((lesson: any, index: number) => {
                    return (
                      <Typography variant="h6" key={lesson.id}>
                        {index + 1 + "."} {lesson.name}
                      </Typography>
                    );
                  })
                ) : (
                  <Typography variant="h6" mt={2}>
                    Path Not Setup
                  </Typography>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      {showModal && (
        <EditLearningPath
          category={showModal}
          lang={lang}
          closeModal={closeModal}
          callback={callback}
        />
      )}
    </>
  );
};

export default LearningPath;
