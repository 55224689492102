import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { AxiosResponse } from "axios";

import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { useAppDispatch } from "../../../Redux/hooks";
import { refreshAdministratorsPage } from "../../../Redux/reducers/administratorsSlice";
import { AdminMap } from "./Administrators";

const schema = yup.object().shape({
  firstName: yup.string().required("*First Name is Required"),
  lastName: yup.string().required("*Last Name is Required"),
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("*Email ID is Required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  type: string;
};

const AddAdminModal = ({ showModal, closeModal, type }: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const dispatch = useAppDispatch();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let data = {
        ...values,
        role: type === "health_coach" ? "coach" : type,
      };
      let res: AxiosResponse;
      if (type === "health_coach") {
        res = await http.post(`/admins/coach`, data);
      } else if (type === "clinical_assistant") {
        res = await http.post(`/clinical-assistant`, data);
      } else {
        res = await http.post(`/admins`, data);
      }
      toastMessage("success", res.data.message);
      closeModal();
      dispatch(refreshAdministratorsPage());
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={`Add ${AdminMap[type]}`}
          onCloseClick={closeModal}
        />
        {/* {type === "health_coach" && (
          <Box
            sx={{
              backgroundColor: "rgba(222, 141, 51, 0.10)",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#DE8D33",
              borderRadius: "6px",
              width: "80px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Image sx={{ color: "#DE8D33", fontSize: "40px" }}></Image>
          </Box>
        )} */}
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, getFieldProps }) => (
            <Form>
              <Box sx={{ display: "flex", gap: 1.5 }}>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel sx={LabelStyle} htmlFor="firstName">
                    First Name
                  </FormLabel>
                  <TextField
                    id="firstName"
                    placeholder="First Name"
                    error={
                      touched?.firstName && errors?.firstName ? true : false
                    }
                    helperText={
                      touched?.firstName && errors?.firstName
                        ? errors?.firstName
                        : " "
                    }
                    {...getFieldProps("firstName")}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel sx={LabelStyle} htmlFor="lastName">
                    Last Name
                  </FormLabel>
                  <TextField
                    id="lastName"
                    placeholder="Last Name"
                    error={touched?.lastName && errors?.lastName ? true : false}
                    helperText={
                      touched?.lastName && errors?.lastName
                        ? errors?.lastName
                        : " "
                    }
                    {...getFieldProps("lastName")}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    Email
                  </FormLabel>
                  <TextField
                    id="email"
                    placeholder="email@example.com"
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email ? errors?.email : " "
                    }
                    {...getFieldProps("email")}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddAdminModal;
