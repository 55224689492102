import { useSearchParams } from "react-router-dom";
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";
import { useEffect, useMemo, useState } from "react";

import {
  NoDataContainer,
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
  pageSize,
  paginationLabel,
} from "../Common/styles/table";

import { errorToastMessage } from "../../utils/toast";
import { DateTime } from "luxon";
import { debounce } from "lodash";
import { AxiosResponse } from "axios";
import http from "../../utils/http";

const Schedule = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const [columnSort, setColumnSort] = useState(searchParams.get("sort") || "");
  const [sortOrder, setSortOrder] = useState(searchParams.get("order") || "");

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    if (searchText) {
      params.set("search", searchText);
    }

    if (sortOrder) {
      params.set("order", sortOrder);
    }
    if (columnSort) {
      params.set("sort", columnSort);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, searchText, sortOrder, columnSort, page]);

  const handleSort = (order: string, column: string) => {
    if (columnSort === column && sortOrder === order) {
      setSortOrder("");
      setColumnSort("");
    } else {
      setColumnSort(column);
      setSortOrder(order);
    }
  };

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setSearchText(val);
        setPage(0);
      }, 500),
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url = `/consultation/paginated?page=${
          page + 1
        }&size=${pageSize}&type=mixed`;
        if (searchText) {
          url += `&search=${searchText}`;
        }
        if (sortOrder && columnSort) {
          url += `&sortBy=${columnSort}&orderBy=${sortOrder}`;
        }
        const res: AxiosResponse = await http.get(url);

        const resData = res.data?.data?.rows;

        const newData = resData?.map((item: any) => {
          const duration: any =
            item?.startedAt && item?.endedAt
              ? DateTime?.fromISO(item?.endedAt).diff(
                  DateTime?.fromISO(item?.startedAt),
                  "minutes"
                )?.minutes
              : "";

          return {
            id: item?.id,
            userId: item?.user?.code,
            date: item?.scheduledAt
              ? DateTime?.fromISO(item.scheduledAt)?.toFormat("dd LLL yyyy")
              : null,
            startTime:
              item?.startedAt && item?.status === "completed"
                ? DateTime?.fromISO(item.startedAt)?.toFormat("hh:mm a")
                : null,
            endTime:
              item?.endedAt && item?.status === "completed"
                ? DateTime?.fromISO(item.endedAt)?.toFormat("hh:mm a")
                : null,
            duration:
              typeof duration === "number" && item?.status === "completed"
                ? Math?.floor(duration)?.toString()
                : "",
            // link: item.link || "-",
          };
        });
        setData(newData);
        setLoading(false);
        setTotalCount(res.data?.data?.count || 0);
      } catch (error) {
        errorToastMessage(error as Error);
        setLoading(false);
      }
    };
    fetchData();
  }, [
    setLoading,
    setData,
    setTotalCount,
    page,
    sortOrder,
    columnSort,
    searchText,
  ]);

  // const handleLink = (patientId: string) => {
  //   if (patientId) {
  //     navigate(`/app/participants/my-patients/${patientId}`);
  //   } else {
  //     errorToastMessage(new Error("User Id not available"));
  //   }
  // };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === columnSort && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === columnSort && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Past sessions
          </Typography>
          <TextField
            style={{ width: "250px" }}
            placeholder="Search for user ID"
            onChange={(e) => modifySearchTerm(e.target.value)}
            defaultValue={searchText}
          />
        </Box>
      </StyledHeader>
      <Box sx={{ p: 3, overflow: "auto", height: "calc(100% - 85px)" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                User ID <SortLabel column="code" />
              </StyledTableCell>
              <StyledTableCell>
                Date
                <SortLabel column="scheduledAt" />
              </StyledTableCell>
              <StyledTableCell>Start time</StyledTableCell>
              <StyledTableCell>End time</StyledTableCell>
              <StyledTableCell>Duration (min)</StyledTableCell>
              {/* <StyledTableCell></StyledTableCell> */}
            </TableRow>
          </TableHead>
          {!loading && data?.length > 0 && (
            <>
              <TableBody>
                {data.map((item: any) => (
                  <TableRow key={item.id}>
                    <StyledTableCell>{item?.userId || "-"}</StyledTableCell>
                    <StyledTableCell>{item?.date || "-"}</StyledTableCell>
                    <StyledTableCell>{item?.startTime || "-"}</StyledTableCell>
                    <StyledTableCell>{item?.endTime || "-"}</StyledTableCell>
                    <StyledTableCell>{item?.duration || "-"}</StyledTableCell>
                    {/* <StyledTableCell align="right">
                    <IconButton size="small">
                      <MoreVertIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </StyledTableCell> */}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {totalCount > pageSize && (
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={totalCount}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>

        {!loading && data?.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No data available
            </Typography>
          </NoDataContainer>
        )}

        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Schedule;
