import { Box, FormLabel, Typography } from "@mui/material";
import { CMSInputLabel, UploadWrapper } from "../styles";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { useAppDispatch } from "../../../../Redux/hooks";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { uploadFile } from "../../../../utils/upload";
import {
  changeSectionValue,
  setLoading,
} from "../../../../Redux/reducers/reflectionBuilderSlice";
import {
  AudioUploadIcon,
  VideoUploadIcon,
  ImageUploadIcon,
} from "../../../CMS/Icons";

type Props = {
  label: string;
  section: any;
  index: number;
  type: "audio" | "video" | "image";
  accept: string;
};

const UploadItem: React.FC<Props> = ({
  label,
  section,
  type,
  accept,
  index,
}) => {
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          dispatch(setLoading(true));
          const url = await uploadFile(file, "education_lesson_image");
          dispatch(
            changeSectionValue({
              index,
              type: "value",
              value: url,
            })
          );
          dispatch(setLoading(false));
        }
      } catch (err) {
        dispatch(setLoading(false));
        errorToastMessage(err as Error);
      }
    },
    [index, dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      [accept]: [],
    },
  });

  return (
    <Box>
      <FormLabel sx={CMSInputLabel}>{label}</FormLabel>
      <Box {...getRootProps({ className: "dropzone" })} sx={UploadWrapper}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {section.value ? (
            <Typography variant="subtitle1" fontWeight={"medium"}>
              File available. Drop Files to change
            </Typography>
          ) : (
            <>
              {type === "audio" && <AudioUploadIcon />}
              {type === "video" && <VideoUploadIcon />}
              {type === "image" && <ImageUploadIcon />}
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UploadItem;
