import React, { useState } from "react";
import ReactPlayer from "react-player";
import { logCustomGaEvent } from "../../../utils/webviewAnalytics";
import VideoTranscript from "./VideoTranscript";
import { Backdrop, Box } from "@mui/material";

type Props = {
  item: any;
  pageId: string;
  pageTitle?: string;
  lang: string;
};

export const AudioPlayer: React.FC<Props> = ({ item, pageId, pageTitle }) => {
  const playHandler = () => {
    logCustomGaEvent("em_audio_play", {
      page_id: pageId,
      page_title: pageTitle,
      section_id: item?.id,
      section_title: item?.label,
    });
  };

  const pauseHandler = () => {
    logCustomGaEvent("em_audio_pause", {
      page_id: pageId,
      page_title: pageTitle,
      section_id: item?.id,
      section_title: item?.label,
    });
  };

  const endHandler = () => {
    logCustomGaEvent("em_audio_end", {
      page_id: pageId,
      page_title: pageTitle,
      section_id: item?.id,
      section_title: item?.label,
    });
  };

  return (
    <div className="cms-audio">
      <div className="cms-media-label">{item.label}</div>
      <ReactPlayer
        className="education-audio"
        controls
        width="100%"
        url={item.value}
        onPause={pauseHandler}
        onPlay={playHandler}
        onEnded={endHandler}
      />
    </div>
  );
};

export const VideoPlayer: React.FC<Props> = ({
  item,
  pageId,
  pageTitle,
  lang,
}) => {
  const playHandler = () => {
    logCustomGaEvent("em_video_play", {
      page_id: pageId,
      page_title: pageTitle,
      section_id: item?.id,
      section_title: item?.label,
    });
  };

  const pauseHandler = () => {
    logCustomGaEvent("em_video_pause", {
      page_id: pageId,
      page_title: pageTitle,
      section_id: item?.id,
      section_title: item?.label,
    });
  };

  const endHandler = () => {
    logCustomGaEvent("em_video_end", {
      page_id: pageId,
      page_title: pageTitle,
      section_id: item?.id,
      section_title: item?.label,
    });
  };

  return (
    <>
      <div className="cms-video">
        <div className="cms-media-label">{item.label}</div>
        <ReactPlayer
          className="education-video"
          controls
          width="100%"
          // height={item?.format === "portrait" ? "560px" : "300px"}
          // height={`calc((100vw - 46px) / ${
          //   item?.format?.split(":")[0] / item?.format?.split(":")[1] || "300px"
          // })`}
          height={
            parseFloat(item?.format)
              ? `calc((100vw - 46px) / ${parseFloat(item?.format)})`
              : "300px"
          }
          url={item.value}
          onPause={pauseHandler}
          onPlay={playHandler}
          onEnded={endHandler}
        />
      </div>
      {item.transcript && (
        <VideoTranscript
          transcript={item.transcript}
          lang={lang}
          pageId={pageId}
          sectionId={item?.id}
          pageTitle={pageTitle || ""}
          sectionTitle={item?.label}
        />
      )}
    </>
  );
};

const PreviewImageWrapper = {
  height: "65%",
  overflow: "hidden",
  ".previewImage": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
};

type PreviewProps = {
  handleClose: Function;
  url: string;
  altText: string;
};

const ImagePreview: React.FC<PreviewProps> = ({
  handleClose,
  url,
  altText,
}) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      onClick={() => {
        handleClose();
      }}
    >
      <Box
        sx={PreviewImageWrapper}
        onClick={() => {
          handleClose();
        }}
      >
        <img
          src={url}
          className="previewImage"
          alt={altText}
          onClick={(e) => e.stopPropagation()}
        />
      </Box>
    </Backdrop>
  );
};

export const ImageSection: React.FC<Props> = ({ item, pageId }) => {
  const [preview, setPreview] = useState(false);

  const togglePreview = () => {
    if (preview) {
      setPreview(false);
    } else {
      logCustomGaEvent("em_image_preview", {
        page_id: pageId,
        section_id: item?.id,
      });
      setPreview(true);
    }
  };

  return (
    <>
      <div className="cms-picture">
        <div className="cms-media-label">{item.label}</div>
        <img
          src={item.value}
          alt={item.altText || "section"}
          onClick={togglePreview}
        />
      </div>
      {preview && (
        <ImagePreview
          handleClose={togglePreview}
          url={item.value}
          altText={item.altText || "section"}
        />
      )}
    </>
  );
};
