import { SxProps, tableCellClasses } from "@mui/material";

export const accTableStyle: SxProps = {
  [`&.${tableCellClasses.body}`]: {
    bgcolor: "#E5E7EB",
    p: "8px 15px",
    height: "auto",
    fontSize: "14px",
    fontWeight: 600,
    color: "#4B5563",
  },
};

export const accTableBodyStyle = {
  "& > tr:last-child > td": {
    border: "none",
  },
};

export const accInAccHeaderStyle = {
  ".Mui-expanded": {
    m: "12px 0px !important",
  },
  bgcolor: "#E5E7EB",
  p: "8px 15px",
  height: "16px",
  fontSize: "14px",
  fontWeight: 600,
  color: "#4B5563",
}