import { DateTime } from "luxon";
import http from "./http";
import { errorToastMessage } from "./toast";

export const messageFormatter = (document: any, userId: any, docId: string) => {
  const message = { ...document };
  if (message.sentBy !== userId) {
    message.received = true;
  }
  if (message.sentBy === userId) {
    message.userName = "You";
  }
  if (message?.sentAt?.seconds) {
    const time = DateTime.fromSeconds(message.sentAt.seconds);
    if (time.hasSame(DateTime.now(), "day")) {
      message.date = "Today";
    } else {
      message.date = time.toFormat("dd LLL yyyy");
    }
    message.time = time.toFormat("hh:mm a");
  }
  if (docId) {
    message.id = docId;
  }
  return message;
};

export const computeRoomName = (user1: string, user2: string) => {
  if (user1 && user2) {
    const cmp = user1.localeCompare(user2);
    let chatRoom = "chat_";
    if (cmp === -1) {
      chatRoom += user1 + "_" + user2;
    } else {
      chatRoom += user2 + "_" + user1;
    }
    return chatRoom;
  } else {
    return "";
  }
};

export const getChatUrls = async (chats: any[]) => {
  try {
    const obj = new Map();
    chats.forEach((message, index) => {
      if (
        message.resourceType === "image" ||
        message.resourceType === "video" ||
        message.resourceType === "audio"
      ) {
        obj.set(index, message.resourceUrl);
      }
    });
    const urls: any[] = [];
    obj.forEach((value) => {
      urls.push(value);
    });
    if (urls.length > 0) {
      const res = await http.post(`/assets/get_signed_urls`, {
        urls: urls,
      });
      const { urls: updatedUrls } = res.data.data;
      const iterator = obj.keys();
      updatedUrls.forEach((url: any) => {
        const key = iterator.next().value;
        chats[key].resourceUrl = url;
      });
    }
  } catch (err) {
  } finally {
    return chats;
  }
};

export const attachmentDownloader = async (url: string) => {
  try {
    const res = await http.post(`/assets/get_signed_urls`, {
      urls: [url],
    });
    const { urls: updatedUrls } = res.data.data;
    window.open(updatedUrls[0], "_blank");
  } catch (err) {
    errorToastMessage(err as Error);
  }
};
