import { Box, Typography } from "@mui/material";
import { CapsuleIcon } from "../../Common/assets/FoodJournalIcons";
import {
  NoDataContainer,
  medicationDetails,
  medicationName,
  medicationWrapper,
} from "./style";
import { DateTime } from "luxon";

const MedicationJournal: React.FC<any> = ({ meds }) => {
  return (
    <Box sx={medicationWrapper}>
      <Typography variant="subtitle2" fontWeight={600} mb={2}>
        Medication
      </Typography>
      {meds.length > 0 ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(268px, 1fr))",
            gridGap: "20px",
          }}
        >
          {meds.map((item: any, index: number) => (
            <Box key={index} sx={medicationDetails}>
              <Box sx={medicationName}>
                <Box>
                  <CapsuleIcon />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="medium"
                    color="#216B64"
                    textTransform={"capitalize"}
                  >
                    {item?.medication?.name}
                  </Typography>
                  <Box
                    sx={{
                      mt: 0.5,
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="#212121"
                      fontWeight="medium"
                    >
                      {item?.medication?.strength}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: 0.5,
                    }}
                  >
                    <Typography color="#212121" fontWeight="medium">
                      {item?.isSkipped ? "Skipped" : "Taken"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: 1,
                      color: "#676970",
                      padding: "6px",
                      fontSize: "15px",
                      fontWeight: 500,
                      backgroundColor: "#F2F2F3",
                      borderRadius: "5px",
                      alignSelf: "flex-start",
                    }}
                  >
                    {DateTime.fromISO(item?.trackDate).toFormat("hh:mm a")}
                  </Box>
                  {item?.medication?.notes && (
                    <Box
                      sx={{
                        mt: 1.5,
                        fontWeight: 500,
                        fontSize: "13px",
                        color: "#4d4f56",
                        padding: "6px",
                        backgroundColor: "#F2F2F3",
                        borderRadius: "5px",
                        display: "inline-block",
                      }}
                    >
                      Notes: {item?.medication?.notes}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={NoDataContainer}>
          <Typography variant="subtitle1" fontWeight="regular" color={"gray"}>
            Data Unavailable
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MedicationJournal;
