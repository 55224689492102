import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { useDropzone } from "react-dropzone";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setModalEducationId,
  toggleCMS,
} from "../../../Redux/reducers/cmsSlice";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { quillFormats, quillModules } from "../../../utils/reactquill";
import { uploadFile } from "../../../utils/upload";
import { ImageUploadIcon } from "../Icons";
import { UploadWrapper } from "../cms.style";
import { debounce } from "lodash";
import { delayOptions } from "../../../utils/delay";

const UploadItem: React.FC<any> = ({ image, setFieldValue }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const url = await uploadFile(file, "education_lesson_image");
          setFieldValue("imageUrl", url);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box {...getRootProps({ className: "dropzone" })} sx={UploadWrapper}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

let schema = yup.object().shape({
  name: yup.string().required("Module Name is Required"),
  description: yup.string().required("Description is Required"),
  summary: yup
    .string()
    .required("Summary is Required")
    .typeError("Summary is Required"),
  externalName: yup.string().required("External Name is Required"),
  readTime: yup
    .number()
    .min(1, "Read Time must be greater than 0")
    .required("Read Time is Required"),
});

const AddEducationModal: React.FC<any> = () => {
  const dispatch = useAppDispatch();

  const [initialData, setInitialData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [entryBotLoader, setEntryBotLoader] = useState(false);
  const [exitBotLoader, setExitBotLoader] = useState(false);
  const [entryBots, setEntryBots] = useState<any>([]);
  const [exitBots, setExitBots] = useState<any>([]);
  const { educationModalId } = useAppSelector((state) => state.cms);
  const navigate = useNavigate();

  const closeModal = () => {
    dispatch(setModalEducationId(null));
  };

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);

      const body = {
        ...values,
        lang: "en",
        type: 1,
      };
      if (body?.entryBot?.id) {
        body.entryBotId = body?.entryBot?.id;
      } else {
        body.entryBotId = null;
      }
      if (body?.exitBot?.id) {
        body.exitBotId = body?.exitBot?.id;
      } else {
        body.exitBotId = null;
        body.exitBotDelay = 0;
      }
      delete body.entryBot;
      delete body.exitBot;

      let res: AxiosResponse;
      if (educationModalId !== "new") {
        res = await http.put(`/lms/lessons/${educationModalId}`, body);
        toastMessage("success", res.data.message);
        dispatch(toggleCMS());
        closeModal();
      } else {
        res = await http.post(`/lms/lessons`, body);
        toastMessage("success", res.data.message);
        closeModal();
        navigate(`/app/cms/${res.data.data.id}`);
      }
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const res: AxiosResponse = await http.get(
          `/lms/lessons/${educationModalId}`
        );
        const response = res.data.data;
        setInitialData({
          name: response?.name || "",
          description: response?.description || "",
          type: response?.type || 1,
          summary: response?.summary || "",
          adaptive: response?.adaptive || false,
          imageUrl: response?.imageUrl || "",
          entryBot: response?.entryBot || null,
          exitBot: response?.exitBot || null,
          lang: response?.lang || "English",
          externalName: response?.externalName || "",
          exitBotDelay: response?.exitBotDelay || 0,
          readTime: response?.readTime || 1,
        });
        if (response?.entryBot) {
          setEntryBots([response?.entryBot]);
        }
        if (response?.exitBot) {
          setExitBots([response?.exitBot]);
        }
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setModalEducationId(null));
      }
    };
    if (educationModalId && educationModalId !== "new") {
      fetchDetails();
    } else {
      setInitialData({});
      setLoading(false);
    }
  }, [educationModalId, dispatch, setInitialData, setLoading]);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string, name: string, lang: string) => {
        try {
          if (value) {
            if (name === "entryBot") {
              setEntryBotLoader(true);
            } else {
              setExitBotLoader(true);
            }
            const res: AxiosResponse = await http.get(
              `/bots?page=1&size=15&status=active&search=${value}&type=general&lang=${lang}`
            );
            const newBots = res.data.data.bots.map((bot: any) => {
              return {
                id: bot?.id,
                name: bot?.name,
              };
            });

            if (name === "entryBot") {
              setEntryBots(newBots);
            } else {
              setExitBots(newBots);
            }
            if (name === "entryBot") {
              setEntryBotLoader(false);
            } else {
              setExitBotLoader(false);
            }
          }
        } catch (err) {
          if (name === "entryBot") {
            setEntryBotLoader(false);
          } else {
            setExitBotLoader(false);
          }
          errorToastMessage(err as Error);
        }
      }, 500),
    []
  );

  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={
            educationModalId === "new"
              ? "Add Education Module"
              : "Edit Education Module"
          }
          onCloseClick={closeModal}
        />
        {!loading ? (
          <Formik
            initialValues={{
              name: initialData?.name || "",
              description: initialData?.description || "",
              type: 1,
              summary: initialData?.summary || "",
              adaptive: initialData?.adaptive || false,
              imageUrl: initialData?.imageUrl || "",
              entryBot: initialData?.entryBot || null,
              exitBot: initialData?.exitBot || null,
              exitBotDelay: initialData?.exitBotDelay || 0,
              lang: initialData?.lang || "en",
              externalName: initialData?.externalName || "",
              readTime: initialData?.readTime || 1,
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              submitHandler(values);
            }}
          >
            {({
              handleSubmit,
              getFieldProps,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="education-module-name">
                    Education Module Name*
                  </FormLabel>
                  <TextField
                    placeholder="Module Name"
                    id="education-module-name"
                    {...getFieldProps("name")}
                    error={touched?.name && errors?.name ? true : false}
                    helperText={
                      touched?.name && errors?.name
                        ? (errors?.name as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="externalName">
                    External Name*
                  </FormLabel>
                  <TextField
                    placeholder="External Name"
                    id="external-name"
                    {...getFieldProps("externalName")}
                    error={
                      touched?.externalName && errors?.externalName
                        ? true
                        : false
                    }
                    helperText={
                      touched?.externalName && errors?.externalName
                        ? (errors?.externalName as string)
                        : " "
                    }
                  />
                </FormControl>

                <FormControl sx={InputWrapper}>
                  <FormLabel
                    sx={LabelStyle}
                    htmlFor="education-module-description"
                  >
                    Description
                  </FormLabel>
                  <TextField
                    multiline
                    placeholder="Module Description"
                    id="education-module-description"
                    {...getFieldProps("description")}
                    error={
                      touched?.description && errors?.description ? true : false
                    }
                    helperText={
                      touched?.description && errors?.description
                        ? (errors?.description as string)
                        : " "
                    }
                  />
                </FormControl>
                {/* <FormControl
                  sx={InputWrapper}
                  error={touched?.type && errors?.type ? true : false}
                >
                  <FormLabel sx={LabelStyle} htmlFor="education-type">
                    Show to
                  </FormLabel>
                  <Select
                    id="education-type"
                    fullWidth
                    value={values?.type}
                    onChange={(e) => setFieldValue("type", e.target.value)}
                  >
                    <MenuItem key="patient" value={1}>
                      Patient
                    </MenuItem>
                    <MenuItem key="Caregiver" value={2}>
                      Caregiver
                    </MenuItem>
                    <MenuItem key="Both" value={0}>
                      Both Caregiver and Patient
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {touched?.type && errors?.type
                      ? (errors?.type as string)
                      : " "}
                  </FormHelperText>
                </FormControl> */}
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="read-time">
                    Read Time
                  </FormLabel>
                  <TextField
                    type="number"
                    placeholder="Read Time"
                    id="read-time"
                    {...getFieldProps("readTime")}
                    error={touched?.readTime && errors?.readTime ? true : false}
                    helperText={
                      touched?.readTime && errors?.readTime
                        ? (errors?.readTime as string)
                        : " "
                    }
                    inputProps={{
                      min: 1,
                    }}
                  />
                </FormControl>
                {/*educationModalId === "new" && (
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="lang">
                      Language
                    </FormLabel>
                    <Select
                      fullWidth
                      id="language"
                      sx={{ mb: 2 }}
                      value={values.lang}
                      onChange={(e) => {
                        setFieldValue("lang", e.target.value);
                        setFieldValue("entryBot", null);
                        setFieldValue("exitBot", null);
                      }}
                    >
                      {LANGUAGES.map((lang) => (
                        <MenuItem key={lang.value} value={lang.value}>
                          {lang.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                      )*/}
                <UploadItem
                  image={values?.imageUrl}
                  setFieldValue={setFieldValue}
                />
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="education-summary">
                    Summary
                  </FormLabel>
                  <ReactQuill
                    id="education-summary"
                    modules={quillModules}
                    formats={quillFormats}
                    placeholder="Module Summary"
                    defaultValue={values.summary}
                    className="quill-container"
                    onChange={(val: any) => {
                      setFieldValue("summary", val);
                    }}
                  />
                  <FormHelperText
                    error={touched?.summary && errors?.summary ? true : false}
                  >
                    {touched?.summary && errors?.summary
                      ? (errors?.summary as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
                <Stack direction="row" alignItems={"center"} spacing={1} mb={2}>
                  <FormLabel
                    sx={{ ...LabelStyle, mb: 0 }}
                    htmlFor="education-adaptive"
                  >
                    Show in Library
                  </FormLabel>
                  <Switch
                    id="education-adaptive"
                    checked={values.adaptive}
                    onChange={(e) =>
                      setFieldValue("adaptive", e.target.checked)
                    }
                  />
                </Stack>
                <FormControl sx={{ ...InputWrapper, mb: 2 }}>
                  <FormLabel sx={LabelStyle} htmlFor="lesson-entry">
                    Lesson Entry Bot
                  </FormLabel>
                  <Autocomplete
                    id="lesson-entry"
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input")
                        handleSearch(value, "entryBot", values.lang);
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("entryBot", value);
                    }}
                    options={entryBots}
                    value={values?.entryBot || null}
                    getOptionLabel={(option) => option?.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    loading={entryBotLoader}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    clearOnBlur={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Bots by typing..."
                      />
                    )}
                  />
                </FormControl>
                <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="lesson-exit">
                      Lesson Completion Bot
                    </FormLabel>
                    <Autocomplete
                      id="lesson-exit"
                      filterOptions={(x) => x}
                      onInputChange={(_1: any, value: any, reason: string) => {
                        if (reason === "input")
                          handleSearch(value, "exitBot", values.lang);
                      }}
                      onChange={(_1: any, value: any) => {
                        setFieldValue("exitBot", value);
                      }}
                      options={exitBots}
                      value={values?.exitBot || null}
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                      }}
                      loading={exitBotLoader}
                      loadingText={<CircularProgress size={20} />}
                      noOptionsText="No Results"
                      clearOnBlur={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search Bots by typing..."
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle}>Trigger in Days</FormLabel>
                    <Select
                      value={values.exitBotDelay}
                      onChange={(e) =>
                        setFieldValue("exitBotDelay", parseInt(e.target.value))
                      }
                    >
                      {delayOptions.map((number) => (
                        <MenuItem key={number} value={number}>
                          {number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 1,
                  }}
                >
                  {!submitLoader ? (
                    <>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                      <Button onClick={closeModal} variant="outlined">
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AddEducationModal;
