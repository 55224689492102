import React, { useEffect, useState } from "react";
import { Badge, Box, Typography } from "@mui/material";
import { StyledHeader, HeaderLeftContent } from "../Common/styles/header";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../Common/UI/TabPanel";
import Session from "./Session";
import Exercise from "./Exercise";
import LiveChat from "./LiveChat";
import { useSearchParams } from "react-router-dom";
import { errorToastMessage } from "../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { useAppSelector } from "../../Redux/hooks";

const Dashboard = () => {
  const { refreshNotifications, refreshExercises } = useAppSelector(
    (state) => state.notification
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [dashboardTab, setDashboardTab] = useState(
    searchParams.get("type") || "session"
  );
  const [sortOrder, setSortOrder] = useState("");
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [unreadUsersCount, setUnreadUsersCount] = useState(0);
  const [newCompletedExerciseCount, setNewCompletedExerciseCount] = useState(0);

  const handleChange = (_: any, newType: string) => {
    setSortOrder("");
    setPage(0);
    setDashboardTab(newType);
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (dashboardTab) {
      params.set("type", dashboardTab);
    }

    if (page) {
      params.set("page", page.toString());
    }

    if (sortOrder) {
      params.set("order", sortOrder);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, dashboardTab, sortOrder, page]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res: AxiosResponse = await http.get("/notifications/chat/count");
        setUnreadUsersCount(+res?.data?.data?.count || 0);
      } catch (err) {
        errorToastMessage(err as Error);
      }
    };
    fetchData();
  }, [refreshNotifications]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res: AxiosResponse = await http.get(
          "/self-reflection-assignments/completed/count"
        );
        setNewCompletedExerciseCount(res?.data?.data || 0);
      } catch (err) {
        errorToastMessage(err as Error);
      }
    };
    fetchData();
  }, [refreshExercises]);

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Dashboard
          </Typography>
        </Box>
      </StyledHeader>
      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={dashboardTab} onChange={handleChange}>
          <StyledTab
            label="Today's sessions"
            value="session"
            {...a11yProps(0)}
          />
          <StyledTab
            label={
              <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
                Live chat
                <Badge
                  color="error"
                  badgeContent={unreadUsersCount}
                  sx={{ ".MuiBadge-badge": { top: "-3px" } }}
                />
              </Box>
            }
            value="chat"
            {...a11yProps(1)}
          />

          <StyledTab
            label={
              <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
                Self-reflection exercise
                <Badge
                  color="error"
                  badgeContent={newCompletedExerciseCount}
                  sx={{ ".MuiBadge-badge": { top: "-3px" } }}
                />
              </Box>
            }
            value="exercise"
            {...a11yProps(2)}
          />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 146px)",
          overflow: "auto",
        }}
      >
        <MainTabPanel value={dashboardTab} index={"session"}>
          <Session />
        </MainTabPanel>
        <MainTabPanel value={dashboardTab} index={"chat"}>
          <LiveChat
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            page={page}
            setPage={setPage}
          />
        </MainTabPanel>
        <MainTabPanel value={dashboardTab} index={"exercise"}>
          <Exercise
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            page={page}
            setPage={setPage}
          />
        </MainTabPanel>
      </Box>
    </>
  );
};

export default Dashboard;
