import { ChevronLeft, East, West } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { MaterialUISwitch, StyledHeader } from "../../Common/styles/header";
import PollPreview from "./CMSPollPreview";
import CMSQuestionPreview from "./CMSQuestionPreview";
import { previewContainer, previewWrapper } from "./style";

import "../CMSwebview.scss";
import SpacedPreview from "./SpacedPreview";
import VideoTranscriptPreview from "./VideoTranscriptPreview";
import { getTranslation } from "../../../utils/webviewLang";

const Bookmark = ({ bookmarked, color }: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="cp"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z"
        stroke={color}
        fill={bookmarked ? color : "none"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CMSPreview = ({
  title,
  sections,
  handleBack,
  currentPage,
  total,
  handlePagination,
  isQuestionMandatory,
  finishHandler,
  position,
  moveToPage,
  isDarkMode,
  setMode,
  lang,
}: any) => {
  const topRef = useRef<HTMLDivElement>(null);
  const [hideButtons, setHideButtons] = useState(false);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollTop = 0;
    }
  }, [sections]);

  useLayoutEffect(() => {
    let spaced = false;
    sections.forEach((section: any) => {
      if (section.type === "spaced_learning") {
        spaced = true;
      }
    });
    setHideButtons(spaced);
  }, [setHideButtons, sections]);

  const showButtons = () => {
    setHideButtons(false);
  };

  const resourceHanlder = (url: string) => {
    window.open(url, "_blank");
  };

  const switchForm = (item: any) => {
    switch (item.type) {
      case "title":
        return (
          <div className="cms-title" key={item.id}>
            {item.value}
          </div>
        );
      case "subtitle":
        return (
          <div className="cms-subtitle" key={item.id}>
            {item.value}
          </div>
        );
      case "picture":
        return (
          <div className="cms-picture" key={item.id}>
            <div className="cms-media-label">{item.label}</div>
            <img src={item.value} alt={item.altText || "section"} />
          </div>
        );
      case "question":
        return (
          <div className="cms-question" key={item.id}>
            <CMSQuestionPreview
              questions={item.questions}
              lang={lang}
              isQuestionMandatory={isQuestionMandatory}
            />
          </div>
        );
      case "text":
        return (
          <div className="cms-text" key={item.id}>
            <div dangerouslySetInnerHTML={{ __html: item.value }}></div>
          </div>
        );
      case "resource":
        return (
          <div className="cms-resources" key={item.id}>
            {item.resources.map((res: any) => {
              return (
                <div key={res.key}>
                  <div
                    className="link"
                    onClick={() => resourceHanlder(res.value)}
                  >
                    {res.label}
                  </div>
                </div>
              );
            })}
          </div>
        );
      case "video":
        return (
          <div className="cms-video" key={item.id}>
            <div className="cms-media-label">{item.label}</div>
            <ReactPlayer
              className="education-video"
              controls
              // height={item?.format === "portrait" ? "560px" : "300px"}
              //316px is the width of preview container (360px) - padding - outline
              height={
                parseFloat(item?.format)
                  ? `calc(316px / ${parseFloat(item?.format)})`
                  : "300px"
              }
              width="100%"
              url={item.value}
            />
            {item.transcript && (
              <VideoTranscriptPreview
                transcript={item.transcript}
                lang={lang}
              />
            )}
          </div>
        );
      case "audio":
        return (
          <div className="cms-audio" key={item.id}>
            <div className="cms-media-label">{item.label}</div>
            <ReactPlayer
              className="education-audio"
              controls
              width="100%"
              url={item.value}
            />
          </div>
        );
      case "poll":
        return (
          <div className="cms-question" key={item.id}>
            <div className="question-container">
              <div className="question-title">{item.value}</div>
              <PollPreview choices={item.choices} />
            </div>
          </div>
        );
      case "spaced_learning":
        return (
          <div className="cms-question" key={item.id}>
            <SpacedPreview
              spacedQuestion={item.spacedLearning}
              showButtons={showButtons}
              handlePagination={handlePagination}
              isQuestionMandatory={isQuestionMandatory}
              isDarkMode={isDarkMode}
              lang={lang}
            />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <Box sx={{ height: "100%" }}>
      <StyledHeader>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ChevronLeft
            onClick={handleBack}
            sx={{ cursor: "pointer", height: 50, width: 50, mr: 1 }}
          />
          <Typography fontSize={24} fontWeight="medium">
            {title}
          </Typography>
        </Box>
        <MaterialUISwitch
          sx={{ m: 1 }}
          checked={isDarkMode}
          onChange={setMode}
        />
      </StyledHeader>
      <Box
        sx={previewWrapper}
        className={
          isDarkMode
            ? "cms-webview-wrapper preview dark"
            : "cms-webview-wrapper preview"
        }
      >
        <Box
          sx={previewContainer}
          className="cms-preview-container"
          ref={topRef}
        >
          <div className="cms-pagination">
            {[...Array(total)].map((_, index: number) => {
              const width = (100 - total * 1) / total;
              return (
                <div
                  onClick={() => moveToPage(index + 1)}
                  className={index + 1 <= currentPage ? "filled" : "unfilled"}
                  style={{ width: `${width}%` }}
                  key={index}
                />
              );
            })}
          </div>
          <div className="cms-unit-details">
            <div className="cms-unit-details-wrapper">
              <div className="unit-title">{title}</div>
              <div className="unit-position">
                {getTranslation("unit", lang) + " " + position}
              </div>
            </div>
            <Bookmark color={isDarkMode ? "#ffffff" : "#384670"} />
          </div>
          {sections?.map((section: any) => {
            return switchForm(section);
          })}
          <div className="button-container">
            <Button
              variant="contained"
              onClick={() => {
                if (currentPage !== 1) {
                  handlePagination(-1);
                }
              }}
              disabled={currentPage === 1}
              sx={{
                backgroundColor: isDarkMode ? "#2E2E2E" : "#EDEDEE",
                color: isDarkMode ? "#FFF" : "#181C62",
                "&:hover": {
                  backgroundColor: isDarkMode ? "#2E2E2E" : "#EDEDEE",
                  color: isDarkMode ? "#FFF" : "#181C62",
                },
              }}
              startIcon={
                <West
                // htmlColor={
                //   currentPage === 1
                //     ? "rgba(0, 0, 0, 0.26)"
                //     : isDarkMode
                //     ? "#fff"
                //     : "#181C62"
                // }
                />
              }
            >
              Back
            </Button>
            {!hideButtons &&
              (currentPage < total ? (
                <Button
                  onClick={() => handlePagination(1)}
                  endIcon={<East />}
                  variant="contained"
                >
                  {getTranslation("next", lang)}
                </Button>
              ) : (
                <Button onClick={finishHandler} variant="contained">
                  {getTranslation("finish", lang)}
                </Button>
              ))}
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default CMSPreview;
