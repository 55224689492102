import { useEffect, useState } from "react";
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  NoDataContainer,
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
  pageSize,
  paginationLabel,
} from "../../../../Common/styles/table";
import { DateTime } from "luxon";
import { errorToastMessage } from "../../../../../utils/toast";
import { useParams } from "react-router-dom";
import http from "../../../../../utils/http";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import {
  setJournalPage,
  setSortColumn,
  setSortOrder,
} from "../../../../../Redux/reducers/myPatientsSlice";
import JournalResponseModal from "./JournalResponseModal";

const PatientOwnDiary = () => {
  const { journalPage, sortColumn, sortOrder } = useAppSelector(
    (state) => state.myPatients
  );
  const dispatch = useAppDispatch();

  const [data, setData] = useState<any>([]);
  // const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  // const [sortOrder, setSortOrder] = useState("");
  // const [sortColumn, setSortColumn] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  const handleChangePage = (_: unknown, newPage: number) => {
    dispatch(setJournalPage(newPage));
    // setPage(newPage);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url = `/self-reflection-assignments/journal?type=own_diary&participantId=${id}&page=${
          journalPage + 1
        }&size=${pageSize}`;

        if (sortOrder && sortColumn) {
          url += `&order=${sortOrder}&sortBy=${sortColumn}`;
        }

        const res = await http.get(url);

        const newData = res?.data?.data?.rows?.map((item: any) => {
          return {
            id: item?.id,
            userTitle: item?.title,
            date: item?.createdAt
              ? DateTime.fromISO(item?.createdAt).toFormat("dd LLL yyyy")
              : "-",
            value: item?.value,
          };
        });
        setData(newData);
        setLoading(false);
        setTotalCount(res?.data?.data?.count);
      } catch (error) {
        errorToastMessage(error as Error);
        setLoading(false);
      }
    };
    fetchData();
  }, [
    setLoading,
    setData,
    setTotalCount,
    journalPage,
    id,
    sortOrder,
    sortColumn,
  ]);

  const handleCellClick = (row: any) => {
    setShowModal(true);
    setRowData(row);
  };

  const closeModal = () => {
    setShowModal(false);
    setRowData(null);
  };

  const handleSort = (order: string, column: string) => {
    if (sortColumn === column && sortOrder === order) {
      dispatch(setSortOrder(""));
      dispatch(setSortColumn(""));
    } else {
      dispatch(setSortOrder(order));
      dispatch(setSortColumn(column));
    }
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell width="50%">User title</StyledTableCell>
            {/* <StyledTableCell>Description</StyledTableCell> */}
            <StyledTableCell width="50%">
              Date
              <SortLabel column="createdAt" />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {!loading && data.length > 0 && (
          <>
            <TableBody>
              {data.map((item: any) => (
                <TableRow key={item.id}>
                  <StyledTableCell
                    width="50%"
                    onClick={() => handleCellClick(item)}
                    style={{ cursor: "pointer" }}
                  >
                    {item.userTitle || "-"}
                  </StyledTableCell>
                  {/* <StyledTableCell>{item.description || "-"}</StyledTableCell> */}
                  <StyledTableCell width="50%">{item.date}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {totalCount > pageSize && (
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={totalCount}
                    page={journalPage}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                )}
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {!loading && data.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No data available
          </Typography>
        </NoDataContainer>
      )}

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      {showModal && (
        <JournalResponseModal
          showModal={showModal}
          closeModal={closeModal}
          data={rowData}
          participantId={id}
        />
      )}
    </>
  );
};

export default PatientOwnDiary;
