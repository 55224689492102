import "react-quill/dist/quill.snow.css";
import CMSInput from "./CMSInput";
import { Box, Typography } from "@mui/material";
import { ContentItemWrapper } from "../styles";
import ContentText from "./ContentText";
import ResourceItem from "./ResourceItem";
import UploadItem from "./UploadItem";
import EducationPoll from "./EducationPoll";
import EducationQuestion from "./EducationQuestion";
import SpacedLearning from "./SpacedLearning";
import VideoTranscript from "./VideoTranscript";
import VideoFormat from "./VideoFormat";

type Props = {
  index: number;
  section: any;
};

const ContentItem: React.FC<Props> = ({ section, index }) => {
  const switchForm = () => {
    switch (section?.type) {
      case "title":
        return (
          <CMSInput
            section={section}
            index={index}
            header
            placeholder="Title"
            type="value"
            label="Title"
          />
        );
      case "subtitle":
        return (
          <CMSInput
            section={section}
            index={index}
            header
            placeholder="Subtitle"
            type="value"
            label="Subtitle"
          />
        );
      case "resource":
        return <ResourceItem section={section} index={index} />;
      case "audio":
        return (
          <>
            <Typography variant="h6" mb={2} fontWeight={"medium"}>
              {section.name}
            </Typography>
            <CMSInput
              section={section}
              index={index}
              placeholder="Audio Title"
              type="label"
              label="Audio Title"
            />
            <UploadItem
              section={section}
              index={index}
              label="Audio Upload"
              type="audio"
              accept="audio/*"
            />
            <Typography sx={{ textAlign: "center" }} variant="h6">
              OR
            </Typography>
            <CMSInput
              section={section}
              index={index}
              placeholder="Type your link here..."
              type="value"
              label="Audio Link"
            />
          </>
        );
      case "video":
        return (
          <>
            <Typography variant="h6" mb={2} fontWeight={"medium"}>
              {section.name}
            </Typography>
            <CMSInput
              section={section}
              index={index}
              placeholder="Video title"
              type="label"
              label="Video Title"
            />
            <UploadItem
              section={section}
              index={index}
              label="Video Upload"
              type="video"
              accept="video/*"
            />
            <Typography sx={{ textAlign: "center" }} variant="h6">
              OR
            </Typography>
            <CMSInput
              section={section}
              index={index}
              placeholder="Type your link here..."
              type="value"
              label="Video Link"
            />
            <VideoFormat section={section} index={index} />
            <VideoTranscript section={section} index={index} />
          </>
        );
      case "picture":
        return (
          <>
            <Typography variant="h6" mb={2} fontWeight={"medium"}>
              {section.name}
            </Typography>
            <CMSInput
              section={section}
              index={index}
              placeholder="Image title"
              type="label"
              label="Image Title"
            />
            <CMSInput
              section={section}
              index={index}
              placeholder="Image Alt text"
              type="altText"
              label="Image Alt-Text"
            />
            <UploadItem
              section={section}
              index={index}
              label="Image Upload"
              type="image"
              accept="image/*"
            />
            <Typography sx={{ textAlign: "center" }} variant="h6">
              OR
            </Typography>
            <CMSInput
              section={section}
              index={index}
              placeholder="Type your link here..."
              type="value"
              label="Image Link"
            />
          </>
        );
      case "text":
        return <ContentText section={section} index={index} />;
      case "poll":
        return (
          <>
            <Typography variant="h6" mb={2} fontWeight={"medium"}>
              {section.name}
            </Typography>
            <CMSInput
              section={section}
              index={index}
              placeholder="Poll title"
              type="value"
              label="Poll title"
            />
            <EducationPoll section={section} index={index} />
          </>
        );
      case "question":
        return <EducationQuestion section={section} index={index} />;
      case "spaced_learning":
        return (
          <>
            <Typography variant="h6" mb={2} fontWeight={"medium"}>
              {section.name}
              <SpacedLearning section={section} index={index} />
            </Typography>
          </>
        );
      default:
        return <>Unimplemented</>;
    }
  };

  return <Box sx={ContentItemWrapper}>{switchForm()}</Box>;
};

export default ContentItem;
