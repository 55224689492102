import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Clear,
  Delete,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useAppDispatch } from "../../../../Redux/hooks";
import {
  addAssessment,
  addAssessmentChoice,
  changeQuestionType,
  deleteAssessment,
  deleteAssessmentChoice,
  handleAssessmentChange,
  handleAssessmentChoiceChange,
  moveAssessment,
  setLoading,
} from "../../../../Redux/reducers/cmsBuilderSlice";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { uploadFile } from "../../../../utils/upload";
import { InputWrapper } from "../../../Common/styles/form";
import { ImageUploadIcon } from "../../Icons";
import {
  ArrayIconButtonStyle,
  ArrayIconButtonStyle2,
  CMSInputLabel,
  MiniUploadWrapper,
  UploadWrapper,
} from "../styles";
import QuestionAddon from "./QuestionAddon";

type Props = {
  section: any;
  index: number;
};

const EducationQuestion: React.FC<Props> = ({ section, index }) => {
  const dispatch = useAppDispatch();

  const addItem = () => {
    dispatch(addAssessment({ sectionIndex: index }));
  };

  return (
    <>
      {section.questions.map((question: any, questionIndex: number) => {
        return (
          <QuestionItem
            key={question.key}
            index={questionIndex}
            question={question}
            sectionIndex={index}
            totalQuestions={section.questions.length}
          />
        );
      })}
      <Button
        onClick={addItem}
        startIcon={<Add />}
        variant="contained"
        sx={{ mb: 2 }}
      >
        Add Assessment
      </Button>
    </>
  );
};

type UploadProps = {
  choiceIndex: number;
  questionIndex: number;
  sectionIndex: number;
  image: any;
};

const UploadItem: React.FC<UploadProps> = ({
  choiceIndex,
  questionIndex,
  sectionIndex,
  image,
}) => {
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          dispatch(setLoading(true));
          const url = await uploadFile(file, "education_lesson_image");
          dispatch(
            handleAssessmentChoiceChange({
              optionIndex: choiceIndex,
              questionIndex,
              sectionIndex,
              type: "image",
              value: url,
            })
          );
          dispatch(setLoading(false));
        }
      } catch (err) {
        dispatch(setLoading(false));
        errorToastMessage(err as Error);
      }
    },
    [sectionIndex, dispatch, questionIndex, choiceIndex]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  const clearImage = () => {
    dispatch(
      handleAssessmentChoiceChange({
        optionIndex: choiceIndex,
        questionIndex: questionIndex,
        sectionIndex: sectionIndex,
        type: "image",
        value: "",
      })
    );
  };

  return (
    <>
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...MiniUploadWrapper, mt: 4 }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <ImageUploadIcon />
          )}
        </Box>
      </Box>
      {image && (
        <IconButton
          onClick={clearImage}
          title="Clear image"
          sx={ArrayIconButtonStyle2}
          color="error"
        >
          <Clear />
        </IconButton>
      )}
    </>
  );
};

type QuestionItemProps = {
  question: any;
  questionIndex: number;
  sectionIndex: number;
  disableDelete?: boolean;
};

const MCQItem: React.FC<QuestionItemProps> = ({
  question,
  questionIndex,
  sectionIndex,
}) => {
  const dispatch = useAppDispatch();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    dispatch(
      handleAssessmentChange({
        questionIndex: questionIndex,
        sectionIndex: sectionIndex,
        value: event.target.value,
        type,
      })
    );
  };

  const handleAddonChange = (value: any, type: string) => {
    dispatch(
      handleAssessmentChange({
        questionIndex: questionIndex,
        sectionIndex: sectionIndex,
        value: value,
        type,
      })
    );
  };

  const handleOptionChange = (
    value: any,
    type: string,
    optionIndex: number
  ) => {
    dispatch(
      handleAssessmentChoiceChange({
        questionIndex: questionIndex,
        sectionIndex: sectionIndex,
        value: value,
        type,
        optionIndex,
        questionType: question?.type,
      })
    );
  };

  const addChoice = () => {
    dispatch(
      addAssessmentChoice({
        questionIndex,
        sectionIndex,
      })
    );
  };

  const deleteChoice = (choiceIndex: number) => {
    dispatch(
      deleteAssessmentChoice({
        questionIndex,
        sectionIndex,
        choiceIndex,
      })
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", mb: 2, alignItems: "center", gap: "20px" }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Correct Answer Explanation</FormLabel>
          <TextField
            fullWidth
            placeholder="Type your text here..."
            value={question.correctExplanation}
            onChange={(e) => handleChange(e, "correctExplanation")}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Wrong Answer Explanation</FormLabel>
          <TextField
            fullWidth
            placeholder="Type your text here..."
            value={question.wrongExplanation}
            onChange={(e) => handleChange(e, "wrongExplanation")}
          />
        </FormControl>
      </Box>
      <FormControl sx={{ ...InputWrapper, width: "auto" }}>
        <FormControlLabel
          sx={CMSInputLabel}
          label="Link to a Education module in case of incorrect answer"
          control={
            <Checkbox
              checked={question?.linkModule}
              onChange={(e) =>
                handleAddonChange(e.target.checked, "linkModule")
              }
            />
          }
        />
      </FormControl>
      {question?.linkModule && (
        <QuestionAddon
          handleAddonChange={handleAddonChange}
          question={question}
          editable={true}
        />
      )}
      {question.choices.map((choice: any, optionIndex: number) => {
        return (
          <Box
            key={choice.key}
            sx={{ display: "flex", mb: 2, alignItems: "center", gap: "10px" }}
          >
            <FormControl sx={InputWrapper}>
              <FormLabel sx={CMSInputLabel}>
                {"Answer Option " + (optionIndex + 1)}
              </FormLabel>
              <TextField
                fullWidth
                placeholder="Type your text here..."
                value={choice.label}
                onChange={(e) =>
                  handleOptionChange(e.target.value, "label", optionIndex)
                }
              />
            </FormControl>
            <Box sx={{ alignSelf: "flex-end", flexShrink: 0 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={choice.isCorrect}
                    onChange={(e) =>
                      handleOptionChange(
                        e.target.checked,
                        "isCorrect",
                        optionIndex
                      )
                    }
                  />
                }
                label="Check If option is correct"
              />
            </Box>
            <UploadItem
              choiceIndex={optionIndex}
              image={choice.image}
              questionIndex={questionIndex}
              sectionIndex={sectionIndex}
            />
            {optionIndex === 0 ? (
              <IconButton onClick={addChoice} sx={ArrayIconButtonStyle2}>
                <Add />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => deleteChoice(optionIndex)}
                color="error"
                sx={ArrayIconButtonStyle2}
              >
                <Delete />
              </IconButton>
            )}
          </Box>
        );
      })}
    </>
  );
};

// const EducationTimer: React.FC<QuestionItemProps> = ({
//   question,
//   questionIndex,
//   sectionIndex,
// }) => {
//   const dispatch = useAppDispatch();

//   const handleOptionChange = (value: any, type: string) => {
//     dispatch(
//       handleAssessmentChoiceChange({
//         questionIndex: questionIndex,
//         sectionIndex: sectionIndex,
//         value: value,
//         type,
//         optionIndex: 0,
//       })
//     );
//   };

//   const handleChange = (
//     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
//     type: string
//   ) => {
//     dispatch(
//       handleAssessmentChange({
//         questionIndex: questionIndex,
//         sectionIndex: sectionIndex,
//         value: event.target.value,
//         type,
//       })
//     );
//   };

//   return (
//     <>
//       <FormControl sx={InputWrapper}>
//         <FormLabel sx={CMSInputLabel}>Feedback (optional)</FormLabel>
//         <TextField
//           fullWidth
//           placeholder="Type your text here..."
//           value={question.correctExplanation}
//           onChange={(e) => handleChange(e, "correctExplanation")}
//         />
//       </FormControl>
//       <Box sx={{ display: "flex", my: 2, alignItems: "center", gap: "20px" }}>
//         <FormControl sx={InputWrapper}>
//           <FormLabel sx={CMSInputLabel}>Minutes</FormLabel>
//           <TextField
//             fullWidth
//             placeholder="minutes"
//             value={question.choices[0].minutes}
//             InputProps={{
//               type: "number",
//             }}
//             onChange={(e) => handleOptionChange(e.target.value, "minutes")}
//           />
//         </FormControl>
//         <FormControl sx={InputWrapper}>
//           <FormLabel sx={CMSInputLabel}>Seconds</FormLabel>
//           <TextField
//             fullWidth
//             InputProps={{
//               type: "number",
//             }}
//             placeholder="seconds"
//             value={question.choices[0].seconds}
//             onChange={(e) => handleOptionChange(e.target.value, "seconds")}
//           />
//         </FormControl>
//       </Box>
//     </>
//   );
// };

const EducationSlider: React.FC<QuestionItemProps> = ({
  question,
  questionIndex,
  sectionIndex,
}) => {
  const dispatch = useAppDispatch();

  const handleOptionChange = (value: any, type: string) => {
    dispatch(
      handleAssessmentChoiceChange({
        questionIndex: questionIndex,
        sectionIndex: sectionIndex,
        value: value,
        type,
        optionIndex: 0,
      })
    );
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    dispatch(
      handleAssessmentChange({
        questionIndex: questionIndex,
        sectionIndex: sectionIndex,
        value: event.target.value,
        type,
      })
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", my: 2, alignItems: "center", gap: "20px" }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Max value</FormLabel>
          <TextField
            fullWidth
            placeholder="Max Value"
            value={question.choices[0].maxValue}
            InputProps={{
              type: "number",
            }}
            onChange={(e) => handleOptionChange(e.target.value, "maxValue")}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Mid value (optional)</FormLabel>
          <TextField
            fullWidth
            InputProps={{
              type: "number",
            }}
            placeholder="Mid Value"
            value={question.choices[0].midValue}
            onChange={(e) => handleOptionChange(e.target.value, "midValue")}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Min value</FormLabel>
          <TextField
            fullWidth
            InputProps={{
              type: "number",
            }}
            placeholder="Min Value"
            value={question.choices[0].minValue}
            onChange={(e) => handleOptionChange(e.target.value, "minValue")}
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", my: 2, alignItems: "center", gap: "20px" }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Max Label</FormLabel>
          <TextField
            fullWidth
            placeholder="Max Label"
            value={question.choices[0].maxLabel}
            onChange={(e) => handleOptionChange(e.target.value, "maxLabel")}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Mid Label (optional)</FormLabel>
          <TextField
            fullWidth
            placeholder="Mid Label"
            value={question.choices[0].midLabel}
            onChange={(e) => handleOptionChange(e.target.value, "midLabel")}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Min Label</FormLabel>
          <TextField
            fullWidth
            placeholder="Min Label"
            value={question.choices[0].minLabel}
            onChange={(e) => handleOptionChange(e.target.value, "minLabel")}
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", my: 2, alignItems: "center", gap: "20px" }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>
            Cut off for Correct Explanation
          </FormLabel>
          <TextField
            fullWidth
            placeholder="Cutoff"
            value={question.choices[0].cutoff}
            InputProps={{
              type: "number",
            }}
            onChange={(e) => handleOptionChange(e.target.value, "cutoff")}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Correct Explanation</FormLabel>
          <TextField
            fullWidth
            placeholder="Correct Explanation"
            value={question.correctExplanation}
            onChange={(e) => handleChange(e, "correctExplanation")}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Wrong Explanation</FormLabel>
          <TextField
            fullWidth
            placeholder="Wrong Explanation"
            value={question.wrongExplanation}
            onChange={(e) => handleChange(e, "wrongExplanation")}
          />
        </FormControl>
      </Box>
    </>
  );
};

const EducationTitle: React.FC<QuestionItemProps> = ({
  question,
  questionIndex,
  sectionIndex,
  disableDelete,
}) => {
  const dispatch = useAppDispatch();

  const deleteItem = () => {
    dispatch(
      deleteAssessment({
        sectionIndex: sectionIndex,
        questionIndex: questionIndex,
      })
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      handleAssessmentChange({
        questionIndex: questionIndex,
        sectionIndex: sectionIndex,
        value: event.target.value,
        type: "title",
      })
    );
  };

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          dispatch(setLoading(true));
          const url = await uploadFile(file, "education_lesson_image");
          dispatch(
            handleAssessmentChange({
              questionIndex: questionIndex,
              sectionIndex: sectionIndex,
              value: url,
              type: "imageUrl",
            })
          );
          dispatch(setLoading(false));
        }
      } catch (err) {
        dispatch(setLoading(false));
        errorToastMessage(err as Error);
      }
    },
    [sectionIndex, questionIndex, dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <Box sx={{ width: "70%", mb: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "20px", mb: 2 }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Question Title</FormLabel>
          <TextField
            fullWidth
            placeholder="Type your text here..."
            value={question.title}
            onChange={handleChange}
          />
        </FormControl>
        <IconButton
          onClick={deleteItem}
          sx={ArrayIconButtonStyle2}
          color="error"
          disabled={disableDelete}
        >
          <Delete />
        </IconButton>
      </Box>
      <Box {...getRootProps({ className: "dropzone" })} sx={UploadWrapper}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {question.imageUrl ? (
            <Typography variant="subtitle1" fontWeight={"medium"}>
              File available. Drop Files to change
            </Typography>
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

type QuestionProps = {
  index: number;
  question: any;
  sectionIndex: number;
  totalQuestions: number;
};

const QuestionItem: React.FC<QuestionProps> = ({
  index,
  question,
  sectionIndex,
  totalQuestions,
}) => {
  const dispatch = useAppDispatch();

  const handleTypeChange = (event: SelectChangeEvent) => {
    dispatch(
      changeQuestionType({
        questionIndex: index,
        sectionIndex: sectionIndex,
        newType: event.target.value,
      })
    );
  };

  const rearrangeAssessment = (offset: number) => {
    dispatch(
      moveAssessment({
        questionIndex: index,
        sectionIndex: sectionIndex,
        offset,
      })
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <FormControl
          sx={{
            width: "300px",
            mb: 2,
          }}
        >
          <FormLabel sx={CMSInputLabel}>Assessment Type</FormLabel>
          <Select value={question.type} onChange={handleTypeChange}>
            <MenuItem value="multi_select">Multiple choice question</MenuItem>
            <MenuItem value="slider">Slider</MenuItem>
            {/* <MenuItem value="timer">Timer</MenuItem> */}
            <MenuItem value="single_select">Quiz</MenuItem>
            <MenuItem value="text">Text</MenuItem>
          </Select>
        </FormControl>
        <IconButton
          disabled={index === 0}
          onClick={() => rearrangeAssessment(-1)}
          sx={ArrayIconButtonStyle}
        >
          <ArrowUpward />
        </IconButton>
        <IconButton
          disabled={index === totalQuestions - 1}
          onClick={() => rearrangeAssessment(1)}
          sx={ArrayIconButtonStyle}
        >
          <ArrowDownward />
        </IconButton>
      </Box>
      <EducationTitle
        question={question}
        questionIndex={index}
        sectionIndex={sectionIndex}
        disableDelete={totalQuestions < 2}
      />
      {(question.type === "multi_select" ||
        question.type === "single_select") && (
        <MCQItem
          question={question}
          questionIndex={index}
          sectionIndex={sectionIndex}
          key={question.type + question.key}
        />
      )}
      {/* {question.type === "timer" && (
        <EducationTimer
          question={question}
          questionIndex={index}
          sectionIndex={sectionIndex}
          key={question.type + question.key}
        />
      )} */}
      {question.type === "slider" && (
        <EducationSlider
          question={question}
          questionIndex={index}
          sectionIndex={sectionIndex}
          key={question.type + question.key}
        />
      )}
    </>
  );
};

export default EducationQuestion;
