import React from "react";
import {
  // AudioFile,
  Close,
  Description,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { ClearAttachmentWrapper, MediaAttachmentContainer } from "./style";

const ChatAttachment = ({ attachment, clearAttachment }: any) => {
  return (
    <Box
      sx={{
        ...MediaAttachmentContainer,
        height: "70px",
        display: "flex",
        gap: 2,
        alignItems: "center",
      }}
    >
      {/* {attachment.type.includes("audio") ? (
        <AudioFile htmlColor="gray" sx={{ height: "45px", width: "45px" }} />
      ) : (
        <Description htmlColor="gray" sx={{ height: "45px", width: "45px" }} />
      )} */}
      <Description htmlColor="gray" sx={{ height: "45px", width: "45px" }} />

      <Typography variant="subtitle1" fontWeight="medium">
        {attachment?.name}
      </Typography>
      <Box sx={ClearAttachmentWrapper}>
        <IconButton onClick={clearAttachment}>
          <Close />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatAttachment;
