import { Button } from "@mui/material";
import { useState } from "react";
import { indxToAlpha } from "../../../utils/education";
import { toastMessage } from "../../../utils/toast";
import { getTranslation } from "../../../utils/webviewLang";

type Props = {
  spacedQuestion: any;
  showButtons: Function;
  handlePagination: Function;
  isDarkMode: boolean;
  lang: string;
  isQuestionMandatory: boolean;
};

const SpacedPreview: React.FC<Props> = ({
  spacedQuestion,
  showButtons,
  handlePagination,
  isDarkMode,
  isQuestionMandatory,
  lang,
}) => {
  const [answer, setAnswer] = useState<any[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [explanation, setExplanation] = useState("");

  const changeHandler = (id: string) => {
    if (spacedQuestion.allowMultipleSelection) {
      if (answer.includes(id)) {
        setAnswer(answer.filter((a) => a !== id));
      } else {
        setAnswer((pre) => [...pre, id]);
      }
    } else {
      setAnswer([id]);
    }
  };

  const submitAnswer = () => {
    if (answer.length === 0) {
      toastMessage("warning", "Select an answer to submit");
      return;
    }
    if (spacedQuestion.allowMultipleSelection) {
      const correct =
        spacedQuestion?.correctAnswer?.every((id: any) => {
          return answer?.includes(id);
        }) && spacedQuestion?.correctAnswer?.length === answer.length;
      correct ? setExplanation("correct") : setExplanation("wrong");
    } else {
      if (spacedQuestion.correctAnswer[0] === answer[0]) {
        setExplanation("correct");
      } else {
        setExplanation("wrong");
      }
    }
    showButtons();
    setSubmitted(true);
  };

  return (
    <div className="question-container">
      <div className="question-title">{spacedQuestion.title}</div>
      {spacedQuestion.imageUrl && (
        <img
          src={spacedQuestion.imageUrl}
          className="question-image"
          alt="question"
        />
      )}
      <div className="question-choices">
        {spacedQuestion.choices.map((choice: any, index: any) => {
          const selected = answer.includes(choice.id);
          return (
            <div
              key={choice.id}
              className={
                selected ? "question-choice selected" : "question-choice"
              }
              onClick={() => changeHandler(choice.id)}
            >
              {choice.imageUrl && (
                <img
                  src={choice.imageUrl}
                  className="question-choice-image"
                  alt="choice"
                />
              )}
              <span>{indxToAlpha(index) + ". " + choice.label}</span>
            </div>
          );
        })}
      </div>
      {isQuestionMandatory && (
        <div className="compulsory">
          {"(" + getTranslation("question_compulsory", lang) + ")"}
        </div>
      )}
      {submitted && explanation ? (
        explanation === "correct" ? (
          <>
            <div className={"explanation " + explanation}>
              {spacedQuestion?.correctExplanation}
            </div>
            <Button
              fullWidth
              onClick={() => handlePagination(2)}
              size="large"
              sx={{
                my: 1,
                backgroundColor: isDarkMode ? "#2E2E2E" : "#FFFFFF",
                color: isDarkMode ? "#fff" : "#181C62",
                textTransform: "none",
                height: "60px",
                fontWeight: 600,
                fontSize: "1.125rem",
              }}
              variant="outlined"
            >
              {getTranslation("skip_video", lang)}
            </Button>
            <Button
              fullWidth
              onClick={() => handlePagination(1)}
              sx={{
                my: 1,
                textTransform: "none",
                height: "60px",
                fontWeight: 600,
                fontSize: "1.125rem",
              }}
              variant="contained"
              size="large"
            >
              {getTranslation("watch_video", lang)}
            </Button>
          </>
        ) : (
          <div className={"explanation " + explanation}>
            {spacedQuestion?.wrongExplanation}
          </div>
        )
      ) : (
        <Button
          fullWidth
          onClick={submitAnswer}
          sx={{
            my: 2,
            height: "60px",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "1.125rem",
          }}
          variant="contained"
        >
          {getTranslation("show_answer", lang)}
        </Button>
      )}
    </div>
  );
};

export default SpacedPreview;
