import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { errorToastMessage } from "../../../../../utils/toast";
import { Box, CircularProgress, Typography } from "@mui/material";
import { historyCard } from "../../style";
import { useParams } from "react-router-dom";
import http from "../../../../../utils/http";

const PatientHistory = ({ refresh }: any) => {
  const [data, setData] = useState<any>([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const url = `/notes?participantId=${id}`;
        const res = await http.get(url);

        const newData = res.data.data.map((item: any) => {
          return {
            id: item.id,
            date: item?.createdAt
              ? DateTime.fromISO(item?.createdAt).toFormat(
                  "dd-MM-yyyy 'at' hh:mm a"
                )
              : "-",
            notes: item?.note || "-",
            smartGoal: item?.title || "-",
          };
        });
        setData(newData);
        setLoading(false);
      } catch (error) {
        errorToastMessage(error as Error);
        setLoading(false);
      }
    };
    fetchData();
  }, [id, refresh]);
  return (
    <>
      {data.length !== 0 && (
        <Typography variant="h6" fontWeight="medium" sx={{ p: "5px" }}>
          History
        </Typography>
      )}
      {!loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {data.map((item: any) => {
            return (
              <Box sx={historyCard} key={item.id}>
                <Typography
                  fontWeight="medium"
                  sx={{ color: "secondary.main", fontSize: "13px" }}
                >
                  {item.date}
                </Typography>
                <Box>
                  <Typography variant="body1" fontWeight="medium">
                    Smart Goal
                  </Typography>
                  <Typography variant="subtitle1" fontWeight="light">
                    {item?.smartGoal}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="body1" fontWeight="medium">
                    Notes
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight="light"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    {item?.notes}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={25} />
        </Box>
      )}
    </>
  );
};

export default PatientHistory;
