import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { errorToastMessage } from "../../../../../utils/toast";
import { StyledTableCell } from "../../../../Common/styles/table";
import { accTableBodyStyle, accTableStyle } from "./style";

const AccMap = ["frequency", "intensity", "time"];

const Analytics = () => {
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [data, setData] = useState<any>(null);

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded((prev) =>
        isExpanded ? [...prev, panel] : prev.filter((item) => item !== panel)
      );
    };

  useEffect(() => {
    try {
      setLoading(true);

      // setData({
      //   frequency: {
      //     id: "1",
      //     category: "fd",
      //     firstCons: "as",
      //     secondCons: "As",
      //     lastCons: "Sa",
      //     beg: "asw",
      //     median: "as",
      //   },
      //   intensity: {
      //     id: "1",
      //     category: "fd",
      //     firstCons: "as",
      //     secondCons: "As",
      //     lastCons: "Sa",
      //     beg: "asw",
      //     median: "as",
      //     educationModule: [
      //       {
      //         id: "1",
      //         category: "fd1",
      //         firstCons: "as",
      //         secondCons: "As",
      //         lastCons: "Sa",
      //         beg: "asw",
      //         median: "as",
      //       },
      //       {
      //         id: "2",
      //         category: "fd2",
      //         firstCons: "as",
      //         secondCons: "As",
      //         lastCons: "Sa",
      //         beg: "asw",
      //         median: "as",
      //       },
      //     ],
      //     conversation: [
      //       {
      //         id: "1",
      //         category: "fd1",
      //         firstCons: "as",
      //         secondCons: "As",
      //         lastCons: "Sa",
      //         beg: "asw",
      //         median: "as",
      //       },
      //       {
      //         id: "2",
      //         category: "fd2",
      //         firstCons: "as",
      //         secondCons: "As",
      //         lastCons: "Sa",
      //         beg: "asw",
      //         median: "as",
      //       },
      //     ],
      //     other: [
      //       {
      //         id: "1",
      //         category: "fd1",
      //         firstCons: "as",
      //         secondCons: "As",
      //         lastCons: "Sa",
      //         beg: "asw",
      //         median: "as",
      //       },
      //       {
      //         id: "2",
      //         category: "fd2",
      //         firstCons: "as",
      //         secondCons: "As",
      //         lastCons: "Sa",
      //         beg: "asw",
      //         median: "as",
      //       },
      //     ],
      //   },
      //   time: null,
      // });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  }, []);

  return !loading ? (
    <>
      {AccMap.map((item) => {
        const rowData = data?.[item];
        return (
          <Accordion
            key={item}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded.includes(`panel${item}`)}
            onChange={handleChange(`panel${item}`)}
            sx={{
              boxShadow: "none",
              border: "1px solid #E5E7EB",
              borderRadius: "8px !important",
              mb: 2.5,
            }}
          >
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <ExpandMore sx={{ fontSize: 24 }} />
                </IconButton>
              }
              sx={{
                ".Mui-expanded": {
                  m: "12px 0px !important",
                },
              }}
            >
              <Box
                sx={{
                  py: 1.5,
                  width: "100%",
                }}
              >
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="secondary"
                  mb={0.5}
                  sx={{ textTransform: "capitalize" }}
                >
                  {item}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                p: 2,
                display: "flex",
                gap: "16px",
                flexWrap: "wrap",
                mb: 2,
              }}
            >
              {data && data?.[item] ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ paddingBlock: "20px !important" }}>
                        Category
                      </StyledTableCell>
                      <StyledTableCell>{`1st consultation (Beg -> 1st)`}</StyledTableCell>
                      <StyledTableCell>{`2nd consultation (1st -> 2nd)`}</StyledTableCell>
                      <StyledTableCell>
                        Last Consultation (Until now)
                      </StyledTableCell>
                      <StyledTableCell>Beginning (Until now)</StyledTableCell>
                      <StyledTableCell>Median (IQR)</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={accTableBodyStyle}>
                    <TableRow>
                      <StyledTableCell>{rowData?.category}</StyledTableCell>
                      <StyledTableCell>{rowData?.firstCons}</StyledTableCell>
                      <StyledTableCell>{rowData?.secondCons}</StyledTableCell>
                      <StyledTableCell>{rowData?.lastCons}</StyledTableCell>
                      <StyledTableCell>{rowData?.beg}</StyledTableCell>
                      <StyledTableCell>{rowData?.median}</StyledTableCell>
                    </TableRow>
                    {rowData?.educationModule?.length && (
                      <>
                        <TableRow>
                          <StyledTableCell colSpan={6} sx={accTableStyle}>
                            Education Module
                          </StyledTableCell>
                        </TableRow>
                        {rowData?.educationModule?.map((row: any) => (
                          <TableRow key={row?.id}>
                            <StyledTableCell>{row?.category}</StyledTableCell>
                            <StyledTableCell>{row?.firstCons}</StyledTableCell>
                            <StyledTableCell>{row?.secondCons}</StyledTableCell>
                            <StyledTableCell>{row?.lastCons}</StyledTableCell>
                            <StyledTableCell>{row?.beg}</StyledTableCell>
                            <StyledTableCell>{row?.median}</StyledTableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                    {rowData?.conversation?.length && (
                      <>
                        <TableRow>
                          <StyledTableCell colSpan={6} sx={accTableStyle}>
                            Conversation
                          </StyledTableCell>
                        </TableRow>
                        {rowData?.educationModule?.map((row: any) => (
                          <TableRow key={row?.id}>
                            <StyledTableCell>{row?.category}</StyledTableCell>
                            <StyledTableCell>{row?.firstCons}</StyledTableCell>
                            <StyledTableCell>{row?.secondCons}</StyledTableCell>
                            <StyledTableCell>{row?.lastCons}</StyledTableCell>
                            <StyledTableCell>{row?.beg}</StyledTableCell>
                            <StyledTableCell>{row?.median}</StyledTableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                    {rowData?.other?.length && (
                      <>
                        <TableRow>
                          <StyledTableCell colSpan={6} sx={accTableStyle}>
                            Other
                          </StyledTableCell>
                        </TableRow>
                        {rowData?.educationModule?.map((row: any) => (
                          <TableRow key={row?.id}>
                            <StyledTableCell>{row?.category}</StyledTableCell>
                            <StyledTableCell>{row?.firstCons}</StyledTableCell>
                            <StyledTableCell>{row?.secondCons}</StyledTableCell>
                            <StyledTableCell>{row?.lastCons}</StyledTableCell>
                            <StyledTableCell>{row?.beg}</StyledTableCell>
                            <StyledTableCell>{row?.median}</StyledTableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    padding: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" color="gray">
                    No data available
                  </Typography>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  );
};

export default Analytics;
